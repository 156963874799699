<template>
  <div class="body-bg">
    <div class="enhanced-security">
      <header class="header-bg">
        <div class="header-container container">
          <a href="https://www.fhb.com/"
            ><img
              class="header-logo"
              srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
              src="@/assets/images/FHBColorLogo-2x.png"
              alt="First Hawaiian Bank home logo"
              title="FHB home"
          /></a>
        </div>
      </header>
      <div role="main">
        <div v-if="alertMessage" class="err-container">
          <div role="alert" class="boe-err-msg" id="otp-err-msg">
            <i class="fas fa-exclamation-triangle" style="color: #a1252e"></i>{{ alertMessage }}
          </div>
        </div>
        <div class="boe-section-header">
          <img class="center" :src="padlock" alt="" />
          <h1 id="enhanced-security-title">Enhanced Security</h1>
          <p>
            Aloha <strong>{{ givenName }} {{ surname }}</strong
            >,
          </p>
          <p>
            To confirm your identity, we will send a verification code to the mobile phone number or
            email address provided on your FHB Online &#38; Mobile Banking enrollment form.
          </p>
        </div>
        <div class="boe-form-row">
          <div class="boe-select-delivery-method">
            <p class="boe-delivery-method-label">
              <label for="credit-otp-method">Where should we send your code?</label>
            </p>
            <select
              id="credit-otp-method"
              name="otp-method"
              v-model="sentOTPMethodModel"
              @change="onChange($event)"
            >
              <option value="">Choose delivery method</option>
              <option value="email">email</option>
              <option value="mobile">mobile</option>
            </select>
          </div>
        </div>
        <div class="v-spacer-20" />
        <button
          id="send-code-btn"
          class="boe-button"
          :disabled="sentOTPMethodModel == ''"
          @click="sendCode"
        >
          Send Code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoApi from "@/app/ll-commercial-api"
import { mapActions, mapGetters } from "vuex"
// import Header from '@/components/Header'

export default {
  name: "SendCode",
  components: {
    // Header
  },
  computed: mapGetters("businessCreditOwner", ["ownerId", "appId"]),
  data() {
    return {
      username: "Temp",
      padlock: require("@/assets/images/padlock.svg"),
      givenName: "",
      surname: "",
      OTPMethods: "",
      sentOTPMethodModel: "",
      sentOTPMethod: "",
      alertMessage: "",
    }
  },
  created() {
    console.log(this.ownerId, this.appId)
  },
  methods: {
    ...mapActions("businessCreditOwner", [
      "updateLoading",
      "updateSentOTPMethod",
      "updateOtpValidated",
      "updateID",
      "updateUsername",
    ]),
    onChange(event) {
      console.log(event.target.value)
      document.cookie = "otp_delivery_method=" + event.target.value
    },
    async sendCode() {
      this.sentOTPMethod = this.sentOTPMethodModel
      await this.requestOTP()
      if (this.alertMessage === "") {
        this.updateSentOTPMethod(this.sentOTPMethod)
      }
    },

    async requestOTP() {
      this.updateLoading(true)
      const respOTPRequest = await UserInfoApi.getCreditAppOwnerOtp(
        this.appId,
        this.ownerId,
        this.sentOTPMethod
      )
      if (respOTPRequest.status === 403 && respOTPRequest.data.message === "otp_exceeded") {
        this.alertMessage = respOTPRequest.data.response
        this.updateSentOTPMethod("")
        this.updateLoading(false)
        return
      }
      if (respOTPRequest.status === 409) {
        this.alertMessage =
          "This link has expired. To complete your FHB Online & Mobile Banking enrollment please call us at (888) 643-4343, Monday - Friday between 8am-7pm HST, to request a new link."
        this.updateLoading(false)
        return
      }
      if (respOTPRequest.status !== 200) {
        this.alertMessage = "Error fetching data, please refresh and try again"

        console.error("Error fetching data, please refresh and try again")
        console.error(respOTPRequest)
        this.updateLoading(false)
        return
      }
      this.alertMessage = ""
      this.updateLoading(false)
      this.$router.push({ name: "Code Verification" })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

span-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.boe-section-header > h1 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
  color: $black;
}
.boe-section-header > p {
  margin: 0.35em 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
  color: $black;
}
.boe-form-row:not(:last-of-type) {
  margin-bottom: 25px;
}
.boe-select-delivery-method {
  color: $black;
  display: block;
  position: relative;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.boe-select-delivery-method > select {
  width: 100%;
  height: 50px;
  margin: auto;
  padding-left: 20px;
  opacity: 0.4;

  @include media-breakpoint-up(sm) {
    max-width: 300px;
  }
}

.boe-delivery-method-label {
  text-align: center;
  margin-bottom: 0.25em;
  position: relative;
}

.boe-button {
  width: auto;
  min-width: 15em;
  height: 40px;
  background-color: $red;
  color: $white;
  margin: 0px auto;
  border-radius: 20px;
  border-style: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0.95rem;
  padding: 0.25em 2.5em;
  position: relative;
  line-height: 1.5;
}

.boe-button:hover {
  cursor: pointer;
  background-color: $redHover;
}

.boe-button:focus {
  background-color: $redHover;
}

.boe-err-msg {
  margin-top: 9px;
  text-align: center;
  color: $redDark;
  font-size: 0.9em;
  background: #f8b9be;
  border-radius: 5px;
  /* border: 1px solid #A1252D; */
  padding: 12px;
}
.boe-err-msg i {
  margin-right: 10px;
}

.err-container {
  height: 60px;
}

.center {
  height: auto;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header-container a {
  margin: auto;
}

.header-container {
  align-items: center;
  text-align: center;
  display: flex;
  gap: 1em;
  height: 55px;
  flex-wrap: wrap;
  padding: 0.3em 0 0.5em;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    height: 80px;
    gap: 2em;
  }
}

.enhanced-security {
  background-color: $white;
  box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1em 2.5em 2.5em;
  @include media-breakpoint-up(sm) {
    margin: auto;
    max-width: 480px;
  }
  @include media-breakpoint-up(lg) {
    margin: auto;
    max-width: 580px;
  }
}

.body-bg {
  background-color: $orangeFaint;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    padding: 1em 2em;
  }
}
</style>
