<template>
  <div class="business-page" id="business-page-id">
    <Header />
    <Breadcrumb_credit_app />
    <MainId />

    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Business Banking Relationships</h1>

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="section-description">
            <h2>Business Deposit Information</h2>
            <p>
              Please list out all the relevant deposit accounts for the business. This will help us
              better understand your business.
            </p>
          </div>

          <!-- banking relation -->
          <div v-for="i in this.numBankingRelations" :key="i">
            <!-- financial institution name -->
            <div v-if="i != 1">
              <hr class="solid" />
              <div class="v-spacer-20" />
            </div>
            <div class="ll-row row1-container deposit-information">
              <div class="ll-item ll-fname">
                <div class="tf-label" v-bind:id="`commercial-bank-name-${i - 1}`">
                  <label :for="`relationships-fi-name-input-${i}`"
                    >Name of Financial Institution<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  :id="`relationships-fi-name-input-${i}`"
                  :aria-describedby="`cred-banking-fi-err-${i - 1}`"
                  name="relationships-fi-name-input"
                  :data-test="`ll-bc-account-name-${i - 1}`"
                  type="text"
                  v-model="this.banking_relation_names[i - 1]"
                  :class="
                    missing_bank_name[i - 1] || invalid_bank_name[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateBankName(i - 1)"
                  required
                />
                <div :id="`cred-banking-fi-err-${i - 1}`" class="ind-err-container err-min-height">
                  <div
                    v-if="missing_bank_name[i - 1] || invalid_bank_name[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-account-name-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid name</div>
                  </div>
                </div>
              </div>

              <!-- Account type -->
              <div class="ll-item ll-acct-type">
                <div class="tf-label" v-bind:id="`commercial-bank-type-${i - 1}`">
                  <label :for="`relationships-acct-type-input-${i}`"
                    >Account or Service Type<sup class="fhb-red">*</sup></label
                  >
                </div>
                <select
                  class="ll-select select-suffix"
                  name="banking-account-type"
                  :id="`relationships-acct-type-input-${i}`"
                  :aria-describedby="`cred-banking-acct-err-${i - 1}`"
                  :data-test="`ll-bc-account-type-${i - 1}`"
                  v-model="this.banking_relation_accounts[i - 1]"
                  @change="validateBankType(i - 1)"
                  required
                >
                  <option value="">Select Account Type</option>
                  <option
                    v-for="accType in account_types"
                    :value="accType.value"
                    v-bind:key="accType.key"
                  >
                    {{ accType.key }}
                  </option>
                </select>
                <div
                  :id="`cred-banking-acct-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="missing_bank_type[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-account-type-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div class="form-error-txt fieldErr">Please select an account type</div>
                  </div>
                </div>
              </div>

              <!-- Balance -->
              <div class="ll-item ll-balance" v-bind:id="`commercial-bank-balance-${i - 1}`">
                <div class="tf-label">
                  <label :for="`relationships-acct-bal-input-${i}`"
                    >Balance<sup class="fhb-red">*</sup></label
                  >
                </div>
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  name="relationships-acct-bal-input"
                  :aria-describedby="`cred-banking-bal-err-${i - 1}`"
                  :id="`relationships-acct-bal-input-${i}`"
                  v-model="this.banking_relation_balances[i - 1]"
                  :class="
                    missing_bank_balance[i - 1] || invalid_bank_balance[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateBankBalance(i - 1)"
                  :data-test="`ll-bc-account-balance-${i - 1}`"
                  required
                />
                <div :id="`cred-banking-bal-err-${i - 1}`" class="ind-err-container err-min-height">
                  <div
                    v-if="missing_bank_balance[i - 1] || invalid_bank_balance[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-account-balance-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter the balance for this account
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add row -->
              <!-- <div class="ll-item delete-container" v-if="i == this.numBankingRelations">
                <button v-if="i == this.numBankingRelations" type="button" class="add-btn " @click="addBankingRelation()"
                  :data-test="`ll-bc-add-account-btn`" id="add-account-button">
                  <span class="add-plus"></span>
                </button>
              </div> -->

              <!-- Delete Row -->
              <div class="ll-item delete-container" v-bind:id="`commercial-bank-delete-${i - 1}`">
                <button
                  v-if="i == this.numBankingRelations"
                  type="button"
                  class="add-btn"
                  @click="addBankingRelation(i)"
                  :data-test="`ll-bc-add-account-btn`"
                  id="add-account-button"
                >
                  <span class="add-plus"></span>
                </button>
                <button
                  v-if="this.numBankingRelations > 1"
                  type="button"
                  class="delete-button"
                  @click="initAccountDelete(i - 1)"
                  :data-test="`ll-bc-account-delete-account-btn-${i - 1}`"
                >
                  <img
                    class="delete-icon"
                    srcset="@/assets/images/trash-gray.png 1x, @/assets/images/trash-gray-2x.png 2x"
                    src="@/assets/images/trash-gray-2x.png"
                    alt=""
                  />
                  <span class="sr-only">Delete Account</span>
                </button>
              </div>
            </div>
          </div>

          <div class="v-spacer-20" />

          <div class="v-spacer-60" />

          <div class="section-description">
            <h2>Business Loan Information</h2>
            <p>
              Please list out all the relevant loan(s) for the business. This will help us better
              understand your business.
            </p>
          </div>

          <!-- Lender relation -->
          <div v-for="i in this.numLenderRelations" :key="i">
            <div class="v-spacer-20" />
            <div v-if="i != 1">
              <div class="v-spacer-10" />
              <hr class="solid" />
              <div class="v-spacer-20" />
            </div>
            <div class="ll-row row1-container loan-information">
              <!-- Lender name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" v-bind:id="`commercial-loan-name-${i - 1}`">
                  <label :for="`relationships-lender-name-input-${i}`">Name of Lender</label>
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  placeholder="Lender Name"
                  :id="`relationships-lender-name-input-${i}`"
                  name="relationships-lender-name-input"
                  :aria-describedby="`cred-banking-lender-err-${i - 1}`"
                  :data-test="`ll-bc-lender-name-${i - 1}`"
                  type="text"
                  v-model="this.lender_names[i - 1]"
                  :class="invalid_loan_name[i - 1] ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateLoanName(i - 1)"
                />
                <div
                  :id="`cred-banking-lender-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="invalid_loan_name[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-lender-name-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid name</div>
                  </div>
                </div>
              </div>

              <!-- Loan type -->
              <div class="ll-item ll-acct-type">
                <div class="tf-label" v-bind:id="`commercial-loan-type-${i - 1}`">
                  <label :for="`relationships-loan-type-input-${i}`">Type of Loan</label>
                </div>
                <select
                  class="ll-select select-suffix"
                  name="applicant_suffix"
                  :for="`relationships-loan-type-input-${i}`"
                  :data-test="`ll-bc-lender-loan-type-${i - 1}`"
                  v-model="this.lender_loan_types[i - 1]"
                >
                  <option value="">Select a Loan Type</option>
                  <option
                    v-for="loanType in loan_types"
                    :value="loanType.value"
                    v-bind:key="loanType.key"
                  >
                    {{ loanType.key }}
                  </option>
                </select>
              </div>

              <!-- Maturity Date -->
              <div class="field-group ll-date">
                <div class="tf-label" v-bind:id="`commercial-loan-date-${i - 1}`">
                  <label :for="`relationships-mat-date-input-${i}`">Maturity Date</label>
                </div>
                <birthday-input
                  class="ll-textfield tf-dob"
                  :id="`relationships-mat-date-input-${i}`"
                  :aria-describedby="`cred-banking-mdate-err-${i - 1}`"
                  name="relationships-mat-date-input"
                  v-model="this.lender_dates[i - 1]"
                  :class="invalid_loan_date[i - 1] ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateLoanDate(i - 1)"
                  :data-test="`ll-bc-lender-date-${i - 1}`"
                />
                <div
                  :id="`cred-banking-mdate-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="invalid_loan_date[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-lender-date-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid date (MM/DD/YYYY)
                    </div>
                  </div>
                </div>
              </div>

              <!-- Interest Rate -->
              <div class="ll-item ll-rate" v-bind:id="`commercial-loan-rate-${i - 1}`">
                <div class="tf-label">
                  <label :for="`relationships-int-rate-input-${i}`">Interest Rate</label>
                </div>
                <percentage-input
                  class="ll-textfield tf-addr-years"
                  type="text"
                  :id="`relationships-int-rate-input-${i}`"
                  :aria-describedby="`cred-banking-intrate-err-${i - 1}`"
                  name="relationships-int-rate-input"
                  v-model="this.lender_rates[i - 1]"
                  :class="invalid_loan_rate[i - 1] ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateLoanRate(i - 1)"
                  :data-test="`ll-bc-lender-rate-${i - 1}`"
                />
                <div
                  :id="`cred-banking-intrate-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="invalid_loan_rate[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-lender-rate-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter the interest rate as a percent for this loan
                    </div>
                  </div>
                </div>
              </div>

              <!-- Original Amount -->
              <div class="ll-item ll-amount" v-bind:id="`commercial-loan-amount-${i - 1}`">
                <div class="tf-label">
                  <label :for="`relationships-orig-amt-input-${i}`">Original Amount</label>
                </div>
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  :id="`relationships-orig-amt-input-${i}`"
                  name="relationships-orig-amt-input"
                  v-model="this.lender_amounts[i - 1]"
                  :data-test="`ll-bc-lender-amount-${i - 1}`"
                />
              </div>

              <!-- Monthly Payment -->
              <div class="ll-item ll-amount" v-bind:id="`commercial-loan-payment-${i - 1}`">
                <div class="tf-label">
                  <label :for="`relationships-monthly-payment-input-${i}`">Monthly Payment</label>
                </div>
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  :id="`relationships-monthly-payment-input-${i}`"
                  :aria-describedby="`cred-banking-mopay-err-${i - 1}`"
                  name="relationships-monthly-payment-input"
                  v-model="this.lender_payments[i - 1]"
                  :class="
                    missing_loan_payment[i - 1] || invalid_loan_payment[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateLoanPayment(i - 1)"
                  :data-test="`ll-bc-lender-payment-${i - 1}`"
                />
                <div
                  :id="`cred-banking-mopay-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="missing_loan_payment[i - 1] || invalid_loan_payment[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-lender-payment-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter the monthly payment for this loan
                    </div>
                  </div>
                </div>
              </div>

              <!-- Remaining Balance -->
              <div class="ll-item ll-amount" v-bind:id="`commercial-loan-balance-${i - 1}`">
                <div class="tf-label">
                  <label :for="`relationships-remaining-bal-input-${i}`">Remaining Balance</label>
                </div>
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  :id="`relationships-remaining-bal-input-${i}`"
                  :aria-describedby="`cred-banking-rbal-err-${i - 1}`"
                  v-model="this.lender_balances[i - 1]"
                  :class="
                    missing_loan_balance[i - 1] || invalid_loan_balance[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateLoanBalance(i - 1)"
                  :data-test="`ll-bc-lender-balance-${i - 1}`"
                />
                <div
                  :id="`cred-banking-rbal-err-${i - 1}`"
                  class="ind-err-container err-min-height"
                >
                  <div
                    v-if="missing_loan_balance[i - 1] || invalid_loan_balance[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-lender-balance-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter the remaining balance for this loan
                    </div>
                  </div>
                </div>
              </div>

              <!-- Secured/Unsecured -->
              <div class="ll-item ll-acct-type" v-bind:id="`commercial-loan-rate-${i - 1}`">
                <div class="tf-label" v-bind:id="`commercial-loan-type-${i - 1}`">
                  <label :for="`relationships-sec-input-${i}`">Is Secured</label>
                </div>
                <select
                  class="ll-select select-suffix"
                  name="applicant_suffix"
                  :id="`relationships-sec-input-${i}`"
                  :data-test="`ll-bc-lender-loan-type-${i - 1}`"
                  v-model="this.lender_is_secured[i - 1]"
                >
                  <option value="null">Select an option</option>
                  <option v-for="opt in is_secured_options" :value="opt.value" v-bind:key="opt.key">
                    {{ opt.key }}
                  </option>
                </select>
              </div>
              <!-- Collateral description -->
              <div
                class="ll-item ll-desc"
                v-if="this.lender_is_secured[i - 1] && this.lender_is_secured[i - 1] == true"
              >
                <div class="tf-label" v-bind:id="`commercial-loan-name-${i - 1}`">
                  <label :for="`relationships-collateral-type-input-${i}`"
                    >Type of Collateral</label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  :id="`relationships-collateral-type-input-${i}`"
                  placeholder="Description"
                  :data-test="`ll-bc-lender-name-${i - 1}`"
                  type="text"
                  v-model="this.lender_collateral_description[i - 1]"
                />
              </div>

              <!-- Delete entry -->
              <div class="ll-item delete-container" v-bind:id="`commercial-bank-delete-${i - 1}`">
                <button
                  v-if="i === this.numLenderRelations"
                  type="button"
                  class="add-btn"
                  @click="addLenderRelation(i)"
                  id="add-loan-button"
                  :data-test="`ll-bc-add-lender-btn`"
                >
                  <span class="add-plus"></span>
                </button>
                <button
                  type="button"
                  v-if="this.numLenderRelations > 1"
                  class="delete-button"
                  @click="initLoanDelete(i - 1)"
                  :data-test="`ll-bc-delete-lender-btn-${i - 1}`"
                >
                  <img
                    class="delete-icon"
                    srcset="@/assets/images/trash-gray.png 1x, @/assets/images/trash-gray-2x.png 2x"
                    src="@/assets/images/trash-gray-2x.png"
                    alt=""
                  />
                  <span class="sr-only">Delete Entry</span>
                </button>
              </div>
            </div>
          </div>

          <div class="v-spacer-20" />

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button
              type="button"
              class="back-btn"
              @click="handleBack()"
              :data-test="`ll-bc-btn-back`"
            >
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              id="ll-continue-btn"
              type="button"
              class="continue-btn"
              @click="handleContinue()"
              :data-test="`ll-bc-btn-continue`"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />
      </form>
    </div>

    <Footer />
  </div>
  <DeleteAccount
    v-if="showDeleteAccount"
    @close="closeDeleteAccount"
    @delete="deleteBankRelation"
    :accountName="'test'"
    :index="accountRowIndex"
  />
  <DeleteLoan
    v-if="showDeleteLoan"
    @close="closeDeleteLoan"
    :loanName="'test'"
    @delete="deleteLenderRelation"
    :index="loanRowIndex"
  />
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Constants from "@/app/business_constants"
import Validator from "@/app/validation"
import Header from "@/components/Header"
import Breadcrumb_credit_app from "@/views/business/credit_app/Breadcrumb_credit_app.vue"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import BirthdayInput from "@/components/CustomInputs/BirthdayInput.vue"
import MoneyInput from "../../../components/CustomInputs/MoneyInput.vue"
import PercentageInput from "../../../components/CustomInputs/PercentageInput.vue"
import DeleteAccount from "../../../components/Modals/DeleteAccount.vue"
import DeleteLoan from "../../../components/Modals/DeleteLoan.vue"
import utils from "../../../app/utils"
// import MoneyInput from '@/components/CustomInputs/MoneyInput.vue'

export default {
  name: "Getting Started",
  components: {
    Header,
    Breadcrumb_credit_app,
    MainId,
    Footer,
    BirthdayInput,
    MoneyInput,
    PercentageInput,
    DeleteAccount,
    DeleteLoan,
  },
  data() {
    return {
      account_types: Constants.AccountTypes,
      loan_types: Constants.LoanTypes,

      banking_relation_names: [],
      banking_relation_accounts: [],
      banking_relation_balances: [],

      lender_names: [],
      lender_loan_types: [],
      lender_dates: [],
      lender_rates: [],
      lender_payments: [],
      lender_amounts: [],
      lender_balances: [],
      lender_is_secured: [],
      lender_collateral_description: [],

      missing_bank_name: [],
      invalid_bank_name: [],
      missing_bank_type: [],
      missing_bank_balance: [],
      invalid_bank_balance: [],

      invalid_loan_name: [],
      invalid_loan_date: [],
      invalid_loan_rate: [],
      invalid_loan_payment: [],
      missing_loan_payment: [],
      missing_loan_balance: [],
      invalid_loan_balance: [],

      is_secured_options: [
        { key: "Secured", value: true },
        { key: "Unsecured", value: false },
      ],

      errCnt: 0,
      systemErrorMsg: "",
      showDeleteAccount: false,
      showDeleteLoan: false,
      accountRowIndex: null,
      loanRowIndex: null,
    }
  },
  created() {
    this.setCurrentPage(3)
    for (let i = 0; i < this.numBankingRelations; i++) {
      let rel = this.bankingRelations[i]
      this.banking_relation_names.push(rel.financial_institution)
      this.banking_relation_accounts.push(rel.account_type)
      this.banking_relation_balances.push(
        rel.balance ? Utils.formatStringFromMoney(rel.balance) : rel.balance
      )
      this.missing_bank_name.push(false)
      this.invalid_bank_name.push(false)
      this.missing_bank_type.push(false)
      this.missing_bank_balance.push(false)
      this.invalid_bank_balance.push(false)
    }
    for (let i = 0; i < this.numLenderRelations; i++) {
      let rel = this.lenderRelations[i]
      this.lender_names.push(rel.lender)
      this.lender_dates.push(rel.maturity_date)
      this.lender_rates.push(
        rel.interest_rate ? utils.formatPercentage(rel.interest_rate) : rel.interest_rate
      )
      this.lender_payments.push(
        rel.monthly_payment ? Utils.formatStringFromMoney(rel.monthly_payment) : rel.monthly_payment
      )
      this.lender_amounts.push(
        rel.original_amount ? Utils.formatStringFromMoney(rel.original_amount) : rel.original_amount
      )
      this.lender_balances.push(
        rel.remaining_balance
          ? Utils.formatStringFromMoney(rel.remaining_balance)
          : rel.remaining_balance
      )
      this.lender_loan_types.push(rel.loan_type)
      this.lender_is_secured.push(rel.is_secured)
      this.lender_collateral_description.push(rel.collateral_description)

      this.invalid_loan_name.push(false)
      this.invalid_loan_date.push(false)
      this.invalid_loan_rate.push(false)
      this.missing_loan_payment.push(false)
      this.invalid_loan_payment.push(false)
      this.missing_loan_balance.push(false)
      this.invalid_loan_balance.push(false)
    }
  },
  computed: {
    ...mapGetters("businessCreditApp", [
      "applicationID",
      "jwtToken",
      "currentPage",
      "businessBankingRelations",
      "businessLenderRelations",
      "businessNumBankingRelations",
      "businessNumLenderRelations",
    ]),
    bankingRelations: {
      get() {
        return this.businessBankingRelations
      },
      // set(value) { this.setBusinessBankingRelations(value) }
    },
    lenderRelations: {
      get() {
        return this.businessLenderRelations
      },
      // set(value) { this.setBusinessLenderRelations(value) }
    },
    numBankingRelations: {
      get() {
        return this.businessNumBankingRelations
      },
      set(value) {
        this.setBusinessNumBankingRelations(value)
      },
    },
    numLenderRelations: {
      get() {
        return this.businessNumLenderRelations
      },
      set(value) {
        this.setBusinessNumLenderRelations(value)
      },
    },
    banksContainErrors: {
      get() {
        if (this.missing_bank_name.includes(true)) return true
        if (this.invalid_bank_name.includes(true)) return true
        if (this.missing_bank_type.includes(true)) return true
        if (this.missing_bank_balance.includes(true)) return true
        if (this.invalid_bank_balance.includes(true)) return true
        return false
      },
    },
    loansContainErrors: {
      get() {
        if (this.invalid_loan_name.includes(true)) return true
        if (this.invalid_loan_date.includes(true)) return true
        if (this.invalid_loan_rate.includes(true)) return true
        if (this.invalid_loan_payment.includes(true)) return true
        if (this.missing_loan_payment.includes(true)) return true
        if (this.missing_loan_balance.includes(true)) return true
        if (this.invalid_loan_balance.includes(true)) return true
        return false
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("businessCreditApp", [
      "setCurrentPage",
      "setValidatedNavPage",
      "setBusinessBankingRelations",
      "appendBusinessBankingRelations",
      "setBusinessLenderRelations",
      "appendBusinessLenderRelations",
      "setBusinessNumBankingRelations",
      "setBusinessNumLenderRelations",
    ]),
    closeDeleteAccount(i) {
      this.showDeleteAccount = false
      const bg = document.getElementById("business-page-id")
      bg.setAttribute("aria-hidden", false)
      bg.removeAttribute("inert", "")
      this.$nextTick(() => {
        const field = document.getElementById(`relationships-fi-name-input-${i}`)
        field.focus()
      })
    },
    closeDeleteLoan(i) {
      this.showDeleteLoan = false
      const bg = document.getElementById("business-page-id")
      bg.setAttribute("aria-hidden", false)
      bg.removeAttribute("inert", "")
      this.$nextTick(() => {
        const field = document.getElementById(`relationships-lender-name-input-${i}`)
        field.focus()
      })
    },
    initAccountDelete(index) {
      this.showDeleteAccount = true
      this.accountRowIndex = index
      this.$nextTick(() => {
        const modal = document.getElementById("modal-container")
        modal.focus()
        const bg = document.getElementById("business-page-id")
        bg.setAttribute("aria-hidden", true)
        bg.setAttribute("inert", "")
      })
    },
    initLoanDelete(index) {
      this.showDeleteLoan = true
      this.loanRowIndex = index
      this.$nextTick(() => {
        const modal = document.getElementById("modal-container")
        modal.focus()
        const bg = document.getElementById("business-page-id")
        bg.setAttribute("aria-hidden", true)
        bg.setAttribute("inert", "")
      })
    },
    async putPage(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.putCreditAppPage(this.applicationID, payload, this.jwtToken)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          // update breadcrumb state
          this.setValidatedNavPage({ name: "relationship", isValid: 1 })

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Business Owners" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp?.data?.response?.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    formattedBankBalance(i) {
      let str = `${this.banking_relation_balances[i]}`
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedLoanRate(i) {
      let str = `${this.lender_rates[i]}`.replace("%", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedLoanPayment(i) {
      let str = `${this.lender_payments[i]}`
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedLoanAmount(i) {
      let str = `${this.lender_amounts[i]}`.replace("$", "").replaceAll(",", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedLoanBalance(i) {
      let str = `${this.lender_balances[i]}`
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    addBankingRelation(index) {
      for (var i = 0; i < this.numBankingRelations; i++) {
        this.validateAccount(i)
      }
      if (this.numBankingRelations <= 1 && this.isEmptyAccount(0)) return
      if (
        !this.banksContainErrors &&
        this.banking_relation_accounts[this.numBankingRelations - 1]
      ) {
        this.missing_bank_name.push(false)
        this.invalid_bank_name.push(false)
        this.missing_bank_type.push(false)
        this.missing_bank_balance.push(false)
        this.invalid_bank_balance.push(false)

        this.numBankingRelations += 1
      }
      this.$nextTick(() => {
        const field = document.getElementById(`relationships-fi-name-input-${index + 1}`)
        field.focus()
      })
    },
    addLenderRelation(index) {
      for (var i = 0; i < this.numLenderRelations; i++) {
        this.validateLoan(i)
      }
      if (this.numLenderRelations <= 1 && this.isEmptyLoan(0)) return
      if (!this.loansContainErrors && this.lender_loan_types[this.numLenderRelations - 1]) {
        this.invalid_loan_name.push(false)
        this.invalid_loan_date.push(false)
        this.invalid_loan_rate.push(false)
        this.missing_loan_payment.push(false)
        this.invalid_loan_payment.push(false)
        this.missing_loan_balance.push(false)
        this.invalid_loan_balance.push(false)

        this.numLenderRelations += 1
      }
      this.$nextTick(() => {
        const field = document.getElementById(`relationships-lender-name-input-${index + 1}`)
        field.focus()
      })
    },
    deleteBankRelation(i) {
      this.banking_relation_names.splice(i, 1)
      this.banking_relation_accounts.splice(i, 1)
      this.banking_relation_balances.splice(i, 1)
      this.missing_bank_name.splice(i, 1)
      this.invalid_bank_name.splice(i, 1)
      this.missing_bank_type.splice(i, 1)
      this.missing_bank_balance.splice(i, 1)
      this.invalid_bank_balance.splice(i, 1)

      this.numBankingRelations -= 1
      this.accountRowIndex = i
      this.closeDeleteAccount(i)
    },
    deleteLenderRelation(i) {
      this.lender_names.splice(i, 1)
      this.lender_loan_types.splice(i, 1)
      this.lender_dates.splice(i, 1)
      this.lender_rates.splice(i, 1)
      this.lender_payments.splice(i, 1)
      this.lender_amounts.splice(i, 1)
      this.lender_balances.splice(i, 1)
      this.lender_is_secured.splice(i, 1)
      this.lender_collateral_description.splice(i, 1)
      // errors
      this.invalid_loan_name.splice(i, 1)
      this.invalid_loan_date.splice(i, 1)
      this.invalid_loan_rate.splice(i, 1)
      this.missing_loan_payment.splice(i, 1)
      this.invalid_loan_payment.splice(i, 1)
      this.missing_loan_balance.splice(i, 1)
      this.invalid_loan_balance.splice(i, 1)

      this.numLenderRelations -= 1
      this.loanRowIndex = i
      this.closeDeleteLoan(i)
    },
    createBankingRelations() {
      for (var i = 0; i < this.banking_relation_names.length; i++) {
        var relation = {
          financial_institution: this.banking_relation_names[i],
          account_type: this.banking_relation_accounts[i],
          balance: this.formattedBankBalance(i),
        }
        if (i == 0) {
          this.setBusinessBankingRelations([relation])
        } else {
          this.appendBusinessBankingRelations(relation)
        }
      }
    },
    createLenderRelations() {
      for (var i = 0; i < this.lender_names.length; i++) {
        var relation = {
          lender: this.lender_names[i],
          loan_type: this.lender_loan_types[i],
          maturity_date: this.lender_dates[i],
          interest_rate: this.lender_rates[i] ? this.formattedLoanRate(i) : null,
          monthly_payment: this.lender_payments[i] ? this.formattedLoanPayment(i) : null,
          original_amount: this.lender_amounts[i] ? this.formattedLoanAmount(i) : null,
          remaining_balance: this.lender_balances[i] ? this.formattedLoanBalance(i) : null,
          is_secured: this.lender_is_secured[i],
          collateral_description: this.lender_collateral_description[i],
        }
        if (i == 0) {
          this.setBusinessLenderRelations([relation])
        } else {
          this.appendBusinessLenderRelations(relation)
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "Business Info" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")

      this.validatePage()

      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length
        if (!this.errCnt) {
          this.createBankingRelations()
          this.createLenderRelations()
          let payload = {
            page: 3,

            deposits: this.bankingRelations,
            loans: this.lenderRelations,
          }
          this.putPage(payload)
        } else {
          this.setValidatedNavPage({ name: "relationship", isValid: 0 })
          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missing_bank_name.includes(true) || this.invalid_bank_name.includes(true)) {
              let i = this.indexOfError(this.missing_bank_name, this.invalid_bank_name)
              offset = jQuery(`#commercial-bank-name-${i}`).offset().top
            } else if (this.missing_bank_type.includes(true)) {
              let i = this.indexOfError(this.missing_bank_type)
              offset = jQuery(`#commercial-bank-type-${i}`).offset().top
            } else if (
              this.missing_bank_balance.includes(true) ||
              this.invalid_bank_balance.includes(true)
            ) {
              let i = this.indexOfError(this.missing_bank_balance, this.invalid_bank_balance)
              offset = jQuery(`#commercial-bank-balance-${i}`).offset().top
            } else if (this.invalid_loan_name.includes(true)) {
              let i = this.indexOfError(this.invalid_loan_name)
              offset = jQuery(`#commercial-loan-name-${i}`).offset().top
            } else if (this.invalid_loan_date.includes(true)) {
              let i = this.indexOfError(this.invalid_loan_date)
              offset = jQuery(`#commercial-loan-date-${i}`).offset().top
            } else if (this.invalid_loan_rate.includes(true)) {
              let i = this.indexOfError(this.invalid_loan_rate)
              offset = jQuery(`#commercial-loan-rate-${i}`).offset().top
            } else if (
              this.missing_loan_payment.includes(true) ||
              this.invalid_loan_payment.includes(true)
            ) {
              let i = this.indexOfError(this.missing_loan_payment, this.invalid_loan_payment)
              offset = jQuery(`#commercial-loan-payment-${i}`).offset().top
            } else if (
              this.missing_loan_balance.includes(true) ||
              this.invalid_loan_balance.includes(true)
            ) {
              let i = this.indexOfError(this.missing_loan_balance, this.invalid_loan_balance)
              offset = jQuery(`#commercial-loan-balance-${i}`).offset().top
            }

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      })
    },
    indexOfError(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] == true) return i
      }
      if (arr2) {
        for (let i = 0; i < arr2.length; i++) {
          if (arr2[i] == true) return i
        }
      }
    },
    validatePage() {
      this.validateAccount(0)
      this.validateLoan(0)
      for (let i = 1; i < this.numBankingRelations; i++) {
        if (
          this.banking_relation_names[i] ||
          this.banking_relation_accounts[i] ||
          this.banking_relation_balances[i]
        ) {
          this.validateAccount(i)
        }
      }
    },
    validateAccount(i) {
      if (this.isEmptyAccount(i)) return
      this.validateBankName(i)
      this.validateBankBalance(i)
      this.validateBankType(i)
    },
    validateLoan(i) {
      if (this.isEmptyLoan(i)) return
      this.validateLoanName(i)
      this.validateLoanDate(i)
      this.validateLoanRate(i)
      this.validateLoanPayment(i)
      this.validateLoanAmount(i)
      this.validateLoanBalance(i)
    },
    isEmptyAccount(i) {
      return (
        this.banking_relation_names[i] == "" &&
        this.banking_relation_balances[i] == "" &&
        this.banking_relation_accounts[i] == ""
      )
    },
    isEmptyLoan(i) {
      return (
        this.lender_names[i] == "" &&
        this.lender_loan_types[i] == "" &&
        this.lender_dates[i] == "" &&
        this.lender_rates[i] == null &&
        this.lender_payments[i] == null &&
        this.lender_amounts[i] == null &&
        this.lender_balances[i] == null &&
        this.lender_is_secured[i] == null
      )
    },
    validateBankName(i) {
      this.banking_relation_names[i] = this.banking_relation_names[i]
        ? this.banking_relation_names[i].trim()
        : ""
      this.missing_bank_name[i] = false
      this.invalid_bank_name[i] = false
      if (this.isEmptyAccount(i)) return
      this.missing_bank_name[i] = this.banking_relation_names[i] ? false : true
      if (!this.missing_bank_name[i]) {
        this.invalid_bank_name[i] = Validator.isValidName(this.banking_relation_names[i])
          ? false
          : true
      }
    },
    validateBankType(i) {
      this.missing_bank_type[i] = false
      if (this.isEmptyAccount(i)) return
      this.missing_bank_type[i] = this.banking_relation_accounts[i] ? false : true
    },
    validateBankBalance(i) {
      this.missing_bank_balance[i] = false
      this.invalid_bank_balance[i] = false
      if (this.isEmptyAccount(i)) return
      this.missing_bank_balance[i] = this.banking_relation_balances[i] ? false : true
      if (!this.missing_bank_balance[i]) {
        this.invalid_bank_balance[i] =
          `${this.banking_relation_balances[i]}`.replace("$", "") == "0"
      }
    },
    validateLoanName(i) {
      this.lender_names[i] = this.lender_names[i] ? this.lender_names[i].trim() : ""
      this.invalid_loan_name[i] = false
      if (this.isEmptyLoan(i)) return
      if (this.lender_names[i]) {
        this.invalid_loan_name[i] = Validator.isValidName(this.lender_names[i]) ? false : true
      }
    },
    validateLoanDate(i) {
      this.invalid_loan_date[i] = false
      if (this.isEmptyLoan(i)) return
      if (this.lender_dates[i]) {
        this.invalid_loan_date[i] = Validator.isValidDate(this.lender_dates[i]) ? false : true
      }
    },
    validateLoanRate(i) {
      this.invalid_loan_rate[i] = false
      if (this.isEmptyLoan(i)) return
      if (this.lender_rates[i]) {
        let rate = `${this.lender_rates[i]}`.replaceAll("%", "")
        let fl = parseFloat(rate)
        let rateFl = +fl
        this.invalid_loan_rate[i] = rateFl > parseFloat("100") ? true : false
      }
    },
    validateLoanPayment(i) {
      this.missing_loan_payment[i] = false
      this.invalid_loan_payment[i] = false
      if (this.isEmptyLoan(i)) return
      if (!this.lender_amounts[i]) return
      this.missing_loan_payment[i] = this.lender_payments[i] ? false : true
      if (!this.missing_loan_payment[i] && this.lender_amounts[i]) {
        let paymentfl = parseFloat(
          `${this.lender_payments[i]}`.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")
        )
        let payment = +paymentfl
        let amountfl = parseFloat(
          `${this.lender_amounts[i]}`.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")
        )
        let amount = +amountfl
        this.invalid_loan_payment[i] = payment > amount || payment == parseFloat("0")
      }
    },
    validateLoanAmount(i) {
      if (this.isEmptyLoan(i)) return
      if (this.lender_amounts[i] && this.lender_payments[i]) {
        this.validateLoanPayment(i)
      }
      if (this.lender_amounts[i] && this.lender_balances[i]) {
        this.validateLoanBalance(i)
      }
    },
    validateLoanBalance(i) {
      this.missing_loan_balance[i] = false
      this.invalid_loan_balance[i] = false
      if (this.isEmptyLoan(i)) return
      if (!this.lender_amounts[i]) return
      this.missing_loan_balance[i] = this.lender_balances[i] ? false : true
      // if (!this.missing_loan_balance[i] && this.lender_amounts[i]) {
      //   let balancefl = parseFloat(this.lender_balances[i].replaceAll("$","").replaceAll(",", "").replaceAll(" ", ""))
      //   let balance = +balancefl
      //   let amountfl = parseFloat(this.lender_amounts[i].replaceAll("$","").replaceAll(",", "").replaceAll(" ", ""))
      //   let amount = +amountfl
      //   this.invalid_loan_balance[i] = (balance > amount) || (balance == parseFloat("0"))
      // }
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  & + .ll-row {
    margin-top: 2em;
  }
}

.ll-item {
  position: relative;
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}

.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.row2-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.add-btn {
  align-items: center;
  background: $white;
  border: 1px solid $grayDark;
  border-radius: 100%;
  font-weight: 700;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  display: inline-block;
  &:hover,
  &:focus {
    background: $yellowHover;
    border: 1px solid $yellowHover;
    cursor: pointer;
  }
}

.delete-button {
  height: 30px;
  width: 30px;
  align-content: center;
  border-radius: 50%;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border: 0;
  margin-right: 0px;
  &:hover,
  &:focus {
    background: $redFaint;
    //border: 1px solid $grayDark;
  }

  .delete-icon {
    height: auto;
    width: 14px;
  }
}

.delete-button:hover {
  cursor: pointer;
}

.section-description {
  margin-bottom: 1em;
  h2 {
    margin-bottom: 0;
  }
  h2 + p {
    margin-bottom: 0;
    margin-top: 0.25em;
  }
}

.row1-container.deposit-information {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
  @include media-breakpoint-up(md) {
    .ll-fname {
      flex: 1;
    }

    .ll-balance {
      flex-basis: 18em;
    }
  }
  @include media-breakpoint-only(md) {
    .ll-fname {
      min-width: 100%;
    }
  }
}

.row1-container.loan-information {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
  @include media-breakpoint-up(md) {
    .ll-fname {
      min-width: 11em;
    }

    .ll-balance {
      flex-basis: 18em;
    }

    .ll-rate {
      width: 8em;
    }

    .ll-amount {
      width: 11em;
    }

    .ll-desc {
      flex: 1;
    }

    .ll-acct-type {
      width: 12em;
    }

    .ll-date {
      width: 9em;
    }
  }
  @include media-breakpoint-only(md) {
    .ll-fname {
      min-width: 100%;
    }
  }
}

.delete-container {
  display: flex;
  align-items: center;
  max-width: 82px !important;
  width: 100%;
  padding: 0px 1px;
  button {
    margin: 0 5px;
  }
}
.err-min-height {
  min-height: 24px;
}
</style>
