<template>
  <div class="filer-page">
    <Header />
    <Breadcrumb_credit_app />
    <MainId />

    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Tell Us About Yourself</h1>
          <div class="tf-note fhb-red txt-bold">
            This may be filled out by someone other than the owner.
          </div>
          <div class="tf-note">
            Required fields are indicated by an asterisk (<span class="fhb-red"
              ><strong>*</strong></span
            >)
          </div>

          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="field-group">
            <!-- Loan Amount -->
            <div class="ll-item ll-fname">
              <div class="tf-label" id="business-label-loan-amount">
                <label for="buscred-filer-loanamt-input"
                  >Requested Loan Amount<sup class="fhb-red">*</sup></label
                >
              </div>
              <money-input
                maxlength="512"
                class="ll-textfield tf-fname"
                data-test="ll-bc-filer-fname"
                type="text"
                id="buscred-filer-loanamt-input"
                aria-describedby="cred-filer-lamt-err"
                name="loan-amount"
                :disabled="this.processing"
                v-model="loanAmt"
                :class="
                  missingLoanAmount || invalidLoanAmount ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateLoanAmount"
                required
              />
              <div id="cred-filer-lamt-err" class="ind-err-container">
                <div
                  v-if="missingLoanAmount || invalidLoanAmount"
                  class="form-error-msg"
                  data-test="ll-bc-filer-fname-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a Loan Amount</div>
                </div>
              </div>
            </div>
            <div class="v-spacer-60" />
            <div class="ll-row row1-container">
              <!-- First Name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" id="business-label-filer-fname">
                  <label for="buscred-filer-fname-input"
                    >First Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  data-test="ll-bc-filer-fname"
                  type="text"
                  id="buscred-filer-fname-input"
                  aria-describedby="cred-filer-fname-err"
                  name="fname"
                  :disabled="this.processing"
                  v-model="filerFName"
                  :class="missingFName || invalidFName ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateFName"
                  required
                />
                <div id="cred-filer-fname-err" class="ind-err-container">
                  <div
                    v-if="missingFName || invalidFName"
                    class="form-error-msg"
                    data-test="ll-bc-filer-fname-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
              </div>
              <!-- Middle Initial -->
              <div class="ll-item ll-middle-initial">
                <div class="tf-label" id="business-label-filer-mi">
                  <label for="buscred-filer-mi-input">MI</label>
                </div>
                <input
                  maxlength="1"
                  class="ll-textfield tf-middle-initial"
                  data-test="ll-bc-filer-mi"
                  type="text"
                  id="buscred-filer-loanamt-input"
                  aria-describedby="cred-filer-mi-err"
                  name="middle-initial"
                  :disabled="this.processing"
                  v-model="filerMI"
                  :class="filerMI && invalidMName ? 'll-textfield-error' : 'll-textfield'"
                  @input="validateMI"
                />
                <div id="cred-filer-mi-err" class="ind-err-container">
                  <div
                    v-if="filerMI && invalidMI"
                    class="form-error-msg"
                    data-test="ll-bc-filer-mi-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid middle initial
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <!-- Last Name -->
            <div class="ll-item ll-lname">
              <div class="tf-label" id="business-label-filer-lname">
                <label for="buscred-filer-lname-input">Last Name<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-lname"
                type="text"
                id="buscred-filer-lname-input"
                aria-describedby="cred-filer-lname-err"
                name="lname"
                data-test="ll-bc-filer-lname"
                :disabled="this.processing"
                v-model="filerLName"
                :class="missingLName || invalidLName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateLName"
                required
              />
              <div id="cred-filer-lname-err" class="ind-err-container">
                <div
                  v-if="missingLName || invalidLName"
                  class="form-error-msg"
                  data-test="ll-bc-filer-lname-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />
          <div class="ll-row row2-container">
            <!-- Email -->
            <div>
              <div class="tf-label" id="business-label-filer-email">
                <label for="buscred-filer-email-input">Email<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                data-test="ll-bc-filer-email"
                class="ll-textfield"
                type="text"
                id="buscred-filer-email-input"
                aria-describedby="cred-filer-email-hint cred-filer-email-err"
                name="email"
                v-model="email"
                :disabled="this.processing"
                :class="missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateEmail"
                required
              />
              <div id="cred-filer-email-hint" class="tf-note">
                To contact you about your inquiry and to send you special offers/information. You
                may opt out of marketing communications at any time.
              </div>
              <div id="cred-filer-email-err" class="ind-err-container">
                <div
                  v-if="missingEmail || invalidEmail"
                  class="form-error-msg"
                  data-test="ll-bc-filer-email-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid email</div>
                </div>
              </div>
            </div>
            <!-- Phone Number -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-filer-phone">
                <label for="buscred-filer-mobile-input"
                  >Mobile Phone Number<sup class="fhb-red">*</sup></label
                >
              </div>
              <div class="ll-prefix-wrap">
                <phone-input
                  class="ll-textfield tf-phone"
                  id="buscred-filer-mobile-input"
                  name="mobile-phone"
                  aria-describedby="filer-us-phone-note cred-filer-tel-err"
                  v-model="filerPhone"
                  :disabled="this.processing"
                  :class="missingPhone || invalidPhone ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validatePhone"
                  data-test="ll-bc-filer-phone"
                  required
                />
              </div>
              <div id="filer-us-phone-note" class="tf-note">
                Please provide a U.S. phone number.
              </div>
              <div id="cred-filer-tel-err" class="ind-err-container">
                <div
                  v-if="missingPhone || invalidPhone"
                  class="form-error-msg"
                  data-test="ll-bc-filer-phone-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <!-- Company Structure -->
          <div class="ll-row">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-company-structure">
                <label for="buscred-filer-costructure-input"
                  >What is your company structure?<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                class="ll-select"
                id="buscred-filer-costructure-input"
                aria-describedby="cred-filer-costr-err"
                name="company_stucture"
                v-model="businessCompanyStructure"
                :disabled="this.processing"
                :class="missingCompanyStructure ? 'll-select-error' : 'll-select'"
                required
                @change="validateBusinessCompanyStructure($event)"
                data-test="cb-business-structure"
              >
                <option value="">Select Your Company Structure</option>
                <option v-for="pos in company_structure" :value="pos.value" v-bind:key="pos.key">
                  {{ pos.key }}
                </option>
              </select>
              <div id="cred-filer-costr-err" class="ind-err-container">
                <div
                  v-if="missingCompanyStructure"
                  class="form-error-msg"
                  data-test="cb-business-structure-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a company structure</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- Company Position -->
          <div v-if="this.businessCompanyStructure">
            <div
              v-if="businessCompanyStructure !== 'sole' && businessCompanyStructure !== 'other'"
              class="ll-item ll-state"
            >
              <div class="v-spacer-10" />
              <div class="tf-label" id="business-label-filer-position">
                <label for="buscred-filer-position-input"
                  >What role best represents your position in the Company?<sup class="fhb-red"
                    >*</sup
                  ></label
                >
              </div>
              <select
                v-if="
                  this.businessCompanyStructure === 'corp' ||
                  this.businessCompanyStructure === 'prof-corp'
                "
                class="ll-select select-state"
                name="us_state"
                id="buscred-filer-position-input"
                aria-describedby="cred-filer-pos-err"
                v-model="filerPosition"
                :disabled="this.processing"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="ll-bc-filer-state"
              >
                <option value="">Select Your Position</option>
                <option value="N/A">N/A</option>
                <option
                  v-for="pos in company_positions_corp"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'partnership' ||
                  this.businessCompanyStructure === 'limited'
                "
                class="ll-select select-state"
                name="us_state"
                id="buscred-filer-position-input"
                aria-describedby="cred-filer-pos-err"
                v-model="filerPosition"
                :disabled="this.processing"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="ll-bc-filer-state"
              >
                <option value="">Select Your Position</option>
                <option value="N/A">N/A</option>
                <option
                  v-for="pos in company_positions_partnership"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'llc' ||
                  this.businessCompanyStructure === 'single-llc'
                "
                class="ll-select select-state"
                name="us_state"
                id="buscred-filer-position-input"
                aria-describedby="cred-filer-pos-err"
                v-model="filerPosition"
                :disabled="this.processing"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="ll-bc-filer-state"
              >
                <option value="">Select Your Position</option>
                <option value="N/A">N/A</option>
                <option
                  v-for="pos in company_positions_LLC"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <div id="cred-filer-pos-err" class="ind-err-container">
                <div
                  v-if="missingPosition"
                  class="form-error-msg"
                  data-test="ll-bc-filer-state-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a position</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Are you an owner? CB -->
          <div class="v-spacer-30" />
          <div class="ll-item ll-consent">
            <label for="owner-cb" id="business-label-filer-owner">
              <input
                :disabled="this.processing"
                class="cb-consent"
                type="checkbox"
                id="owner-cb"
                name="owner-cb"
                value="owner-cb"
                @click="isOwner()"
                data-test="ll-bc-filer-owner-cb"
              />
              <span
                ><strong>I am an owner</strong><br />Do not check this if you are filling this on
                behalf of someone else</span
              >
            </label>
          </div>

          <div class="v-spacer-30" />

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button
              :disabled="this.processing"
              type="button"
              class="back-btn"
              @click="handleBack()"
              data-test="ll-bc-btn-back"
            >
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              :disabled="this.processing"
              :class="'continue-btn'"
              id="ll-continue-btn"
              type="button"
              @click="handleContinue()"
              data-test="ll-bc-btn-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Constants from "@/app/business_constants"
import Validator from "@/app/validation"
import Header from "@/components/Header"
import Breadcrumb_credit_app from "@/views/business/credit_app/Breadcrumb_credit_app.vue"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import PhoneInput from "@/components/CustomInputs/PhoneInput.vue"
import MoneyInput from "@/components/CustomInputs/MoneyInput.vue"

// import MoneyInput from '@/components/CustomInputs/MoneyInput.vue'

export default {
  name: "Getting Started",
  components: {
    Header,
    Breadcrumb_credit_app,
    MainId,
    Footer,
    PhoneInput,
    MoneyInput,
  },
  data() {
    return {
      company_structure: Constants.CompanyStructure,
      company_positions_corp: Constants.CompanyPositionsCorp,
      company_positions_LLC: Constants.CompanyPositionsLLC,
      company_positions_partnership: Constants.CompanyPositionsPartnership,

      processing: false,
      errCnt: 0,
      systemErrorMsg: "",

      missingLoanAmount: false,
      invalidLoanAmount: false,

      missingFName: false,
      invalidFName: false,

      invalidMName: false,

      missingLName: false,
      invalidLName: false,

      missingEmail: false,
      invalidEmail: false,

      missingPhone: false,
      invalidPhone: false,

      missingPosition: false,

      missingCompanyStructure: false,
    }
  },
  created() {
    this.setCurrentPage(1)
  },
  computed: {
    ...mapGetters("businessCreditApp", [
      "currentPage",
      "loanAmount",
      "filerFirstName",
      "filerMiddleInitial",
      "companyStructure",
      "filerLastName",
      "filerEmail",
      "filerPhoneNumber",
      "filerCompanyPosition",
      "filerAgreeToTerms",
      "filerIsOwner",
    ]),
    ...mapGetters("questionnaire", ["qid"]),
    convertedLoanAmount: {
      get() {
        return this.formatMoneyFromString(this.loanAmt)
      },
    },
    loanAmt: {
      get() {
        return this.loanAmount
      },
      set(value) {
        this.setLoanAmount(value)
      },
    },
    filerFName: {
      get() {
        return this.filerFirstName
      },
      set(value) {
        this.setFilerFirstName(value)
      },
    },
    filerMI: {
      get() {
        return this.filerMiddleInitial
      },
      set(value) {
        this.setFilerMiddleInitial(value)
      },
    },
    filerLName: {
      get() {
        return this.filerLastName
      },
      set(value) {
        this.setFilerLastName(value)
      },
    },
    email: {
      get() {
        return this.filerEmail
      },
      set(value) {
        this.setFilerEmail(value)
      },
    },
    filerPhone: {
      get() {
        return this.filerPhoneNumber
      },
      set(value) {
        this.setFilerPhoneNumber(value)
      },
    },
    formattedPhone: {
      get() {
        return this.filerPhone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", "")
      },
    },
    filerPosition: {
      get() {
        return this.filerCompanyPosition
      },
      set(value) {
        this.setFilerCompanyPosition(value)
      },
    },
    businessCompanyStructure: {
      get() {
        return this.companyStructure
      },
      set(value) {
        this.setCompanyStructure(value)
      },
    },
    isOwnerFiler: {
      get() {
        return this.filerIsOwner
      },
      set(value) {
        this.setFilerIsOwner(value)
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("businessCreditApp", [
      "setJWTToken",
      "setCurrentPage",
      "setValidatedNavPage",
      "setLoanAmount",
      "setFilerFirstName",
      "setFilerMiddleInitial",
      "setFilerLastName",
      "setFilerEmail",
      "setFilerPhoneNumber",
      "setFilerCompanyPosition",
      "setFilerAgreeToTerms",
      "setApplicationID",
      "setCompanyStructure",
      "setFilerIsOwner",
    ]),
    formatMoneyFromString(mon) {
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    async postPage(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.postCreditAppPage(payload)
      console.log("--- response ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          this.setJWTToken(resp.data.access_token)

          if (resp.data.response) {
            this.setApplicationID(resp.data.response.application_id)
          }
          // update breadcrumb state
          this.setValidatedNavPage({ name: "filer", isValid: 1 })

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Business Info" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message)
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
          this.processing = false
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "Getting Started" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")
      this.processing = true

      this.validatePage()

      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length

        if (!this.errCnt) {
          const payload = {
            page: 1,
            questionnaire_id: this.qid,
            loan_amount: this.convertedLoanAmount,
            first_name: this.filerFName,
            middle_initial: this.filerMI,
            last_name: this.filerLName,
            email: this.email,
            phone_number: this.formattedPhone,
            business_structure: this.businessCompanyStructure,
            company_role: this.businessCompanyStructure !== "sole" ? this.filerPosition : "N/A",
            filer_is_owner: this.isOwnerFiler,
          }
          console.log(payload)
          this.postPage(payload)
        } else {
          this.setValidatedNavPage({ name: "filer", isValid: 0 })

          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missingLoanAmount || this.invalidLoanAmount)
              offset = jQuery("#business-label-loan-amount").offset().top
            if (this.missingFName || this.invalidFName)
              offset = jQuery("#business-label-filer-fname").offset().top
            if (this.invalidMName) offset = jQuery("#business-label-filer-mi").offset().top
            if (this.missingLName || this.invalidLName)
              offset = jQuery("#business-label-filer-lname").offset().top
            if (this.missingEmail || this.invalidEmail)
              offset = jQuery("#business-label-filer-email").offset().top
            if (this.missingPhone || this.invalidPhone)
              offset = jQuery("#business-label-filer-phone").offset().top
            if (this.missingCompanyStructure)
              offset = jQuery("#business-label-company-structure").offset().top
            if (this.businessCompanyStructure && this.businessCompanyStructure !== "sole") {
              if (this.missingPosition)
                offset = jQuery("#business-label-filer-position").offset().top
            }

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn")
          this.processing = false
        }
      })
    },
    validatePage() {
      this.validateLoanAmount()
      this.validateFName()
      this.validateMI()
      this.validateLName()
      this.validateEmail()
      this.validatePhone()
      if (this.businessCompanyStructure != "sole") this.validatePosition()
      this.validateBusinessCompanyStructure()
    },
    validateLoanAmount() {
      this.missingLoanAmount = this.loanAmt ? false : true
      if (!this.missingLoanAmount) {
        this.invalidLoanAmount = Validator.isValidMoney(this.loanAmt) ? false : true
      }
      console.log("loan amount", this.loanAmt, this.missingLoanAmount, this.invalidLoanAmount)
    },
    validateFName() {
      this.filerFName = this.filerFName.trim()
      this.missingFName = this.filerFName ? false : true
      if (!this.missingFName) {
        this.invalidFName = Validator.isValidName(this.filerFName) ? false : true
      }
    },
    validateMI() {
      this.filerMI = this.filerMI.trim()
      if (this.filerMI) {
        this.invalidMI = Validator.isValidName(this.filerMI) ? false : true
      }
    },
    validateLName() {
      this.filerLName = this.filerLName.trim()
      this.missingLName = this.filerLName ? false : true
      if (!this.missingLName) {
        this.invalidLName = Validator.isValidName(this.filerLName) ? false : true
      }
    },
    validateEmail() {
      this.email = this.email.trim()
      this.missingEmail = this.email ? false : true
      if (!this.missingEmail) {
        this.invalidEmail = Validator.isValidEmail(this.email) ? false : true
      }
    },
    validatePhone() {
      this.missingPhone = this.filerPhone ? false : true
      if (!this.missingPhone) {
        this.invalidPhone = Validator.isValidPhoneNumber(this.filerPhone) ? false : true
      }
    },
    validateBusinessCompanyStructure() {
      this.missingCompanyStructure = this.businessCompanyStructure ? false : true
    },
    validatePosition() {
      this.filerCompanyPosition = this.filerCompanyPosition.trim()
      this.missingPosition = false
      if (!this.filerCompanyPosition || this.filerCompanyPosition == "") {
        this.missingPosition = true
      }
    },
    isOwner() {
      let cb = jQuery("#owner-cb")
      this.isOwnerFiler = cb.prop("checked")
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  & + .ll-row {
    margin-top: 2em;
  }
}

.row1-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
  }
}

.continue-btn,
.continue-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.continue-btn-disabled {
  background: $grayVlight;
  color: $gray;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.form-error-msg {
  margin-top: 0.5em;
}
</style>
