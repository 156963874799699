<template>
  <div class="assets-and-liabilities" id="assets-and-liabilities-id">
    <Header v-bind:type="type" />
    <Breadcrumb_personalFi @click="handleBreadcrumbClick" />
    <MainId />
    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">
            <span class="bold-title">Personal Financial Statement</span> as of
            <span id="current_date"></span>
          </div>

          <h1 class="section-title page-title">Assets and Liabilities</h1>
          <span
            ><strong
              >Complete Schedules 1-5 below to populate fields in the Assets & Liabilities
              table</strong
            ></span
          >
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />
          <table class="assets-liabilities">
            <tr>
              <th class="bottom-border right-border" scope="col" colspan="2">Assets</th>

              <th class="bottom-border" scope="col" colspan="2">Liabilities</th>
            </tr>
            <tr>
              <td><label for="assets-cash-in-fhb">Cash in FHB</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  name="monetary-value"
                  id="assets-cash-in-fhb"
                  aria-describedby="assets-cash-in-fhb-error"
                  v-model="assets_cash_in_fhb"
                  :class="
                    invalidCashFhb
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateCashFhb"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-cash-in-fhb-error" class="ind-err-container">
                  <div
                    v-if="invalidCashFhb"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-loans-fhb">Loans Payable to FHB</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-loans-fhb"
                  aria-describedby="liabilities-loans-fhb-error"
                  name="monetary-value"
                  v-model="liabilities_loans_fhb"
                  :class="
                    invalidLoansFhb
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLoansFhb"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-loans-fhb-error" class="ind-err-container">
                  <div
                    v-if="invalidLoansFhb"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-cash-in-other">Cash in Other Banks</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-cash-in-other"
                  aria-describedby="assets-cash-in-other-error"
                  name="monetary-value"
                  v-model="assets_cash_in_other"
                  :class="
                    invalidCashOther
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateCashOther"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-cash-in-other-error" class="ind-err-container">
                  <div
                    v-if="invalidCashOther"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-loans-other">Loans Payable to Other Banks</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-loans-other"
                  aria-describedby="liabilities-loans-other-error"
                  name="monetary-value"
                  v-model="liabilities_loans_other"
                  :class="
                    invalidLoansOther
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLoansOther"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-loans-other-error" class="ind-err-container">
                  <div
                    v-if="invalidLoansOther"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="right-border"></td>

              <td><label for="liabilities-charge-cards">Charge Cards Payable</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-charge-cards"
                  aria-describedby="liabilities-charge-cards-error"
                  name="monetary-value"
                  v-model="liabilities_charge_cards"
                  :class="
                    invalidChargeCards
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateChargeCards"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-charge-cards-error" class="ind-err-container">
                  <div
                    v-if="invalidLoansOther"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-acc-rec">Account Receivable</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-acc-rec"
                  aria-describedby="assets-acc-rec-error"
                  name="monetary-value"
                  v-model="assets_acc_receivable"
                  :class="
                    invalidAccRec
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateAccRec"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-acc-rec-error" class="ind-err-container">
                  <div
                    v-if="invalidAccRec"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-acc-payable">Accounts Payable</label></td>
              <td>
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  id="liabilities-acc-payable"
                  aria-describedby="liabilities-acc-payable-error"
                  name="monetary-value"
                  v-model="liabilities_acc_payable"
                  :class="
                    invalidAccPayable
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateAccPayable"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-acc-payable" class="ind-err-container">
                  <div
                    v-if="invalidAccPayable"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-due-friends">Due from Friends/Relatives</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  id="assets-due-friends"
                  aria-describedby="assets-due-friends-error"
                  name="monetary-value"
                  v-model="assets_due_friends"
                  :class="
                    invalidAssetsDueFriends
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateAssetsDueFriends"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-due-friends-error" class="ind-err-container">
                  <div
                    v-if="invalidAssetsDueFriends"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-due-friends">Due to Friends/Relatives</label></td>
              <td>
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  id="liabilities-due-friends"
                  aria-describedby="liabilities-due-friends-error"
                  name="monetary-value"
                  v-model="liabilities_due_friends"
                  :class="
                    invalidLiabilitiesDueFriends
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLiabilitiesDueFriends"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-due-friends-error" class="ind-err-container">
                  <div
                    v-if="invalidLiabilitiesDueFriends"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-notes-rec">Notes Receivable (Schedule 1)</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  :style="!assets_notes_receivable ? 'background-color:#E7E7E7;' : ''"
                  :disabled="!assets_notes_receivable"
                  id="assets-notes-rec"
                  aria-describedby="assets-notes-rec-error"
                  name="monetary-value"
                  v-model="assets_notes_receivable"
                  :class="
                    invalidNotesRec
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateNotesRec"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-notes-rec-error" class="ind-err-container">
                  <div
                    v-if="invalidNotesRec"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-notes-pay">Notes Payable</label></td>
              <td>
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  id="liabilities-notes-pay"
                  aria-describedby="liabilities-notes-pay-error"
                  name="monetary-value"
                  v-model="liabilities_notes_payable"
                  :class="
                    invalidNotesPayable
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateNotesPayable"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-notes-pay-error" class="ind-err-container">
                  <div
                    v-if="invalidNotesPayable"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-estate-res">Real Estate Residence (Schedule 2)</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  :style="!assets_real_estate_residence ? 'background-color:#E7E7E7;' : ''"
                  id="assets-estate-res"
                  aria-describedby="assets-estate-res-error"
                  name="monetary-value"
                  v-model="assets_real_estate_residence"
                  :disabled="!assets_real_estate_residence"
                  :class="
                    invalidRealEstateRes
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateRealEstateRes"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-estate-res-error" class="ind-err-container">
                  <div
                    v-if="invalidRealEstateRes"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><label for="assets-estate-inv">Real Estate Investments (Schedule 2)</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-estate-inv"
                  aria-describedby="assets-estate-inv-error"
                  name="monetary-value"
                  :style="!assets_real_estate_investments ? 'background-color:#E7E7E7;' : ''"
                  :disabled="!assets_real_estate_investments"
                  v-model="assets_real_estate_investments"
                  :class="
                    invalidRealEstateInv
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateRealEstateInv"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-estate-inv-error" class="ind-err-container">
                  <div
                    v-if="invalidRealEstateInv"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <label for="liabilities-mortgages">Mortgages on Real Estate (Schedule 3)</label>
              </td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-mortgages"
                  aria-describedby="liabilities-mortgages-error"
                  name="monetary-value"
                  :style="!liabilities_mortgages ? 'background-color:#E7E7E7;' : ''"
                  :disabled="!liabilities_mortgages"
                  v-model="liabilities_mortgages"
                  :class="
                    invalidMortgage
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateMortgage"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-mortgages-error" class="ind-err-container">
                  <div
                    v-if="invalidMortgage"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-marketable-sec">Marketable Securities (Schedule 4)</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-marketable-sec"
                  aria-describedby="assets-marketable-sec-error"
                  name="monetary-value"
                  :style="!assets_marketable_securities ? 'background-color:#E7E7E7;' : ''"
                  :disabled="!assets_marketable_securities"
                  v-model="assets_marketable_securities"
                  :class="
                    invalidMarketableSec
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateMarketableSec"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-marketable-sec-error" class="ind-err-container">
                  <div
                    v-if="invalidMarketableSec"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-income-taxes">Income Taxes Payable</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-income-taxes"
                  aria-describedby="liabilities-income-taxes-error"
                  name="monetary-value"
                  v-model="liabilities_income_taxes"
                  :class="
                    invalidIncomeTax
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateIncomeTax"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-income-taxes-error" class="ind-err-container">
                  <div
                    v-if="invalidIncomeTax"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-retirement-acc">Retirement Accounts</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-retirement-acc"
                  aria-describedby="assets-retirement-acc-error"
                  name="monetary-value"
                  v-model="assets_retirement_acc"
                  :class="
                    invalidRetirement
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateRetirement"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-retirement-acc-error" class="ind-err-container">
                  <div
                    v-if="invalidRetirement"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-other-taxes">Other Taxes Payable</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-other-taxes"
                  aria-describedby="liabilities-other-taxes-error"
                  name="monetary-value"
                  v-model="liabilities_other_taxes"
                  :class="
                    invalidOtherTax
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateOtherTax"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-other-taxes-error" class="ind-err-container">
                  <div
                    v-if="invalidOtherTax"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-life-insurance">Life Insurance CSV (Schedule 5)</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-life-insurance"
                  aria-describedby="assets-life-insurance-error"
                  name="monetary-value"
                  :style="!assets_life_insurance ? 'background-color:#E7E7E7;' : ''"
                  :disabled="!assets_life_insurance"
                  v-model="assets_life_insurance"
                  :class="
                    invalidLifeInsurance
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLifeIns"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-life-insurance-error" class="ind-err-container">
                  <div
                    v-if="invalidLifeInsurance"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td><label for="liabilities-life-insurance">Loans on Life Insurance</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-life-insurance"
                  aria-describedby="liabilities-life-insurance-error"
                  name="monetary-value"
                  :disabled="!liabilities_life_insurance"
                  :style="!liabilities_life_insurance ? 'background-color:#E7E7E7;' : ''"
                  v-model="liabilities_life_insurance"
                  :class="
                    invalidLifeInsLoan
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLifeInsLoan"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-life-insurance-error" class="ind-err-container">
                  <div
                    v-if="invalidLifeInsLoan"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-investment-bus">Investment in Business</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-investment-bus"
                  aria-describedby="assets-investment-bus-error"
                  name="monetary-value"
                  v-model="assets_investment_business"
                  :class="
                    invalidInvestBus
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateInvestBus"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-investment-bus-error" class="ind-err-container">
                  <div
                    v-if="invalidInvestBus"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <label for="liabilities-other"
                  >Other Liabilities (i.e. Student, Partnership, etc.)</label
                >
              </td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-other"
                  aria-describedby="liabilities-other-error"
                  v-model="liabilities_other"
                  name="monetary-value"
                  :class="
                    invalidLiabilitiesOther
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLiabilitiesOther"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-other-error" class="ind-err-container">
                  <div
                    v-if="invalidLiabilitiesOther"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-other-investments">Other Investments</label></td>
              <td class="right-border">
                <table class="with-desc">
                  <tr>
                    <td>
                      <label for="assets-other-investments-desc" class="sr-only"
                        >Other Investments Description</label
                      >
                      <input
                        class="ll-text-spacing ll-text ll-text-desc tf-fname"
                        placeholder="Description"
                        id="assets-other-investments-desc"
                        data-test="business-fname"
                        type="text"
                        name="monetary-value"
                        v-model="assets_other_inv_desc"
                      />
                    </td>
                    <td>
                      <money-input
                        class="ll-textfield ll-textfield-money"
                        id="assets-other-investments"
                        aria-describedby="assets-other-investments-error"
                        name="monetary-value"
                        v-model="assets_other_inv"
                        :class="
                          invalidOtherInv
                            ? 'll-textfield-error ll-textfield-money'
                            : 'll-textfield ll-textfield-money'
                        "
                        v-on:blur="validateOtherInv"
                        data-test="financial-monthly-mortgage"
                      />

                      <div id="assets-other-investments-error" class="ind-err-container">
                        <div
                          v-if="invalidOtherInv"
                          class="form-error-msg"
                          data-test="financial-monthly-mortgage-error"
                        >
                          <img
                            class="form-error-icon"
                            srcset="
                              @/assets/images/exclaimation-lg.png    1x,
                              @/assets/images/exclaimation-lg-2x.png 2x
                            "
                            src="@/assets/images/exclaimation-lg-2x.png"
                            alt=""
                          />
                          <div role="alert" class="form-error-txt">
                            Please enter a valid dollar amount
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>

              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><label for="assets-personal-prop">Personal Property</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-personal-prop"
                  aria-describedby="assets-personal-prop-error"
                  name="monetary-value"
                  v-model="assets_personal_prop"
                  :class="
                    invalidPersonalProp
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validatePersonalProp"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-personal-prop-error" class="ind-err-container">
                  <div
                    v-if="invalidPersonalProp"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td class="bottom-border"><label for="liabilities-auto">Auto Loans</label></td>
              <td class="bottom-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-auto"
                  aria-describedby="liabilities-auto-error"
                  name="monetary-value"
                  v-model="liabilities_auto"
                  :class="
                    invalidAutoLoan
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateAutoLoan"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-auto-error" class="ind-err-container">
                  <div
                    v-if="invalidAutoLoan"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="assets-auto">Autos</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-auto"
                  aria-describedby="assets-auto-error"
                  name="monetary-value"
                  v-model="assets_auto"
                  :class="
                    invalidAuto
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateAuto"
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-auto-error" class="ind-err-container">
                  <div
                    v-if="invalidAuto"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td class="totals">
                <label for="liabilities-total-liabilities">Total Liabilities</label>
              </td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-total-liabilities"
                  aria-describedby="liabilities-total-liabilities-error"
                  name="monetary-value"
                  :disabled="true"
                  v-model="liabilities_running_total"
                  :class="
                    invalidLiabilitiesTotal
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-total-liabilities-error" class="ind-err-container">
                  <div
                    v-if="invalidLiabilitiesTotal"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="bottom-border"><label for="assets-other">Other Assets</label></td>
              <td class="right-border bottom-border">
                <table class="with-desc">
                  <tr>
                    <td>
                      <label for="assets-other-desc" class="sr-only"
                        >Other Assets Description</label
                      >
                      <input
                        class="ll-text-spacing ll-text ll-text-desc"
                        placeholder="Description"
                        data-test="business-fname"
                        id="assets-other-desc"
                        name="description"
                        type="text"
                        v-model="assets_other_desc"
                      />
                    </td>
                    <td>
                      <money-input
                        class="ll-textfield ll-textfield-money"
                        id="assets-other"
                        aria-describedby="assets-other-error"
                        v-model="assets_other"
                        :class="
                          invalidAssetsOther
                            ? 'll-textfield-error ll-textfield-money'
                            : 'll-textfield ll-textfield-money'
                        "
                        name="monetary-value"
                        v-on:blur="validateAssetsOther"
                        data-test="financial-monthly-mortgage"
                      />

                      <div id="assets-other-error" class="ind-err-container">
                        <div
                          v-if="invalidAssetsOther"
                          class="form-error-msg"
                          data-test="financial-monthly-mortgage-error"
                        >
                          <img
                            class="form-error-icon"
                            srcset="
                              @/assets/images/exclaimation-lg.png    1x,
                              @/assets/images/exclaimation-lg-2x.png 2x
                            "
                            src="@/assets/images/exclaimation-lg-2x.png"
                            alt=""
                          />
                          <div role="alert" class="form-error-txt">
                            Please enter a valid dollar amount
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>

              <td class="bottom-border totals"><label for="net-worth">Net Worth</label></td>
              <td class="bottom-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="net-worth"
                  aria-describedby="net-worth-error"
                  name="monetary-value"
                  v-model="net_worth_running_total"
                  :disabled="true"
                  :class="
                    invalidNetWorth
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  data-test="financial-monthly-mortgage"
                />

                <div id="net-worth-error" class="ind-err-container">
                  <div
                    v-if="invalidNetWorth"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="totals"><label for="assets-total">Total Assets</label></td>
              <td class="right-border">
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="assets-total"
                  aria-describedby="assets-total-error"
                  name="monetary-value"
                  :disabled="true"
                  v-model="assets_running_total"
                  :class="
                    invalidAssetsTotal
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  data-test="financial-monthly-mortgage"
                />

                <div id="assets-total-error" class="ind-err-container">
                  <div
                    v-if="invalidAssetsTotal"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>

              <td class="totals"><label for="liabilities-total-loan">Total</label></td>
              <td>
                <money-input
                  class="ll-textfield ll-textfield-money"
                  id="liabilities-total-loan"
                  aria-describedby="liabilities-total-loan-error"
                  name="monetary-value"
                  v-model="liabilities_total_loan"
                  :class="
                    invalidLiabilitiesTotalLoan
                      ? 'll-textfield-error ll-textfield-money'
                      : 'll-textfield ll-textfield-money'
                  "
                  v-on:blur="validateLiabilitiesTotalLoan"
                  data-test="financial-monthly-mortgage"
                />

                <div id="liabilities-total-loan-error" class="ind-err-container">
                  <div
                    v-if="invalidLiabilitiesTotalLoan"
                    class="form-error-msg"
                    data-test="financial-monthly-mortgage-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid dollar amount
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <!-- <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div> -->
          <div class="v-spacer-60" />

          <!-- schedule section -->
          <section class="ll-section">
            <!-- Schedule 1 - Notes Receivable or Agreement of Sale Receivable -->
            <h2>Schedule 1 - Notes Receivable or Agreement of Sale Receivable</h2>
            <table class="sch-1-table">
              <tr>
                <th class="bottom-border debtor-spacing">Name of Debtor</th>
                <th class="bottom-border amt-spacing">Amt Due</th>
                <th class="bottom-border payable-spacing">How Payable</th>
                <th class="bottom-border maturity-spacing">Maturity</th>
                <th class="bottom-border remark-spacing">
                  Remarks <span class="sub-note">(Include desc & value of security)</span>
                </th>
              </tr>

              <tr v-for="i in this.numOfSchedule1" :key="i">
                <td>
                  <label v-bind:for="`sch1-name-of-debtor-${i}`" class="sr-only">Debtor Name</label>
                  <input
                    v-bind:id="`sch1-name-of-debtor-${i}`"
                    :aria-describedby="`sch1-name-of-debtor-error-{i}`"
                    placeholder="Name"
                    name="full-name"
                    :data-test="`shc-1-name-${i}`"
                    type="text"
                    v-model="this.sch1_debtorName[i - 1]"
                    :class="
                      missingSch1DebtorName[i - 1]
                        ? 'll-textfield-error'
                        : 'll-text-name ll-text tf-fname'
                    "
                    v-on:blur="validateSch1DebtorName(i - 1)"
                  />

                  <div :id="`sch1-name-of-debtor-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch1DebtorName[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-account-name-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a valid name</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label v-bind:for="`sch1-amt-due-${i}`" class="sr-only">Amount Due</label>
                  <money-input
                    name="monetary-value"
                    v-bind:id="`sch1-amt-due-${i}`"
                    :aria-describedby="`sch1-amt-due-error-{i}`"
                    v-model="this.sch1_amtDue[i - 1]"
                    :class="
                      invalidSch1AmtDue[i - 1] || missingSch1AmtDue[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-text ll-textfield-money'
                    "
                    v-on:blur="validateSch1AmtDue(i - 1)"
                    :data-test="`sch-1-financial-monthly-mortgage-{i}`"
                  />

                  <div :id="`sch1-amt-due-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch1AmtDue[i - 1]"
                      class="form-error-msg"
                      :data-test="`sch-1-financial-monthly-mortgage-error-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch1AmtDue[i - 1]"
                      class="form-error-msg"
                      :data-test="`sch-1-financial-monthly-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch1-payable-${i}`" class="sr-only">How Payable Description</label>
                  <input
                    :id="`sch1-payable-${i}`"
                    :aria-describedby="`sch1-payable-error-{i}`"
                    name="payable-description"
                    :data-test="`sch-1-desc-${i}`"
                    type="text"
                    v-model="this.sch1_payable[i - 1]"
                    placeholder="Description"
                    :class="missingSch1Payable[i - 1] ? 'll-textfield-error' : 'll-text tf-fname'"
                    v-on:blur="validateSch1Payable(i - 1)"
                  />

                  <div :id="`sch1-payable-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch1Payable[i - 1]"
                      class="form-error-msg"
                      :data-test="`sch-1-desc-error-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a description</div>
                    </div>
                  </div>
                </td>

                <td>
                  <label v-bind:for="`sch1-maturity-${i}`" class="sr-only">Maturity Date</label>
                  <BirthdayInput
                    v-bind:id="`sch1-maturity-${i}`"
                    :aria-describedby="`sch1-maturity-error-{i}`"
                    name="date"
                    :data-test="`sch-1-maturity-${i}`"
                    type="text"
                    v-model="this.sch1_maturity[i - 1]"
                    :class="
                      missingSch1Maturity[i - 1] || invalidSch1Maturity[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield tf-dob'
                    "
                    v-on:blur="validateSch1Maturity(i - 1)"
                  />

                  <div :id="`sch1-maturity-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch1Maturity[i - 1] || invalidSch1Maturity[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-account-name-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid date (MM/DD/YYYY)
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <label v-bind:for="`sch1-remarks-${i}`" class="sr-only"
                    >Remarks (include description and value of security)</label
                  >
                  <input
                    v-bind:id="`sch1-remarks-${i}`"
                    :aria-describedby="`sch1-remarks-error-{i}`"
                    name="remarks"
                    placeholder="Remarks"
                    :data-test="`sch-1-remarks-${i}`"
                    type="text"
                    v-model="this.sch1_remarks[i - 1]"
                    :class="
                      missingSch1Remarks[i - 1]
                        ? 'll-textfield-error'
                        : 'll-text ll-text-remark tf-fname'
                    "
                    v-on:blur="validateSch1Remarks(i - 1)"
                  />

                  <div :id="`sch1-remarks-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch1Remarks[i - 1]"
                      class="form-error-msg"
                      :data-test="`sch-1-remarks-error-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a remark</div>
                    </div>
                  </div>
                </td>
                <td class="btn-cell">
                  <button
                    type="button"
                    v-if="i == this.numOfSchedule1"
                    :class="scheduleOneSubmittable[i - 1] ? 'add-btn' : 'add-btn-disabled'"
                    @click="addSchedule1(i)"
                    id="add-schedule-1-btn"
                  >
                    <span>+</span>
                  </button>
                </td>
                <td class="btn-cell">
                  <div class="delete-row" v-if="this.numOfSchedule1 > 1">
                    <button
                      class="delete-button"
                      @click="initSchOneDelete(i - 1)"
                      :id="`delete-button-${i - 1}`"
                      :data-test="`ll - bc - delete -lender - btn - `"
                    >
                      <img
                        class="delete-icon"
                        srcset="
                          @/assets/images/trash-gray.png    1x,
                          @/assets/images/trash-gray-2x.png 2x
                        "
                        src="@/assets/images/trash-gray-2x.png"
                        alt=""
                      />
                      <span class="sr-only">Delete Note Receivable or Agreement of Sale</span>
                    </button>
                  </div>
                </td>
              </tr>
            </table>

            <div class="v-spacer-30" />
            <div class="v-spacer-30" />

            <h2>Schedule 2 - Real Estate</h2>
            <!-- Schedule 2 - Real Estate -->
            <table>
              <tr>
                <th class="bottom-border debtor-spacing">Property Address</th>
                <th class="bottom-border date-spacing">Primary Residence?</th>
                <th class="bottom-border date-spacing">Date Acquired</th>
                <th class="bottom-border payable-spacing">Gross Monthly Rental Income</th>
                <th class="bottom-border name-spacing">Title in Name of</th>
                <th class="bottom-border valuation-spacing">Valuation on this Statement</th>
                <th class="bottom-border remark-spacing">
                  Balance Owed <span class="sub-note">(Details in Schedule 3)</span>
                </th>
              </tr>
              <tr v-for="i in this.numOfSchedule2" :key="i">
                <td>
                  <label :for="`sch2-prop-addr-${i}`" class="sr-only">Property Address</label>
                  <input
                    :id="`sch2-prop-addr-${i}`"
                    :aria-describedby="`sch2-prop-addr-error-{i}`"
                    name="address"
                    placeholder="Address"
                    data-test="business-fname"
                    type="text"
                    v-model="this.sch2_propAddr[i - 1]"
                    :class="
                      missingSch2PropAddr[i - 1]
                        ? 'll-textfield-error'
                        : 'll-text-name ll-text tf-fname'
                    "
                    v-on:blur="validateSch2PropAddr(i - 1)"
                  />

                  <div :id="`sch2-prop-addr-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch2PropAddr[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-account-name-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an address</div>
                    </div>
                  </div>
                </td>

                <td class="radio-input">
                  <label :for="`sch2-primary-address-${i}`" class="sr-only"
                    >Primary residence?</label
                  >
                  <input
                    :id="`sch2-primary-address-${i}`"
                    name="primary-residence-check"
                    data-test="sch2-primary-address"
                    type="radio"
                    :value="i - 1"
                    v-model="sch2_primary_address"
                    @click="validateSch2PrimaryAddress(i - 1)"
                  />
                  <label :for="`sch2-primary-address-${i - 1}`">Yes</label>
                </td>

                <td>
                  <label :for="`sch2-date-acq-${i}`" class="sr-only">Date Acquired</label>
                  <birthday-input
                    class="ll-textfield tf-dob"
                    :id="`sch2-date-acq-${i}`"
                    :aria-describedby="`sch2-date-acq-error-{i}`"
                    v-model="sch2_dateAcq[i - 1]"
                    name="acquired-date"
                    :class="
                      missingSch2DateAcq[i - 1] || invalidSch2DateAcq[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateSch2DateAcq(i - 1)"
                    data-test="cb-business-established-date"
                  />

                  <div :id="`sch2-date-acq-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch2DateAcq[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-account-name-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a date</div>
                    </div>
                    <div
                      v-if="invalidSch2DateAcq[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-account-name-err-${i}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a valid date</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch2-monthly-rental-${i}`" class="sr-only"
                    >Gross Monthly Rental Income</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch2-monthly-rental-${i}`"
                    :aria-describedby="`sch2-monthly-rental-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch2_monthlyRentalInc[i - 1]"
                    :class="
                      invalidSch2MonthlyRentalInc[i - 1] || missingSch2MonthlyRentalInc[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch2MonthlyRentalInc(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch2-monthly-rental-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch2MonthlyRentalInc[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch2MonthlyRentalInc[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch2-payable-${i}`" class="sr-only">Title in Name of</label>
                  <input
                    :id="`sch2-payable-${i}`"
                    :aria-describedby="`sch2-payable-error-{i}`"
                    name="name-title"
                    placeholder="Name"
                    data-test="business-fname"
                    type="text"
                    v-model="this.sch2_title[i - 1]"
                    :class="missingSch2Title[i - 1] ? 'll-textfield-error' : 'll-text tf-fname'"
                    v-on:blur="validateSch2Title(i - 1)"
                  />

                  <div :id="`sch2-payable-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch2Title[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a name</div>
                    </div>
                  </div>
                </td>

                <td>
                  <label :for="`sch2-val-${i}`" class="sr-only">Valuation on this Statement</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    name="monetary-value"
                    :id="`sch2-val-${i}`"
                    :aria-describedby="`sch2-val-error-{i}`"
                    v-model="this.sch2_valuation[i - 1]"
                    :class="
                      invalidSch2Valuation[i - 1] || missingSch2Valuation[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch2Valuation(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch2-val-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch2Valuation[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch2Valuation[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch2-bal-owed-${i}`" class="sr-only">Balance Owed</label>
                  <money-input
                    class="ll-textfield ll-textfield-money-owed"
                    :id="`sch2-bal-owed-${i}`"
                    :aria-describedby="`sch2-bal-owed-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch2_balanceOwed[i - 1]"
                    :class="
                      invalidSch2BalanceOwed[i - 1] || missingSch2BalanceOwed[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch2BalanceOwed(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch2-bal-owed-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch2BalanceOwed[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch2BalanceOwed[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule2 == i"
                    type="button"
                    :class="scheduleTwoSubmittable[i - 1] ? 'add-btn' : 'add-btn-disabled'"
                    @click="addSchedule2(i)"
                    :disabled="!scheduleTwoSubmittable[i - 1]"
                    id="add-schedule-2-btn"
                  >
                    <span>+</span>
                  </button>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule2 > 1"
                    class="delete-button"
                    @click="initSchTwoDelete(i - 1)"
                    v-bind:id="`delete-button-${i - 1}`"
                    :data-test="`ll - bc - delete -lender - btn - `"
                  >
                    <span class="sr-only">Delete</span>
                    <img
                      class="delete-icon"
                      srcset="
                        @/assets/images/trash-gray.png    1x,
                        @/assets/images/trash-gray-2x.png 2x
                      "
                      src="@/assets/images/trash-gray-2x.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </table>
            <div class="v-spacer-30" />

            <!-- Valuations explanation -->
            <div class="ll-item" id="valuation-reason">
              <div class="tf-label">
                <label for="sch2-val-basis"
                  >What is the basis of the above valuations? (Specify whether cost, fair market,
                  tax assessed value, or other basis)</label
                >
              </div>
              <input
                maxlength="512"
                type="text"
                id="sch2-val-basis"
                aria-describedby="sch2-val-basis-error"
                placeholder="Description of Basis"
                name="valuation-desc"
                v-model="Sch2ValuationReason"
                :class="
                  missingSch2ValuationReason
                    ? 'll-textfield-error'
                    : 'll-textfield ll-explaination-textfield'
                "
                v-on:blur="validateSch2ValuationReason()"
                data-test="tf-loan-other"
              />
            </div>

            <div id="sch2-val-basis-error" class="ind-err-container">
              <div
                v-if="missingSch2ValuationReason"
                class="form-error-msg"
                data-test="financial-monthly-mortgage-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a reason</div>
              </div>
            </div>

            <div class="v-spacer-30" />
            <div class="v-spacer-30" />

            <h2>Schedule 3 - Mortgages on Real Estate</h2>

            <!-- Schedule 3 - Mortgages on Real Estate -->
            <table>
              <tr>
                <th class="bottom-border debtor-spacing">To Whom Payable</th>
                <th class="bottom-border amt-spacing">Balance Owed</th>
                <th class="bottom-border payable-spacing">Interest Rate</th>
                <th class="bottom-border maturity-spacing">Maturity</th>
                <th class="bottom-border monthly-spacing">Monthly Payment</th>
              </tr>
              <tr v-for="i in this.numOfSchedule3" :key="i">
                <td>
                  <label :for="`sch3-who-payable-${i}`" class="sr-only">To Whom Payable</label>
                  <input
                    :id="`sch3-who-payable-${i}`"
                    :aria-describedby="`sch3-who-payable-error-{i}`"
                    placeholder="Name"
                    name="payable-name"
                    data-test="business-fname"
                    type="text"
                    v-model="this.sch3_payable[i - 1]"
                    :class="
                      missingSch3Payable[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield ll-text tf-fname'
                    "
                    v-on:blur="validateSch3Payable(i - 1)"
                  />

                  <div :id="`sch3-who-payable-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch3Payable[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a valid input</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch3-bal-owed-${i}`" class="sr-only">Balance Owed</label>
                  <money-input
                    v-bind:id="`sch3-bal-owed-${i}`"
                    :aria-describedby="`sch3-bal-owed-error-{i}`"
                    v-model="this.sch3_balanceOwed[i - 1]"
                    name="monetary-value"
                    :class="
                      invalidSch3BalanceOwed[i - 1] || missingSch3BalanceOwed[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch3BalanceOwed(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch3-bal-owed-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch3BalanceOwed[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch3BalanceOwed[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch3-int-rate-${i}`" class="sr-only">Interest Rate</label>
                  <percentage-input
                    class="ll-textfield ll-textfield-money"
                    v-bind:id="`sch3-int-rate-${i}`"
                    :aria-describedby="`sch3-int-rate-error-{i}`"
                    name="interest-rate"
                    v-model="this.sch3_interestRate[i - 1]"
                    :class="
                      invalidSch3InterestRate[i - 1] || missingSch3InterestRate[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch3InterestRate(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch3-int-rate-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch3InterestRate[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid percentage amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch3InterestRate[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch3-maturity-date-${i}`" class="sr-only">Maturity Date</label>
                  <BirthdayInput
                    :id="`sch3-maturity-date-${i}`"
                    :aria-describedby="`sch3-maturity-date-error-{i}`"
                    name="maturity-date"
                    data-test="sch3-maturity-date"
                    type="text"
                    v-model="this.sch3_maturity[i - 1]"
                    :class="
                      missingSch3Maturity[i - 1] || invalidSch3Maturity[i - 1]
                        ? 'll-textfield-error'
                        : 'll-text tf-dob'
                    "
                    v-on:blur="validateSch3Maturity(i - 1)"
                  />

                  <div :id="`sch3-maturity-date-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch3Maturity[i - 1] || invalidSch3Maturity[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid date (MM/DD/YYYY)
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <label :for="`sch3-monthly-pay-${i}`" class="sr-only">Monthly Payment</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch3-monthly-pay-${i}`"
                    :aria-describedby="`sch3-monthly-pay-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch3_monthlyPayment[i - 1]"
                    :class="
                      invalidSch3MonthlyPayment[i - 1] || missingSch3MonthlyPayment[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch3MonthlyPayment(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch3-monthly-pay-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch3MonthlyPayment[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch3MonthlyPayment[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule3 == i"
                    type="button"
                    :class="scheduleThreeSubmittable[i - 1] ? 'add-btn' : 'add-btn-disabled'"
                    @click="addSchedule3(i)"
                    :disabled="!scheduleThreeSubmittable[i - 1]"
                    tabindex="0"
                    id="add-schedule-3-btn"
                  >
                    +
                  </button>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule3 > 1"
                    class="delete-button"
                    @click="initSchThreeDelete(i - 1)"
                    v-bind:id="`delete-button-${i - 1}`"
                    :data-test="`ll - bc - delete -lender - btn - `"
                  >
                    <span class="sr-only">Delete</span>
                    <img
                      class="delete-icon"
                      srcset="
                        @/assets/images/trash-gray.png    1x,
                        @/assets/images/trash-gray-2x.png 2x
                      "
                      src="@/assets/images/trash-gray-2x.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </table>

            <div class="v-spacer-30" />

            <!-- Delinquent payments -->
            <div class="ll-item" id="delinquent-principal">
              <div class="tf-label">
                <label for="deliquent-details"
                  >If any payment of principal and interest are delinquent, please give
                  details</label
                >
              </div>
              <input
                maxlength="512"
                type="text"
                id="deliquent-details"
                aria-describedby="delinquent-details-error"
                name="delinquent-details"
                :class="
                  missingDelinquentPrincipal
                    ? 'll-textfield-error'
                    : 'll-explaination-textfield ll-textfield'
                "
                placeholder="Yes/No Explain"
                v-model="delinquentPaymentReason"
                data-test="tf-loan-other"
                v-on:blur="validateDelinquentPrincipal()"
              />
            </div>

            <div id="delinquent-details-error" class="ind-err-container">
              <div
                v-if="missingDelinquentPrincipal"
                class="form-error-msg"
                data-test="financial-monthly-mortgage-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please answer the above</div>
              </div>
            </div>

            <div class="v-spacer-20" />
            <!-- Delinquent taxes -->
            <div class="ll-item" id="delinquent-taxes">
              <div class="tf-label">
                <label for="delinquent-taxes-details"
                  >Are any taxes delinquent? If so, give amount and details</label
                >
              </div>
              <input
                maxlength="512"
                class="ll-explaination-textfield ll-textfield"
                type="text"
                id="delinquent-taxes-details"
                aria-describedby="delinquent-taxes-error"
                name="delinquent-taxes-details"
                :class="
                  missingDelinquentTaxes
                    ? 'll-textfield-error'
                    : 'll-explaination-textfield ll-textfield'
                "
                placeholder="Yes/No Explain"
                v-model="delinquentTaxesReason"
                data-test="tf-loan-other"
                v-on:blur="validateDelinquentTaxes()"
              />
            </div>

            <div id="delinquent-taxes-error" class="ind-err-container">
              <div
                v-if="missingDelinquentTaxes"
                class="form-error-msg"
                data-test="financial-monthly-mortgage-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please answer the above</div>
              </div>
            </div>

            <div class="v-spacer-20" />
            <!-- Unrecorded claims -->
            <div class="ll-item" id="unrecorded-claims">
              <div class="tf-label">
                <label for="unrecorded-details"
                  >Are there any unrecorded deeds, liens, or other claims not shown above?</label
                >
                <!-- <sup class="fhb-red">*</sup> -->
              </div>
              <input
                maxlength="512"
                type="text"
                id="unrecorded-details"
                aria-describedby="unrecorded-details-error"
                placeholder="Yes/No Explain"
                name="unrecorded-desc"
                v-model="unrecordedClaims"
                :class="
                  missingUnrecordedClaims
                    ? 'll-textfield-error'
                    : 'll-explaination-textfield ll-textfield'
                "
                v-on:blur="validateUnrecordedClaims()"
                data-test="tf-loan-other"
              />
            </div>

            <div id="unrecorded-details-error" class="ind-err-container">
              <div
                v-if="missingUnrecordedClaims"
                class="form-error-msg"
                data-test="financial-monthly-mortgage-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please answer the above</div>
              </div>
            </div>

            <div class="v-spacer-30" />
            <div class="v-spacer-30" />

            <h2>Schedule 4 - Securities Owned</h2>

            <!-- Schedule 4 - Securities Owned -->
            <table>
              <tr>
                <th scope="col" rowspan="2" class="bottom-border stock-type-spacing">
                  Type of Stock, Bond or Portfolio
                </th>
                <th scope="col" rowspan="2" class="bottom-border stock-shares-spacing">
                  Stock shares Bond amount
                </th>
                <th scope="col" rowspan="2" class="bottom-border value-carried-spacing">
                  Value carried on this statement
                </th>
                <th scope="col" class="bottom-border current-mkt-spacing" colspan="2">
                  <div class="table-subhead">Current Mkt on Listed</div>
                </th>
                <th scope="col" class="bottom-border unlisted-spacing" colspan="3">
                  <div class="table-subhead">Estimated Value on Unlisted</div>
                </th>
              </tr>
              <tr>
                <th scope="col" class="bottom-border desc-spacing">Desc</th>
                <th scope="col" class="bottom-border amount-spacing">Amt</th>
                <th scope="col" class="bottom-border desc-spacing">Desc</th>
                <th scope="col" class="bottom-border amount-spacing-2">Amt</th>
                <th scope="col" class="bottom-border yrly-inc-spacing">Yrly. Inc.</th>
              </tr>
              <tr v-for="i in this.numOfSchedule4" :key="i">
                <td>
                  <label :for="`sch4-type-${i}`" class="sr-only"
                    >Type of Stock, Bond or Portfolio</label
                  >
                  <input
                    :id="`sch4-type-${i}`"
                    :aria-describedby="`sch4-type-error-{i}`"
                    placeholder="Type"
                    data-test="business-fname"
                    type="text"
                    name="stock-type"
                    v-model="this.sch4_type[i - 1]"
                    :class="missingSch4Type[i - 1] ? 'll-textfield-error' : 'll-text'"
                    v-on:blur="validateSch4Type(i - 1)"
                  />

                  <div :id="`sch4-type-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch4Type[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a type</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch4-stock-shares-${i}`" class="sr-only"
                    >Stock shares Bond amount</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch4-stock-shares-${i}`"
                    :aria-describedby="`sch4-stock-shares-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch4_stockShares[i - 1]"
                    :class="
                      invalidSch4StockShares[i - 1] || missingSch4StockShares[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch4StockShares(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch4-stock-shares-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch4StockShares[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch4StockShares[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch4-val-carr-${i}`" class="sr-only"
                    >Value carried on this statement</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch4-val-carr-${i}`"
                    :aria-describedby="`sch4-val-carr-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch4_valueCarried[i - 1]"
                    :class="
                      invalidSch4ValueCarried[i - 1] || missingSch4ValueCarried[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch4ValueCarried(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch4-val-carr-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch4ValueCarried[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch4ValueCarried[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>

                <td class="desc-spacing">
                  <label :for="`sch4-val-unl-desc-${i}`" class="sr-only"
                    >Estimated Value on Unlisted Description</label
                  >
                  <input
                    v-bind:id="`sch4-val-unl-desc-${i}`"
                    :aria-describedby="`sch4-val-unl-desc-error-{i}`"
                    name="unlisted-desc"
                    data-test="business-fname"
                    type="text"
                    placeholder="Description"
                    v-model="this.sch4_listedMkt[i - 1]"
                    :class="missingSch4ListedMkt[i - 1] ? 'll-textfield-error' : 'll-text tf-fname'"
                    v-on:blur="validateSch4ListedMkt(i - 1)"
                  />

                  <div :id="`sch4-val-unl-desc-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch4ListedMkt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a description</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch4-val-unl-amt-${i}`" class="sr-only"
                    >Estimated Value on Unlisted Amount</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch4-val-unl-amt-${i}`"
                    :aria-describedby="`sch4-val-unl-amt-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch4_listedMktAmt[i - 1]"
                    :class="
                      invalidSch4ListedMktAmt[i - 1] || missingSch4ListedMktAmt[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch4ListedMktAmt(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch4-val-unl-amt-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch4ListedMktAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch4ListedMktAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td class="desc-spacing">
                  <label :for="`sch4-mkt-lstd-desc-${i}`" class="sr-only"
                    >Current Market on Listed Description</label
                  >
                  <input
                    :id="`sch4-mkt-lstd-desc-${i}`"
                    :aria-describedby="`sch4-mkt-lstd-desc-error-{i}`"
                    placeholder="Description"
                    name="listed-desc"
                    data-test="business-fname"
                    type="text"
                    v-model="this.sch4_unlistedValue[i - 1]"
                    :class="
                      missingSch4UnlistedValue[i - 1] ? 'll-textfield-error' : 'll-text tf-fname'
                    "
                    v-on:blur="validateSch4UnlistedValue(i - 1)"
                  />

                  <div :id="`sch4-mkt-lstd-desc-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch4UnlistedValue[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a description</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch4-mkt-lstd-amt-${i}`" class="sr-only"
                    >Current Market on Listed Amount</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch4-mkt-lstd-amt-${i}`"
                    :aria-describedby="`sch4-mkt-lstd-amt-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch4_unlistedValueAmt[i - 1]"
                    :class="
                      invalidSch4UnlistedValueAmt[i - 1] || missingSch4UnlistedValueAmt[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch4UnlistedValueAmt(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch4-mkt-lstd-amt-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch4UnlistedValueAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch4UnlistedValueAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch4-mkt-yrly-inc-${i}`" class="sr-only"
                    >Current Market on Listed Yearly Income</label
                  >
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch4-mkt-yrly-inc-${i}`"
                    :aria-describedby="`sch4-mkt-yrly-inc-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch4_unlistedValueYrlyInc[i - 1]"
                    :class="
                      invalidSch4UnlistedValueYrlyInc[i - 1] ||
                      missingSch4UnlistedValueYrlyInc[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch4UnlistedValueYrlyInc(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch4-mkt-yrly-inc-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch4UnlistedValueYrlyInc[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch4UnlistedValueYrlyInc[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="i == this.numOfSchedule4"
                    type="button"
                    :class="scheduleFourSubmittable[i - 1] ? 'add-btn' : 'add-btn-disabled'"
                    @click="addSchedule4(i)"
                    :disabled="!scheduleFourSubmittable[i - 1]"
                    id="add-schedule-4-btn"
                  >
                    +
                  </button>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule4 > 1"
                    class="delete-button"
                    @click="initSchFourDelete(i - 1)"
                    v-bind:id="`delete-button-${i - 1}`"
                    :data-test="`ll - bc - delete -lender - btn - `"
                  >
                    <span class="sr-only">Delete</span>
                    <img
                      class="delete-icon"
                      srcset="
                        @/assets/images/trash-gray.png    1x,
                        @/assets/images/trash-gray-2x.png 2x
                      "
                      src="@/assets/images/trash-gray-2x.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </table>
            <div class="v-spacer-30" />

            <!-- Held securities -->
            <div class="ll-item" id="held-securities">
              <div class="tf-label">
                <label for="securities-name">In whose name are the above securities held?</label>
              </div>
              <input
                maxlength="512"
                class="ll-explaination-textfield ll-textfield"
                type="text"
                id="securities-name"
                aria-describedby="securities-name-error"
                placeholder="Name"
                name="securities-name"
                v-model="heldSecurities"
                :class="missingHeldSecurities ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateHeldSecurities()"
                data-test="tf-loan-other"
              />
            </div>

            <div id="securities-name-error" class="ind-err-container">
              <div
                v-if="missingHeldSecurities"
                class="form-error-msg"
                data-test="financial-monthly-mortgage-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please answer the above</div>
              </div>
            </div>

            <div class="v-spacer-30" />
            <div class="v-spacer-30" />
            <h2>Schedule 5 - Life Insurance</h2>

            <!-- Schedule 5 - Life Insurance -->
            <table>
              <tr>
                <th class="bottom-border stock-type-spacing">Beneficiary</th>
                <th class="bottom-border stock-shares-spacing">Amount of Policy</th>
                <th class="bottom-border value-carried-spacing">Cash Value</th>
                <th class="bottom-border">Amount of Loans</th>
                <th class="bottom-border">Net Cash Value</th>
              </tr>
              <tr v-for="i in this.numOfSchedule5" :key="i">
                <td>
                  <label :for="`sch5-beneficiary-${i}`" class="sr-only">Beneficiary</label>
                  <input
                    :id="`sch5-beneficiary-${i}`"
                    :aria-describedby="`sch5-beneficiary-error-{i}`"
                    placeholder="Name"
                    name="beneficiary-name"
                    data-test="business-fname"
                    type="text"
                    v-model="this.sch5_beneficiary[i - 1]"
                    :class="
                      missingSch5Beneficiary[i - 1]
                        ? 'll-textfield-error'
                        : 'll-text-name ll-text tf-fname'
                    "
                    v-on:blur="validateSch5Beneficiary(i - 1)"
                  />

                  <div :id="`sch5-beneficiary-error-{i}`" class="ind-err-container">
                    <div
                      v-if="missingSch5Beneficiary[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a name</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch5-policy-amt-${i}`" class="sr-only">Amount of Policy</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch5-policy-amt-${i}`"
                    :aria-describedby="`sch5-policy-amt-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch5_policyAmt[i - 1]"
                    :class="
                      invalidSch5PolicyAmt[i - 1] || missingSch5PolicyAmt[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch5PolicyAmt(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch5-policy-amt-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch5PolicyAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch5PolicyAmt[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch5-cash-val-${i}`" class="sr-only">Cash Value</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch5-cash-val-${i}`"
                    :aria-describedby="`sch5-cash-val-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch5_cash[i - 1]"
                    :class="
                      invalidSch5Cash[i - 1] || missingSch5Cash[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch5Cash(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch5-cash-val-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch5Cash[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch5Cash[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch5-loan-amt-${i}`" class="sr-only">Amount of Loans</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch5-loan-amt-${i}`"
                    :aria-describedby="`sch5-loan-amt-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch5_loan[i - 1]"
                    :class="
                      invalidSch5Loan[i - 1] || missingSch5Loan[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch5Loan(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch5-loan-amt-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch5Loan[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch5Loan[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td>
                  <label :for="`sch5-net-cash-val-${i}`" class="sr-only">Net Cash Value</label>
                  <money-input
                    class="ll-textfield ll-textfield-money"
                    :id="`sch5-net-cash-val-${i}`"
                    :aria-describedby="`sch5-net-cash-val-error-{i}`"
                    name="monetary-value"
                    v-model="this.sch5_netCash[i - 1]"
                    :class="
                      invalidSch5NetCash[i - 1] || missingSch5NetCash[i - 1]
                        ? 'll-textfield-error ll-textfield-money'
                        : 'll-textfield ll-textfield-money'
                    "
                    v-on:blur="validateSch5NetCash(i - 1)"
                    data-test="financial-monthly-mortgage"
                  />

                  <div :id="`sch5-net-cash-val-error-{i}`" class="ind-err-container">
                    <div
                      v-if="invalidSch5NetCash[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid dollar amount
                      </div>
                    </div>
                    <div
                      v-if="missingSch5NetCash[i - 1]"
                      class="form-error-msg"
                      data-test="financial-monthly-mortgage-error"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an amount</div>
                    </div>
                  </div>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="i == this.numOfSchedule5"
                    type="button"
                    :class="scheduleFiveSubmittable[i - 1] ? 'add-btn' : 'add-btn-disabled'"
                    @click="addSchedule5(i)"
                    :disabled="!scheduleFiveSubmittable[i - 1]"
                    id="add-schedule-5-btn"
                  >
                    +
                  </button>
                </td>
                <td class="btn-cell">
                  <button
                    v-if="this.numOfSchedule5 > 1"
                    class="delete-button"
                    @click="initSchFiveDelete(i - 1)"
                    v-bind:id="`delete-button-${i - 1}`"
                    :data-test="`ll - bc - delete -lender - btn - `"
                  >
                    <span class="sr-only">Delete</span>
                    <img
                      class="delete-icon"
                      srcset="
                        @/assets/images/trash-gray.png    1x,
                        @/assets/images/trash-gray-2x.png 2x
                      "
                      src="@/assets/images/trash-gray-2x.png"
                      alt=""
                      title="delete"
                    />
                  </button>
                </td>
              </tr>
            </table>
            <div class="v-spacer-30" />

            <div class="sys-err-msg" v-if="systemErrorMsg">
              <div>
                <img
                  class="form-error-icon sys-err-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg.png"
                  alt=""
                />{{ systemErrorMsg }}
              </div>
              <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
                <li v-for="item in systemErrorArray" :key="item.index">
                  {{ item.error_message }} : {{ item.error_value }}
                </li>
              </ul>
            </div>
            <div class="v-spacer-60" />
          </section>
          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              class="continue-btn"
              id="ll-continue-btn"
              type="button"
              @click="handleContinue()"
              data-test="nav-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />
        </section>
      </form>
    </div>
    <Footer />
  </div>
  <DeleteScheduleRow
    v-if="schOneDeleteModal"
    @close="closeSchOneDeleteModal"
    :index="schOneRowIndex"
    @delete="deleteSchedule1"
    scheduleType="Debt"
  />
  <DeleteScheduleRow
    v-if="schTwoDeleteModal"
    @close="closeSchTwoDeleteModal"
    :index="schTwoRowIndex"
    @delete="deleteSchedule2"
    scheduleType="Property"
  />
  <DeleteScheduleRow
    v-if="schThreeDeleteModal"
    @close="closeSchThreeDeleteModal"
    :index="schThreeRowIndex"
    @delete="deleteSchedule3"
    scheduleType="Mortgage"
  />
  <DeleteScheduleRow
    v-if="schFourDeleteModal"
    @close="closeSchFourDeleteModal"
    :index="schFourRowIndex"
    @delete="deleteSchedule4"
    scheduleType="Security"
  />
  <DeleteScheduleRow
    v-if="schFiveDeleteModal"
    @close="closeSchFiveDeleteModal"
    :index="schFiveRowIndex"
    @delete="deleteSchedule5"
    scheduleType="Beneficiary"
  />
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Header from "@/components/Header"
import Validator from "@/app/validation"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import Breadcrumb_personalFi from "@/views/business/personal_fi_statement/Breadcrumb_personalFi.vue"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import MoneyInput from "@/components/CustomInputs/MoneyInput"
import BirthdayInput from "../../../components/CustomInputs/BirthdayInput.vue"
import PercentageInput from "../../../components/CustomInputs/PercentageInput.vue"
import DeleteScheduleRow from "@/components/Modals/DeleteScheduleRow.vue"

export default {
  name: "Assets And Liabilities Page",
  components: {
    Header,
    MainId,
    Footer,
    MoneyInput,
    Breadcrumb_personalFi,
    BirthdayInput,
    PercentageInput,
    DeleteScheduleRow,
  },
  data() {
    return {
      systemErrorMsg: "",

      errCnt: 0,

      missingMortgageRent: false,
      invalidMortgageRent: false,

      missingCashFhb: false,
      invalidCashFhb: false,

      missingLoansFhb: false,
      invalidLoansFhb: false,

      missingCashOther: false,
      invalidCashOther: false,

      missingLoansOther: false,
      invalidLoansOther: false,

      missingChargeCards: false,
      invalidChargeCards: false,

      missingAccRec: false,
      invalidAccRec: false,

      missingAccPayable: false,
      invalidAccPayable: false,

      missingAssetsDueFriends: false,
      invalidAssetsDueFriends: false,

      missingLiabilitiesDueFriends: false,
      invalidLiabilitiesDueFriends: false,

      missingNotesRec: false,
      invalidNotesRec: false,

      missingNotesPayable: false,
      invalidNotesPayable: false,

      missingRealEstateRes: false,
      invalidRealEstateRes: false,

      missingRealEstateInv: false,
      invalidRealEstateInv: false,

      missingMortgage: false,
      invalidMortgage: false,

      missingMarketableSec: false,
      invalidMarketableSec: false,

      missingIncomeTax: false,
      invalidIncomeTax: false,

      missingRetirement: false,
      invalidRetirement: false,

      missingOtherTax: false,
      invalidOtherTax: false,

      missingLifeInsurance: false,
      invalidLifeInsurance: false,

      missingLifeInsLoan: false,
      invalidLifeInsLoan: false,

      missingInvestBus: false,
      invalidInvestBus: false,

      missingLiabilitiesOther: false,
      invalidLiabilitiesOther: false,

      missingOtherInvDesc: false,
      invalidOtherInvDesc: false,

      missingOtherInv: false,
      invalidOtherInv: false,

      missingPersonalProp: false,
      invalidPersonalProp: false,

      missingAutoLoan: false,
      invalidAutoLoan: false,

      missingAuto: false,
      invalidAuto: false,

      missingLiabilitiesTotal: false,
      invalidLiabilitiesTotal: false,

      missingAssetsOtherDesc: false,

      missingAssetsOther: false,
      invalidAssetsOther: false,

      missingNetWorth: false,
      invalidNetWorth: false,

      missingAssetsTotal: false,
      invalidAssetsTotal: false,

      missingLiabilitiesTotalLoan: false,
      invalidLiabilitiesTotalLoan: false,

      // schedules
      scheduleSystemErrorMsg: "",

      scheduleErrCnt: 0,

      sch1_debtorName: [],
      missingSch1DebtorName: [],

      sch1_amtDue: [],
      missingSch1AmtDue: [],
      invalidSch1AmtDue: [],

      sch1_payable: [],
      missingSch1Payable: [],

      sch1_maturity: [],
      missingSch1Maturity: [],
      invalidSch1Maturity: [],

      sch1_remarks: [],
      missingSch1Remarks: [],

      sch2_propAddr: [],
      missingSch2PropAddr: [],

      sch2_dateAcq: [],
      invalidSch2DateAcq: [],
      missingSch2DateAcq: [],

      sch2_monthlyRentalInc: [],
      missingSch2MonthlyRentalInc: [],
      invalidSch2MonthlyRentalInc: [],

      sch2_title: [],
      missingSch2Title: [],

      sch2_valuation: [],
      missingSch2Valuation: [],
      invalidSch2Valuation: [],

      sch2_balanceOwed: [],
      missingSch2BalanceOwed: [],
      invalidSch2BalanceOwed: [],

      sch2_primary_address: null,

      sch3_payable: [],
      missingSch3Payable: [],

      sch3_balanceOwed: [],
      missingSch3BalanceOwed: [],
      invalidSch3BalanceOwed: [],

      sch3_interestRate: [],
      missingSch3InterestRate: [],
      invalidSch3InterestRate: [],

      sch3_maturity: [],
      missingSch3Maturity: [],
      invalidSch3Maturity: [],

      sch3_monthlyPayment: [],
      missingSch3MonthlyPayment: [],
      invalidSch3MonthlyPayment: [],

      sch4_type: [],
      missingSch4Type: [],

      sch4_stockShares: [],
      missingSch4StockShares: [],
      invalidSch4StockShares: [],

      sch4_valueCarried: [],
      missingSch4ValueCarried: [],
      invalidSch4ValueCarried: [],

      sch4_listedMkt: [],
      missingSch4ListedMkt: [],

      sch4_listedMktAmt: [],
      missingSch4ListedMktAmt: [],
      invalidSch4ListedMktAmt: [],

      sch4_unlistedValue: [],
      missingSch4UnlistedValue: [],

      sch4_unlistedValueAmt: [],
      missingSch4UnlistedValueAmt: [],
      invalidSch4UnlistedValueAmt: [],

      sch4_unlistedValueYrlyInc: [],
      missingSch4UnlistedValueYrlyInc: [],
      invalidSch4UnlistedValueYrlyInc: [],

      sch5_beneficiary: [],
      missingSch5Beneficiary: [],

      sch5_policyAmt: [],
      missingSch5PolicyAmt: [],
      invalidSch5PolicyAmt: [],

      sch5_cash: [],
      missingSch5Cash: [],
      invalidSch5Cash: [],

      sch5_loan: [],
      missingSch5Loan: [],
      invalidSch5Loan: [],

      sch5_netCash: [],
      missingSch5NetCash: [],
      invalidSch5NetCash: [],

      missingDelinquentPrincipal: false,
      missingDelinquentTaxes: false,
      missingUnrecordedClaims: false,

      missingHeldSecurities: false,
      missingSch2ValuationReason: false,

      scheduleOneSubmittable: [false],
      scheduleTwoSubmittable: [false],
      scheduleThreeSubmittable: [false],
      scheduleFourSubmittable: [false],
      scheduleFiveSubmittable: [false],
      schOneDeleteModal: false,
      schTwoDeleteModal: false,
      schThreeDeleteModal: false,
      schFourDeleteModal: false,
      schFiveDeleteModal: false,
      schOneRowIndex: null,
      schTwoRowIndex: null,
      schThreeRowIndex: null,
      schFourRowIndex: null,
      schFiveRowIndex: null,
    }
  },
  created() {
    this.setCurrentPage(2)

    // schedules
    for (let i = 0; i < this.numOfSchedule1; i++) {
      let sch1 = this.schedule1[i]
      this.sch1_debtorName.push(sch1.debtor_name)
      this.sch1_amtDue.push(this.formatMoneyToString(sch1.amount_due))
      this.sch1_payable.push(sch1.payable)
      this.sch1_maturity.push(sch1.maturity)
      this.sch1_remarks.push(sch1.remarks)

      this.pushSch1Errors()
    }

    for (let i = 0; i < this.numOfSchedule2; i++) {
      let sch2 = this.schedule2[i]
      this.sch2_propAddr.push(sch2.property_addr)
      this.sch2_dateAcq.push(sch2.date_acquired)
      this.sch2_monthlyRentalInc.push(this.formatMoneyToString(sch2.monthly_rental_inc))
      this.sch2_title.push(sch2.title)
      this.sch2_valuation.push(this.formatMoneyToString(sch2.valuation))
      this.sch2_balanceOwed.push(this.formatMoneyToString(sch2.balance_owed))
      if (sch2.is_primary_residence == "Y") this.sch2_primary_address = i

      this.pushSch2Errors()
    }

    for (let i = 0; i < this.numOfSchedule3; i++) {
      let sch3 = this.schedule3[i]
      this.sch3_payable.push(sch3.payable)
      this.sch3_balanceOwed.push(this.formatMoneyToString(sch3.balance_owed))
      this.sch3_interestRate.push(this.formatPercentageToString(sch3.interest_rate))
      this.sch3_maturity.push(sch3.maturity)
      this.sch3_monthlyPayment.push(this.formatMoneyToString(sch3.monthly_payment))

      this.pushSch3Errors()
    }

    for (let i = 0; i < this.numOfSchedule4; i++) {
      let sch4 = this.schedule4[i]
      this.sch4_type.push(sch4.type)
      this.sch4_stockShares.push(this.formatMoneyToString(sch4.stock_shares))
      this.sch4_valueCarried.push(this.formatMoneyToString(sch4.value_carried))
      this.sch4_listedMkt.push(sch4.listed_mkt)
      this.sch4_listedMktAmt.push(this.formatMoneyToString(sch4.listed_mkt_amt))
      this.sch4_unlistedValue.push(sch4.unlisted_value)
      this.sch4_unlistedValueAmt.push(this.formatMoneyToString(sch4.unlisted_value_amt))
      this.sch4_unlistedValueYrlyInc.push(this.formatMoneyToString(sch4.unlisted_value_yrly_inc))

      this.pushSch4Errors()
    }

    for (let i = 0; i < this.numOfSchedule5; i++) {
      let sch5 = this.schedule5[i]
      this.sch5_beneficiary.push(sch5.beneficiary)
      this.sch5_policyAmt.push(this.formatMoneyToString(sch5.policy_amt))
      this.sch5_cash.push(this.formatMoneyToString(sch5.cash))
      this.sch5_loan.push(this.formatMoneyToString(sch5.loan))
      this.sch5_netCash.push(this.formatMoneyToString(sch5.net_cash))

      this.pushSch5Errors()
    }
  },
  computed: {
    ...mapGetters("personalFiStatement", [
      "currentPage",
      "type",
      "application_id",
      "jwt_token",
      "num_of_sch1",
      "num_of_sch2",
      "num_of_sch3",
      "num_of_sch4",
      "num_of_sch5",
      "schedule1",
      "schedule2",
      "schedule3",
      "schedule4",
      "schedule5",
    ]),
    assets_cash_in_fhb: {
      get() {
        return this.$store.state.personalFiStatement.assets.cash_in_fhb
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsCashInFhb", value)
      },
    },
    assets_cash_in_other: {
      get() {
        return this.$store.state.personalFiStatement.assets.cash_in_other
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsCashInOther", value)
      },
    },
    assets_acc_receivable: {
      get() {
        return this.$store.state.personalFiStatement.assets.account_receivable
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsAccReceivable", value)
      },
    },
    assets_due_friends: {
      get() {
        return this.$store.state.personalFiStatement.assets.due_from_friends
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsDueFromFriends", value)
      },
    },
    assets_notes_receivable: {
      get() {
        return this.$store.state.personalFiStatement.assets.notes_receivable
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsNotesReceivable", value)
      },
    },
    assets_real_estate_residence: {
      get() {
        return this.$store.state.personalFiStatement.assets.real_estate_residence
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsRealEstateResi", value)
      },
    },
    assets_real_estate_investments: {
      get() {
        return this.$store.state.personalFiStatement.assets.real_estate_investments
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsRealEstateInv", value)
      },
    },
    assets_marketable_securities: {
      get() {
        return this.$store.state.personalFiStatement.assets.marketable_securities
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsMarketableSec", value)
      },
    },
    assets_retirement_acc: {
      get() {
        return this.$store.state.personalFiStatement.assets.retirement_accounts
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsRetirementAcc", value)
      },
    },
    assets_life_insurance: {
      get() {
        return this.$store.state.personalFiStatement.assets.life_insurance
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsLifeIns", value)
      },
    },
    assets_investment_business: {
      get() {
        return this.$store.state.personalFiStatement.assets.investment_in_business
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsInvInBus", value)
      },
    },
    assets_other_inv: {
      get() {
        return this.$store.state.personalFiStatement.assets.other_investments
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsOtherInv", value)
      },
    },
    assets_other_inv_desc: {
      get() {
        return this.$store.state.personalFiStatement.assets.other_investments_desc
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsOtherInvDesc", value)
      },
    },
    assets_personal_prop: {
      get() {
        return this.$store.state.personalFiStatement.assets.personal_property
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsPersonalProp", value)
      },
    },
    assets_auto: {
      get() {
        return this.$store.state.personalFiStatement.assets.autos
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsAuto", value)
      },
    },
    assets_other: {
      get() {
        return this.$store.state.personalFiStatement.assets.other_assets
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsOtherAssets", value)
      },
    },
    assets_other_desc: {
      get() {
        return this.$store.state.personalFiStatement.assets.other_assets_desc
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsOtherAssetsDesc", value)
      },
    },
    assets_running_total: {
      get() {
        return this.sumOfAssets()
      },
    },
    assets_total: {
      get() {
        return this.$store.state.personalFiStatement.assets.total_assets
      },
      set(value) {
        this.$store.commit("personalFiStatement/setAssetsTotalAssets", value)
      },
    },
    liabilities_loans_fhb: {
      get() {
        return this.$store.state.personalFiStatement.loans.loans_to_fhb
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansToFhb", value)
      },
    },
    liabilities_loans_other: {
      get() {
        return this.$store.state.personalFiStatement.loans.loans_to_other
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansToOther", value)
      },
    },
    liabilities_charge_cards: {
      get() {
        return this.$store.state.personalFiStatement.loans.charge_cards
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansChargeCards", value)
      },
    },
    liabilities_acc_payable: {
      get() {
        return this.$store.state.personalFiStatement.loans.accounts_payable
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansAccPayable", value)
      },
    },
    liabilities_due_friends: {
      get() {
        return this.$store.state.personalFiStatement.loans.due_to_friends
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansDueToFriends", value)
      },
    },
    liabilities_notes_payable: {
      get() {
        return this.$store.state.personalFiStatement.loans.notes_payable
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansNotesPayable", value)
      },
    },
    liabilities_mortgages: {
      get() {
        return this.$store.state.personalFiStatement.loans.real_estate_mortgages
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansRealEstateMortg", value)
      },
    },
    liabilities_income_taxes: {
      get() {
        return this.$store.state.personalFiStatement.loans.income_taxes
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansIncomeTaxes", value)
      },
    },
    liabilities_other_taxes: {
      get() {
        return this.$store.state.personalFiStatement.loans.other_taxes
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansOtherTaxes", value)
      },
    },
    liabilities_life_insurance: {
      get() {
        return this.$store.state.personalFiStatement.loans.life_insurance_loan
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansLifeIns", value)
      },
    },
    liabilities_other: {
      get() {
        return this.$store.state.personalFiStatement.loans.other_liabilities
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansOther", value)
      },
    },
    liabilities_auto: {
      get() {
        return this.$store.state.personalFiStatement.loans.auto_loans
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansAuto", value)
      },
    },
    liabilities_running_total: {
      get() {
        return this.sumOfLiabilities()
      },
    },
    liabilities_total: {
      get() {
        return this.$store.state.personalFiStatement.loans.total_liabilities
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansTotalLiabilities", value)
      },
    },
    net_worth_running_total: {
      get() {
        return this.calculateNetWorth()
      },
    },
    liabilities_net_worth: {
      get() {
        return this.$store.state.personalFiStatement.loans.net_worth
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansNetWorth", value)
      },
    },
    liabilities_total_loan: {
      get() {
        return this.$store.state.personalFiStatement.loans.total_loans
      },
      set(value) {
        this.$store.commit("personalFiStatement/setLoansTotal", value)
      },
    },

    // schedules
    Sch2ValuationReason: {
      get() {
        return this.$store.state.personalFiStatement.sch2ValuationReason
      },
      set(value) {
        this.$store.commit("personalFiStatement/SetSch2ValuationReason", value)
      },
    },
    delinquentPaymentReason: {
      get() {
        return this.$store.state.personalFiStatement.sch3DelinquentPaymentReason
      },
      set(value) {
        this.$store.commit("personalFiStatement/SetSch3DelinquentPaymentReason", value)
      },
    },
    delinquentTaxesReason: {
      get() {
        return this.$store.state.personalFiStatement.sch3DelinquentTaxReason
      },
      set(value) {
        this.$store.commit("personalFiStatement/SetSch3DelinquentTaxReason", value)
      },
    },
    unrecordedClaims: {
      get() {
        return this.$store.state.personalFiStatement.sch3UnrecordedClaim
      },
      set(value) {
        this.$store.commit("personalFiStatement/SetSch3UnrecordedClaim", value)
      },
    },

    heldSecurities: {
      get() {
        return this.$store.state.personalFiStatement.sch4HeldSecurities
      },
      set(value) {
        this.$store.commit("personalFiStatement/SetSch4HeldSecurities", value)
      },
    },

    numOfSchedule1: {
      get() {
        return this.$store.state.personalFiStatement.num_of_sch1
      },
      set(value) {
        this.$store.commit("personalFiStatement/setNumOfSch1", value)
      },
    },
    schedule1: {
      get() {
        return this.$store.state.personalFiStatement.schedule1
      },
    },

    numOfSchedule2: {
      get() {
        return this.$store.state.personalFiStatement.num_of_sch2
      },
      set(value) {
        this.$store.commit("personalFiStatement/setNumOfSch2", value)
      },
    },
    schedule2: {
      get() {
        return this.$store.state.personalFiStatement.schedule2
      },
    },
    numOfSchedule3: {
      get() {
        return this.$store.state.personalFiStatement.num_of_sch3
      },
      set(value) {
        this.$store.commit("personalFiStatement/setNumOfSch3", value)
      },
    },
    schedule3: {
      get() {
        return this.$store.state.personalFiStatement.schedule3
      },
    },
    numOfSchedule4: {
      get() {
        return this.$store.state.personalFiStatement.num_of_sch4
      },
      set(value) {
        this.$store.commit("personalFiStatement/setNumOfSch4", value)
      },
    },
    schedule4: {
      get() {
        return this.$store.state.personalFiStatement.schedule4
      },
    },
    numOfSchedule5: {
      get() {
        return this.$store.state.personalFiStatement.num_of_sch5
      },
      set(value) {
        this.$store.commit("personalFiStatement/setNumOfSch5", value)
      },
    },
    schedule5: {
      get() {
        return this.$store.state.personalFiStatement.schedule5
      },
    },
  },
  mounted() {
    this.setType("commercial-loan")
    jQuery("html, body").animate({ scrollTop: 0 })

    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    document.getElementById("current_date").innerHTML = month + "/" + day + "/" + year
  },
  methods: {
    ...mapActions("personalFiStatement", [
      "setCurrentPage",
      "setType",
      "setAppFirstName",
      "setAppBankerIndicator",
      "setAppPreferredBankerName",
      "setAppMiddleInitial",
      "setAppLastName",
      "setAppEmail",
      "setAppPhoneNumber",
      "setAppCompanyPosition",
      "setAppAgreeToTerms",
      "setValidatedNavPage",
      "setNumOfSch1",
      "setNumOfSch2",
      "setNumOfSch3",
      "setNumOfSch4",
      "setNumOfSch5",
      "setSchedule1",
      "setSchedule2",
      "setSchedule3",
      "setSchedule4",
      "setSchedule5",
      "appendSch1",
      "appendSch2",
      "appendSch3",
      "appendSch4",
      "appendSch5",
      "setCurrentPage",
      "setType",
      "setValidatedNavPage",
      "SetSch2ValuationReason",
      "SetSch3DelinquentPaymentReason",
      "SetSch3DelinquentTaxReason",
      "SetSch3UnrecordedClaim",
      "SetSch4HeldSecurities",
    ]),
    closeSchOneDeleteModal() {
      this.schOneDeleteModal = false
      this.$nextTick(() => {
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", false)
        bg.removeAttribute("inert")
        document.getElementById("add-schedule-1-btn").focus()
      })
    },
    closeSchTwoDeleteModal() {
      this.schTwoDeleteModal = false
      this.$nextTick(() => {
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", false)
        bg.removeAttribute("inert")
        document.getElementById("add-schedule-2-btn").focus()
      })
    },
    closeSchThreeDeleteModal() {
      this.schThreeDeleteModal = false
      this.$nextTick(() => {
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", false)
        bg.removeAttribute("inert")
        document.getElementById("add-schedule-3-btn").focus()
      })
    },
    closeSchFourDeleteModal() {
      this.schFourDeleteModal = false
      this.$nextTick(() => {
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", false)
        bg.removeAttribute("inert")
        document.getElementById("add-schedule-4-btn").focus()
      })
    },
    closeSchFiveDeleteModal() {
      this.schFiveDeleteModal = false
      this.$nextTick(() => {
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", false)
        bg.removeAttribute("inert")
        document.getElementById("add-schedule-5-btn").focus()
      })
    },
    focusModal() {
      this.$nextTick(() => {
        const modal = document.getElementById("modal-container")
        modal.focus()
        const bg = document.getElementById("assets-and-liabilities-id")
        bg.setAttribute("aria-hidden", true)
        bg.setAttribute("inert", "")
      })
    },
    initSchOneDelete(index) {
      this.schOneRowIndex = index
      this.schOneDeleteModal = true
      this.focusModal()
    },
    initSchTwoDelete(index) {
      this.schTwoRowIndex = index
      this.schTwoDeleteModal = true
      this.focusModal()
    },
    initSchThreeDelete(index) {
      this.schThreeRowIndex = index
      this.schThreeDeleteModal = true
      this.focusModal()
    },
    initSchFourDelete(index) {
      this.schFourRowIndex = index
      this.schFourDeleteModal = true
      this.focusModal()
    },
    initSchFiveDelete(index) {
      this.schFiveRowIndex = index
      this.schFiveDeleteModal = true
      this.focusModal()
    },
    sumOfAssets() {
      let tot =
        this.formatMoneyFromString(this.assets_cash_in_fhb) +
        this.formatMoneyFromString(this.assets_cash_in_other) +
        this.formatMoneyFromString(this.assets_acc_receivable) +
        this.formatMoneyFromString(this.assets_due_friends) +
        this.formatMoneyFromString(this.assets_notes_receivable) +
        this.formatMoneyFromString(this.assets_real_estate_residence) +
        this.formatMoneyFromString(this.assets_real_estate_investments) +
        this.formatMoneyFromString(this.assets_marketable_securities) +
        this.formatMoneyFromString(this.assets_retirement_acc) +
        this.formatMoneyFromString(this.assets_life_insurance) +
        this.formatMoneyFromString(this.assets_investment_business) +
        this.formatMoneyFromString(this.assets_other_inv) +
        this.formatMoneyFromString(this.assets_personal_prop) +
        this.formatMoneyFromString(this.assets_auto) +
        this.formatMoneyFromString(this.assets_other)
      let ret = `$${tot}`
      this.assets_total = ret
      return ret
    },
    sumOfLiabilities() {
      let tot =
        this.formatMoneyFromString(this.liabilities_loans_fhb) +
        this.formatMoneyFromString(this.liabilities_loans_other) +
        this.formatMoneyFromString(this.liabilities_charge_cards) +
        this.formatMoneyFromString(this.liabilities_acc_payable) +
        this.formatMoneyFromString(this.liabilities_due_friends) +
        this.formatMoneyFromString(this.liabilities_notes_payable) +
        this.formatMoneyFromString(this.liabilities_mortgages) +
        this.formatMoneyFromString(this.liabilities_income_taxes) +
        this.formatMoneyFromString(this.liabilities_other_taxes) +
        this.formatMoneyFromString(this.liabilities_life_insurance) +
        this.formatMoneyFromString(this.liabilities_other) +
        this.formatMoneyFromString(this.liabilities_auto)
      let ret = `$${tot}`
      this.liabilities_total = ret
      return ret
    },
    calculateNetWorth() {
      let tot =
        this.formatMoneyFromString(this.assets_running_total) -
        this.formatMoneyFromString(this.liabilities_running_total)
      let ret = `$${tot}`
      this.liabilities_net_worth = ret
      return ret
    },
    formatMoneyFromString(mon) {
      if (mon == "") {
        return 0
      }
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    async putPagePersonalFi(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.putPagePersonalFi(this.application_id, payload, this.jwt_token)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          // update breadcrumb state
          this.setValidatedNavPage({ name: "personal", isValid: 1 })

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Annual Income And Expenses" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    handleBreadcrumbClick(pageNum) {
      if (pageNum < this.currentPage) {
        this.handleBack()
      }
    },
    handleBack() {
      // update values in vuex on back click.
      this.backCreateSchedule1()
      this.backCreateSchedule2()
      this.backCreateSchedule3()
      this.backCreateSchedule4()
      this.backCreateSchedule5()

      this.$router.push({ name: "PersonalFi Personal Info" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")

      this.validatePage()

      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length

        if (!this.errCnt) {
          this.createSchedule1()
          this.createSchedule2()
          this.createSchedule3()
          this.createSchedule4()
          this.createSchedule5()

          const payload = {
            page: this.currentPage,
            asset_fhb_cash: this.assets_cash_in_fhb
              ? parseFloat(this.assets_cash_in_fhb.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_other_cash: this.assets_cash_in_other
              ? parseFloat(this.assets_cash_in_other.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_account_receivable: this.assets_acc_receivable
              ? parseFloat(this.assets_acc_receivable.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_due_from_friends: this.assets_due_friends
              ? parseFloat(this.assets_due_friends.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_schedule_1: this.assets_notes_receivable
              ? parseFloat(this.assets_notes_receivable.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_schedule_2: this.assets_real_estate_residence
              ? parseFloat(
                  this.assets_real_estate_residence.replaceAll(",", "").replaceAll("$", "")
                )
              : 0,
            asset_schedule_3: this.assets_real_estate_investments
              ? parseFloat(
                  this.assets_real_estate_investments.replaceAll(",", "").replaceAll("$", "")
                )
              : 0,
            asset_schedule_4: this.assets_marketable_securities
              ? parseFloat(
                  this.assets_marketable_securities.replaceAll(",", "").replaceAll("$", "")
                )
              : 0,
            asset_retirement_accounts: this.assets_retirement_acc
              ? parseFloat(this.assets_retirement_acc.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_life_insurance: this.assets_life_insurance
              ? parseFloat(this.assets_life_insurance.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_investment_business: this.assets_investment_business
              ? parseFloat(this.assets_investment_business.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_investment_other: this.assets_other_inv
              ? parseFloat(this.assets_other_inv.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_investment_other_explanation: this.assets_other_inv_desc,
            asset_property: this.assets_personal_prop
              ? parseFloat(this.assets_personal_prop.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_auto: this.assets_auto
              ? parseFloat(this.assets_auto.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_other: this.assets_other
              ? parseFloat(this.assets_other.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            asset_other_explanation: this.assets_other_desc,
            asset_total: this.assets_total
              ? parseFloat(this.assets_total.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_fhb: this.liabilities_loans_fhb
              ? parseFloat(this.liabilities_loans_fhb.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_other: this.liabilities_loans_other
              ? parseFloat(this.liabilities_loans_other.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_card_payable: this.liabilities_charge_cards
              ? parseFloat(this.liabilities_charge_cards.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_account_payable: this.liabilities_acc_payable
              ? parseFloat(this.liabilities_acc_payable.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_due_to_friends: this.liabilities_due_friends
              ? parseFloat(this.liabilities_due_friends.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_notes_payable: this.liabilities_notes_payable
              ? parseFloat(this.liabilities_notes_payable.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_mortgage: this.liabilities_mortgages
              ? parseFloat(this.liabilities_mortgages.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_income_tax: this.liabilities_income_taxes
              ? parseFloat(this.liabilities_income_taxes.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_other_tax: this.liabilities_other_taxes
              ? parseFloat(this.liabilities_other_taxes.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_life_insurance: this.liabilities_life_insurance
              ? parseFloat(this.liabilities_life_insurance.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_liabilities: this.liabilities_other
              ? parseFloat(this.liabilities_other.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_auto: this.liabilities_auto
              ? parseFloat(this.liabilities_auto.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_total_liabilities: this.liabilities_total
              ? parseFloat(this.liabilities_total.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_net_worth: this.liabilities_net_worth
              ? parseFloat(this.liabilities_net_worth.replaceAll(",", "").replaceAll("$", ""))
              : 0,
            loan_total: this.liabilities_total_loan
              ? parseFloat(this.liabilities_total_loan.replaceAll(",", "").replaceAll("$", ""))
              : 0,

            schedule_1: this.schedule1,
            schedule_2: this.schedule2,
            schedule_2_reason: this.Sch2ValuationReason,
            schedule_3: this.schedule3,
            schedule_3_principal_delinquent: this.delinquentPaymentReason,
            schedule_3_taxes_delinquent: this.delinquentTaxesReason,
            schedule_3_unrecorded: this.unrecordedClaims,
            schedule_4: this.schedule4,
            schedule_4_held_names: this.heldSecurities,
            schedule_5: this.schedule5,
          }

          this.putPagePersonalFi(payload)
        } else {
          this.setValidatedNavPage({ name: "personal", isValid: 0 })

          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missingCashFhb || this.invalidCashFhb)
              offset = jQuery("#assets-cash-in-fhb").offset().top

            if (this.missingLoansFhb || this.invalidLoansFhb)
              offset = jQuery("#liabilities-loans-fhb").offset().top
            if (this.missingCashOther || this.invalidCashOther)
              offset = jQuery("#assets-cash-in-other").offset().top
            if (this.missingLoansOther || this.invalidLoansOther)
              offset = jQuery("#liabilities-loans-other").offset().top
            if (this.missingChargeCards || this.invalidChargeCards)
              offset = jQuery("#liabilities-charge-cards").offset().top
            if (this.missingAccRec || this.invalidAccRec)
              offset = jQuery("#assets-acc-rec").offset().top
            if (this.missingAccPayable || this.invalidAccPayable)
              offset = jQuery("#liabilities-acc-payable").offset().top
            if (this.missingAssetsDueFriends || this.invalidAssetsDueFriends)
              offset = jQuery("#assets-due-friends").offset().top
            if (this.missingLiabilitiesDueFriends || this.invalidLiabilitiesDueFriends)
              offset = jQuery("#liabilities-due-friends").offset().top
            if (this.missingNotesRec || this.invalidNotesRec)
              offset = jQuery("#assets-notes-rec").offset().top
            if (this.missingNotesPayable || this.invalidNotesPayable)
              offset = jQuery("#liabilities-notes-pay").offset().top
            if (this.missingRealEstateRes || this.invalidRealEstateRes)
              offset = jQuery("#assets-estate-res").offset().top
            if (this.missingRealEstateInv || this.invalidRealEstateInv)
              offset = jQuery("#assets-estate-inv").offset().top
            if (this.missingMortgage || this.invalidMortgage)
              offset = jQuery("#liabilities-mortgages").offset().top
            if (this.missingMarketableSec || this.invalidMarketableSec)
              offset = jQuery("#assets-marketable-sec").offset().top
            if (this.missingIncomeTax || this.invalidIncomeTax)
              offset = jQuery("#liabilities-income-taxes").offset().top
            if (this.missingRetirement || this.invalidRetirement)
              offset = jQuery("#assets-retirement-acc").offset().top
            if (this.missingOtherTax || this.invalidOtherTax)
              offset = jQuery("#liabilities-other-taxes").offset().top
            if (this.missingLifeInsurance || this.invalidLifeInsurance)
              offset = jQuery("#assets-life-insurance").offset().top
            if (this.missingLifeInsLoan || this.invalidLifeInsLoan)
              offset = jQuery("#liabilities-life-insurance").offset().top
            if (this.missingInvestBus || this.invalidInvestBus)
              offset = jQuery("#assets-investment-bus").offset().top
            if (this.missingLiabilitiesOther || this.invalidLiabilitiesOther)
              offset = jQuery("#liabilities-other").offset().top
            if (this.missingOtherInv || this.invalidOtherInv)
              offset = jQuery("#assets-other-investments").offset().top
            if (this.missingPersonalProp || this.invalidPersonalProp)
              offset = jQuery("#assets-personal-prop").offset().top
            if (this.missingAutoLoan || this.invalidAutoLoan)
              offset = jQuery("#liabilities-auto").offset().top
            if (this.missingAuto || this.invalidAuto) offset = jQuery("#assets-auto").offset().top
            if (this.missingLiabilitiesTotal || this.invalidLiabilitiesTotal)
              offset = jQuery("#liabilities-total-liabilities").offset().top
            if (this.missingAssetsOther || this.invalidAssetsOther)
              offset = jQuery("#assets-other").offset().top
            if (this.missingNetWorth || this.invalidNetWorth)
              offset = jQuery("#net-worth").offset().top
            if (this.missingAssetsTotal || this.invalidAssetsTotal)
              offset = jQuery("#assets-total").offset().top
            if (this.missingLiabilitiesTotalLoan || this.invalidLiabilitiesTotalLoan)
              offset = jQuery("#liabilities-total-loan").offset().top

            // schedules errors
            if (
              this.missingSch1DebtorName.includes(true) ||
              this.missingSch1AmtDue.includes(true) ||
              this.invalidSch1AmtDue.includes(true) ||
              this.missingSch1Payable.includes(true) ||
              this.missingSch1Maturity.includes(true) ||
              this.invalidSch1Maturity.includes(true) ||
              this.missingSch1Remarks.includes(true)
            )
              offset = jQuery(`#sch1-name-of-debtor`).offset().top

            if (
              this.missingSch2PropAddr.includes(true) ||
              this.missingSch2DateAcq.includes(true) ||
              this.invalidSch2DateAcq.includes(true) ||
              this.invalidSch2MonthlyRentalInc.includes(true) ||
              this.missingSch2MonthlyRentalInc.includes(true) ||
              this.missingSch2Title.includes(true) ||
              this.invalidSch2Valuation.includes(true) ||
              this.missingSch2Valuation.includes(true) ||
              this.invalidSch2BalanceOwed.includes(true) ||
              this.missingSch2BalanceOwed.includes(true)
            )
              offset = jQuery(`#sch2-prop-addr`).offset().top

            if (this.missingSch2ValuationReason) offset = jQuery("#valuation-reason").offset().top

            if (
              this.missingSch3Payable.includes(true) ||
              this.missingSch3BalanceOwed.includes(true) ||
              this.invalidSch3BalanceOwed.includes(true) ||
              this.missingSch3InterestRate.includes(true) ||
              this.invalidSch3InterestRate.includes(true) ||
              this.missingSch3Maturity.includes(true) ||
              this.invalidSch3Maturity.includes(true) ||
              this.missingSch3MonthlyPayment.includes(true) ||
              this.invalidSch3MonthlyPayment.includes(true)
            )
              offset = jQuery(`#sch3-payable`).offset().top

            if (this.missingDelinquentPrincipal)
              offset = jQuery("#delinquent-principal").offset().top
            if (this.missingDelinquentTaxes) offset = jQuery("#delinquent-taxes").offset().top
            if (this.missingUnrecordedClaims) offset = jQuery("#unrecorded-claims").offset().top

            if (
              this.missingSch4Type.includes(true) ||
              this.invalidSch4StockShares.includes(true) ||
              this.missingSch4StockShares.includes(true) ||
              this.invalidSch4ValueCarried.includes(true) ||
              this.missingSch4ValueCarried.includes(true) ||
              this.missingSch4ListedMkt.includes(true) ||
              this.invalidSch4ListedMktAmt.includes(true) ||
              this.missingSch4ListedMktAmt.includes(true) ||
              this.missingSch4UnlistedValue.includes(true) ||
              this.invalidSch4UnlistedValueAmt.includes(true) ||
              this.missingSch4UnlistedValueAmt.includes(true) ||
              this.invalidSch4UnlistedValueYrlyInc.includes(true) ||
              this.missingSch4UnlistedValueYrlyInc.includes(true)
            )
              offset = jQuery(`#sch4-type`).offset().top

            if (this.missingHeldSecurities) offset = jQuery("#held-securities").offset().top

            if (
              this.invalidSch5PolicyAmt.includes(true) ||
              this.missingSch5PolicyAmt.includes(true) ||
              this.invalidSch5Cash.includes(true) ||
              this.missingSch5Cash.includes(true) ||
              this.invalidSch5Loan.includes(true) ||
              this.missingSch5Loan.includes(true) ||
              this.invalidSch5NetCash.includes(true) ||
              this.missingSch5NetCash.includes(true)
            )
              offset = jQuery(`#sch5-policy-amt`).offset().top

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      })
    },
    validatePage() {
      this.validateCashFhb()
      this.validateCashOther()
      this.validateAccRec()
      this.validateAssetsDueFriends()
      this.validateNotesRec()
      this.validateRealEstateRes()
      this.validateRealEstateInv()
      this.validateMarketableSec()
      this.validateRetirement()
      this.validateLifeIns()
      this.validateInvestBus()
      this.validateOtherInv()
      this.validatePersonalProp()
      this.validateAuto()
      this.validateAssetsOther()
      this.validateAssetsTotal()

      this.validateLoansFhb()
      this.validateLoansOther()
      this.validateChargeCards()
      this.validateAccPayable()
      this.validateLiabilitiesDueFriends()
      this.validateNotesPayable()
      this.validateMortgage()
      this.validateIncomeTax()
      this.validateOtherTax()
      this.validateLifeInsLoan()
      this.validateLiabilitiesOther()
      this.validateAutoLoan()
      this.validateLiabilitiesTotal()
      this.validateNetWorth()
      this.validateLiabilitiesTotalLoan()

      //  validate schedules
      for (let i = 0; i < this.numOfSchedule1; i++) {
        // handle empty row (added by accident?)
        if (i != 0 && this.isEmptySch1(i)) {
          this.deleteSchedule1(i)
          i--
          continue
        }
        this.validateSchedule1(i)
      }
      for (let i = 0; i < this.numOfSchedule2; i++) {
        if (i != 0 && this.isEmptySch2(i)) {
          this.deleteSchedule2(i)
          i--
          continue
        }
        this.validateSchedule2(i)
      }
      for (let i = 0; i < this.numOfSchedule3; i++) {
        if (i != 0 && this.isEmptySch3(i)) {
          this.deleteSchedule3(i)
          i--
          continue
        }
        this.validateSchedule3(i)
      }
      for (let i = 0; i < this.numOfSchedule4; i++) {
        if (i != 0 && this.isEmptySch4(i)) {
          this.deleteSchedule4(i)
          i--
          continue
        }
        this.validateSchedule4(i)
      }
      for (let i = 0; i < this.numOfSchedule5; i++) {
        if (i != 0 && this.isEmptySch5(i)) {
          this.deleteSchedule5(i)
          i--
          continue
        }
        this.validateSchedule5(0)
      }
      this.validateSch2ValuationReason()
      this.validateHeldSecurities()
      this.validateUnrecordedClaims()
    },
    validateCashFhb() {
      this.missingCashFhb = this.assets_cash_in_fhb ? false : true
      if (!this.missingCashFhb) {
        this.invalidCashFhb = Validator.isValidMoney(this.assets_cash_in_fhb) ? false : true
      }
    },
    validateCashOther() {
      this.missingCashOther = this.assets_cash_in_other ? false : true
      if (!this.missingCashOther) {
        this.invalidCashOther = Validator.isValidMoney(this.assets_cash_in_other) ? false : true
      }
    },
    validateAccRec() {
      this.missingAccRec = this.assets_acc_receivable ? false : true
      if (!this.missingAccRec) {
        this.invalidAccRec = Validator.isValidMoney(this.assets_acc_receivable) ? false : true
      }
    },
    validateAssetsDueFriends() {
      this.missingAssetsDueFriends = this.assets_due_friends ? false : true
      if (!this.missingAssetsDueFriends) {
        this.invalidAssetsDueFriends = Validator.isValidMoney(this.assets_due_friends)
          ? false
          : true
      }
    },
    validateNotesRec() {
      this.missingNotesRec = this.assets_notes_receivable ? false : true
      if (!this.missingNotesRec) {
        this.invalidNotesRec = Validator.isValidMoney(this.assets_notes_receivable) ? false : true
      }
    },
    validateRealEstateRes() {
      this.missingRealEstateRes = this.assets_real_estate_residence ? false : true
      if (!this.missingRealEstateRes) {
        this.invalidRealEstateRes = Validator.isValidMoney(this.assets_real_estate_residence)
          ? false
          : true
      }
    },
    validateRealEstateInv() {
      this.missingRealEstateInv = this.assets_real_estate_investments ? false : true
      if (!this.missingRealEstateInv) {
        this.invalidRealEstateInv = Validator.isValidMoney(this.assets_real_estate_investments)
          ? false
          : true
      }
    },
    validateMarketableSec() {
      this.missingMarketableSec = this.assets_marketable_securities ? false : true
      if (!this.missingMarketableSec) {
        this.invalidMarketableSec = Validator.isValidMoney(this.assets_marketable_securities)
          ? false
          : true
      }
    },
    validateRetirement() {
      this.missingRetirement = this.assets_retirement_acc ? false : true
      if (!this.missingRetirement) {
        this.invalidRetirement = Validator.isValidMoney(this.assets_retirement_acc) ? false : true
      }
    },
    validateLifeIns() {
      this.missingLifeInsurance = this.assets_life_insurance ? false : true
      if (!this.missingLifeInsurance) {
        this.invalidLifeInsurance = Validator.isValidMoney(this.assets_life_insurance)
          ? false
          : true
      }
    },
    validateInvestBus() {
      this.missingInvestBus = this.assets_investment_business ? false : true
      if (!this.missingInvestBus) {
        this.invalidInvestBus = Validator.isValidMoney(this.assets_investment_business)
          ? false
          : true
      }
    },
    validateOtherInv() {
      this.missingOtherInv = this.assets_other_inv ? false : true
      if (!this.missingOtherInv) {
        this.invalidOtherInv = Validator.isValidMoney(this.assets_other_inv) ? false : true
      }
    },
    validatePersonalProp() {
      this.missingPersonalProp = this.assets_personal_prop ? false : true
      if (!this.missingPersonalProp) {
        this.invalidPersonalProp = Validator.isValidMoney(this.assets_personal_prop) ? false : true
      }
    },
    validateAuto() {
      this.missingAuto = this.assets_auto ? false : true
      if (!this.missingAuto) {
        this.invalidAuto = Validator.isValidMoney(this.assets_auto) ? false : true
      }
    },
    validateAssetsOther() {
      this.missingAssetsOther = this.assets_other ? false : true
      if (!this.missingAssetsOther) {
        this.invalidAssetsOther = Validator.isValidMoney(this.assets_other) ? false : true
      }
    },
    validateAssetsTotal() {
      this.missingAssetsTotal = this.assets_total ? false : true
      if (!this.missingAssetsTotal) {
        this.invalidAssetsTotal = Validator.isValidMoney(this.assets_total) ? false : true
      }
    },
    validateLoansFhb() {
      this.missingLoansFhb = this.liabilities_loans_fhb ? false : true
      if (!this.missingLoansFhb) {
        this.invalidLoansFhb = Validator.isValidMoney(this.liabilities_loans_fhb) ? false : true
      }
    },
    validateLoansOther() {
      this.missingLoansOther = this.liabilities_loans_other ? false : true
      if (!this.missingLoansOther) {
        this.invalidLoansOther = Validator.isValidMoney(this.liabilities_loans_other) ? false : true
      }
    },
    validateChargeCards() {
      this.missingChargeCards = this.liabilities_charge_cards ? false : true
      if (!this.missingChargeCards) {
        this.invalidChargeCards = Validator.isValidMoney(this.liabilities_charge_cards)
          ? false
          : true
      }
    },
    validateAccPayable() {
      this.missingAccPayable = this.liabilities_acc_payable ? false : true
      if (!this.missingAccPayable) {
        this.invalidAccPayable = Validator.isValidMoney(this.liabilities_acc_payable) ? false : true
      }
    },
    validateLiabilitiesDueFriends() {
      this.missingLiabilitiesDueFriends = this.liabilities_due_friends ? false : true
      if (!this.missingLiabilitiesDueFriends) {
        this.invalidLiabilitiesDueFriends = Validator.isValidMoney(this.liabilities_due_friends)
          ? false
          : true
      }
    },
    validateNotesPayable() {
      this.missingNotesPayable = this.liabilities_notes_payable ? false : true
      if (!this.missingNotesPayable) {
        this.invalidNotesPayable = Validator.isValidMoney(this.liabilities_notes_payable)
          ? false
          : true
      }
    },
    validateMortgage() {
      this.missingMortgage = this.liabilities_mortgages ? false : true
      if (!this.missingMortgage) {
        this.invalidMortgage = Validator.isValidMoney(this.liabilities_mortgages) ? false : true
      }
    },
    validateIncomeTax() {
      this.missingIncomeTax = this.liabilities_income_taxes ? false : true
      if (!this.missingIncomeTax) {
        this.invalidIncomeTax = Validator.isValidMoney(this.liabilities_income_taxes) ? false : true
      }
    },
    validateOtherTax() {
      this.missingOtherTax = this.liabilities_other_taxes ? false : true
      if (!this.missingOtherTax) {
        this.invalidOtherTax = Validator.isValidMoney(this.liabilities_other_taxes) ? false : true
      }
    },
    validateLifeInsLoan() {
      this.missingLifeInsLoan = this.liabilities_life_insurance ? false : true
      if (!this.missingLifeInsLoan) {
        this.invalidLifeInsLoan = Validator.isValidMoney(this.liabilities_life_insurance)
          ? false
          : true
      }
    },
    validateLiabilitiesOther() {
      this.missingLiabilitiesOther = this.liabilities_other ? false : true
      if (!this.missingLiabilitiesOther) {
        this.invalidLiabilitiesOther = Validator.isValidMoney(this.liabilities_other) ? false : true
      }
    },
    validateAutoLoan() {
      this.missingAutoLoan = this.liabilities_auto ? false : true
      if (!this.missingAutoLoan) {
        this.invalidAutoLoan = Validator.isValidMoney(this.liabilities_auto) ? false : true
      }
    },
    validateLiabilitiesTotal() {
      this.missingLiabilitiesTotal = this.liabilities_total ? false : true
      if (!this.missingLiabilitiesTotal) {
        this.invalidLiabilitiesTotal = Validator.isValidMoney(this.liabilities_total) ? false : true
      }
    },
    validateNetWorth() {
      this.missingNetWorth = this.liabilities_net_worth ? false : true
      // if (!this.missingNetWorth) {
      //   this.invalidNetWorth = Validator.isValidMoney(this.liabilities_net_worth) ? false : true;
      // }
    },
    validateLiabilitiesTotalLoan() {
      this.missingLiabilitiesTotalLoan = this.liabilities_total_loan ? false : true
      if (!this.missingLiabilitiesTotalLoan) {
        this.invalidLiabilitiesTotalLoan = Validator.isValidMoney(this.liabilities_total_loan)
          ? false
          : true
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
    formatMoneyToString(mon) {
      if (!mon) return ""
      return `$${mon}`
    },
    formatPercentageToString(per) {
      if (!per) return ""
      return `${per}%`
    },

    // schedules
    pushSch1Errors() {
      this.missingSch1DebtorName.push(false)
      this.missingSch1AmtDue.push(false)
      this.invalidSch1AmtDue.push(false)
      this.missingSch1Payable.push(false)
      this.missingSch1Maturity.push(false)
      this.invalidSch1Maturity.push(false)
      this.missingSch1Remarks.push(false)
    },
    pushSch2Errors() {
      this.missingSch2PropAddr.push(false)
      this.invalidSch2DateAcq.push(false)
      this.missingSch2DateAcq.push(false)
      this.missingSch2MonthlyRentalInc.push(false)
      this.invalidSch2MonthlyRentalInc.push(false)
      this.missingSch2Title.push(false)
      this.missingSch2Valuation.push(false)
      this.invalidSch2Valuation.push(false)
      this.missingSch2BalanceOwed.push(false)
      this.invalidSch2BalanceOwed.push(false)
    },
    pushSch3Errors() {
      this.missingSch3Payable.push(false)
      this.missingSch3BalanceOwed.push(false)
      this.invalidSch3BalanceOwed.push(false)
      this.missingSch3InterestRate.push(false)
      this.invalidSch3InterestRate.push(false)
      this.missingSch3Maturity.push(false)
      this.invalidSch3Maturity.push(false)
      this.missingSch3MonthlyPayment.push(false)
      this.invalidSch3MonthlyPayment.push(false)
    },
    pushSch4Errors() {
      this.missingSch4Type.push(false)
      this.missingSch4StockShares.push(false)
      this.invalidSch4StockShares.push(false)
      this.missingSch4ValueCarried.push(false)
      this.invalidSch4ValueCarried.push(false)
      this.missingSch4ListedMkt.push(false)
      this.missingSch4ListedMktAmt.push(false)
      this.invalidSch4ListedMktAmt.push(false)
      this.missingSch4UnlistedValue.push(false)
      this.missingSch4UnlistedValueAmt.push(false)
      this.invalidSch4UnlistedValueAmt.push(false)
      this.missingSch4UnlistedValueYrlyInc.push(false)
      this.invalidSch4UnlistedValueYrlyInc.push(false)
    },
    pushSch5Errors() {
      this.missingSch5Beneficiary.push(false)
      this.missingSch5PolicyAmt.push(false)
      this.invalidSch5PolicyAmt.push(false)
      this.missingSch5Cash.push(false)
      this.invalidSch5Cash.push(false)
      this.missingSch5Loan.push(false)
      this.invalidSch5Loan.push(false)
      this.missingSch5NetCash.push(false)
      this.invalidSch5NetCash.push(false)
    },

    validateSchedule1(i) {
      if (this.isEmptySch1(i)) return
      this.validateSch1DebtorName(i)
      this.validateSch1AmtDue(i)
      this.validateSch1Payable(i)
      this.validateSch1Maturity(i)
      this.validateSch1Remarks(i)
    },
    isEmptySch1(i) {
      return (
        !this.sch1_debtorName[i] &&
        !this.sch1_amtDue[i] &&
        !this.sch1_payable[i] &&
        !this.sch1_maturity[i] &&
        !this.sch1_remarks[i]
      )
    },
    isEmptySch2(i) {
      return (
        !this.sch2_propAddr[i] &&
        !this.sch2_dateAcq[i] &&
        !this.sch2_monthlyRentalInc[i] &&
        !this.sch2_title[i] &&
        !this.sch2_valuation[i] &&
        !this.sch2_balanceOwed[i]
      )
    },
    isEmptySch3(i) {
      return (
        !this.sch3_payable[i] &&
        !this.sch3_balanceOwed[i] &&
        !this.sch3_interestRate[i] &&
        !this.sch3_maturity[i] &&
        !this.sch3_monthlyPayment[i]
      )
    },
    isEmptySch4(i) {
      return (
        !this.sch4_type[i] &&
        !this.sch4_stockShares[i] &&
        !this.sch4_valueCarried[i] &&
        !this.sch4_listedMkt[i] &&
        !this.sch4_listedMktAmt[i] &&
        !this.sch4_unlistedValue[i] &&
        !this.sch4_unlistedValueAmt[i] &&
        !this.sch4_unlistedValueYrlyInc[i]
      )
    },
    isEmptySch5(i) {
      return (
        !this.sch5_beneficiary[i] &&
        !this.sch5_policyAmt[i] &&
        !this.sch5_cash[i] &&
        !this.sch5_loan[i] &&
        !this.sch5_netCash[i]
      )
    },
    validateUnrecordedClaims() {
      this.missingUnrecordedClaims = false
      if (this.numOfSchedule3 <= 1 && this.isEmptySch3(0)) return
      this.missingUnrecordedClaims = this.unrecordedClaims ? false : true
    },
    validateDelinquentPrincipal() {
      this.missingDelinquentPrincipal = false
      if (this.numOfSchedule3 <= 1 && this.isEmptySch3(0)) return
      this.missingDelinquentPrincipal = this.delinquentPaymentReason ? false : true
    },
    validateDelinquentTaxes() {
      this.missingDelinquentTaxes = false
      if (this.numOfSchedule3 <= 1 && this.isEmptySch3(0)) return
      this.missingDelinquentTaxes = this.delinquentTaxesReason ? false : true
    },

    validateHeldSecurities() {
      this.missingHeldSecurities = false
      if (this.numOfSchedule4 <= 1 && this.isEmptySch4(0)) return
      this.missingHeldSecurities = this.heldSecurities ? false : true
    },

    validateSch2ValuationReason() {
      this.missingSch2ValuationReason = false
      if (this.numOfSchedule2 <= 1 && this.isEmptySch2(0)) return
      this.missingSch2ValuationReason = this.Sch2ValuationReason ? false : true
    },

    validateSch1AmtDue(i) {
      let total
      this.missingSch1AmtDue[i] = false
      this.invalidSch1AmtDue[i] = false
      if (this.isEmptySch1(i)) return
      this.missingSch1AmtDue[i] = this.sch1_amtDue[i] ? false : true
      if (!this.missingSch1AmtDue[i]) {
        this.invalidSch1AmtDue[i] = Validator.isValidMoney(this.sch1_amtDue[i]) ? false : true
      }
      // add amounts and set to notes receivable value
      for (let j = 0; j < this.sch1_amtDue.length; j++) {
        if (j == 0) {
          total = this.formatMoneyFromString(this.sch1_amtDue[j])
        } else {
          total += this.formatMoneyFromString(this.sch1_amtDue[j])
        }
      }
      this.assets_notes_receivable = this.formatMoneyToString(total)
      this.checkAddBtnSchedule1(i)
    },
    validateSch1DebtorName(i) {
      this.missingSch1DebtorName[i] = false
      if (this.isEmptySch1(i)) return
      this.missingSch1DebtorName[i] = this.sch1_debtorName[i] ? false : true
      this.checkAddBtnSchedule1(i)
    },
    validateSch1Payable(i) {
      this.missingSch1Payable[i] = false
      if (this.isEmptySch1(i)) return
      this.missingSch1Payable[i] = this.sch1_payable[i] ? false : true
      this.checkAddBtnSchedule1(i)
    },
    validateSch1Maturity(i) {
      this.missingSch1Maturity[i] = false
      this.invalidSch1Maturity[i] = false
      if (this.isEmptySch1(i)) return
      this.missingSch1Maturity[i] = this.sch1_maturity[i] ? false : true
      if (!this.missingSch1Maturity[i]) {
        this.invalidSch1Maturity[i] = Validator.isValidDateFormat(this.sch1_maturity[i])
          ? false
          : true
        this.checkAddBtnSchedule1(i)
      }
    },
    validateSch1Remarks(i) {
      this.missingSch1Remarks[i] = false
      if (this.isEmptySch1(i)) return
      this.missingSch1Remarks[i] = this.sch1_remarks[i] ? false : true
      this.checkAddBtnSchedule1(i)
    },
    validateSchedule2(i) {
      if (this.isEmptySch2(i)) return
      this.validateSch2PropAddr(i)
      this.validateSch2DateAcq(i)
      this.validateSch2MonthlyRentalInc(i)
      this.validateSch2Title(i)
      this.validateSch2Valuation(i)
      this.validateSch2BalanceOwed(i)
    },
    validateSch2PropAddr(i) {
      this.missingSch2PropAddr[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2PropAddr[i] = this.sch2_propAddr[i] ? false : true
      this.checkAddBtnSchedule2(i)
    },
    validateSch2DateAcq(i) {
      this.missingSch2DateAcq[i] = false
      this.invalidSch2DateAcq[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2DateAcq[i] = this.sch2_dateAcq[i] ? false : true
      if (!this.missingSch2DateAcq[i]) {
        this.invalidSch2DateAcq[i] = Validator.isValidDateFormat(this.sch2_dateAcq[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule2(i)
    },
    validateSch2MonthlyRentalInc(i) {
      this.missingSch2MonthlyRentalInc[i] = false
      this.invalidSch2MonthlyRentalInc[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2MonthlyRentalInc[i] = this.sch2_monthlyRentalInc[i] ? false : true
      if (!this.missingSch2MonthlyRentalInc[i]) {
        this.invalidSch2MonthlyRentalInc[i] = Validator.isValidMoney(this.sch2_monthlyRentalInc[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule2(i)
    },
    validateSch2Title(i) {
      this.missingSch2Title[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2Title[i] = this.sch2_title[i] ? false : true
      this.checkAddBtnSchedule2(i)
    },
    validateSch2Valuation(i) {
      this.missingSch2Valuation[i] = false
      this.invalidSch2Valuation[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2Valuation[i] = this.sch2_valuation[i] ? false : true
      if (!this.missingSch2Valuation[i]) {
        this.invalidSch2Valuation[i] = Validator.isValidMoney(this.sch2_valuation[i]) ? false : true
      }
      // add amounts and set to real estate residence value for assets and values
      let total = 0
      for (let j = 0; j < this.sch2_valuation.length; j++) {
        if (this.sch2_primary_address == j) {
          this.assets_real_estate_residence = this.sch2_valuation[j]
        } else {
          if (j == 0) {
            total = this.formatMoneyFromString(this.sch2_valuation[j])
          }
          if (j !== 0) {
            total += this.formatMoneyFromString(this.sch2_valuation[j])
          }
        }
      }
      this.assets_real_estate_investments = this.formatMoneyToString(total)
      this.checkAddBtnSchedule2(i)
    },
    validateSch2BalanceOwed(i) {
      this.missingSch2BalanceOwed[i] = false
      this.invalidSch2BalanceOwed[i] = false
      if (this.isEmptySch2(i)) return
      this.missingSch2BalanceOwed[i] = this.sch2_balanceOwed[i] ? false : true
      if (!this.missingSch2BalanceOwed[i]) {
        this.invalidSch2BalanceOwed[i] = Validator.isValidMoney(this.sch2_balanceOwed[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule2(i)
    },

    validateSchedule3(i) {
      if (this.isEmptySch3(i)) return
      this.validateSch3Payable(i)
      this.validateSch3BalanceOwed(i)
      this.validateSch3InterestRate(i)
      this.validateSch3Maturity(i)
      this.validateSch3MonthlyPayment(i)
    },
    validateSch3Payable(i) {
      this.missingSch3Payable[i] = false
      if (this.isEmptySch3(i)) return
      this.missingSch3Payable[i] = this.sch3_payable[i] ? false : true
      this.checkAddBtnSchedule3(i)
    },
    validateSch3BalanceOwed(i) {
      let total
      this.missingSch3BalanceOwed[i] = false
      this.invalidSch3BalanceOwed[i] = false
      if (this.isEmptySch3(i)) return
      this.missingSch3BalanceOwed[i] = this.sch3_balanceOwed[i] ? false : true
      if (!this.missingSch3BalanceOwed[i]) {
        this.invalidSch3BalanceOwed[i] = Validator.isValidMoney(this.sch3_balanceOwed[i])
          ? false
          : true
      }
      // add amounts and set to liabilities
      for (let j = 0; j < this.sch3_balanceOwed.length; j++) {
        if (j === 0) {
          total = this.formatMoneyFromString(this.sch3_balanceOwed[j])
        } else {
          total += this.formatMoneyFromString(this.sch3_balanceOwed[j])
        }
      }
      this.liabilities_mortgages = this.formatMoneyToString(total)
      this.checkAddBtnSchedule3(i)
    },
    validateSch3InterestRate(i) {
      this.missingSch3InterestRate[i] = false
      this.invalidSch3InterestRate[i] = false
      if (this.isEmptySch3(i)) return
      this.missingSch3InterestRate[i] = this.sch3_interestRate[i] ? false : true
      if (!this.missingSch3InterestRate[i]) {
        this.invalidSch3InterestRate[i] = !(
          parseFloat(this.sch3_interestRate[i].replace("%", "")) > 0 &&
          parseFloat(this.sch3_interestRate[i].replace("%", "")) <= 100
        )
      }
      this.checkAddBtnSchedule3(i)
    },
    validateSch3Maturity(i) {
      this.missingSch3Maturity[i] = false
      this.invalidSch3Maturity[i] = false
      if (this.isEmptySch3(i)) return
      this.missingSch3Maturity[i] = this.sch3_maturity[i] ? false : true
      if (!this.missingSch3Maturity[i]) {
        this.invalidSch3Maturity[i] = Validator.isValidDateFormat(this.sch3_maturity[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule3(i)
    },
    validateSch3MonthlyPayment(i) {
      this.missingSch3MonthlyPayment[i] = false
      this.invalidSch3MonthlyPayment[i] = false
      if (this.isEmptySch3(i)) return
      this.missingSch3MonthlyPayment[i] = this.sch3_monthlyPayment[i] ? false : true
      if (!this.missingSch3MonthlyPayment[i]) {
        this.invalidSch3MonthlyPayment[i] = Validator.isValidMoney(this.sch3_monthlyPayment[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule3(i)
    },

    addSchedule1(i) {
      if (
        this.isEmptySch1(this.numOfSchedule1 - 1) ||
        this.missingSch1DebtorName.includes(true) ||
        this.invalidSch1AmtDue.includes(true) ||
        this.missingSch1AmtDue.includes(true) ||
        this.missingSch1Payable.includes(true) ||
        this.missingSch1Maturity.includes(true) ||
        this.invalidSch1Maturity.includes(true) ||
        this.missingSch1Remarks.includes(true)
      ) {
        return
      }
      this.pushSch1Errors()
      this.numOfSchedule1 += 1
      this.$nextTick(() => {
        document.getElementById(`sch1-name-of-debtor-${i + 1}`).focus()
      })
    },
    checkAddBtnSchedule1(i) {
      if (
        this.sch1_debtorName[i] &&
        this.sch1_amtDue[i] &&
        this.sch1_payable[i] &&
        this.sch1_maturity[i] &&
        this.sch1_remarks[i]
      ) {
        if (!(this.invalidSch1AmtDue[i] || this.invalidSch1Maturity[i])) {
          this.scheduleOneSubmittable[i] = true
          this.$nextTick(() => {
            document.getElementById("add-schedule-1-btn").focus()
          })
        } else {
          this.scheduleOneSubmittable[i] = false
        }
      } else {
        this.scheduleOneSubmittable[i] = false
      }
    },
    checkAddBtnSchedule2(i) {
      if (
        this.sch2_propAddr[i] &&
        this.sch2_primary_address != null &&
        this.sch2_dateAcq[i] &&
        this.sch2_monthlyRentalInc[i] &&
        this.sch2_title[i] &&
        this.sch2_valuation[i] &&
        this.sch2_balanceOwed[i]
      ) {
        if (
          !this.invalidSch2DateAcq[i] &&
          !this.invalidSch2MonthlyRentalInc[i] &&
          !this.invalidSch2Valuation[i] &&
          !this.invalidSch2BalanceOwed[i]
        ) {
          this.scheduleTwoSubmittable[i] = true
          this.$nextTick(() => {
            document.getElementById("add-schedule-2-btn").focus()
          })
        } else {
          this.scheduleTwoSubmittable[i] = false
        }
      } else {
        this.scheduleTwoSubmittable[i] = false
      }
    },
    checkAddBtnSchedule3(i) {
      //validateSch3
      if (
        this.sch3_payable[i] &&
        this.sch3_balanceOwed[i] &&
        this.sch3_interestRate[i] &&
        this.sch3_maturity[i] &&
        this.sch3_monthlyPayment[i]
      ) {
        if (
          !this.invalidSch3BalanceOwed[i] &&
          !this.invalidSch3InterestRate[i] &&
          !this.invalidSch3Maturity[i] &&
          !this.invalidSch3MonthlyPayment[i]
        ) {
          this.scheduleThreeSubmittable[i] = true
          this.$nextTick(() => {
            document.getElementById("add-schedule-3-btn").focus()
          })
        } else {
          this.scheduleThreeSubmittable[i] = false
        }
      } else {
        this.scheduleThreeSubmittable[i] = false
      }
    },
    checkAddBtnSchedule4(i) {
      //validateSch4
      if (
        this.sch4_type[i] &&
        this.sch4_stockShares[i] &&
        this.sch4_valueCarried[i] &&
        this.sch4_listedMkt[i] &&
        this.sch4_listedMktAmt[i] &&
        this.sch4_unlistedValue[i] &&
        this.sch4_unlistedValueAmt[i] &&
        this.sch4_unlistedValueYrlyInc[i]
      ) {
        if (
          !this.invalidSch4StockShares[i] &&
          !this.invalidSch4ValueCarried[i] &&
          !this.invalidSch4ListedMktAmt[i] &&
          !this.invalidSch4UnlistedValueAmt[i] &&
          !this.invalidSch4UnlistedValueYrlyInc[i]
        ) {
          this.scheduleFourSubmittable[i] = true
          this.$nextTick(() => {
            document.getElementById("add-schedule-4-btn").focus()
          })
        } else {
          this.scheduleFourSubmittable[i] = false
        }
      } else {
        this.scheduleFourSubmittable[i] = false
      }
    },
    checkAddBtnSchedule5(i) {
      //validateSch5
      if (
        this.sch5_beneficiary &&
        this.sch5_policyAmt[i] &&
        this.sch5_cash[i] &&
        this.sch5_loan[i] &&
        this.sch5_netCash[i]
      ) {
        if (
          !this.invalidSch5PolicyAmt[i] &&
          !this.invalidSch5Cash[i] &&
          !this.invalidSch5Loan[i] &&
          !this.invalidSch5NetCash[i]
        ) {
          this.scheduleFiveSubmittable[i] = true
          this.$nextTick(() => {
            document.getElementById("add-schedule-5-btn").focus()
          })
        } else {
          this.scheduleFiveSubmittable[i] = false
        }
      } else {
        this.scheduleFiveSubmittable[i] = false
      }
    },

    deleteSchedule1(i) {
      this.sch1_debtorName.splice(i, 1)
      this.sch1_amtDue.splice(i, 1)
      this.sch1_payable.splice(i, 1)
      this.sch1_maturity.splice(i, 1)
      this.sch1_remarks.splice(i, 1)
      this.scheduleOneSubmittable.splice(i, 1)

      this.missingSch1DebtorName.splice(i, 1)
      this.missingSch1AmtDue.splice(i, 1)
      this.invalidSch1AmtDue.splice(i, 1)
      this.missingSch1Payable.splice(i, 1)
      this.missingSch1Maturity.splice(i, 1)
      this.invalidSch1Maturity.splice(i, 1)
      this.missingSch1Remarks.splice(i, 1)

      this.numOfSchedule1 -= 1

      // update schedule 1 field in assets section
      const total = this.sch1_amtDue.reduce((a, b) => a + this.formatMoneyFromString(b), 0)
      this.assets_notes_receivable = this.formatMoneyToString(total)

      this.closeSchOneDeleteModal()
    },

    addSchedule2(j) {
      for (var i = 0; i < this.numOfSchedule2; i++) {
        this.validateSchedule2(i)
      }
      if (
        this.isEmptySch2(this.numOfSchedule2 - 1) ||
        this.missingSch2PropAddr.includes(true) ||
        this.missingSch2DateAcq.includes(true) ||
        this.invalidSch2DateAcq.includes(true) ||
        this.invalidSch2MonthlyRentalInc.includes(true) ||
        this.missingSch2MonthlyRentalInc.includes(true) ||
        this.missingSch2Title.includes(true) ||
        this.invalidSch2Valuation.includes(true) ||
        this.missingSch2Valuation.includes(true) ||
        this.invalidSch2BalanceOwed.includes(true) ||
        this.missingSch2BalanceOwed.includes(true)
      ) {
        return
      }
      this.pushSch2Errors()
      this.numOfSchedule2 += 1
      this.$nextTick(() => {
        document.getElementById(`sch2-prop-addr-${j + 1}`).focus()
      })
    },
    deleteSchedule2(i) {
      this.sch2_propAddr.splice(i, 1)
      this.sch2_dateAcq.splice(i, 1)
      this.sch2_monthlyRentalInc.splice(i, 1)
      this.sch2_title.splice(i, 1)
      this.sch2_valuation.splice(i, 1)
      this.sch2_balanceOwed.splice(i, 1)
      this.scheduleTwoSubmittable.splice(i, 1)

      this.missingSch2PropAddr.splice(i, 1)
      this.invalidSch2DateAcq.splice(i, 1)
      this.missingSch2DateAcq.splice(i, 1)
      this.missingSch2MonthlyRentalInc.push(false)
      this.invalidSch2MonthlyRentalInc.splice(i, 1)
      this.missingSch2Title.splice(i, 1)
      this.missingSch2Valuation.splice(i, 1)
      this.invalidSch2Valuation.splice(i, 1)
      this.missingSch2BalanceOwed.splice(i, 1)
      this.invalidSch2BalanceOwed.splice(i, 1)

      this.numOfSchedule2 -= 1

      if (i == this.sch2_primary_address) this.sch2_primary_address = null
      if (i < this.sch2_primary_address) this.sch2_primary_address -= 1

      // update schedule 2 field totals in assets section
      let total = 0
      for (let j = 0; j < this.sch2_valuation.length; j++) {
        // If primary address matches index, then it still exists and wasn't deleted
        if (this.sch2_primary_address === j) {
          this.assets_real_estate_residence = this.sch2_valuation[j]
        } else {
          this.assets_real_estate_residence = ""
          if (j == 0) {
            total = this.formatMoneyFromString(this.sch2_valuation[j])
          }
          if (j !== 0) {
            total += this.formatMoneyFromString(this.sch2_valuation[j])
          }
        }
      }
      this.assets_real_estate_investments = this.formatMoneyToString(total)
      this.closeSchTwoDeleteModal()
    },
    addSchedule3(j) {
      for (var i = 0; i < this.numOfSchedule3; i++) {
        this.validateSchedule3(i)
      }
      if (
        this.isEmptySch3(this.numOfSchedule3 - 1) ||
        this.missingSch3Payable.includes(true) ||
        this.invalidSch3BalanceOwed.includes(true) ||
        this.missingSch3BalanceOwed.includes(true) ||
        this.invalidSch3InterestRate.includes(true) ||
        this.missingSch3InterestRate.includes(true) ||
        this.missingSch3Maturity.includes(true) ||
        this.invalidSch3Maturity.includes(true) ||
        this.invalidSch3MonthlyPayment.includes(true) ||
        this.missingSch3MonthlyPayment.includes(true)
      ) {
        return
      }

      this.pushSch3Errors()

      this.numOfSchedule3 += 1
      this.$nextTick(() => {
        document.getElementById(`sch3-who-payable-${j + 1}`).focus()
      })
    },
    deleteSchedule3(i) {
      this.sch3_payable.splice(i, 1)
      this.sch3_balanceOwed.splice(i, 1)
      this.sch3_interestRate.splice(i, 1)
      this.sch3_maturity.splice(i, 1)
      this.sch3_monthlyPayment.splice(i, 1)
      this.scheduleThreeSubmittable.splice(i, 1)

      this.missingSch3Payable.splice(i, 1)
      this.missingSch3BalanceOwed.splice(i, 1)
      this.invalidSch3BalanceOwed.splice(i, 1)
      this.missingSch3InterestRate.splice(i, 1)
      this.invalidSch3InterestRate.splice(i, 1)
      this.missingSch3Maturity.splice(i, 1)
      this.invalidSch3Maturity.splice(i, 1)
      this.missingSch3MonthlyPayment.splice(i, 1)
      this.invalidSch3MonthlyPayment.splice(i, 1)

      this.numOfSchedule3 -= 1

      // update schedule 3 field totals in assets section
      const total = this.sch3_balanceOwed.reduce((a, b) => a + this.formatMoneyFromString(b), 0)
      this.liabilities_mortgages = this.formatMoneyToString(total)
      this.schThreeDeleteModal = true
      this.closeSchThreeDeleteModal()
    },
    validateSchedule4(i) {
      if (this.isEmptySch4(i)) return
      this.validateSch4Type(i)
      this.validateSch4StockShares(i)
      this.validateSch4ValueCarried(i)
      this.validateSch4ListedMkt(i)
      this.validateSch4ListedMktAmt(i)
      this.validateSch4UnlistedValue(i)
      this.validateSch4UnlistedValueAmt(i)
      this.validateSch4UnlistedValueYrlyInc(i)
    },
    validateSch4Type(i) {
      this.missingSch4Type[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4Type[i] = this.sch4_type[i] ? false : true
      this.checkAddBtnSchedule4(i)
    },
    validateSch4StockShares(i) {
      this.missingSch4StockShares[i] = false
      this.invalidSch4StockShares[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4StockShares[i] = this.sch4_stockShares[i] ? false : true
      if (!this.missingSch4StockShares[i]) {
        this.invalidSch4StockShares[i] = Validator.isValidMoney(this.sch4_stockShares[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule4(i)
    },
    validateSch4ValueCarried(i) {
      let total
      this.missingSch4ValueCarried[i] = false
      this.invalidSch4ValueCarried[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4ValueCarried[i] = this.sch4_valueCarried[i] ? false : true
      if (!this.missingSch4ValueCarried[i]) {
        this.invalidSch4ValueCarried[i] = Validator.isValidMoney(this.sch4_valueCarried[i])
          ? false
          : true
      }
      // add amounts and set total to marketable securities
      for (let j = 0; j < this.sch4_valueCarried.length; j++) {
        if (j == 0) {
          total = this.formatMoneyFromString(this.sch4_valueCarried[j])
        } else {
          total += this.formatMoneyFromString(this.sch4_valueCarried[j])
        }
      }
      this.assets_marketable_securities = this.formatMoneyToString(total)
      this.checkAddBtnSchedule4(i)
    },
    validateSch4ListedMkt(i) {
      this.missingSch4ListedMkt[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4ListedMkt[i] = this.sch4_listedMkt[i] ? false : true
      this.checkAddBtnSchedule4(i)
    },
    validateSch4ListedMktAmt(i) {
      this.missingSch4ListedMktAmt[i] = false
      this.invalidSch4ListedMktAmt[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4ListedMktAmt[i] = this.sch4_listedMktAmt[i] ? false : true
      if (!this.missingSch4ListedMktAmt[i]) {
        this.invalidSch4ListedMktAmt[i] = Validator.isValidMoney(this.sch4_listedMktAmt[i])
          ? false
          : true
        this.checkAddBtnSchedule4(i)
      }
      this.checkAddBtnSchedule4(i)
    },
    validateSch4UnlistedValue(i) {
      this.missingSch4UnlistedValue[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4UnlistedValue[i] = this.sch4_unlistedValue[i] ? false : true
      this.checkAddBtnSchedule4(i)
    },
    validateSch4UnlistedValueAmt(i) {
      this.missingSch4UnlistedValueAmt[i] = false
      this.invalidSch4UnlistedValueAmt[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4UnlistedValueAmt[i] = this.sch4_unlistedValueAmt[i] ? false : true
      if (!this.missingSch4UnlistedValueAmt[i]) {
        this.invalidSch4UnlistedValueAmt[i] = Validator.isValidMoney(this.sch4_unlistedValueAmt[i])
          ? false
          : true
      }
      this.checkAddBtnSchedule4(i)
    },
    validateSch4UnlistedValueYrlyInc(i) {
      this.missingSch4UnlistedValueYrlyInc[i] = false
      this.invalidSch4UnlistedValueYrlyInc[i] = false
      if (this.isEmptySch4(i)) return
      this.missingSch4UnlistedValueYrlyInc[i] = this.sch4_unlistedValueYrlyInc[i] ? false : true
      if (!this.missingSch4UnlistedValueYrlyInc[i]) {
        this.invalidSch4UnlistedValueYrlyInc[i] = Validator.isValidMoney(
          this.sch4_unlistedValueYrlyInc[i]
        )
          ? false
          : true
      }
      this.checkAddBtnSchedule4(i)
    },
    addSchedule4(j) {
      for (var i = 0; i < this.numOfSchedule4; i++) {
        this.validateSchedule4(i)
      }
      if (
        this.isEmptySch4(this.numOfSchedule4 - 1) ||
        this.missingSch4Type.includes(true) ||
        this.missingSch4StockShares.includes(true) ||
        this.invalidSch4StockShares.includes(true) ||
        this.missingSch4ValueCarried.includes(true) ||
        this.invalidSch4ValueCarried.includes(true) ||
        this.missingSch4ListedMkt.includes(true) ||
        this.missingSch4ListedMktAmt.includes(true) ||
        this.invalidSch4ListedMktAmt.includes(true) ||
        this.missingSch4UnlistedValue.includes(true) ||
        this.missingSch4UnlistedValueAmt.includes(true) ||
        this.invalidSch4UnlistedValueAmt.includes(true) ||
        this.missingSch4UnlistedValueYrlyInc.includes(true) ||
        this.invalidSch4UnlistedValueYrlyInc.includes(true)
      ) {
        return
      }
      this.pushSch4Errors()
      this.numOfSchedule4 += 1
      this.$nextTick(() => {
        document.getElementById(`sch4-type-${j + 1}`).focus()
      })
    },
    deleteSchedule4(i) {
      this.sch4_type.splice(i, 1)
      this.sch4_stockShares.splice(i, 1)
      this.sch4_valueCarried.splice(i, 1)
      this.sch4_listedMkt.splice(i, 1)
      this.sch4_listedMktAmt.splice(i, 1)
      this.sch4_unlistedValue.splice(i, 1)
      this.sch4_unlistedValueAmt.splice(i, 1)
      this.sch4_unlistedValueYrlyInc.splice(i, 1)
      this.scheduleFourSubmittable.splice(i, 1)

      this.missingSch4Type.splice(i, 1)
      this.missingSch4StockShares.splice(i, 1)
      this.invalidSch4StockShares.splice(i, 1)
      this.missingSch4ValueCarried.splice(i, 1)
      this.invalidSch4ValueCarried.splice(i, 1)
      this.missingSch4ListedMkt.splice(i, 1)
      this.missingSch4ListedMktAmt.splice(i, 1)
      this.invalidSch4ListedMktAmt.splice(i, 1)
      this.missingSch4UnlistedValue.splice(i, 1)
      this.missingSch4UnlistedValueAmt.splice(i, 1)
      this.invalidSch4UnlistedValueAmt.splice(i, 1)
      this.missingSch4UnlistedValueYrlyInc.splice(i, 1)
      this.invalidSch4UnlistedValueYrlyInc.splice(i, 1)

      this.numOfSchedule4 -= 1

      // update schedule 4 field totals in assets section
      const total = this.sch4_valueCarried.reduce((a, b) => a + this.formatMoneyFromString(b), 0)
      this.assets_marketable_securities = this.formatMoneyToString(total)
      this.schFourDeleteModal = true
      this.closeSchFourDeleteModal()
    },
    validateSchedule5(i) {
      if (this.isEmptySch5(i)) return
      this.validateSch5Beneficiary(i)
      this.validateSch5PolicyAmt(i)
      this.validateSch5Cash(i)
      this.validateSch5Loan(i)
      this.validateSch5NetCash(i)
    },
    validateSch5Beneficiary(i) {
      this.missingSch5Beneficiary[i] = false
      if (this.isEmptySch5(i)) return
      this.missingSch5Beneficiary[i] = this.sch5_beneficiary[i] ? false : true
      this.checkAddBtnSchedule5(i)
    },
    validateSch5PolicyAmt(i) {
      this.missingSch5PolicyAmt[i] = false
      this.invalidSch5PolicyAmt[i] = false
      if (this.isEmptySch5(i)) return
      this.missingSch5PolicyAmt[i] = this.sch5_policyAmt[i] ? false : true
      if (!this.missingSch5PolicyAmt[i]) {
        this.invalidSch5PolicyAmt[i] = Validator.isValidMoney(this.sch5_policyAmt[i]) ? false : true
      }
      this.checkAddBtnSchedule5(i)
    },
    validateSch5Cash(i) {
      let total
      this.missingSch5Cash[i] = false
      this.invalidSch5Cash[i] = false
      if (this.isEmptySch5(i)) return
      this.missingSch5Cash[i] = this.sch5_cash[i] ? false : true
      if (!this.missingSch5Cash[i]) {
        this.invalidSch5Cash[i] = Validator.isValidMoney(this.sch5_cash[i]) ? false : true
      }
      // add amounts and set sum total to life insurance value
      for (let j = 0; j < this.sch5_cash.length; j++) {
        if (j === 0) {
          total = this.formatMoneyFromString(this.sch5_cash[j])
        } else {
          total += this.formatMoneyFromString(this.sch5_cash[j])
        }
      }
      this.assets_life_insurance = this.formatMoneyToString(total)
      this.checkAddBtnSchedule5(i)
    },

    validateSch5Loan(i) {
      let total
      this.missingSch5Loan[i] = false
      this.invalidSch5Loan[i] = false
      if (this.isEmptySch5(i)) return
      this.missingSch5Loan[i] = this.sch5_loan[i] ? false : true
      if (!this.missingSch5Loan[i]) {
        this.invalidSch5Loan[i] = Validator.isValidMoney(this.sch5_loan[i]) ? false : true
      }
      // add amounts and set sum total to life insurance for liabilities
      for (let j = 0; j < this.sch5_loan.length; j++) {
        if (j == 0) {
          total = this.formatMoneyFromString(this.sch5_loan[j])
        } else {
          total += this.formatMoneyFromString(this.sch5_loan[j])
        }
      }
      this.liabilities_life_insurance = this.formatMoneyToString(total)
      this.checkAddBtnSchedule5(i)
    },

    validateSch5NetCash(i) {
      this.missingSch5NetCash[i] = false
      this.invalidSch5NetCash[i] = false
      if (this.isEmptySch5(i)) return
      this.missingSch5NetCash[i] = this.sch5_netCash[i] ? false : true
      if (!this.missingSch5NetCash[i]) {
        this.invalidSch5NetCash[i] = Validator.isValidMoney(this.sch5_netCash[i]) ? false : true
      }
      this.checkAddBtnSchedule5(i)
    },

    addSchedule5(j) {
      for (var i = 0; i < this.numOfSchedule5; i++) {
        this.validateSchedule5(i)
      }
      if (
        this.isEmptySch5(this.numOfSchedule5 - 1) ||
        this.missingSch5Beneficiary.includes(true) ||
        this.missingSch5PolicyAmt.includes(true) ||
        this.invalidSch5PolicyAmt.includes(true) ||
        this.missingSch5Cash.includes(true) ||
        this.invalidSch5Cash.includes(true) ||
        this.missingSch5Loan.includes(true) ||
        this.invalidSch5Loan.includes(true) ||
        this.missingSch5NetCash.includes(true) ||
        this.invalidSch5NetCash.includes(true)
      ) {
        return
      }
      this.pushSch5Errors()
      this.numOfSchedule5 += 1
      this.$nextTick(() => {
        document.getElementById(`sch5-beneficiary-${j + 1}`).focus()
      })
    },
    deleteSchedule5(i) {
      this.sch5_beneficiary.splice(i, 1)
      this.sch5_policyAmt.splice(i, 1)
      this.sch5_cash.splice(i, 1)
      this.sch5_loan.splice(i, 1)
      this.sch5_netCash.splice(i, 1)
      this.scheduleFiveSubmittable.splice(i, 1)

      this.missingSch5Beneficiary.splice(i, 1)
      this.missingSch5PolicyAmt.splice(i, 1)
      this.invalidSch5PolicyAmt.splice(i, 1)
      this.missingSch5Cash.splice(i, 1)
      this.invalidSch5Cash.splice(i, 1)
      this.missingSch5Loan.splice(i, 1)
      this.invalidSch5Loan.splice(i, 1)
      this.missingSch5NetCash.splice(i, 1)
      this.invalidSch5NetCash.splice(i, 1)

      this.numOfSchedule5 -= 1

      // update schedule 4 cash field total in assets section
      const total = this.sch5_cash.reduce((a, b) => a + this.formatMoneyFromString(b), 0)
      this.assets_life_insurance = this.formatMoneyToString(total)

      // update schedule 4 loans field total in assets section
      const totalLoans = this.sch5_loan.reduce((a, b) => a + this.formatMoneyFromString(b), 0)
      this.liabilities_life_insurance = this.formatMoneyToString(totalLoans)
      this.schFiveDeleteModal = true
      this.closeSchFiveDeleteModal()
    },

    createSchedule1() {
      for (var i = 0; i < this.numOfSchedule1; i++) {
        var sch1 = {
          amount_due: this.sch1_amtDue[i]
            ? parseFloat(this.sch1_amtDue[i].replaceAll(",", "").replaceAll("$", ""))
            : null,
          debtor_name: this.sch1_debtorName[i],
          payable: this.sch1_payable[i],
          maturity: this.sch1_maturity[i],
          remarks: this.sch1_remarks[i],
        }
        if (i == 0) {
          this.setSchedule1([sch1])
        } else {
          this.appendSch1(sch1)
        }
      }
    },
    createSchedule2() {
      for (var i = 0; i < this.sch2_monthlyRentalInc.length; i++) {
        var sch2 = {
          monthly_rental_inc: parseFloat(
            this.sch2_monthlyRentalInc[i].replaceAll(",", "").replaceAll("$", "")
          ),
          valuation: parseFloat(this.sch2_valuation[i].replaceAll(",", "").replaceAll("$", "")),
          balance_owed: parseFloat(
            this.sch2_balanceOwed[i].replaceAll(",", "").replaceAll("$", "")
          ),
          property_addr: this.sch2_propAddr[i],
          date_acquired: this.sch2_dateAcq[i],
          title: this.sch2_title[i],
          is_primary_residence: "N",
        }
        if (this.sch2_primary_address == i) sch2.is_primary_residence = "Y"

        if (i == 0) {
          this.setSchedule2([sch2])
        } else {
          this.appendSch2(sch2)
        }
      }
    },
    createSchedule3() {
      for (var i = 0; i < this.sch3_payable.length; i++) {
        var sch3 = {
          interest_rate: this.sch3_interestRate[i]
            ? parseFloat(this.sch3_interestRate[i].replaceAll(",", "").replaceAll("$", ""))
            : null,
          monthly_payment: parseFloat(
            this.sch3_monthlyPayment[i].replaceAll(",", "").replaceAll("$", "")
          ),
          balance_owed: parseFloat(
            this.sch3_balanceOwed[i].replaceAll(",", "").replaceAll("$", "")
          ),
          payable: this.sch3_payable[i],
          maturity: this.sch3_maturity[i],
        }
        if (i == 0) {
          this.setSchedule3([sch3])
        } else {
          this.appendSch3(sch3)
        }
      }
    },
    createSchedule4() {
      for (var i = 0; i < this.sch4_type.length; i++) {
        var sch4 = {
          stock_shares: parseFloat(
            this.sch4_stockShares[i].replaceAll(",", "").replaceAll("$", "")
          ),
          value_carried: parseFloat(
            this.sch4_valueCarried[i].replaceAll(",", "").replaceAll("$", "")
          ),
          listed_mkt_amt: parseFloat(
            this.sch4_listedMktAmt[i].replaceAll(",", "").replaceAll("$", "")
          ),
          unlisted_value_amt: parseFloat(
            this.sch4_unlistedValueAmt[i].replaceAll(",", "").replaceAll("$", "")
          ),
          unlisted_value_yrly_inc: parseFloat(
            this.sch4_unlistedValueYrlyInc[i].replaceAll(",", "").replaceAll("$", "")
          ),
          type: this.sch4_type[i],
          listed_mkt: this.sch4_listedMkt[i],
          unlisted_value: this.sch4_unlistedValue[i],
        }
        if (i == 0) {
          this.setSchedule4([sch4])
        } else {
          this.appendSch4(sch4)
        }
      }
    },
    createSchedule5() {
      for (var i = 0; i < this.sch5_beneficiary.length; i++) {
        var sch5 = {
          policy_amt: parseFloat(this.sch5_policyAmt[i].replaceAll(",", "").replaceAll("$", "")),
          cash: parseFloat(this.sch5_cash[i].replaceAll(",", "").replaceAll("$", "")),
          loan: parseFloat(this.sch5_loan[i].replaceAll(",", "").replaceAll("$", "")),
          net_cash: parseFloat(this.sch5_netCash[i].replaceAll(",", "").replaceAll("$", "")),
          beneficiary: this.sch5_beneficiary[i],
        }
        if (i == 0) {
          this.setSchedule5([sch5])
        } else {
          this.appendSch5(sch5)
        }
      }
    },
    backCreateSchedule1() {
      for (var i = 0; i < this.numOfSchedule1; i++) {
        var sch1 = {
          amount_due: this.sch1_amtDue[i]
            ? parseFloat(this.sch1_amtDue[i].replaceAll(",", "").replaceAll("$", ""))
            : null,
          debtor_name: this.sch1_debtorName[i],
          payable: this.sch1_payable[i],
          maturity: this.sch1_maturity[i],
          remarks: this.sch1_remarks[i],
        }
        if (i == 0) {
          this.setSchedule1([sch1])
        } else {
          this.appendSch1(sch1)
        }
      }
    },
    backCreateSchedule2() {
      for (var i = 0; i < this.numOfSchedule2; i++) {
        var sch2 = {
          monthly_rental_inc: this.sch2_monthlyRentalInc[i]
            ? parseFloat(this.sch2_monthlyRentalInc[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          valuation: this.sch2_valuation[i]
            ? parseFloat(this.sch2_valuation[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          balance_owed: this.sch2_balanceOwed[i]
            ? parseFloat(this.sch2_balanceOwed[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          property_addr: this.sch2_propAddr[i],
          date_acquired: this.sch2_dateAcq[i],
          title: this.sch2_title[i],
          is_primary_residence: "N",
        }
        if (this.sch2_primary_address == i) sch2.is_primary_residence = "Y"

        if (i == 0) {
          this.setSchedule2([sch2])
        } else {
          this.appendSch2(sch2)
        }
      }
    },
    backCreateSchedule3() {
      for (var i = 0; i < this.numOfSchedule3; i++) {
        var sch3 = {
          interest_rate: this.sch3_interestRate[i]
            ? parseFloat(this.sch3_interestRate[i].replaceAll(",", "").replaceAll("$", ""))
            : null,
          monthly_payment: this.sch3_monthlyPayment[i]
            ? parseFloat(this.sch3_monthlyPayment[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          balance_owed: this.sch3_balanceOwed[i]
            ? parseFloat(this.sch3_balanceOwed[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          payable: this.sch3_payable[i],
          maturity: this.sch3_maturity[i],
        }
        if (i == 0) {
          this.setSchedule3([sch3])
        } else {
          this.appendSch3(sch3)
        }
      }
    },
    backCreateSchedule4() {
      for (var i = 0; i < this.numOfSchedule4; i++) {
        var sch4 = {
          stock_shares: this.sch4_stockShares[i]
            ? parseFloat(this.sch4_stockShares[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          value_carried: this.sch4_valueCarried[i]
            ? parseFloat(this.sch4_valueCarried[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          listed_mkt_amt: this.sch4_listedMktAmt[i]
            ? parseFloat(this.sch4_listedMktAmt[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          unlisted_value_amt: this.sch4_unlistedValueAmt[i]
            ? parseFloat(this.sch4_unlistedValueAmt[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          unlisted_value_yrly_inc: this.sch4_unlistedValueYrlyInc[i]
            ? parseFloat(this.sch4_unlistedValueYrlyInc[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          type: this.sch4_type[i],
          listed_mkt: this.sch4_listedMkt[i],
          unlisted_value: this.sch4_unlistedValue[i],
        }
        if (i == 0) {
          this.setSchedule4([sch4])
        } else {
          this.appendSch4(sch4)
        }
      }
    },
    backCreateSchedule5() {
      for (var i = 0; i < this.numOfSchedule5; i++) {
        var sch5 = {
          policy_amt: this.sch5_policyAmt[i]
            ? parseFloat(this.sch5_policyAmt[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          cash: this.sch5_cash[i]
            ? parseFloat(this.sch5_cash[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          loan: this.sch5_loan[i]
            ? parseFloat(this.sch5_loan[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          net_cash: this.sch5_netCash[i]
            ? parseFloat(this.sch5_netCash[i].replaceAll(",", "").replaceAll("$", ""))
            : "",
          beneficiary: this.sch5_beneficiary[i],
        }
        if (i == 0) {
          this.setSchedule5([sch5])
        } else {
          this.appendSch5(sch5)
        }
      }
    },
    validateSch2PrimaryAddress(i) {
      // unclicks if clicking the one that's clicked
      if (this.sch2_primary_address == i) {
        this.sch2_primary_address = null
        this.assets_real_estate_residence = ""
      } else {
        // because of the @click event handler, the primary address isn't yet set at this function
        // Need to set primary address here despite it being used as v-model.
        this.sch2_primary_address = i
      }
      this.updateSch2Assets()
      this.checkAddBtnSchedule2(i)
    },

    // Updates schedule 2 assets when selecting or unselecting a primary address
    updateSch2Assets() {
      let total = 0
      for (let j = 0; j < this.sch2_valuation.length; j++) {
        if (this.sch2_primary_address == j) {
          this.assets_real_estate_residence = this.sch2_valuation[j]
        } else {
          if (j == 0) {
            total = this.formatMoneyFromString(this.sch2_valuation[j])
          }
          if (j !== 0) {
            total += this.formatMoneyFromString(this.sch2_valuation[j])
          }
        }
      }
      this.assets_real_estate_investments = this.formatMoneyToString(total)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 2.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1fr;
    text-align: right;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
  }
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.ll-city {
  @include media-breakpoint-up(xl) {
    .tf-city {
      min-width: 380px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex: 1;
    max-width: unset;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.row4-container {
  display: grid;
  grid-column-gap: 3em;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.row6-container {
  display: grid;
  grid-template-columns: 1fr 60px;
  text-align: left;
}

// schedules
.loan-or-line {
  outline: 1px dotted $orange;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 2.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1fr;
    text-align: right;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
  }
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      //min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.row4-container {
  display: grid;
  grid-column-gap: 3em;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.ll-textfield {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.ll-explaination-textfield {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
  height: 50px;
  width: 100%;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}

.row6-container {
  display: grid;
  grid-template-columns: 1fr 60px;
  text-align: left;
}

.ll-text {
  background-color: $white;
  border: 1px solid $grayMid;
  border-radius: 3px;
  box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
}

.left-border {
  border-left: 2px solid black;
}

.top-border {
  border-top: 2px solid black;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
  margin-left: auto;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin-left: auto;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
  margin-left: auto;
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
  margin-left: auto;
}

.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;
  margin-left: auto;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 11em;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.add-btn {
  align-items: center;
  background: $white;
  border: 1px solid $grayDark;
  border-radius: 50%;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  height: 30px;
  width: 30px;
  justify-content: center;

  &:hover,
  &:focus {
    background: $grayLight;
    cursor: pointer;
  }
}
.add-btn-disabled {
  align-items: center;
  background: $grayLight;
  border: 1px solid $grayDark;
  border-radius: 50%;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  height: 30px;
  width: 30px;
  justify-content: center;
}

.delete-button {
  height: 30px;
  width: 30px;
  align-content: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  &:hover,
  &:focus {
    background: $redFaint;
    //border: 1px solid $grayDark;
  }
}

.delete-button:hover {
  cursor: pointer;
}

.debtor-spacing {
  width: 14em;
}

.amt-spacing {
  width: 8em;
}

.payable-spacing {
  width: 8em;
}

.maturity-spacing {
  width: 9em;
}

.stock-type-spacing {
  width: 8em;
}

.stock-shares-spacing {
  width: 9em;
}

.value-carried-spacing {
  width: 9em;
}

.current-mkt-spacing {
  width: 9em;
}

.desc-spacing {
  width: 6em;
}

.unlisted-spacing {
  width: 12em;
}

.amount-spacing {
  width: 8em;
}

.amount-spacing-2 {
  width: 8em;
}

.yrly-inc-spacing {
  width: 8em;
}

.spacing {
  width: 8em;
}

.valuation-spacing {
  width: 8em;
}

.monthly-spacing {
  width: 8em;
}

.date-spacing {
  width: 8em;
}

table:not(.with-desc) {
  border-collapse: unset;
  border-spacing: 4px;
  min-width: 800px;

  table {
    min-width: unset;
  }
}

table td,
table th {
  vertical-align: top;
  padding: 8px 0 0;

  input {
    font-size: 0.9rem;
    height: 35px;
    padding-left: 6px;
    padding-right: 6px;
    width: 100%;
  }
}

table th {
  font-size: 0.9rem;
}

table .form-error-msg {
  padding-top: 0.35em;
  width: 100%;

  .form-error-txt {
    font-size: 0.8rem;
  }
}

table.assets-liabilities {
  border-collapse: collapse;

  > tr > th {
    padding: 4px;
  }

  tr {
    border: 0;
  }

  td {
    padding: 4px;
    vertical-align: middle;
  }
}

.assets-liabilities > tr > td,
.assets-liabilities > tr > th,
.income-expenses > tr > td,
.income-expenses > tr > th {
  padding: 6px 0;
  &:nth-child(2) {
    padding-right: 8px;
  }
  &:nth-child(3) {
    padding-left: 8px;
  }

  .ll-textfield {
    float: right;
  }
}

.sub-note {
  display: block;
  font-size: 0.75rem;
  font-weight: normal;
}

.btn-cell {
  vertical-align: center;
  width: 2%;
}

h2 {
  margin: 0;
}

.delete-icon {
  height: auto;
  width: 14px;
}
table {
  width: 100%;
  tr {
    border-bottom: solid 1px $gray;
  }
}
.radio-input {
  input {
    width: 25px;
    height: 25px;

    // visibility: hidden;
  }
  // input[type='radio']:after {
  //   width: 25px;
  //   height: 25px;
  //   border-radius: 15px;
  //   left: 5px;
  //   position: relative;
  //   background-color: $grayLight;
  //   content: '';
  //   display: inline-block;
  //   visibility: visible;
  //   border: 2px solid $grayDark;
  // }

  // input[type='radio']:checked:after {
  //   width: 25px;
  //   height: 25px;
  //   border-radius: 15px;
  //   left: 5px;
  //   position: relative;
  //   background-color: $yellow;
  //   content: '';
  //   display: inline-block;
  //   visibility: visible;
  //   border: 2px solid $yellow;
  // }
  display: flex;
  align-items: center;
}
</style>
