<template>
  <div class="business-page">
    <Header />
    <Breadcrumb_credit_app />
    <MainId />

    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Tell Us About the Business Owners</h1>

          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div v-for="i in this.numOwners" :key="i">
            <div v-if="i > 1">
              <div class="v-spacer-30" />
              <hr class="solid" />

              <div class="v-spacer-30" />
            </div>

            <div class="field-group">
              <h2>{{ `Owner ${i}` }}</h2>
              <div class="ll-row row1-container">
                <!-- First Name -->
                <div class="ll-item ll-fname">
                  <div class="tf-label" v-bind:id="`business-label-owner-fname-${i - 1}`">
                    <label :for="`buscred-owner-fname-input-${i}`"
                      >First Name<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-fname"
                    :id="`buscred-owner-fname-input-${i}`"
                    :aria-describedby="`cred-owner-fname-err-${i - 1}`"
                    name="fname"
                    :data-test="`ll-bc-owner-fname-${i - 1}`"
                    type="text"
                    v-model="this.owner_first_name[i - 1]"
                    :class="
                      this.missing_owner_first_name[i - 1] || invalid_owner_first_name[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateFName(i - 1)"
                    required
                  />
                  <div :id="`cred-owner-fname-err-${i - 1}`" class="ind-err-container">
                    <div
                      v-if="missing_owner_first_name[i - 1] || invalid_owner_first_name[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-fname-err-${i - 1}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                    </div>
                  </div>
                </div>
                <!-- Middle Initial -->
                <div class="ll-item ll-middle-initial">
                  <div class="tf-label" v-bind:id="`business-label-owner-mi-${i - 1}`">
                    <label :for="`buscred-owner-mi-input-${i}`">MI<sup>&nbsp;</sup></label>
                  </div>
                  <input
                    maxlength="1"
                    class="ll-textfield tf-middle-initial"
                    :data-test="`ll-bc-owner-mname-${i - 1}`"
                    type="text"
                    :id="`buscred-owner-mi-input-${i}`"
                    :aria-describedby="`cred-owner-mi-err-${i - 1}`"
                    name="middle-initial"
                    v-model="owner_middle_name[i - 1]"
                    :class="
                      owner_middle_name[i - 1] && invalid_owner_middle_name[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    @input="validateMI(i - 1)"
                  />
                  <div :id="`cred-owner-mi-err-${i - 1}`" class="ind-err-container">
                    <div
                      v-if="owner_middle_name[i - 1] && invalid_owner_middle_name[i - 1]"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-mname-err-${i - 1}`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">
                        Please enter a valid middle initial
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="v-spacer-30" />
              <!-- Last Name -->
              <div class="ll-item ll-lname">
                <div class="tf-label" v-bind:id="`business-label-owner-lname-${i - 1}`">
                  <label :for="`buscred-owner-lname-input-${i}`"
                    >Last Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-lname"
                  type="text"
                  :id="`buscred-owner-lname-input-${i}`"
                  name="lname"
                  :aria-describedby="`cred-owner-lname-err-${i - 1}`"
                  :data-test="`ll-bc-owner-last-name-${i - 1}`"
                  v-model="owner_last_name[i - 1]"
                  :class="
                    missing_owner_last_name[i - 1] || invalid_owner_last_name[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateLName(i - 1)"
                  required
                />
                <div :id="`cred-owner-lname-err-${i - 1}`" class="ind-err-container">
                  <div
                    v-if="missing_owner_last_name[i - 1] || invalid_owner_last_name[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-last-name-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="v-spacer-30" />

            <!-- row 3 -->
            <div class="ll-row row2-container">
              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-label-owner-dob-${i - 1}`">
                  <label :for="`buscred-owner-dob-input-${i}`"
                    >Date of Birth<sup class="fhb-red">*</sup></label
                  >
                </div>

                <birthday-input
                  class="ll-textfield tf-dob"
                  :id="`buscred-owner-dob-input-${i}`"
                  name="dob"
                  :aria-describedby="`cred-owner-dob-hint-${i - 1} cred-owner-dob-err-${i - 1}`"
                  v-model="owner_birthday[i - 1]"
                  :data-test="`ll-bc-owner-dob-${i - 1}`"
                  :class="
                    missing_owner_birthday[i - 1] || invalid_owner_birthday[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateDOB(i - 1)"
                  required
                />

                <div :id="`cred-owner-dob-hint-${i - 1}`" class="tf-note">
                  You must be 18 years or older to apply.
                </div>
                <div :id="`cred-owner-dob-err-${i - 1}`" class="ind-err-container">
                  <div
                    v-if="missing_owner_birthday[i - 1] || invalid_owner_birthday[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-dob-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid date (MM/DD/YYYY)
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="v-spacer-40" />

            <div class="ll-row row2-container">
              <!-- Phone Number -->
              <div class="ll-item ll-phone">
                <div class="tf-label" v-bind:id="`business-label-owner-phone-${i - 1}`">
                  <label :for="`buscred-owner-mobile-input-${i}`"
                    >Mobile Phone Number<sup class="fhb-red">*</sup></label
                  >
                </div>
                <div class="ll-prefix-wrap">
                  <phone-input
                    class="ll-textfield tf-phone"
                    :id="`buscred-owner-mobile-input-${i}`"
                    name="mobile"
                    :aria-describedby="`bowner-us-phone-note-${i} cred-owner-tel-err-${i - 1}`"
                    v-model="owner_phone[i - 1]"
                    :class="
                      missing_owner_phone[i - 1] || invalid_owner_phone[i - 1]
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validatePhone(i - 1)"
                    :data-test="`ll-bc-owner-phone-${i - 1}`"
                    required
                  />
                </div>
                <div :id="`bowner-us-phone-note-${i}`" class="tf-note">
                  Please provide a U.S. phone number.
                </div>
                <div :id="`cred-owner-tel-err-${i - 1}`" class="ind-err-container">
                  <div
                    v-if="missing_owner_phone[i - 1] || invalid_owner_phone[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-phone-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div class="form-error-txt fieldErr">Please enter a valid phone number</div>
                  </div>
                </div>
              </div>

              <!-- Email -->
              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-label-owner-email-${i - 1}`">
                  <label :for="`buscred-owner-email-input-${i}`"
                    >Email<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  :data-test="`ll-bc-owner-email-${i - 1}`"
                  class="ll-textfield"
                  type="text"
                  :id="`buscred-owner-email-input-${i}`"
                  :aria-describedby="`cred-owner-email-hint-${i - 1} cred-owner-email-err-${i - 1}`"
                  name="email"
                  v-model="owner_email[i - 1]"
                  :class="
                    missing_owner_email[i - 1] || invalid_owner_email[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateEmail(i - 1)"
                  required
                />
                <div :id="`cred-owner-email-hint-${i - 1}`" class="tf-note">
                  To contact you about your inquiry and to send you special offers/information. You
                  may opt out of marketing communications at any time.
                </div>
                <div :id="`cred-owner-email-err-${i - 1}`" class="ind-err-container">
                  <div
                    v-if="missing_owner_email[i - 1] || invalid_owner_email[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-email-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid email</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="v-spacer-30" />

            <!-- Company Position -->
            <div
              v-if="
                this.businessCompanyStructure &&
                this.businessCompanyStructure !== 'sole' &&
                this.businessCompanyStructure !== 'other'
              "
              class="ll-item ll-state"
            >
              <div class="tf-label" v-bind:id="`business-label-owner-role-${i - 1}`">
                <label :for="`buscred-owner-position-input-${i}`"
                  >What role best represents your position in the Company?<sup class="fhb-red"
                    >*</sup
                  ></label
                >
              </div>
              <select
                v-if="
                  this.businessCompanyStructure === 'corp' ||
                  this.businessCompanyStructure === 'prof-corp'
                "
                class="ll-select select-state"
                :id="`buscred-owner-position-input-${i}`"
                :aria-describedby="`cred-owner-pos-err-${i - 1}`"
                name="us_state"
                v-model="owner_role[i - 1]"
                :class="missing_owner_role[i - 1] ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition(i - 1)"
                :data-test="`ll-bc-owner-position-${i - 1}`"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_corp"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'partnership' ||
                  this.businessCompanyStructure === 'limited'
                "
                :id="`buscred-owner-position-input-${i}`"
                :aria-describedby="`cred-owner-pos-err-${i - 1}`"
                class="ll-select select-state"
                name="us_state"
                v-model="owner_role[i - 1]"
                :class="missing_owner_role[i - 1] ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition(i - 1)"
                :data-test="`ll-bc-owner-position-${i - 1}`"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_partnership"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'llc' ||
                  this.businessCompanyStructure === 'single-llc'
                "
                :id="`buscred-owner-position-input-${i}`"
                :aria-describedby="`cred-owner-pos-err-${i - 1}`"
                class="ll-select select-state"
                name="us_state"
                v-model="owner_role[i - 1]"
                :class="missing_owner_role[i - 1] ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition(i - 1)"
                :data-test="`ll-bc-owner-position-${i - 1}`"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_LLC"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <div :id="`cred-owner-pos-err-${i - 1}`" class="ind-err-container">
                <div
                  v-if="missing_owner_role[i - 1]"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-position-err-${i - 1}`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a position</div>
                </div>
              </div>
            </div>
            <div v-else></div>

            <div class="v-spacer-30" />

            <!-- percentage of business owned -->
            <div id="percentage-form">
              <div class="ll-item" v-bind:id="`business-percentage-owned-${i - 1}`">
                <div class="tf-label">
                  <label :for="`buscred-owner-percent-input-${i}`"
                    >Percentage of Business You Own<sup class="fhb-red">*</sup></label
                  >
                </div>
                <percentage-input
                  class="ll-textfield tf-addr-years"
                  type="text"
                  :id="`buscred-owner-percent-input-${i - 1}`"
                  :aria-describedby="`cred-owner-percent-err-${i - 1}`"
                  name="percentage"
                  v-model="owner_percentage[i - 1]"
                  :class="
                    missing_owner_percentage[i - 1] || invalid_owner_percentage[i - 1]
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validatePercentageOwned(i - 1)"
                  :data-test="`ll-bc-owner-percentage-${i - 1}`"
                  required
                />
                <div :id="`cred-owner-percent-err-${i - 1}`" class="ind-err-container">
                  <div
                    v-if="missing_owner_percentage[i - 1] || invalid_owner_percentage[i - 1]"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-percentage-err-${i - 1}`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter the interest rate as a percent for this loan
                    </div>
                  </div>
                  <div v-if="invalid_percentage_total" class="form-error-msg">
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please ensure added percentages do not exceed 100% if there is more than one
                      owner
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  end of owner for loop -->

          <div class="v-spacer-30" />
          <hr class="solid" />
          <div class="v-spacer-20" />
          <button
            type="button"
            class="toggle-btn-up toggle-btn-sm"
            @click="addOwner()"
            data-test="ll-bc-add-owner-btn"
          >
            <div class="add-plus toggle-btn-txt">Add Business Owner</div>
          </button>

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="ll-bc-btn-back">
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              id="ll-continue-btn"
              type="button"
              class="continue-btn"
              @click="handleContinue()"
              data-test="ll-bc-btn-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Validator from "@/app/validation"
import Header from "@/components/Header"
import Breadcrumb_credit_app from "@/views/business/credit_app/Breadcrumb_credit_app.vue"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import BirthdayInput from "@/components/CustomInputs/BirthdayInput.vue"
import PhoneInput from "../../../components/CustomInputs/PhoneInput.vue"
import PercentageInput from "../../../components/CustomInputs/PercentageInput.vue"
import Constants from "@/app/business_constants"

export default {
  name: "Getting Started",
  components: {
    Header,
    Breadcrumb_credit_app,
    MainId,
    Footer,
    BirthdayInput,
    PhoneInput,
    PercentageInput,
  },
  data() {
    return {
      company_positions_corp: Constants.CompanyPositionsCorp,
      company_positions_LLC: Constants.CompanyPositionsLLC,
      company_positions_partnership: Constants.CompanyPositionsPartnership,

      owner_first_name: [],
      owner_last_name: [],
      owner_middle_name: [],
      owner_birthday: [],
      owner_ssn: [],

      owner_role: [],
      owner_percentage: [],
      owner_through_trust: [],

      owner_street_address: [],
      owner_apt_unit: [],
      owner_city: [],
      owner_state: [],
      owner_zip: [],

      owner_mailing_same: [],
      owner_mailing_address: [],
      owner_mailing_apt_unit: [],
      owner_mailing_city: [],
      owner_mailing_state: [],
      owner_mailing_zip: [],

      owner_phone: [],
      owner_email: [],

      owner_resident_status: [],
      owner_years_at_address: [],
      owner_months_at_address: [],
      owner_years_in_state: [],
      owner_months_in_state: [],
      owner_occupancy: [],

      owner_monthly_salary: [],
      owner_bonus: [],
      owner_dividends: [],
      owner_rentals: [],
      owner_gross_income: [],

      owner_assets_in_trust: [],
      owner_assets_explanation: [],

      owner_num_assets: [0],
      owner_asset_type: [[]],
      owner_asset_institution: [[]],
      owner_asset_amount: [[]],
      owner_asset_balance: [[]],
      // life insurance
      owner_loan_on_insurance: [[]],
      // auto
      owner_asset_auto_year: [[]],
      owner_asset_auto_make_model: [[]],
      owner_asset_monthly_payment: [[]],
      // real estate
      owner_asset_property_owner: [[]],
      owner_asset_property_location: [[]],
      owner_asset_property_rental_income: [[]],

      // errors
      missing_owner_first_name: [],
      invalid_owner_first_name: [],
      missing_owner_last_name: [],
      invalid_owner_last_name: [],
      invalid_owner_middle_name: [],
      missing_owner_birthday: [],
      invalid_owner_birthday: [],
      missing_owner_role: [],

      missing_owner_percentage: [],
      invalid_owner_percentage: [],
      missing_owner_phone: [],
      invalid_owner_phone: [],
      missing_owner_email: [],
      invalid_owner_email: [],

      total: 0,

      invalid_percentage_total: false,

      errCnt: 0,
      systemErrorMsg: "",
    }
  },
  created() {
    console.log("business type: ", this.business_type)
    this.setCurrentPage(4)
    for (let i = 0; i < this.numOwners; i++) {
      let owner = this.owners[i]
      if (this.filerIsOwner) {
        owner.first_name = this.filerFirstName
        owner.last_name = this.filerLastName
        owner.middle_initial = this.filerMiddleInitial
        owner.phone_number = this.filerPhoneNumber
        owner.email = this.filerEmail
        owner.company_position = this.filerCompanyPosition
      }
      this.owner_first_name.push(owner.first_name)
      this.owner_last_name.push(owner.last_name)
      this.owner_middle_name.push(owner.middle_initial)
      this.owner_birthday.push(owner.date_of_birth)
      this.owner_phone.push(owner.phone_number)
      this.owner_email.push(owner.email)
      this.owner_role.push(owner.company_position)
      this.owner_percentage.push(owner.percentage_owned)

      // errors
      this.pushFalseOnErrors()
    }
  },
  computed: {
    ...mapGetters("businessCreditApp", [
      "applicationID",
      "jwtToken",
      "currentPage",
      "businessOwners",
      "numBusinessOwners",
      "companyStructure",
      "filerIsOwner",
      "filerFirstName",
      "filerMiddleInitial",
      "filerLastName",
      "filerEmail",
      "filerPhoneNumber",
      "filerCompanyPosition",
    ]),
    ...mapGetters("questionnaire", ["qid", "business_type"]),
    owners: {
      get() {
        return this.businessOwners
      },
    },
    numOwners: {
      get() {
        return this.numBusinessOwners
      },
      set(value) {
        this.setNumBusinessOwners(value)
      },
    },
    todaysDate: {
      get() {
        let today = new Date()
        var dd = String(today.getDate()).padStart(2, "0")
        var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
        var yyyy = today.getFullYear()

        today = mm + "/" + dd + "/" + yyyy
        return today
      },
    },
    businessCompanyStructure: {
      get() {
        return this.companyStructure
      },
      set(value) {
        this.setCompanyStructure(value)
      },
    },

    ownersHaveErrors: {
      get() {
        if (this.missing_owner_first_name.includes(true)) return true
        if (this.invalid_owner_first_name.includes(true)) return true
        if (this.missing_owner_last_name.includes(true)) return true
        if (this.invalid_owner_last_name.includes(true)) return true
        if (this.invalid_owner_middle_name.includes(true)) return true
        if (this.missing_owner_birthday.includes(true)) return true
        if (this.invalid_owner_birthday.includes(true)) return true
        if (this.missing_owner_role.includes(true)) return true
        if (this.missing_owner_percentage.includes(true)) return true
        if (this.invalid_owner_percentage.includes(true)) return true

        if (this.missing_owner_phone.includes(true)) return true
        if (this.invalid_owner_phone.includes(true)) return true
        if (this.missing_owner_email.includes(true)) return true
        if (this.invalid_owner_email.includes(true)) return true

        console.log(`ownersHaveErrors() assets are valid`)
        return false
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("businessCreditApp", [
      "setCurrentPage",
      "setValidatedNavPage",
      "setBusinessOwners",
      "appendBusinessOwner",
      "setNumBusinessOwners",
      "setCompanyStructure",
      "setSubmissionID",
      "setDocumentUrls",
      "setAdditionalDocumentUrls",
    ]),
    pushFalseOnErrors() {
      this.missing_owner_first_name.push(false)
      this.invalid_owner_first_name.push(false)
      this.missing_owner_last_name.push(false)
      this.invalid_owner_last_name.push(false)
      this.invalid_owner_middle_name.push(false)
      this.missing_owner_birthday.push(false)
      this.invalid_owner_birthday.push(false)
      this.missing_owner_role.push(false)
      this.missing_owner_percentage.push(false)
      this.invalid_owner_percentage.push(false)

      this.missing_owner_phone.push(false)
      this.invalid_owner_phone.push(false)
      this.missing_owner_email.push(false)
      this.invalid_owner_email.push(false)
    },

    async putPage(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.putCreditAppPage(this.applicationID, payload, this.jwtToken)
      console.log("--- response ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          // update breadcrumb state
          this.setValidatedNavPage({ name: "owners", isValid: 1 })

          if (resp.data.response?.documents) {
            this.setDocumentUrls(resp.data.response.documents)
            this.setAdditionalDocumentUrls(resp.data.response.additional_documents)
            // sleep for a smooth transition
            await new Promise(r => setTimeout(r, 400))

            this.$router.push({ name: "Credit-Document-Upload" })
            return
          }
          this.$router.push({ name: "Business Owners Received" })
          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message)
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    formattedPercentageOwned(i) {
      let str = this.owner_percentage[i].replace("%", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlySalary(i) {
      let str = this.owner_monthly_salary[i]
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlyBonus(i) {
      let str = this.owner_bonus[i].replace("$", "").replaceAll(",", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlyDividends(i) {
      let str = this.owner_dividends[i].replace("$", "").replaceAll(",", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedRentals(i) {
      let str = this.owner_rentals[i].replace("$", "").replaceAll(",", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedGrossIncome(i) {
      let str = this.owner_gross_income[i].replace("$", "").replaceAll(",", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    maskedMoney(str) {
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`)
      return `$${str}`
    },
    formattedPhone(i) {
      let str = this.owner_phone[i]
        .replaceAll("-", "")
        .replaceAll(" ", "")
        .replace("+1", "")
        .replace("(", "")
        .replace(")", "")
      return `+1${str}`
    },
    addOwner() {
      this.validatePage()
      if (!this.ownersHaveErrors && this.owner_first_name[this.numOwners - 1]) {
        this.pushFalseOnErrors()
        this.owner_num_assets.push(1)
        this.owner_mailing_same.push(true)

        this.owner_asset_type.push([""])
        this.owner_asset_institution.push([""])
        this.owner_asset_amount.push([null])
        this.owner_asset_balance.push([null])
        this.owner_loan_on_insurance.push([""])
        this.owner_asset_auto_year.push([""])
        this.owner_asset_auto_make_model.push([""])
        this.owner_asset_monthly_payment.push([null])
        this.owner_asset_property_owner.push([""])
        this.owner_asset_property_location.push([""])
        this.owner_asset_property_rental_income.push([null])

        this.numOwners += 1
        console.log(`owner added total: ${this.numOwners}`)
      } else {
        this.errCnt = jQuery(".form-error-txt").length
        this.scrollToError()
      }
    },

    createOwners() {
      for (var i = 0; i < this.owner_first_name.length; i++) {
        console.log("length of owners: ", this.owner_first_name.length)
        var owner = {
          first_name: this.owner_first_name[i],
          middle_initial: this.owner_middle_name[i],
          last_name: this.owner_last_name[i],
          date_of_birth: this.owner_birthday[i],
          phone_number: this.formattedPhone(i),
          email: this.owner_email[i],
          ssn: this.owner_ssn[i],
          company_position: this.owner_role[i],
          percentage_owned: this.owner_percentage[i], // percent
          ownership_interest_through_trust: this.owner_through_trust[i] == "yes" ? true : false,
          current_address: this.owner_street_address[i],
          current_apt_unit: this.owner_apt_unit[i],
          current_city: this.owner_city[i],
          current_state_territory: this.owner_state[i],
          current_zipcode: this.owner_zip[i],

          mailing_address_same: this.owner_mailing_same[i],
          mailing_address: this.owner_mailing_address[i],
          mailing_apt_unit: this.owner_mailing_apt_unit[i],
          mailing_city: this.owner_mailing_city[i],
          mailing_state_territory: this.owner_mailing_state[i],
          mailing_zipcode: this.owner_mailing_zip[i],

          personal_assets_in_trust: this.owner_assets_in_trust[i] == "yes" ? true : false,
          asset_explanation: this.owner_assets_explanation[i],

          financial_condition: [],
        }
        for (let j = 0; j < 1; j++) {
          let item = {
            item_type: "",
            institution_name: "",
            amount: null,
            balance: null,
            loans_on_insurance: "",
            auto_year: "",
            auto_make_model: "",
            monthly_payment: null,
            property_owner: "",
            property_location: "",
            rental_income: null,
          }
          owner.financial_condition.push(item)
        }
        if (i == 0) {
          this.setBusinessOwners([owner])
        } else {
          this.appendBusinessOwner(owner)
        }
      }
    },
    createOwnerPayload() {
      let payload = []
      for (let i = 0; i < this.owners.length; i++) {
        let owner = {
          first_name: this.owners[i].first_name,
          middle_initial: this.owners[i].middle_initial,
          last_name: this.owners[i].last_name,
          date_of_birth: this.owners[i].date_of_birth,
          company_role: this.owners[i].company_position,
          percentage_owned: this.formattedPercentageOwned(i),

          phone: this.owners[i].phone_number,
          email: this.owners[i].email,

          financial_condition: [],
        }
        for (let j = 0; j < 1; j++) {
          let item = {
            item_type: "",
            institution_name: "",
            amount: null,
            balance: null,
            loans_on_insurance: "",
            auto_year: "",
            auto_make_model: "",
            monthly_payment: null,
            property_owner: "",
            property_location: "",
            rental_income: "",
          }
          owner.financial_condition.push(item)
        }
        payload.push(owner)
      }
      return payload
    },
    handleBack() {
      this.$router.push({ name: "Banking Relations" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")

      this.validatePage()
      this.validatePercentageTotal()

      this.$nextTick(async () => {
        this.errCnt = jQuery(".form-error-txt").length
        if (!this.errCnt) {
          this.createOwners()
          let ownerPayload = this.createOwnerPayload()
          const payload = {
            page: 4,
            business_owners: ownerPayload,
          }
          console.log(payload)
          await this.putPage(payload)
        } else {
          this.setValidatedNavPage({ name: "owners", isValid: 0 })
          this.scrollToError()
        }
      })
    },
    scrollToError() {
      if (this.errCnt > 1) {
        jQuery("html, body").animate({
          scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
        })
      } else {
        let offset = 0

        if (
          this.missing_owner_first_name.includes(true) ||
          this.invalid_owner_first_name.includes(true)
        ) {
          let i = this.indexOfError(this.missing_owner_first_name, this.invalid_owner_first_name)
          offset = jQuery(`#business-label-owner-fname-${i}`).offset().top
        } else if (
          this.missing_owner_last_name.includes(true) ||
          this.invalid_owner_last_name.includes(true)
        ) {
          let i = this.indexOfError(this.missing_owner_last_name, this.invalid_owner_last_name)
          offset = jQuery(`#business-label-owner-lname-${i}`).offset().top
        } else if (this.invalid_owner_middle_name.includes(true)) {
          let i = this.indexOfError(this.invalid_owner_middle_name)
          offset = jQuery(`#business-label-owner-mi-${i}`).offset().top
        } else if (
          this.missing_owner_birthday.includes(true) ||
          this.invalid_owner_birthday.includes(true)
        ) {
          let i = this.indexOfError(this.missing_owner_birthday, this.invalid_owner_birthday)
          offset = jQuery(`#business-label-owner-dob-${i}`).offset().top
        } else if (
          this.missing_owner_phone.includes(true) ||
          this.invalid_owner_phone.includes(true)
        ) {
          let i = this.indexOfError(this.missing_owner_phone, this.invalid_owner_phone)
          offset = jQuery(`#business-label-owner-phone-${i}`).offset().top
        } else if (
          this.missing_owner_email.includes(true) ||
          this.invalid_owner_email.includes(true)
        ) {
          let i = this.indexOfError(this.missing_owner_email, this.invalid_owner_email)
          offset = jQuery(`#business-label-owner-email-${i}`).offset().top
        } else if (this.invalid_percentage_total) {
          offset = jQuery(`#percentage-form`).offset().top
        }

        jQuery("html, body").animate({ scrollTop: offset })
      }
      // remove processing
      Utils.removeProcessingMask("#ll-continue-btn")
    },
    indexOfError(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] == true) return i
      }
      if (arr2) {
        for (let i = 0; i < arr2.length; i++) {
          if (arr2[i] == true) return i
        }
      }
    },

    validatePage() {
      for (let i = 0; i < this.numOwners; i++) {
        this.validateOwner(i)
      }
    },
    validateOwner(i) {
      this.validateFName(i)
      this.validateMI(i)
      this.validateLName(i)
      this.validateEmail(i)
      this.validatePhone(i)
      this.validateDOB(i)
      this.validatePercentageOwned(i)
      if (this.businessCompanyStructure !== "sole") {
        this.validatePosition(i)
      }
    },
    validateFName(i) {
      this.owner_first_name[i] = this.owner_first_name[i].trim()
      this.missing_owner_first_name[i] = this.owner_first_name[i] ? false : true
      if (!this.missing_owner_first_name[i]) {
        this.invalid_owner_first_name[i] = Validator.isValidName(this.owner_first_name[i])
          ? false
          : true
      }
    },
    validateMI(i) {
      this.owner_middle_name[i] = this.owner_middle_name[i]
        ? this.owner_middle_name[i].trim()
        : null
      if (this.owner_middle_name[i]) {
        this.invalid_owner_middle_name[i] = Validator.isValidName(this.owner_middle_name[i])
          ? false
          : true
      }
    },
    validateLName(i) {
      this.owner_last_name[i] = this.owner_last_name[i].trim()
      this.missing_owner_last_name[i] = this.owner_last_name[i] ? false : true
      if (!this.missing_owner_last_name[i]) {
        this.invalid_owner_last_name[i] = Validator.isValidName(this.owner_last_name[i])
          ? false
          : true
      }
    },
    validateEmail(i) {
      this.owner_email[i] = this.owner_email[i].trim()
      this.missing_owner_email[i] = this.owner_email[i] ? false : true
      if (!this.missing_owner_email[i]) {
        this.invalid_owner_email[i] = Validator.isValidEmail(this.owner_email[i]) ? false : true
      }
    },
    validatePhone(i) {
      this.missing_owner_phone[i] = this.owner_phone[i] ? false : true
      if (!this.missing_owner_phone[i]) {
        this.invalid_owner_phone[i] = Validator.isValidPhoneNumber(this.owner_phone[i])
          ? false
          : true
      }
    },
    validatePosition(i) {
      this.missing_owner_role[i] = false
      if (!this.owner_role[i] || this.owner_role[i] == "") {
        this.missing_owner_role[i] = true
      }
    },

    validateDOB(i) {
      this.missing_owner_birthday[i] = this.owner_birthday[i] ? false : true
      if (!this.missing_owner_birthday[i]) {
        this.invalid_owner_birthday[i] = Validator.isValidDate(this.owner_birthday[i], 18)
          ? false
          : true
      }
    },
    validatePercentageOwned(i) {
      this.missing_owner_percentage[i] = this.owner_percentage[i] ? false : true
      if (!this.missing_owner_percentage[i]) {
        let sum = 0
        for (let o = 0; o < this.owner_percentage.length; o++) {
          sum += parseFloat(this.owner_percentage[o])
        }
        this.invalid_owner_percentage[i] = sum > 100
      }
    },

    validatePercentageTotal() {
      for (let j = 0; j < this.numOwners; j++) {
        this.total = parseFloat(this.owner_percentage[j]) + this.total
      }
      this.invalid_percentage_total = this.total > 100
      this.total = 0
    },

    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}

h2 {
  margin: 0;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  & + .ll-row {
    margin-top: 2em;
  }
}

.ll-item {
  position: relative;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}

.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// table
table,
tr,
td {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid black;
}

td {
  padding: 5px;
}

.main-table {
  border: 1px solid black;
}

.inner-table {
  border: 1px solid #bbb;
}

.inner-table-row {
  border: 1px solid #bbb;
}

.table-col1 {
  width: 25%;
}

.table-col2 {
  width: 55%;
}

.table-col3 {
  width: 20%;
}
</style>
