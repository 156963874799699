<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">
        Do you intend to apply jointly with another borrower?
      </legend>
      <div class="v-spacer-30" />
      <div class="card-group row-2 input-line">
        <label class="card left" :for="`${id}-yes`" data-test="ll-wiz-existing-false">
          <input class="sr-only" type="radio" :id="`${id}-yes`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special"
              ><strong>Yes</strong>, my business is interested in jointly applying for a loan with
              another business</span
            >
          </div>
        </label>
        <label class="card right" :for="`${id}-no`" data-test="ll-wiz-existing-true">
          <input class="sr-only" type="radio" :id="`${id}-no`" :name="`${name}`" />
          <div class="card-content">
            <span><strong>No</strong>, only my business will apply.</span>
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionJointApplication",
  props: {
    modelValue: String,
    id: String,
    name: String,
  },
  methods: {
    choice() {
      if (document.getElementById(`${this.id}-yes`).checked) {
        this.$emit("update:modelValue", true)
        this.$emit("finalCardUpdate")
      } else if (document.getElementById(`${this.id}-no`).checked) {
        this.$emit("update:modelValue", false)
        this.$emit("finalCardUpdate")
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
