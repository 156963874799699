<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">
        Please select the company type that best describes your business
      </legend>
      <div class="v-spacer-30" />
      <div class="card-group row-3 input-line">
        <label
          v-if="this.loanType != 'nonprofit'"
          class="card"
          :for="`${id}-sole-proprietorship`"
          data-test="ll-wiz-sole"
        >
          <input class="sr-only" type="radio" :id="`${id}-sole-proprietorship`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Sole Proprietorship</span>
          </div>
        </label>
        <label
          v-if="this.loanType != 'nonprofit'"
          class="card"
          :for="`${id}-general-partnership`"
          data-test="ll-wiz-general-partnership"
        >
          <input class="sr-only" type="radio" :id="`${id}-general-partnership`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">General Partnership</span>
          </div>
        </label>

        <label class="card" :for="`${id}-llc`" data-test="ll-wiz-llc">
          <input class="sr-only" type="radio" :id="`${id}-llc`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Limited Liability Company (LLC)</span>
          </div>
        </label>

        <label
          v-if="this.loanType != 'nonprofit'"
          class="card"
          :for="`${id}-limit-partnership`"
          data-test="ll-wiz-coll-vehicle"
        >
          <input class="sr-only" type="radio" :id="`${id}-limit-partnership`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Limited Partnership</span>
          </div>
        </label>

        <label class="card" :for="`${id}-corporation`" data-test="ll-wiz-corp">
          <input class="sr-only" type="radio" :id="`${id}-corporation`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Corporation</span>
          </div>
        </label>

        <label class="card" :for="`${id}-other`" data-test="ll-wiz-other">
          <input class="sr-only" type="radio" :id="`${id}-other`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Other (e.g., non-profit)</span>
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionBusinessType",
  props: {
    modelValue: String,
    loanType: String,
    id: String,
    name: String,
  },
  methods: {
    choice() {
      if (
        this.loanType != "nonprofit" &&
        document.getElementById(`${this.id}-sole-proprietorship`).checked
      ) {
        this.$emit("update:modelValue", "sole")
      } else if (
        this.loanType != "nonprofit" &&
        document.getElementById(`${this.id}-general-partnership`).checked
      ) {
        this.$emit("update:modelValue", "partnership")
      } else if (document.getElementById(`${this.id}-llc`).checked) {
        this.$emit("update:modelValue", "llc")
      } else if (
        this.loanType != "nonprofit" &&
        document.getElementById(`${this.id}-limit-partnership`).checked
      ) {
        this.$emit("update:modelValue", "limited")
      } else if (document.getElementById(`${this.id}-corporation`).checked) {
        this.$emit("update:modelValue", "corp")
      } else if (document.getElementById(`${this.id}-other`).checked) {
        this.$emit("update:modelValue", "other")
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
