<template>
  <div role="complementary" aria-labelledby="skiptomain" class="skip-link">
    <a id="skiptomain" href="#main" tabindex="0" class="element-invisible element-focusable">
      Skip to main content
    </a>
  </div>
  <div>
    <!-- <div v-if="isLocalhost" class="env-banner env-localhost">localhost</div>
    <div v-if="isDev" class="env-banner env-dev">development</div>
    <div v-if="isStaging" class="env-banner env-staging">staging</div> -->
  </div>

  <router-view />
</template>

<script>
import { datadogLogs } from "@datadog/browser-logs"
import Swal from "sweetalert2"
import { mapActions } from "vuex"
import store from "@/store"

export default {
  name: "App",
  data() {
    return {
      isDev: false,
      isStaging: false,
      isLocalhost: false,
      env: "prod",
      timeoutWarning: "",
      timeoutLogOut: "",
    }
  },
  created() {
    if (window.location.href.indexOf("dev.") > -1) {
      this.isDev = true
      this.env = "dev"
    } else if (window.location.href.indexOf("staging.") > -1) {
      this.isStaging = true
      this.env = "staging"
    } else if (window.location.href.indexOf("localhost") > -1) {
      this.isLocalhost = true
      this.env = "localhost"
    }

    // Setup client side logging
    datadogLogs.init({
      clientToken: "pubbd1377c9304b7ba925f84e2be008783c",
      site: "datadoghq.com",
      service: "boe-admin-fe",
      env: this.env,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    })
  },
  mounted() {
    this.refresh()

    // not sure what cloneNode does but doc is never used -kw
    // var doc = document.cloneNode(true);
    document.addEventListener("click", this.refresh)
    document.addEventListener("keydown", this.refresh)

    // Confirm on Refresh/leave page
    // window.addEventListener('beforeunload', function (e) {
    //   console.log("refresh")
    //   var msg = 'alert...';
    //   (e || window.event).returnValue = msg

    //   return msg;
    // });
  },
  methods: {
    ...mapActions("personalFiStatement", ["resetAll"]),
    clearSessionData() {
      console.log("clearSessionData()...")
      this.resetAll()
    },
    isTimeoutIgnored() {
      return (
        this.$route.name == "Getting Started Line" ||
        this.$route.name == "Getting Started Loan" ||
        this.$route.name == "Questionnaire"
      )
    },
    refresh() {
      console.log("refresh()...")

      clearTimeout(this.timeoutWarning)
      clearTimeout(this.timeoutLogOut)

      const warningTime = 28 * 60 * 1000 // 28 minutes - change this to 4 for testing
      let logoutTime = 30 * 60 * 1000 // 30 minutes - change this to 5 for testing
      // If the token is going to timeout the next time the warning is going to display, skip and only use timeoutLogOut
      this.timeoutWarning = setTimeout(() => {
        if (this.isTimeoutIgnored()) {
          return
        }
        if (this.isExpired()) {
          console.log("Expired, timing out")
          this.displaySessionTimedOut()
        } else {
          console.log("Not expired, showing warning")
          this.displaySessionTimeoutWarning()
        }
      }, warningTime)

      this.timeoutLogOut = setTimeout(() => {
        if (this.isTimeoutIgnored()) {
          return
        }
        this.displaySessionTimedOut()
      }, logoutTime)
    },
    isExpired() {
      const jwtToken = store.getters["jwt_token"]
      if (jwtToken != null) {
        const parts = jwtToken.split(".")
        if (parts.length > 1) {
          const claims = JSON.parse(atob(parts[1]))
          const warningEpoch = Math.floor(Date.now() / 1000)
          return claims.exp < warningEpoch
        }
      }
      return false
    },
    displaySessionTimeoutWarning() {
      console.log("send warning...")
      const imagePath = require("@/assets/images/timeout-2x.png")
      Swal.fire({
        title: "Session Timeout Warning",
        text: "Your session is about to be cleared due to inactivity",
        imageUrl: imagePath,
        imageWidth: 97,
        imageHeight: 125,
        confirmButtonText: "Continue Session",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(result => {
        if (result.isConfirmed) {
          console.log("continue session...")
        }
      })
    },
    displaySessionTimedOut() {
      console.log("send logout...")
      const imagePath = require("@/assets/images/stop-watch-2x.png")
      // clear out the session info
      this.clearSessionData()

      Swal.fire({
        title: "Session Timed Out",
        text: "Your session has been cleared due to inactivity",
        backdrop: false,
        imageUrl: imagePath,
        imageWidth: 97,
        imageHeight: 125,
        confirmButtonText: "Start Over",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(result => {
        if (result.isConfirmed) {
          console.log("continue...")
        }
      })

      this.pushStartingPage()
    },
    pushStartingPage() {
      if (this.$route.path.includes("credit/auth")) {
        this.$router.push("/credit/auth")
      } else if (this.$route.path.includes("credit")) {
        this.$router.push("/credit")
      } else if (this.$route.path.includes("connectToBankerSimplified")) {
        this.$router.push("/connectToBankerSimplified/banker")
      } else if (this.$route.path.includes("connectToBanker")) {
        this.$router.push("/connectToBanker")
      } else {
        this.$router.push("/")
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
a {
  word-wrap: break-word;
}
#app {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  line-height: 1.5;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}
a,
a:visited,
a:link,
button {
  color: $black;
}
.skip-link a {
  color: $redDark;
}
.upload_link {
  background-color: $white;
  border: 1px solid $gray;
  text-decoration: none;
  padding: 6px 24px 4px;
  font-size: 1rem;
  border-radius: 20px;
  min-height: 40px;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowFocus;
    border-color: $grayDark;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }

  &:visited {
    background-color: $redFaint;
  }
}
.tel {
  white-space: nowrap;
}
select {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
.container {
  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
  }
  @include media-breakpoint-down(lg) {
    margin-left: 2em;
    margin-right: 2em;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 1.5rem;
    margin-right: 1.5em;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}
h1 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
h2 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
h3 {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
input {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
button,
.swal2-popup {
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
}
textarea {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff") format("woff");
}
@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}
@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "CircularXXTT-Bold";
  src: url("./assets/fonts/CircularXXTT-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "CircularXXTT-Book";
  src: url("./assets/fonts/CircularXXTT-Book.ttf") format("woff2");
}
.env-banner {
  display: block;
  position: absolute;
  height: 25px;
  width: 100%;
  color: $white;
  top: 0;
  left: 0;
  z-index: 100;
}
.env-localhost {
  background-color: rgb(37, 161, 66, 0.5);
}
.env-dev {
  color: black;
  background-color: rgb(255, 252, 55, 0.5);
}
.env-staging {
  background-color: rgb(161, 37, 37, 0.5);
}
.fhb-red {
  color: $redSup;
}
.hr {
  border-top: 1px solid $gray;
  height: 1px;
  margin: 2em 0;
}
.btn-container {
  align-items: center;
  column-gap: 2em;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  text-align: center;
}
.back-btn {
  align-items: center;
  background-color: $white;
  color: $black;
  display: flex;
  width: 63px;
  height: 40px;
  justify-content: center;
  border: 1px solid $grayDark;
  border-radius: 20px;
  /*grid-column: 1 / span 1;
  grid-row: 1 / span 1;*/

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.back-icon {
  height: auto;
  width: 8px;
}
.goto-fhb-btn {
  align-items: center;
  background-color: $white;
  border: 1px solid $grayDark;
  border-radius: 20px;
  color: $black;
  display: flex;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 209px;

  &:hover,
  &:active,
  &:focus {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.goto-fhb-text {
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  position: relative;
  text-align: center;
}
.continue-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  width: 170px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.default-btn {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border-radius: 20px;
  color: $black;
  width: 140px;
  height: 40px;
  justify-content: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.029em;
  margin: auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.continue-text {
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  text-transform: uppercase;
}
.v-spacer-60 {
  height: 60px;
}
.v-spacer-50 {
  height: 50px;
}
.v-spacer-40 {
  height: 40px;
}
.v-spacer-30 {
  height: 30px;
}
.v-spacer-20 {
  height: 20px;
}
.v-spacer-15 {
  height: 15px;
}
.v-spacer-10 {
  height: 10px;
}
.v-spacer-5 {
  height: 5px;
}
.separator {
  color: $gray;
  font-weight: 300;
}
.field-group {
  position: relative;
}
.field-group + .field-group {
  @include media-breakpoint-down(lg) {
    margin-top: 2em;
  }
}
.section-title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 2.75rem;
  }
}
.section-title2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5em;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
}
.panel-icon {
  display: inline-block;
  width: 13px;
}
.panel-icon:hover {
  cursor: pointer;
}
.panel-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0;
}
.panel-header:hover {
  cursor: pointer;
}
.panel-title {
  align-items: center;
  display: grid;
  grid-gap: 1.6em;
  grid-template-columns: 13px auto;
  margin-bottom: 1em;

  .panel-icon:hover,
  .panel-icon:focus {
    transform: scale(1.2);
    transition: 200ms ease;
  }

  .panel-icon:active {
    transform: rotate(180deg);
    transition: 200ms ease;
  }
}
.panel-title + div + .panel-title {
  margin-top: 2.5em;
}
.panel-title .panel-header {
  font-size: 1.1rem;
}
.tf-label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}
.icon-validation {
  bottom: 2.5em;
  height: 0;
  padding-right: 1em;
  position: relative;
  text-align: right;
  z-index: 2;

  img {
    height: auto;
    width: 24px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 2em;
  }
}
.icon-validation,
.ll-textfield,
.tf-note {
  @include media-breakpoint-up(lg) {
    max-width: 548px;
  }
}
.grid-container,
.ll-row {
  .icon-validation,
  .ll-textfield,
  .tf-note {
    @include media-breakpoint-up(lg) {
      max-width: unset;
    }
  }
}
.ll-textfield,
.ll-textfield-error {
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.ll-textfield,
textarea {
  background-color: $white;
  border: 1px solid $grayMid;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
}
.ll-textfield-error,
.ll-textfield-error.ll-textfield,
.ll-textfield-error.ll-text {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}
.ll-textfield-money {
  padding-left: 12px;
  padding-right: 12px;
}
.ll-prefix-wrap {
  position: relative;
}
.tf-dollar-prefix {
  align-items: center;
  height: 40px;
  position: absolute;
  display: flex;
  left: 1em;
  bottom: 0;
  width: 12px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.ll-select-error,
.ll-select {
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  height: 42px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  max-width: 548px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.ll-select {
  background-color: $white;
  border: 1px solid $grayMid;
}
.ll-select-error {
  background: $white;
  border: 1px solid $red;
}
.ll-item {
  position: relative;

  > input[type="checkbox"] {
    margin-left: 1px;
  }
}
.ll-item + .ll-item {
  @include media-breakpoint-down(md) {
    margin-top: 1.5em;
  }
}
.ll-fname,
.ll-lname {
  @include media-breakpoint-up(md) {
    flex-basis: calc(50% - 4em - 100px);
  }

  @include media-breakpoint-down(md) {
    flex-basis: calc(100% - 180px - 2em);
  }
}
.ll-fname {
  @include media-breakpoint-between(md, lg) {
    flex: 1;
  }
}
.page-error-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  background-color: $white;
  color: $red;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5;
  text-align: left;
  border: 1px solid $red;
  display: grid;
  grid-template-columns: 50px auto;
}
.page-error-icon-container {
  background-color: $redFaint;
  text-align: center;
}
.page-error-icon {
  height: auto;
  padding-top: 1.2em;
  width: 15px;
}
.page-error-msg {
  padding: 1em;
}
.dump {
  text-align: left;
}
.tf-note {
  margin-top: 4px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  a {
    &:hover,
    &:active,
    &:focus {
      color: $redDark;
    }
  }
}
.footnote {
  background-color: $orangeFocus;
  color: $black;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  grid-gap: 0.25em;
  line-height: 1.5;
  margin-top: 30px;
  padding: 25px;
}
.disclosure-bottom strong {
  font-size: 0.95rem;
  font-weight: 700;
  letter-spacing: 0.029em;
}
.disclosure-bottom {
  border: 1px solid $grayMid;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  border-radius: 3px;
  padding: 15px;

  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.form-error-msg {
  align-items: start;
  display: grid;
  grid-column-gap: 0.35em;
  grid-template-columns: 15px auto;
  margin-top: 3px;

  .form-error-icon {
    height: auto;
    margin-top: 1.5px;
    width: 15px;
  }
}
.form-error-txt {
  display: inline-block;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: $redDark;
}
.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}
.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}
.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}
.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}
.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}
.toggle-btn-up:hover {
  cursor: pointer;
}
.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}
.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}
.toggle-btn-container-error {
  border: 1px dotted $red;
}
.sys-err-msg {
  border: 1px solid $red;
  color: $red;
  padding: 0.75em 1.5em;
  text-align: center;
}
.sys-err-icon {
  margin-right: 10px;
}
.sys-err-list {
  display: inline-block;
  text-align: left;
}
.txt-bold {
  font-weight: 800;
}
body img[src*="turn"] {
  bottom: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
}
.all-caps {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.header-logo,
.submitted-logo {
  height: auto;
  @include media-breakpoint-up(md) {
    padding-bottom: 12px;
    width: 225px;
  }
  @include media-breakpoint-down(md) {
    width: 150px;
  }
}
.page-bg {
  background-color: $orangeFaint;
  height: 100%;
  min-height: 100vh;
  text-align: center;
}
.content-container {
  align-content: center;
  background-image: url("assets/images/orange@1x.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0 2em;
  width: 100%;

  @include media-breakpoint-down(sm) {
    background-size: 800px;
  }
}
.overlay-content {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  position: relative;
  text-align: center;
  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }
  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
}
.submitted-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.submitted {
  height: auto;
  margin: 2em auto;
  padding: 2em;
  text-align: center;
  @include media-breakpoint-up(sm) {
    background: $white;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 500px;
    width: calc(100% - 4em);
  }
  @include media-breakpoint-up(lg) {
    max-width: 700px;
    padding: 2em 4em;
  }
}
.overlay-splash {
  margin: 2em 0 0.5em;
}
.overlay-title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}
.overlay-subtitle {
  margin-top: 0.25em;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}
.overlay-subtitle {
  margin-top: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.011em;
  text-align: center;
}
.overlay-body {
  display: inline-block;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-top: 15px;
}
.return-fhb-link-btn {
  background-color: $white;
  border-radius: 20px;
  display: block;
  min-height: 40px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin: auto;
  padding: 0.8em 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 240px;
  border: 1px solid $grayDark;
  box-sizing: border-box;
  border-radius: 20px;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.upload_link {
  background-color: $white;
  border-radius: 20px;
  font-size: 1rem;
  min-height: 40px;
  line-height: 1.5;
  margin: auto;
  padding: 0.5em 1.5em;
  text-align: center;
  text-decoration: none;
  border: 1px solid $grayDark;
  box-sizing: border-box;
  border-radius: 20px;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
.sr-only {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.gradiant-bar {
  height: 20px;
  background: linear-gradient(180deg, $grayVlight 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
  position: relative;
  z-index: 80;
}

.processing {
  background-color: $grayLight !important;
  border-color: $grayLight !important;
  color: $grayDark !important;
}
.processing:hover {
  cursor: default !important;
  background-color: $grayLight !important;
}

.confirm-btn {
  outline: 2px dotted $red;
}

.swal2-image {
  margin-top: 3em;
}

.swal2-actions {
  padding-bottom: 1.5em;
}

.swal2-popup {
  position: relative;
}

.swal2-popup button.swal2-styled.swal2-confirm {
  align-items: center;
  display: flex;
  background: $yellowLight;
  border: none;
  border-radius: 20px;
  color: $black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.029em;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  width: 220px;

  @include media-breakpoint-down(sm) {
    flex: 1;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: unset;
}

.swal2-confirm {
  font-weight: 700;
}

.swal2-actions.swal2-loading {
  display: none;
  /*right: 186px;
  position: absolute;
  top: 49px;*/
}

.swal2-loader {
  border-color: $white transparent $white transparent;
  height: 1.8em;
  width: 1.8em;
}

.swal2-styled.swal2-confirm:focus {
  background-color: $yellowHover;
  box-shadow: 0 0 0 3px rgba(240, 171, 54, 0.3);
}

.section-description {
  margin-bottom: 2.5em;
}

.form-title {
  font-size: 1rem;
  font-weight: 700;
  //letter-spacing: 1px;
  margin: 0.5em 0;
  //text-transform: uppercase;
}

.breadcrumb {
  margin-bottom: 30px;
}

.breadcrumb .grid-container {
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
    max-width: calc(100% - 3em);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1em;
    row-gap: 0.5em;
  }

  @include media-breakpoint-up(sm) {
    display: grid;
    text-align: center;
    margin-top: 5px;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 25px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(860px + 2em);
  }

  @include media-breakpoint-up(xxl) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(1200px + 4em);
  }
}

.breadcrumb .grid-item {
  padding: 2px 0;
  position: relative;

  /*@include media-breakpoint-down(sm) {
    &:first-child {
      padding-top: 0;

      &::after {
        height: 26px;
      }
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        height: 26px;
      }
    }
  }

  &::after {
    border-left: 1px solid $gray;
    content: '';
    display: block;
    height: 28px;
    width: 1px;
    position: absolute;
    left: 12px;
    top: 0;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }*/

  @include media-breakpoint-down(sm) {
    align-items: center;
    display: grid;
    grid-template-columns: 25px auto;
    margin: 0;
    max-width: 200px;
    text-align: left;
  }

  @include media-breakpoint-up(sm) {
    display: grid;
    grid-row-gap: 0.5em;
    grid-template-columns: 1fr 32px 1fr;
    grid-template-rows: auto 32px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
  }
}

.stage-name {
  font-style: normal;
  font-weight: 400;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  line-height: 1.25;
  text-align: left;
  padding: 2px 5px;

  @include media-breakpoint-up(sm) {
    padding: 2px 15px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.95rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 0.8rem;

    > span {
      display: block;
    }
  }

  @include media-breakpoint-up(sm) {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
    text-align: center;
  }
}

.stage-name .stage-info {
  @include media-breakpoint-down(sm) {
    //display: none;
  }
}

.stage-num {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  line-height: 1.5;
  text-align: center;
  color: $black;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 4;

  img {
    height: auto;
    width: 12px;
  }

  @include media-breakpoint-up(sm) {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
  }
}

.circle,
.circle-current,
.circle-validated,
.circle-prev {
  align-items: center;
  border: 1px solid $gray;
  display: flex;
  font-size: 0.75rem;
  width: 24px;
  height: 24px;
  justify-content: center;
  border-radius: 50%;

  @include media-breakpoint-up(sm) {
    font-size: 0.85rem;
    width: 32px;
    height: 32px;
  }
}

.circle {
  background-color: $white;
}

.circle-current {
  background: $yellowLight;
  border-color: $yellowLight;
}

.circle-validated {
  background: $grayLight;

  &:hover {
    background-color: fade-out($yellowHover, 0.5);
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.circle-prev {
  &:hover {
    cursor: pointer;
  }
}

.box-right-start,
.box-right,
.box-left,
.box-left-end {
  border-top: 1px solid $gray;
  align-self: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.box-right-start {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
}

.box-right {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
}

.box-left {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.box-left-end {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

table {
  border-collapse: collapse;

  &.assets-liabilities,
  &.income-expenses {
    width: 100%;
  }

  .bottom-border {
    border-bottom: 1px solid $gray;
  }
  .right-border {
    border-right: 1px solid $gray;
  }

  .totals {
    font-size: 0.85rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .ll-text {
    background-color: $white;
    border: 1px solid $grayMid;
    box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
    height: 35px;
    width: 8em;
    float: right;
    clear: both;

    @include media-breakpoint-up(md) {
      width: 10em;
    }
  }

  .ll-textfield {
    background-color: $white;
    border: 1px solid $grayMid;
    box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
    font-size: 0.9rem;
    height: 35px;
    clear: both;
    width: 10.25em;
  }
}

.ll-text-desc {
  border: 1px solid $grayMid;
  border-radius: 3px;
  box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4);
  font-size: 0.9rem;
  padding-left: 8px;
  padding-right: 8px;
}

.with-desc {
  width: 100%;
}

.with-desc td,
.with-desc th {
  vertical-align: top;
  &:first-child {
    padding-top: 0px;
    padding-right: 0.2em;
    width: 50%;
  }

  &:nth-child(2) {
    padding-top: 0px;
    width: 50%;
  }
}
.with-desc tr {
  border: none !important;
}

/* Solid border */
hr.solid {
  border: 0;
  border-top: 1px solid $gray;
}

.ll-middle-initial {
  width: 200px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.add-plus:before {
  // add + on + Add button so that it doesn't read to screenreaders
  content: "+ ";
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
</style>
