import auth from "./auth_routes.js"
import store from "@/store"

const jwtISS = "fhb-loans-commercial"

const businessCreditRoutes = {
  getToken() {
    return store.getters["businessCreditApp/jwtToken"]
  },
  getRedirect() {
    return "/credit"
  },
  loadBusinessCreditRoutes(routes) {
    routes.push(
      {
        path: "/credit",
        name: "Getting Started Business Credit",
        component: () => import("../views/business/credit_app/GettingStarted.vue"),
      },
      {
        path: "/credit/filer",
        name: "Filer Info",
        component: () => import("../views/business/credit_app/FilerPage.vue"),
      },
      {
        path: "/credit/business",
        name: "Business Info",
        component: () => import("../views/business/credit_app/BusinessPage.vue"),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/credit/relations",
        name: "Banking Relations",
        component: () => import("../views/business/credit_app/BankingRelations.vue"),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/credit/owners",
        name: "Business Owners",
        component: () => import("../views/business/credit_app/BusinessOwners.vue"),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/credit/documents",
        name: "Credit-Document-Upload",
        component: () => import("../views/business/credit_app/DocumentUpload.vue"),
        beforeEnter: (to, from, next) => {
          auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        },
      },
      {
        path: "/credit/received",
        name: "Business Owners Received",
        component: () => import("../views/business/credit_app/BusinessReceived.vue"),
        // beforeEnter: (to, from, next) => {
        //   auth.requireAuth(to, from, next, jwtISS, this.getToken(), this.getRedirect())
        // }
      }
    )
    return routes
  },
}

export default businessCreditRoutes
