<template>
  <fieldset
    class="input-line"
    role="radiogroup"
    :aria-describedby="hasNote ? `${id}-note ${id}-error` : `${id}-error`"
  >
    <legend class="tf-label" :id="`${id}-legend`">
      {{ legendText }}<sup class="fhb-red">*</sup>
    </legend>
    <div
      class="options toggle-btn-container"
      :class="missingSelection ? 'toggle-btn-container-error' : ''"
    >
      <label
        v-for="(value, index) in inputValues"
        :key="index"
        :class="modelValue == value.value ? 'input-two-selected' : 'input-two'"
        :for="`${id}-${index}`"
      >
        <input
          class="sr-only"
          type="radio"
          :id="`${id}-${index}`"
          :name="`${name}`"
          :value="value.value"
          @change="onChange"
        />
        <slot :name="index" />
        <span>{{ value.text }}</span>
      </label>
    </div>
    <div v-if="hasNote" class="tf-note" :id="`${id}-note`" :data-test="`${id}-note`">
      {{ hasNote }}
    </div>
    <div :id="`${id}-error`" class="ind-err-container">
      <div v-if="missingSelection" class="form-error-msg" :data-test="`${id}-error`">
        <img
          class="form-error-icon"
          srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
          src="@/assets/images/exclaimation-lg-2x.png"
          alt=""
        />
        <div role="alert" class="form-error-txt">Please select an option</div>
      </div>
    </div>
  </fieldset>
</template>
<script>
export default {
  name: "RadioInputs",
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    inputAmount: Number,
    id: String,
    name: String,
    label: String,
    inputText: Array,
    inputValues: Array,
    missingSelection: Boolean,
    hasNote: Boolean,
    legendText: String,
    class: String,
    ariaDescribedby: String,
  },
  data() {
    return {}
  },
  methods: {
    onChange(e) {
      console.log(e.target.value)
      this.$emit("update:modelValue", e.target.value)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.input-line .options {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.input-line-inline .options {
  display: flex;
  gap: 15px;
}
.options {
  border: 1px solid white;
  margin: 4px 0 0;
  padding: 0 0 3px;
}
.options span {
  display: block;
}
fieldset:focus-within .options,
fieldset:focus-visible .options {
  border: 1px dotted blue;
}
.options.toggle-btn-container-error {
  border: 1px dotted $red;
  //padding: 10px;
}
legend {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0 0 3px 0;
}
.input-two span {
  padding: 20px 100px;
  background: #fff;
  border: 1px solid #898989;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);
  border-radius: 3px;
  &:hover,
  :active,
  :focus {
    background-color: #fed67b33;
    cursor: pointer;
  }
}
.input-two-selected span,
.input-two input:checked span {
  padding: 20px 100px;
  background-color: #fed67b33;
  border-radius: 3px;
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

// input {
//   opacity: 0;         // hide it visually
//   z-index: -1;        // avoid unintended clicks
//   position: absolute; // don't affect other elements positioning
// }
</style>
