<template>
  <div class="input-container">
    <input
      type="text"
      :class="missingSelection ? 'input-err search-input' : 'search-input'"
      :value="inputValue"
      @input="search"
      @keydown="focus($event, 0, '')"
      :name="name"
      :id="id"
      :disabled="disable"
      :data-test="dataTest"
      aria-labelledby="search-label"
      :aria-describedby="ariaDescribe"
    />
    <!-- if value is null, then button should display caret -->
    <button
      v-if="modelValue == null"
      type="button"
      @click="openDropdown"
      :class="missingSelection ? 'input-err-btn' : 'input-btn'"
      id="side-button"
      @keydown="focus($event, 0, '')"
    >
      <img
        class="show-dropdown"
        srcset="@/assets/images/caretDown.png 1x, @/assets/images/caretDown-2x.png 2x"
        src="@/assets/images/caretDown-2x.png"
        alt=""
      />
      <span class="sr-only">View All Options for Type of Business</span>
    </button>
    <!-- otherwise, display X -->
    <button
      v-else
      type="button"
      @click="clear"
      :class="missingSelection ? 'input-err-btn' : 'input-btn'"
      id="side-button"
      @keydown="focus($event, 0, '')"
    >
      <img
        class="clear-image"
        srcset="@/assets/images/X-img.png 1x, @/assets/images/X-img-2x.png 2x"
        src="@/assets/images/X-img-2x.png"
        alt=""
      />
      <span class="sr-only">Clear selection</span>
    </button>
  </div>
  <div v-if="showDropdown" class="dropdown-container">
    <div
      :tabindex="index"
      :id="index"
      v-for="(option, index) in filteredArray"
      :key="index"
      :class="option.key !== 'No results found' ? 'dropdown-box' : 'dropdown-box-disabled'"
      @click="selected(option)"
      @keydown.enter="selected(option)"
      @keydown.space="selected(option)"
      @keydown.prevent="focus($event, index, 'from_div')"
      :disabled="option.key === 'No results found'"
    >
      {{ option.key }}
    </div>
  </div>
</template>
<script>
import jquery from "jquery"

export default {
  name: "DropdownSearch",
  emits: ["update:modelValue", "selected"],
  props: {
    modelValue: Object,
    id: String,
    name: String,
    label: String,
    missingSelection: Boolean,
    errMsg: String,
    disable: Boolean,
    dropdownArray: Array,
    dataTest: String,
    ariaDescribe: String,
  },
  data() {
    return {
      filteredArray: [],
      inputValue: this.modelValue == null ? "" : this.modelValue.key,
      showDropdown: false,
    }
  },
  mounted() {},
  methods: {
    // filter through passed through array to update dropdown
    filter(search) {
      this.filteredArray = this.dropdownArray.filter(row =>
        row.key.toLowerCase().includes(search.toLowerCase())
      )
      if (this.filteredArray.length == 0) {
        this.filteredArray = [{ key: "No results found" }]
      }
    },
    // init search
    search(e) {
      this.showDropdown = true
      // update value in input to reflect what's typed
      this.inputValue = e.target.value
      this.filter(e.target.value)
    },
    // selected div
    selected(option) {
      if (option.key === "No results found") return
      this.inputValue = option.key
      this.$emit("update:modelValue", option)
      // to handle validation functions in parent
      this.$emit("selected")
      this.showDropdown = false
      // focus back on input
      jquery(`#${this.id}`).focus()
    },
    clear() {
      this.inputValue = ""
      this.$emit("update:modelValue", null)
      this.$emit("selected")
      this.showDropdown = false
      jquery(`#${this.id}`).focus()
    },
    openDropdown() {
      this.filteredArray = this.dropdownArray
      this.showDropdown = !this.showDropdown
    },
    moveDown(i) {
      jquery(`#${i}`).focus()
    },
    moveUp(i) {
      if (i == 0) {
        jquery("#0").focus()
      } else {
        jquery(`#${i}`).focus()
      }
    },
    moveRight() {
      jquery("#side-button").focus()
    },
    moveLeft() {
      jquery(`#${this.id}`).focus()
    },
    focus(e, index, from) {
      if (e.keyCode == 37) {
        this.moveLeft()
      } else if (e.keyCode == 38) {
        this.moveUp(index - 1)
      } else if (e.keyCode == 40) {
        if (this.showDropdown) {
          if (from == "from_div") {
            this.moveDown(index + 1)
          } else {
            this.moveDown(index)
          }
        }
      } else if (e.keyCode == 9) {
        if (this.showDropdown && from == "from_div") {
          this.showDropdown = false
          jquery(`#establish_date`).focus()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.search-input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  height: 42px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;

  @include media-breakpoint-up(sm) {
    height: 56px;
  }
}
.dropdown-box {
  padding: 10px;
  &:hover,
  :active {
    background-color: $grayVlight;
    cursor: pointer;
  }
  border-bottom: 1px solid $grayVlight;
}
.dropdown-box-disabled {
  padding: 10px;
  &:hover,
  :active {
    background-color: $grayVlight;
    cursor: pointer;
  }
  border-bottom: 1px solid $grayVlight;
  pointer-events: none;
}
.dropdown-container {
  width: 100%;
  max-width: 100%;
  border: 1px solid $gray;
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  background-color: $white;

  @include media-breakpoint-down(xxl) {
    max-width: 820px;
    width: 100%;
  }
}
.input-container {
  position: relative;
  input {
    border-radius: 3px;
    padding-right: 56px;
    background-color: $white;
    border: 1px solid $grayMid;
  }
  button {
    background-color: $grayVlight;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 8px auto 0;

    &:hover,
    &:focus,
    &:active {
      background-color: $grayLight;
    }
  }
  .input-err {
    background-color: $redFaint;
    border: 1px solid $red;
  }
  .input-err-btn {
    background-color: $redFaint;

    &:hover,
    &:focus,
    &:active {
      background-color: fade-out($red, 0.85);
    }
  }
}
.clear-image {
  width: 11px;
  height: auto;
}
</style>
