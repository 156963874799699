import axios from "axios"

const COMMERCIAL_LOANS_BASE_URL = process.env.VUE_APP_API_URL + "/commercial"

const CommercialAPI = {
  postQuestionnaire(payload) {
    console.log("--- postQuestionnaire - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/questionnaire"

    return axios
      .post(endpoint, payload)
      .then(response => {
        return response
      })
      .catch(err => {
        console.log(err.response)
        return err.response
      })
  },
  postCreditAppPage(payload) {
    console.log("--- postPage - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/businesscredit"

    return axios
      .post(endpoint, payload)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  putCreditAppPage(id, payload, jwtToken) {
    console.log("--- putPage - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/businesscredit/" + id

    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    console.log("--- requestData ---")
    console.log(requestData)

    return axios
      .put(endpoint, payload, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  postCreditAppSubmit(id, payload, jwtToken) {
    console.log("--- postSubmit - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/businesscredit/" + id + "/submit"

    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    return axios
      .post(endpoint, payload, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  getCreditAppOwnerOtp(id, oid, method) {
    console.log("--- get Owner Otp  ---")

    const endpoint =
      COMMERCIAL_LOANS_BASE_URL +
      "/businesscredit/" +
      id +
      "/owners/" +
      oid +
      "/otp?method=" +
      method

    return axios
      .get(endpoint)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  putCreditAppOwnerOtp(id, oid, otpCode) {
    console.log("--- put owner Otp - payload ---")

    const endpoint =
      COMMERCIAL_LOANS_BASE_URL +
      "/businesscredit/" +
      id +
      "/owners/" +
      oid +
      "/otp?code=" +
      otpCode

    return axios
      .put(endpoint)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  getCreditAppOwner(id, oid, jwtToken) {
    console.log("--- get owner ---")
    console.log("token", jwtToken)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/businesscredit/" + id + "/owners/" + oid
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    return axios
      .get(endpoint, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  putCreditAppOwner(id, oid, jwtToken, payload) {
    console.log("--- put owner - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/businesscredit/" + id + "/owners/" + oid
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    console.log("--- requestData ---")
    console.log(requestData)

    return axios
      .put(endpoint, payload, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  postPageBanker(payload) {
    console.log("--- postPage - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/banker"

    return axios
      .post(endpoint, payload)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  putBankerPage(id, payload, jwtToken) {
    console.log("--- putPage - payload ---")
    console.log(payload)
    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/banker/" + id
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    console.log("--- requestData ---")
    console.log(requestData)

    return axios
      .put(endpoint, payload, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  uploadFile(presignedURL, file) {
    var requestData = {
      headers: {
        "Content-Type": file.type,
      },
    }

    return axios
      .put(presignedURL, file, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  deleteFile(id, index, fileType, jwtToken) {
    console.log("--- deleteFile - payload ---")

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/banker/" + id + "/files"
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
      params: {
        file_index: index,
        file_type: fileType,
      },
    }

    return axios
      .delete(endpoint, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  postPagePersonalFi(payload) {
    console.log("--- postPage - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/personalfinancial"

    return axios
      .post(endpoint, payload)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  putPagePersonalFi(id, payload, jwtToken) {
    console.log("--- putPage - payload ---")
    console.log(payload)
    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/personalfinancial/" + id
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    console.log("--- requestData ---")
    console.log(requestData)

    return axios
      .put(endpoint, payload, requestData)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
  postPageSimplifiedBanker(payload) {
    console.log("--- postPageBanker - payload ---")
    console.log(payload)

    const endpoint = COMMERCIAL_LOANS_BASE_URL + "/sbanker"

    return axios
      .post(endpoint, payload)
      .then(response => {
        return response
      })
      .catch(err => {
        return err.response
      })
  },
}

export default CommercialAPI
