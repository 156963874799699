<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">Tell Us Why You're Here</legend>
      <div class="v-spacer-30" />
      <div class="card-group row-2 input-line">
        <label class="card left" :for="`${id}-choice-1`" data-test="ll-wiz-existing-false">
          <input class="sr-only" type="radio" :id="`${id}-choice-1`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special"
              >I want to apply for a <strong>new business loan</strong>.</span
            >
          </div>
        </label>
        <label class="card right" :for="`${id}-choice-2`" data-test="ll-wiz-existing-true">
          <input class="sr-only" type="radio" :id="`${id}-choice-2`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special"
              >I want to <strong>modify, renew, or adjust</strong> an existing business loan with
              FHB.</span
            >
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionExisting",
  props: {
    modelValue: String,
    name: String,
    id: String,
  },
  methods: {
    choice() {
      if (document.getElementById(`${this.id}-choice-1`).checked) {
        this.$emit("update:modelValue", false)
      } else if (document.getElementById(`${this.id}-choice-2`).checked) {
        this.$emit("update:modelValue", true)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
