<template>
  <div id="main"></div>
</template>

<script>
export default {
  name: "MainId",
  props: {
    type: String,
  },
  components: {},
  data() {
    return {}
  },
}
</script>

<style scoped>
#main {
  min-height: 1px;
}
</style>
