<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">Please select the purpose for the loan</legend>
      <div class="v-spacer-30" />
      <div class="card-group row-3 input-line">
        <label
          v-if="this.loanType == 'realestate'"
          class="card"
          :for="`${id}-choice-1`"
          data-test="ll-wiz-purp-construction"
        >
          <input class="sr-only" type="radio" :id="`${id}-choice-1`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Construction and Renovation</span>
          </div>
        </label>
        <label class="card right" :for="`${id}-choice-2`" data-test="ll-wiz-purp-working">
          <input class="sr-only" type="radio" :id="`${id}-choice-2`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Working Capital</span>
          </div>
        </label>

        <label
          class="card right"
          :for="`${id}-choice-3`"
          data-test="ll-wiz-purp-equipment"
          v-if="this.loanType != 'revolving'"
        >
          <input class="sr-only" type="radio" :id="`${id}-choice-3`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Equipment/Vehicle Acquisition</span>
          </div>
        </label>

        <label
          v-if="this.loanType != 'revolving'"
          class="card right"
          :for="`${id}-choice-4`"
          data-test="ll-wiz-purp-estate"
        >
          <input class="sr-only" type="radio" :id="`${id}-choice-4`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Real Estate Purchase</span>
          </div>
        </label>

        <label
          v-if="this.loanType != 'revolving'"
          class="card right"
          :for="`${id}-choice-5`"
          data-test="ll-wiz-purp-startup"
        >
          <input class="sr-only" type="radio" :id="`${id}-choice-5`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Business Startup</span>
          </div>
        </label>

        <label class="card right" :for="`${id}-choice-6`" data-test="ll-wiz-purp-other">
          <input class="sr-only" type="radio" :id="`${id}-choice-6`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Other</span>
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionLoanPurpose",
  props: {
    modelValue: String,
    loanType: String,
    id: String,
    name: String,
  },
  created() {
    console.log(this.loanType)
  },
  methods: {
    choice() {
      if (this.loanType == "realestate" && document.getElementById(`${this.id}-choice-1`).checked) {
        this.$emit("update:modelValue", "construction")
      } else if (document.getElementById(`${this.id}-choice-2`).checked) {
        this.$emit("update:modelValue", "working")
      } else if (
        this.loanType != "revolving" &&
        document.getElementById(`${this.id}-choice-3`).checked
      ) {
        this.$emit("update:modelValue", "equipment")
      } else if (
        this.loanType != "revolving" &&
        document.getElementById(`${this.id}-choice-4`).checked
      ) {
        this.$emit("update:modelValue", "estate")
      } else if (
        this.loanType != "revolving" &&
        document.getElementById(`${this.id}-choice-5`).checked
      ) {
        this.$emit("update:modelValue", "startup")
      } else if (document.getElementById(`${this.id}-choice-6`).checked) {
        this.$emit("update:modelValue", "other")
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
