<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">Please select the collateral for the loan</legend>
      <div class="v-spacer-30" />
      <div class="card-group row-2 input-line">
        <label class="card" :for="`${id}-real-estate`" data-test="ll-wiz-coll-estate">
          <input class="sr-only" type="radio" :id="`${id}-real-estate`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Real Estate</span>
          </div>
        </label>
        <label class="card" :for="`${id}-investments`" data-test="ll-wiz-coll-investments">
          <input class="sr-only" type="radio" :id="`${id}-investments`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Investments/Insurance</span>
          </div>
        </label>

        <label class="card" :for="`${id}-assets`" data-test="ll-wiz-coll-assets">
          <input class="sr-only" type="radio" :id="`${id}-assets`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Company Assets</span>
          </div>
        </label>

        <label
          v-if="this.loanType != 'realestate'"
          class="card"
          :for="`${id}-vehicle`"
          data-test="ll-wiz-coll-vehicle"
        >
          <input class="sr-only" type="radio" :id="`${id}-vehicle`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Vehicle</span>
          </div>
        </label>

        <label class="card" :for="`${id}-deposits`" data-test="ll-wiz-coll-deposits">
          <input class="sr-only" type="radio" :id="`${id}-deposits`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Bank Deposits</span>
          </div>
        </label>

        <label
          v-if="this.loanType != 'realestate'"
          class="card"
          :for="`${id}-none`"
          data-test="ll-wiz-coll-none"
        >
          <input class="sr-only" type="radio" :id="`${id}-none`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">None</span>
          </div>
        </label>

        <label class="card" :for="`${id}-other`" data-test="ll-wiz-coll-other">
          <input class="sr-only" type="radio" :id="`${id}-other`" :name="`${name}`" />
          <div class="card-content">
            <span class="card-special">Other</span>
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionLoanCollateral",
  props: {
    modelValue: String,
    loanType: String,
    id: String,
    name: String,
  },
  methods: {
    choice() {
      if (document.getElementById(`${this.id}-real-estate`).checked) {
        this.$emit("update:modelValue", "estate")
      } else if (document.getElementById(`${this.id}-investments`).checked) {
        this.$emit("update:modelValue", "investments")
      } else if (document.getElementById(`${this.id}-assets`).checked) {
        this.$emit("update:modelValue", "assets")
      } else if (
        this.loanType != "realestate" &&
        document.getElementById(`${this.id}-vehicle`).checked
      ) {
        this.$emit("update:modelValue", "vehicle")
      } else if (document.getElementById(`${this.id}-deposits`).checked) {
        this.$emit("update:modelValue", "deposits")
      } else if (
        this.loanType != "realestate" &&
        document.getElementById(`${this.id}-none`).checked
      ) {
        this.$emit("update:modelValue", "none")
      } else if (document.getElementById(`${this.id}-other`).checked) {
        this.$emit("update:modelValue", "other")
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
