export default {
  namespaced: true,
  state() {
    return {
      qid: null,
      business_type: "",
      amount: "",
    }
  },
  getters: {
    qid: state => state.qid,
    business_type: state => state.business_type,
    amount: state => state.amount,
  },
  actions: {
    setID({ commit }, type) {
      commit("setID", type)
    },
    setBusinessType({ commit }, type) {
      commit("setBusinessType", type)
    },
    setAmount({ commit }, type) {
      commit("setAmount", type)
    },
  },
  mutations: {
    setID(state, value) {
      state.qid = value
    },
    setBusinessType(state, value) {
      state.business_type = value
    },
    setAmount(state, value) {
      state.amount = value
    },
  },
}
