<template>
  <div class="banker-simplified">
    <Header v-bind:type="type" />
    <div class="breadcrumb">
      <div class="gradiant-bar"></div>
    </div>
    <MainId />
    <div class="ll-body container" role="main">
      <table v-if="debug" class="dump">
        <tr>
          <td>errCnt:</td>
          <td>{{ errCnt }}</td>
        </tr>
      </table>

      <form v-on:submit.prevent>
        <section class="ll-section">
          <h1 class="form-title">Connect to Banker Simplified</h1>

          <h2 class="section-title">Tell Us About Yourself and Your Business</h2>
          <div class="section-description">
            <div class="duration-msg">
              <img
                class="classout-icon"
                srcset="@/assets/images/clock.png 1x, @/assets/images/clock-2x.png 2x"
                src="@/assets/images/clock-2x.png"
                alt=""
              />
              <span id="est-duration" class="splash-callout"
                ><span class="est-label">Estimated Time to Complete:</span>
                <span class="est-min">30 Minutes</span></span
              >
            </div>
            <p>
              Thank you for being an existing commercial customer and for your interest in pursuing
              another loan with First Hawaiian Bank. Please complete this simplified digital form to
              connect with a member from our team to help you with your request.
            </p>
          </div>

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="field-group">
            <div class="ll-row row2-container">
              <!-- First Name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" id="business-label-app-fname">
                  <label for="business-label-app-fname-input"
                    >First Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  data-test="cb-app-fname"
                  type="text"
                  id="business-label-app-fname-input"
                  aria-describedby="ctbs-fname-err"
                  name="business-label-app-fname-input"
                  v-model="appFName"
                  :class="missingFName || invalidFName ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateFName"
                  required
                />
                <div id="ctbs-fname-err" class="ind-err-container">
                  <div
                    v-if="missingFName || invalidFName"
                    class="form-error-msg"
                    data-test="cb-app-fname-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
              </div>
              <!-- Middle Initial -->
              <div class="ll-item ll-middle-initial">
                <div class="tf-label" id="business-label-app-mi">
                  <label for="business-label-app-mi-input">MI<sup>&nbsp;</sup></label>
                </div>
                <input
                  maxlength="1"
                  class="ll-textfield tf-middle-initial"
                  data-test="cb-app-mi"
                  type="text"
                  id="business-label-app-mi-input"
                  name="business-label-app-mi-input"
                  aria-describedby="ctbs-mi-err"
                  v-model="appMI"
                  :class="appMI && invalidMI ? 'll-textfield-error' : 'll-textfield'"
                  @input="validateMI"
                />
                <div id="ctbs-mi-err" class="ind-err-container">
                  <div v-if="appMI && invalidMI" class="form-error-msg" data-test="cb-app-mi-error">
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid middle initial
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <div class="ll-row row2-container">
              <!-- Last Name -->
              <div class="ll-item ll-lname">
                <div class="tf-label" id="business-label-app-lname">
                  <label for="business-label-app-lname-input"
                    >Last Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-lname"
                  type="text"
                  data-test="cb-app-lname"
                  id="business-label-app-lname-input"
                  name="business-label-app-lname-input"
                  aria-describedby="ctbs-lname-err"
                  v-model="appLName"
                  :class="missingLName || invalidLName ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateLName"
                  required
                />
                <div id="ctbs-lname-err" class="ind-err-container">
                  <div
                    v-if="missingLName || invalidLName"
                    class="form-error-msg"
                    data-test="cb-app-lname-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
          </div>
          <div class="v-spacer-30" />
          <div class="ll-row">
            <!-- Email -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-app-email">
                <label for="business-label-app-email-input"
                  >Email<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="512"
                data-test="cb-email"
                class="ll-textfield"
                type="text"
                id="business-label-app-email-input"
                aria-describedby="ctbs-email-note ctbs-bus-email-err"
                name="business-label-app-email-input"
                v-model="email"
                :class="missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateEmail"
                required
              />
              <div id="ctbs-email-note" class="tf-note">
                To contact you about your inquiry and to send you special offers/information. You
                may opt out of marketing communications at any time.
              </div>
              <div id="ctbs-bus-email-err" class="ind-err-container">
                <div
                  v-if="missingEmail || invalidEmail"
                  class="form-error-msg"
                  data-test="cb-email-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid email</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />
          <!-- Phone Number -->
          <div class="ll-item">
            <div class="tf-label" id="business-label-app-phone">
              <label for="business-label-app-phone-input"
                >Mobile Phone Number<sup class="fhb-red">*</sup></label
              >
            </div>
            <div class="ll-prefix-wrap">
              <phone-input
                class="ll-textfield tf-phone"
                id="business-label-app-phone-input"
                aria-describedby="ctbs-phone-hint ctbs-bus-phone-err"
                name="business-label-app-phone-input"
                v-model="appPhone"
                :class="missingPhone || invalidPhone ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validatePhone"
                data-test="cb-app-phone"
                required
              />
            </div>
            <div id="ctbs-phone-hint" class="tf-note">Please provide a U.S. phone number.</div>
            <div id="ctbs-bus-phone-err" class="ind-err-container">
              <div
                v-if="missingPhone || invalidPhone"
                class="form-error-msg"
                data-test="cb-app-phone-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <div class="v-spacer-30" />
          <!-- Company Structure -->
          <div class="ll-row">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-company-structure">
                <label for="business-label-company-structure-input"
                  >What is your company structure?<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                class="ll-select"
                name="company_position"
                id="business-label-company-structure-input"
                aria-describedby="ctbs-bus-structure-err"
                v-model="businessCompanyStructure"
                :class="missingBusinessCompanyStructure ? 'll-select-error' : 'll-select'"
                required
                @change="validateBusinessCompanyStructure($event)"
                data-test="cb-business-structure"
              >
                <option value="">Select an option</option>
                <option v-for="opt in company_structures" :value="opt.value" v-bind:key="opt.key">
                  {{ opt.key }}
                </option>
              </select>
              <div id="ctbs-bus-structure-err" class="ind-err-container">
                <div
                  v-if="missingBusinessCompanyStructure"
                  class="form-error-msg"
                  data-test="cb-business-structure-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select an option</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <div class="ll-row">
            <div
              v-if="
                this.businessCompanyStructure &&
                this.businessCompanyStructure !== 'sole' &&
                this.businessCompanyStructure !== 'other'
              "
              class="ll-item ll-position"
            >
              <div class="tf-label" id="business-label-app-position">
                <label for="business-label-app-position-input"
                  >What role best represents your position in the company?<sup class="fhb-red"
                    >*</sup
                  ></label
                >
              </div>
              <select
                v-if="
                  this.businessCompanyStructure === 'corp' ||
                  this.businessCompanyStructure === 'prof-corp'
                "
                class="ll-select"
                name="company_position"
                id="business-label-app-position-input"
                aria-describedby="ctbs-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_corp"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'partnership' ||
                  this.businessCompanyStructure === 'limited'
                "
                class="ll-select"
                name="company_position"
                id="business-label-app-position-input"
                aria-describedby="ctbs-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_partnership"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'llc' ||
                  this.businessCompanyStructure === 'single-llc'
                "
                class="ll-select"
                name="company_position"
                id="business-label-app-position-input"
                aria-describedby="ctbs-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_llc"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <div id="ctbs-bus-pos-err" class="ind-err-container">
                <div
                  v-if="missingPosition"
                  class="form-error-msg"
                  data-test="cb-app-company-position-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a position</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <div class="ll-row">
            <!-- Business Name -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-legal-name">
                <label for="business-label-legal-name-input"
                  >Business Legal Name<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-fname"
                data-test="cb-business-legal-name"
                type="text"
                id="business-label-legal-name-input"
                name="business-label-legal-name-input"
                aria-describedby="ctbs-legal-name-hint ctbs-legal-name-err"
                v-model="businessLegalName"
                :class="missingBusinessLegalName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessLegalName"
                required
              />
              <div id="ctbs-legal-name-hint" class="tf-note">
                If you're a sole proprietorship or independent contractor and do not have a business
                entity, such as an LLC, you can use your legal name.
              </div>
              <div id="ctbs-legal-name-err" class="ind-err-container">
                <div
                  v-if="missingBusinessLegalName"
                  class="form-error-msg"
                  data-test="cb-business-legal-name-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      ../../../assets/images/exclaimation-lg.png    1x,
                      ../../../assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="../../../assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter your business name</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />
          <label
            class="box-checkbox"
            for="alt-business-name"
            id="business-label-alt-business-name-checkbox"
          >
            <input
              class="business-banker"
              type="checkbox"
              id="alt-business-name"
              name="alt-business-name"
              v-model="businessAltNameIndicator"
              data-test="business-alternate-name-indicator"
            />
            <span> I do business under a different name</span>
          </label>
          <div class="v-spacer-10" />
          <div class="ll-row row2-container">
            <div v-if="businessAltNameIndicator">
              <div class="tf-label" id="business-label-alt-business-name">
                <label for="business-label-alt-business-name-input">Doing business as Name</label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield"
                type="text"
                name="business-label-alt-business-name-input"
                id="business-label-alt-business-name-input"
                aria-describedby="ctbs-dba-err"
                data-test="business-alternate-name"
                v-model="businessAltName"
                :class="missingBusinessAltName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessAltName"
              />
              <div id="ctbs-dba-err" class="ind-err-container">
                <div
                  v-if="missingBusinessAltName"
                  class="form-error-msg"
                  data-test="business-alternate-name-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter your business name</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <div class="ll-row">
            <!-- Federal Tax ID -->
            <div>
              <div class="tf-label" id="business-label-tax-ID">
                <label for="business-label-tax-ID-input">
                  {{
                    this.businessCompanyStructure == "sole"
                      ? "Social Security Number"
                      : "Federal Tax ID"
                  }}<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="9"
                data-test="cb-federal-tax"
                class="ll-textfield"
                type="text"
                name="business-label-tax-ID-input"
                aria-describedby="ctbs-taxid-err"
                id="business-label-tax-ID-input"
                v-model="businessTaxID"
                :class="
                  missingBusinessTaxID || invalidBusinessTaxID
                    ? 'll-textfield-error'
                    : 'll-textfield'
                "
                v-on:blur="validateBusinessTaxID"
                required
              />
              <div id="ctbs-taxid-err" class="ind-err-container">
                <div
                  v-if="missingBusinessTaxID || invalidBusinessTaxID"
                  class="form-error-msg"
                  data-test="cb-federal-tax-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">
                    {{
                      this.businessCompanyStructure == "sole"
                        ? "Please Enter your SSN"
                        : "Please Enter your Federal Tax ID"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <!-- Assigned Commercial Banker -->
          <RadioInputs
            :inputValues="inputArr"
            id="banker-sim"
            name="banker-sim"
            v-model="bankerSim"
            legendText="Do you have an assigned banker?"
            :missingSelection="missingBankerSim"
          />
          <!-- <div class="tf-label" id="assigned-banker">Do you have an assigned banker?<sup class="fhb-red">*</sup></div>

          <div class="toggle-btn-container"
              :class="missingBankerSim ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'">

              <button type="button" v-if="bankerSim != 'yes'" class="toggle-btn-up toggle-btn-sm"
                  @click="toggleBanker('yes')" data-test="cb-delinquent-tax-yes">
                  <div class="toggle-btn-txt">
                      Yes
                  </div>
              </button>

              <button type="button" v-if="bankerSim === 'yes'" class="toggle-btn-down toggle-btn-sm"
                  data-test="cb-delinquent-tax-yes">
                  <div class="toggle-btn-txt">
                      Yes
                  </div>
              </button>

              <button type="button" v-if="bankerSim != 'no'" class="toggle-btn-up toggle-btn-sm"
                  @click="toggleBanker('no')" data-test="cb-delinquent-tax-no">
                  <div class="toggle-btn-txt">
                      No or unsure
                  </div>
              </button>
              <button type="button" v-if="bankerSim === 'no'" class="toggle-btn-down toggle-btn-sm"
                  data-test="cb-delinquent-tax-no">
                  <div class="toggle-btn-txt">
                      No or unsure
                  </div>
              </button>

              <div v-if="missingBankerSim" class="form-error-msg" data-test="cb-delinquent-tax-error">
                  <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select an option</div>
              </div>
          </div> -->
          <div class="v-spacer-10" />
          <!-- bankers email -->
          <div class="ll-item" v-if="bankerSim == 'yes'" id="banker-email">
            <div class="tf-label">
              <label for="banker-name-input">Name of Banker<sup class="fhb-red">*</sup></label>
            </div>
            <input
              maxlength="512"
              class="ll-textfield tf-fname"
              type="text"
              name="banker-name-input"
              id="banker-name-input"
              aria-describedby="ctbs-banker-err"
              v-model="bankerSimEmail"
              :class="
                missingBankerSimEmail || invalidBankerSimEmail
                  ? 'll-textfield-error'
                  : 'll-textfield'
              "
              v-on:blur="validateBankerSimEmail()"
              data-test="cb-lawsuit-reason"
              required
            />
            <div id="ctbs-banker-err" class="ind-err-container">
              <div v-if="missingBankerSimEmail || invalidBankerSimEmail" class="form-error-msg">
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter banker's name</div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />
          <!-- Loan Amount -->
          <div class="ll-item ll-fname">
            <div class="tf-label" id="business-label-loan-amount">
              <label for="cbs-business-loan-amount-input"
                >Requested Loan Amount<sup class="fhb-red">*</sup></label
              >
            </div>
            <money-input
              maxlength="512"
              class="ll-textfield tf-fname"
              data-test="ll-bc-filer-fname"
              type="text"
              aria-describedby="ctbs-amt-err"
              name="cbs-business-loan-amount-input"
              id="cbs-business-loan-amount-input"
              v-model="loanAmt"
              :class="
                missingLoanAmount || invalidLoanAmount ? 'll-textfield-error' : 'll-textfield'
              "
              v-on:blur="validateLoanAmount"
              required
            />
            <div id="ctbs-amt-err" class="ind-err-container">
              <div
                v-if="missingLoanAmount || invalidLoanAmount"
                class="form-error-msg"
                data-test="ll-bc-filer-fname-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a Loan Amount</div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <!-- Description on loan request -->
          <div class="tf-label">
            <label for="loan-request"
              >Brief description of this loan request<sup class="fhb-red">*</sup></label
            >
          </div>
          <textarea
            rows="5"
            cols="57"
            wrap="hard"
            id="loan-request"
            name="text"
            aria-describedby="ctbs-desc-err"
            v-model="loanRequest"
            :class="missingLoanRequest ? 'ctbs-textarea-error' : 'ctbs-textarea'"
            v-on:blur="validateLoanRequest()"
            data-test="cb-lawsuit-reason"
            required
          ></textarea>
          <div id="ctbs-desc-err" class="ind-err-container">
            <div v-if="missingLoanRequest" class="form-error-msg">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please enter a description</div>
            </div>
          </div>
          <div class="v-spacer-30" />

          <!-- changes to business -->
          <RadioInputs
            :inputValues="inputArr"
            id="change-business"
            name="change-business-name"
            v-model="busChange"
            legendText="Any significant change to business?"
            :missingSelection="missingBusChange"
            hasNote="(e.g. Authorized Signer, Ownership, Company Structure)"
          />
          <!-- <div class="tf-label" id="business-change">Any significant change to
              business?<sup class="fhb-red">*</sup>
          </div>
          <div class="v-spacer-10" />

          <div class="toggle-btn-container"
              :class="missingBusChange ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'">


              <button type="button" v-if="busChange != 'yes'" class="toggle-btn-up toggle-btn-sm"
                  @click="toggleChange('yes')" data-test="cb-delinquent-tax-yes">
                  <div class="toggle-btn-txt">
                      Yes
                  </div>
              </button>

              <button type="button" v-if="busChange === 'yes'" class="toggle-btn-down toggle-btn-sm"
                  data-test="cb-delinquent-tax-yes">
                  <div class="toggle-btn-txt">
                      Yes
                  </div>
              </button>

              <button type="button" v-if="busChange != 'no'" class="toggle-btn-up toggle-btn-sm"
                  @click="toggleChange('no')" data-test="cb-delinquent-tax-no">
                  <div class="toggle-btn-txt">
                      No
                  </div>
              </button>
              <button type="button" v-if="busChange === 'no'" class="toggle-btn-down toggle-btn-sm"
                  data-test="cb-delinquent-tax-no">
                  <div class="toggle-btn-txt">
                      No
                  </div>
              </button>

              <div v-if="missingBusChange" class="form-error-msg" data-test="cb-delinquent-tax-error">
                  <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select an option</div>
              </div>
          </div> -->
          <div class="v-spacer-10" />

          <!-- bankers documents -->
          <div class="ll-item" v-if="busChange == 'yes'" id="documents">
            <div class="tf-label">
              If yes, please describe and provide updated documentation <sup class="fhb-red">*</sup>
              <div class="v-spacer-20" />
            </div>

            <div class="tf-label">
              <label for="loan-request"
                >Description of Change to Business<sup class="fhb-red">*</sup></label
              >
            </div>
            <textarea
              rows="5"
              cols="57"
              wrap="hard"
              id="loan-request"
              name="text"
              aria-describedby="ctbs-change-desc-err"
              v-model="busChangeDescription"
              :class="missingBusChangeDescription ? 'ctbs-textarea-error' : 'ctbs-textarea'"
              v-on:blur="validateBusChangeDescription()"
              data-test="cb-lawsuit-reason"
              required
            ></textarea>
            <div id="ctbs-change-desc-err" class="ind-err-container">
              <div v-if="missingBusChangeDescription" class="form-error-msg">
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a description</div>
              </div>
            </div>
            <div class="v-spacer-20" />

            <div
              id="document-upload"
              :class="
                missingApplicantFiles
                  ? 'form-upload-container form-upload-container-error'
                  : 'form-upload-container'
              "
            >
              <div>
                <div v-for="n in 3" :key="n">
                  <div class="ll-row">
                    <div v-if="!this.uploaded_documents[n] || !this.uploaded_documents[n]">
                      <div
                        class="dd-area"
                        @dragenter="highlight($event)"
                        @dragover="highlight($event)"
                        @dragleave="unhighlight($event)"
                        @drop="unhighlight($event), handleDrop($event, n)"
                      >
                        <img
                          class="dd-icon clip"
                          srcset="@/assets/images/clip.png 1x, @/assets/images/clip-2x.png 2x"
                          src="@/assets/images/clip-2x.png"
                          alt=""
                        />
                        <div class="dd-txt">
                          <input
                            hidden
                            :id="'app_upload' + '_' + n"
                            type="file"
                            @change="validateFileEvent($event, n)"
                            accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/tiff, image/gif, application/zip"
                            multiple=""
                            data-test="cb-document-upload-1"
                          />
                          <button
                            type="button"
                            class="upload_link"
                            @click="triggerFileInput($event, n)"
                          >
                            Choose file
                          </button>
                          or drop file here
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        :class="
                          this.uploaded_documents[n].valid ||
                          (this.uploaded_documents[n].uploadAttempt &&
                            !this.uploaded_documents[n].uploadFailed)
                            ? 'dd-upload-area'
                            : 'dd-upload-area-error'
                        "
                      >
                        <div class="upload-area">
                          <img
                            class="dd-upload-icon success"
                            v-if="this.uploaded_documents[n].valid"
                            srcset="
                              @/assets/images/green_check.png    1x,
                              @/assets/images/green_check_2x.png 2x
                            "
                            src="@/assets/images/green_check_2x.png"
                            alt=""
                            height="24"
                          />
                          <img
                            class="dd-upload-icon load"
                            v-else-if="
                              this.uploaded_documents[n].uploadAttempt &&
                              !this.uploaded_documents[n].uploadFailed
                            "
                            srcset="@/assets/images/file.png 1x, @/assets/images/file-2x.png 2x"
                            src="@/assets/images/progress_circle.png"
                            alt=""
                            height="24"
                          />
                          <img
                            class="dd-upload-icon fail"
                            v-else
                            srcset="
                              @/assets/images/warning-yellow-circle.png    1x,
                              @/assets/images/warning-yellow-circle-2x.png 2x
                            "
                            src="@/assets/images/warning-yellow-circle-2x.png"
                            alt=""
                            height="24"
                          />
                          <div class="dd-r-txt">
                            {{ this.formatFileName(this.uploaded_documents[n].file.name) }}
                            <span class="dd-reason"> {{ this.uploaded_documents[n].reason }} </span>
                          </div>
                        </div>
                        <div class="button-area">
                          <button
                            :disabled="this.processing"
                            type="button"
                            class="dd-button-success"
                            v-if="this.uploaded_documents[n].valid"
                            @click="triggerFileReset(n)"
                          >
                            <img
                              class="dd-icon trash"
                              srcset="
                                @/assets/images/trash-gray.png    1x,
                                @/assets/images/trash-gray-2x.png 2x
                              "
                              src="@/assets/images/trash-gray-2x.png"
                              alt=""
                              height="12"
                            />
                            Delete
                          </button>
                          <button
                            :disabled="this.processing"
                            type="button"
                            class="dd-button"
                            v-else-if="
                              this.uploaded_documents[n].uploadAttempt &&
                              !this.uploaded_documents[n].uploadFailed
                            "
                            @click="triggerFileReset(n)"
                          >
                            <img
                              class="dd-icon close"
                              srcset="@/assets/images/close.png 1x, @/assets/images/close_2x.png 2x"
                              src="@/assets/images/close_2x.png"
                              alt=""
                              height="12"
                            />
                            Cancel
                          </button>
                          <button
                            :disabled="this.processing"
                            type="button"
                            class="dd-button dd-button-clear"
                            v-else
                            @click="triggerFileReset(n)"
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="v-spacer-10" />
                  </div>
                  <div class="v-spacer-30" />
                </div>
                <div
                  v-if="missingApplicantFiles"
                  class="form-error-msg"
                  data-test="cb-document-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please upload at least one file</div>
                </div>
              </div>
            </div>
            <div v-if="errorAppFileValidation">
              <div class="callout">
                If you have trouble uploading your documents, you can select the "<a
                  href="#financial-label-app-upload-timing"
                  >Share Later</a
                >" option above and continue. You will be contacted by a First Hawaiian Bank
                representative to provide proof of income verification at a later time.
              </div>
            </div>
          </div>

          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              id="ll-continue-btn"
              type="button"
              class="continue-btn"
              @click="handleContinue()"
              data-test="nav-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />
        </section>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Header from "@/components/Header"
import Validator from "@/app/validation"
import MainId from "@/components/MainId"
import Constants from "@/app/business_constants"
import Footer from "@/components/Footer"
import PhoneInput from "@/components/CustomInputs/PhoneInput.vue"
import MoneyInput from "@/components/CustomInputs/MoneyInput.vue"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import Swal from "sweetalert2"
import RadioInputs from "../../../components/CustomInputs/RadioInputs.vue"

export default {
  name: "Personal Info",
  components: {
    Header,
    MainId,
    Footer,
    MoneyInput,
    PhoneInput,
    RadioInputs,
  },
  data() {
    return {
      company_structures: Constants.CompanyStructure,

      company_positions_corp: Constants.CompanyPositionsCorp,
      company_positions_llc: Constants.CompanyPositionsLLC,
      company_positions_partnership: Constants.CompanyPositionsPartnership,

      systemErrorMsg: "",
      errCnt: 0,
      processing: false,

      missingFName: false,
      invalidFName: false,

      invalidMI: false,

      missingLName: false,
      invalidLName: false,

      missingEmail: false,
      invalidEmail: false,

      missingPhone: false,
      invalidPhone: false,

      missingPosition: false,

      use_alt_business_name: false,

      missingBusinessLegalName: false,

      missingBusinessAltName: false,

      missingBusinessCompanyStructure: false,

      missingBusinessTaxID: false,
      invalidBusinessTaxID: false,

      missingLoanAmount: false,
      invalidLoanAmount: false,

      missingBankerSim: false,
      missingBankerSimEmail: false,

      missingApplicantFiles: false,
      errorAppFileValidation: false,
      file_count: 0,

      missingBusChange: false,
      missingBusChangeDescription: false,

      missingLoanRequest: false,
      invalidBankerSimEmail: false,

      imagePath: require("@/assets/images/timeout-2x.png"),
      inputArr: Constants.radioInputValues,
    }
  },
  created() {
    this.setCurrentPage(1)
  },
  computed: {
    ...mapGetters("connectToBanker", [
      "debug",
      "currentPage",
      "type",
      "loan_amount",
      "banker_sim",
      "banker_sim_email",
      "banker_sim_indicator",
      "application_id",
      "bus_change",
      "bus_change_indicator",
      "bus_change_description",
      "uploaded_documents",
      "loan_request",
    ]),
    ...mapGetters("questionnaire", ["qid"]),
    loanAmt: {
      get() {
        return this.loan_amount
      },
      set(value) {
        this.setLoanAmount(value)
      },
    },
    appFName: {
      get() {
        return this.$store.state.connectToBanker.applicant.fname
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppFName", value)
      },
    },
    appMI: {
      get() {
        return this.$store.state.connectToBanker.applicant.middle_initial
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppMiddleInitial", value)
      },
    },
    appLName: {
      get() {
        return this.$store.state.connectToBanker.applicant.lname
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppLName", value)
      },
    },
    email: {
      get() {
        return this.$store.state.connectToBanker.applicant.email
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppEmail", value)
      },
    },
    appPhone: {
      get() {
        return this.$store.state.connectToBanker.applicant.phone_num
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppPhoneNum", value)
      },
    },
    appPosition: {
      get() {
        return this.$store.state.connectToBanker.applicant.company_position
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppCompanyPosition", value)
      },
    },
    businessLegalName: {
      get() {
        return this.$store.state.connectToBanker.business_legal_name
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessLegalName", value)
      },
    },
    businessAltNameIndicator: {
      get() {
        return this.$store.state.connectToBanker.business_alt_name_indicator
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessAltNameIndicator", value)
      },
    },
    businessAltName: {
      get() {
        return this.$store.state.connectToBanker.business_alt_name
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessAltName", value)
      },
    },
    businessCompanyStructure: {
      get() {
        return this.$store.state.connectToBanker.business_company_structure
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessCompanyStructure", value)
      },
    },
    businessTaxID: {
      get() {
        return this.$store.state.connectToBanker.business_tax_ID
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessTaxID", value)
      },
    },
    bankerSimIndicator: {
      get() {
        return this.banker_sim_indicator
      },
      set(value) {
        this.setBankerSimIndicator(value)
      },
    },
    bankerSim: {
      get() {
        return this.banker_sim
      },
      set(value) {
        this.setBankerSim(value)
      },
    },
    bankerSimEmail: {
      get() {
        return this.banker_sim_email
      },
      set(value) {
        this.setBankerSimEmail(value)
      },
    },
    busChange: {
      get() {
        return this.bus_change
      },
      set(value) {
        this.setBusChange(value)
      },
    },
    busChangeIndicator: {
      get() {
        return this.bus_change_indicator
      },
      set(value) {
        this.setBusChangeIndicator(value)
      },
    },
    busChangeDescription: {
      get() {
        return this.bus_change_description
      },
      set(value) {
        this.setBusChangeDescription(value)
      },
    },
    loanRequest: {
      get() {
        return this.loan_request
      },
      set(value) {
        this.setLoanRequest(value)
      },
    },
  },
  mounted() {
    this.setType("commercial-loan")
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("connectToBanker", [
      "setCurrentPage",
      "setUploadedDocumentsReset",
      "setLoanAmount",
      "setUploadedDocuments",
      "setUploadedDocumentsInit",
      "setUploadedAdditionalDocuments",
      "setJWTToken",
      "setType",
      "setBankerSim",
      "setBankerSimEmail",
      "setBankerSimIndicator",
      "setBusChange",
      "setBusChangeIndicator",
      "setBusChangeDescription",
      "setApplicationID",
      "setValidatedNavPage",
      "setUploadedFiles",
      "setUploadedDocumentsReason",
      "setUploadedDocumentsUploadValid",
      "setUploadedDocumentsUploadAttempt",
      "setLoanRequest",
      "setSubmissionID",
    ]),
    formatMoneyFromString(mon) {
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    validateFName() {
      this.appFName = this.appFName.trim()
      this.missingFName = this.appFName ? false : true
      if (!this.missingFName) {
        this.invalidFName = Validator.isValidName(this.appFName) ? false : true
      }
    },
    validateMI() {
      this.appMI = this.appMI.trim()
      if (this.appMI) {
        this.invalidMI = Validator.isValidName(this.appMI) ? false : true
      }
    },
    validateLName() {
      this.appLName = this.appLName.trim()
      this.missingLName = this.appLName ? false : true
      if (!this.missingLName) {
        this.invalidLName = Validator.isValidName(this.appLName) ? false : true
      }
    },
    validateEmail() {
      this.email = this.email.trim()
      this.missingEmail = this.email ? false : true
      if (!this.missingEmail) {
        this.invalidEmail = Validator.isValidEmail(this.email) ? false : true
      }
    },
    validatePhone() {
      this.missingPhone = this.appPhone ? false : true
      if (!this.missingPhone) {
        this.invalidPhone = Validator.isValidPhoneNumber(this.appPhone) ? false : true
      }
    },
    validateBusinessCompanyStructure() {
      this.businessCompanyStructure = this.businessCompanyStructure.trim()
      this.missingBusinessCompanyStructure = this.businessCompanyStructure ? false : true
      if (!this.missingBusinessCompanyStructure && this.appPosition) {
        if (this.businessCompanyStructure == "sole" || this.businessCompanyStructure == "other")
          this.appPosition = ""
        this.validatePosition()
      }
    },
    validatePosition() {
      this.missingPosition = false
      if (
        this.businessCompanyStructure &&
        this.businessCompanyStructure != "sole" &&
        this.businessCompanyStructure != "other"
      ) {
        this.missingPosition = !this.appPosition || this.appPosition == "" ? true : false
      }
    },
    validateBusinessLegalName() {
      this.businessLegalName = this.businessLegalName.trim()
      this.missingBusinessLegalName = this.businessLegalName ? false : true
    },
    validateBusinessAltName() {
      this.businessAltName = this.businessAltName.trim()
      this.missingBusinessAltName = this.businessAltName ? false : true
    },
    validateBusinessTaxID() {
      this.businessTaxID = this.businessTaxID.trim()
      this.missingBusinessTaxID = this.businessTaxID ? false : true
      if (!this.missingBusinessTaxID) {
        this.invalidBusinessTaxID = this.businessTaxID.length != 9
      }
      console.log("validate tax id", this.missingBusinessTaxID, this.invalidBusinessTaxID)
    },
    async postPageBanker(payload) {
      Swal.fire({
        title: "Submitting...",
        text: "Your request is submitting, please wait...",
        imageUrl: this.imagePath,
        imageWidth: 97,
        imageHeight: 125,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showConfirmButton: false,
        showCancelButton: false,
      }).then(result => {
        if (result.isConfirmed) {
          console.log("continue session...")
        }
      })
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.postPageSimplifiedBanker(payload)
      console.log("--- response banker ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.status == 200) {
          const presignedUrls = resp.data.response.urls
          for (var key in this.uploaded_documents) {
            await Api.uploadFile(presignedUrls[key - 1], this.uploaded_documents[key].file)
          }
          this.setSubmissionID(
            resp.data.response.submission_id ? resp.data.response.submission_id : ""
          )

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          Swal.close()
          this.$router.push({ name: "Connect To Banker Simplified Submitted" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "Getting Started" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")
      this.writeFilenamesToStore()
      this.validatePage()
      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length

        if (!this.errCnt) {
          const payload = {
            questionnaire_id: this.qid,
            first_name: this.appFName,
            middle_initial: this.appMI,
            last_name: this.appLName,
            email: this.email,
            phone_number: this.appPhone ? Utils.formatPhoneNumber(this.appPhone) : "",
            business_structure: this.businessCompanyStructure,
            company_role: this.appPosition,
            business_legal_name: this.businessLegalName,
            business_different_name_indicator: this.businessAltNameIndicator,
            business_different_name: this.businessAltName,
            federal_tax_id_ssn: this.businessTaxID,
            loan_amount: this.formatMoneyFromString(this.loanAmt),
            assigned_banker_name: this.bankerSimEmail,
            assigned_banker_indicator: this.bankerSimIndicator,
            business_change_indicator: this.busChangeIndicator,
            business_change_description: this.busChangeDescription,
            loan_request_description: this.loanRequest,
          }

          this.postPageBanker(payload)
        } else {
          this.setValidatedNavPage({ name: "personal", isValid: 0 })

          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missingLoanAmount || this.invalidLoanAmount)
              offset = jQuery("#business-label-loan-amount").offset().top
            if (this.missingBankerSim) offset = jQuery("#assigned-banker").offset().top
            if (this.missingBankerSimEmail || this.invalidBankerSimEmail)
              offset = jQuery("#banker-email").offset().top
            if (this.missingLoanRequest) offset = jQuery("#loan-request").offset().top
            if (this.missingBusChange) offset = jQuery("#business-change").offset().top
            if (this.missingApplicantFiles) offset = jQuery("#documents").offset().top
            if (this.missingPosition) offset = jQuery("#business-label-app-position").offset().top
            if (this.missingBankerName || this.invalidBankerName)
              offset = jQuery("#business-label-app-banker-name").offset().top
            if (this.missingAgree) offset = jQuery("#business-label-app-agree").offset().top

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      })
    },
    validatePage() {
      this.validateLoanAmount()
      this.validateBanker()
      if (this.bankerSimIndicator) {
        this.validateBankerSimEmail()
      }

      this.validateFName()
      this.validateMI()
      this.validateLName()
      this.validateEmail()
      this.validatePhone()
      this.validateBusinessCompanyStructure()
      this.validatePosition()

      this.validateBusinessLegalName()
      if (this.use_alt_business_name) {
        this.validateBusinessAltName()
      }
      this.validateBusinessTaxID()

      this.validateApplicantFileUploads()
      this.validateLoanRequest()
      this.validateBusChange()
      if (this.busChangeIndicator) {
        this.validateBusChangeDescription()
      }
    },
    validateLoanAmount() {
      this.missingLoanAmount = this.loanAmt ? false : true
      if (!this.missingLoanAmount) {
        this.invalidLoanAmount = Validator.isValidMoney(this.loanAmt) ? false : true
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
    validateBankerSimEmail() {
      this.missingBankerSimEmail = this.bankerSimEmail ? false : true
      // if (!this.missingBankerSimEmail) {
      //     this.invalidBankerSimEmail = Validator.isValidEmail(this.bankerSimEmail) ? false : true;
      // }
    },
    validateBusChangeDescription() {
      this.missingBusChangeDescription = this.busChangeDescription ? false : true
    },
    validateBanker() {
      if (this.bankerSim == "yes") {
        this.bankerSimIndicator = true
      } else if (this.bankerSim == "no") {
        this.bankerSimIndicator = false
      }
      this.missingBankerSim = this.bankerSim ? false : true
    },
    validateLoanRequest() {
      this.missingLoanRequest = this.loanRequest ? false : true
    },
    // toggleBanker(val) {
    //   this.setBankerSim(val)
    //   if (val == "yes") {
    //     this.bankerSimIndicator = true
    //     this.$store.commit('connectToBanker/setBankerSimIndicator', true)
    //   } else if (val == "no") {
    //     this.bankerSimIndicator = false
    //     this.$store.commit('connectToBanker/setBankerSimIndicator', false)
    //   }
    //   this.validateBanker()
    // },
    validateBusChange() {
      if (this.busChange == "yes") {
        this.busChangeIndicator = true
        this.$store.commit("connectToBanker/setBusChangeIndicator", true)
      } else if (this.busChange == "no") {
        this.busChangeIndicator = false
        this.$store.commit("connectToBanker/setBusChangeIndicator", false)
      }
      this.missingBusChange = this.busChange ? false : true
    },
    // toggleChange(val) {
    //   this.setBusChange(val)
    //   if (val == "yes") {
    //     this.busChangeIndicator = true
    //     this.$store.commit('connectToBanker/setBusChangeIndicator', true)
    //   } else if (val == "no") {
    //     this.busChangeIndicator = false
    //     this.$store.commit('connectToBanker/setBusChangeIndicator', false)
    //   }
    //   this.validateBusChange()
    // },
    validateApplicantFileUploads() {
      console.log("valid file count: ", this.file_count)
      this.missingApplicantFiles = this.file_count == 0
    },
    highlight(e) {
      e.preventDefault()
      e.stopPropagation()
      e.target.classList.add("highlight")
    },
    unhighlight(e) {
      e.preventDefault()
      e.stopPropagation()
      e.target.classList.remove("highlight")
    },
    handleDrop(e, index) {
      let dt = e.dataTransfer
      let files = dt.files

      if (files && files.length && files.length > 0) {
        this.validateFile(files[0], index)
      }
    },
    validateFileEvent(event, index) {
      let files = event.target.files
      console.log("found files: ", files[0])
      if (files.length == 0) {
        return
      }
      this.setUploadedDocuments({ index: index, file: files[0] })
      console.log(" index value: ", index)
      this.validateFile(files[0], index)
    },
    validateFile(file, index) {
      let valid = Validator.isValidFile(file)
      console.log("index of file: ", index)
      if (!valid) {
        this.setUploadedDocumentsReason({
          index: index,
          reason:
            "could not be uploaded. Only the following extensions are allowed: PDF, JPG, JPEG, ZIP, TIFF, GIF, DOC, and DOCX.",
        })
      }
      let maxFileSize = 5000000
      let maxFileSizeText = "5MB"
      if (file.size > maxFileSize) {
        this.setUploadedDocumentsReason({
          index: index,
          reason:
            "exceeds  the maximum file size for this site. File sizes cannot be larger than " +
            maxFileSizeText +
            ".",
        })
        valid = false
      }
      if (valid) {
        this.file_count++
        this.setUploadedDocuments({ index: index, url: null, file: file })
        this.setUploadedDocumentsUploadAttempt({
          index: index,
          value: true,
        })
      }
    },
    triggerFileInput(event, index) {
      event.preventDefault()
      jQuery("#app_upload_" + index + ":hidden").trigger("click")
    },
    formatByteSize(size) {
      return Utils.formatByteSize(size)
    },
    formatFileName(name) {
      if (name.length > 25) {
        name = name.substring(0, 10) + "..." + name.substring(name.length - 7, name.length)
      }
      return name
    },
    triggerFileReset(index) {
      console.log(this.uploaded_documents)
      if (this.file_count > 0) {
        this.file_count -= 1
      }
      this.setUploadedDocumentsReset({ index: index })
    },
    writeFilenamesToStore() {
      // iterate an object
      const length = Object.keys(this.uploaded_documents).length
      let appFilenames = []

      for (let i = 0; i < length; i++) {
        if (
          !this.uploaded_documents[i] ||
          !Validator.isValidFile(this.uploaded_documents[i].file)
        ) {
          continue
        }
        appFilenames.push(this.uploaded_documents[i].file.name)
      }

      this.setUploadedFiles(appFilenames)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
  }
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.continue-btn-disabled {
  background: $grayVlight;
  color: $gray;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.dd-icon {
  display: inline-block;
  margin-right: 0.25em;
  vertical-align: middle;
}

.dd-upload-icon {
  display: inline-block;
  vertical-align: top;
}

.dd-upload-icon:hover {
  cursor: pointer;
}

.dd-txt {
  display: inline-block;
  font-size: 1.1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.011em;
  text-align: left;
  vertical-align: middle;
}

.dd-r-txt {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: -0.011em;
  padding-left: 20px;
}

.dd-r-fail-txt {
  display: inline-block;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 27px;
  letter-spacing: -0.011em;
  vertical-align: middle;
  text-align: right;
}

.dd-area {
  align-items: center;
  background: #ffffff;
  border: 1px dashed #b0b0b0;
  border-radius: 8px;
  column-gap: 1em;
  display: flex;
  width: 100%;
  min-height: 92px;
  text-align: left;
  padding: 30px;
}

.dd-upload-area {
  border: 1px solid $grayMid;
  border-radius: 8px;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
}

.dd-upload-area-error {
  background-color: fade-out($yellow, 0.8);
  //border: 1px solid $yellow;
  border-left: 6px solid $yellow;
  border-radius: 0;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
}

.dd-upload-area-error .dd-r-text,
.dd-upload-area-error .dd-reason {
  color: $black;
}

.dd-button {
  width: 140px;
  height: 40px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.dd-button-success {
  width: 140px;
  height: 40px;
  background-color: $grayLight;
  border: 1px solid $grayLight;
  border: unset;
  border-radius: 20px;
  color: $grayDark;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  .dd-icon.trash {
    height: 14px;
    width: auto;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    border: 1px solid $grayMid;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.dd-reason {
  font-style: italic;
  color: $grayDark;
}

.dd-area:hover {
  cursor: pointer;
}

.dd-icon {
  &.clip {
    height: auto;
    width: 28px;
  }

  &.trash {
    height: auto;
    width: 24px;
  }

  &.close {
    height: 12px;
    width: 12px;
  }
}

.dd-upload-icon {
  &.success {
    height: 24px;
    width: auto;
  }

  &.load {
    height: 24px;
    width: auto;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.upload-area {
  display: inline-flex;
  padding-right: 20px;
}

.button-area {
  align-self: center;
  margin-left: auto;

  button {
    align-items: center;
    column-gap: 4px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

.highlight {
  border: 2px dashed;
  border-color: $red;
}

.dd-button-clear {
  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    border-color: $grayDark;
  }
}

.ctbs-textarea-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}
.ctbs-textarea,
.ctbs-textarea-error {
  padding: 0.5em;
  font-family: "CircularXXTT-Book", "CircularXXWeb-Book", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 5px;
}

.section-title {
  font-size: 1.75rem;
  margin-bottom: 0.25em;

  @include media-breakpoint-up(sm) {
    font-size: 2.2rem;
  }
}

.toggle-btn-container > button {
  width: 20em;

  @include media-breakpoint-only(sm) {
    width: calc(50% - 0.5em);
  }
}

.toggle-btn-container > .form-error-msg {
  width: 100%;
  flex-basis: 100%;
}

.ll-textfield,
.ll-textfield-error,
.ll-textfield + .tf-note {
  @include media-breakpoint-up(md) {
    max-width: 548px;
  }
}

.duration-msg {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.duration-msg .splash-callout > span {
  display: inline;
}

.ll-middle-initial {
  width: 240px;
}

.row-name-container {
  display: grid;
  grid-column-gap: 1.5em;
  grid-template-columns: 1fr auto 1fr;
  text-align: left;
}

/*.ll-fname,
.ll-lname {
  @include media-breakpoint-up(md) {
    flex: 1;
  }

  @include media-breakpoint-down(md) {
    flex: 1;
  }
}
.ll-fname {
  @include media-breakpoint-between(md,lg) {
    flex: 1;
  }
}*/
</style>
