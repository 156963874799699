<template>
  <div class="business-page">
    <Header />
    <BreadcrumbCreditOwner />
    <MainId />

    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Tell Us About the Business Owners</h1>

          <div class="tf-note txt-bold">Business Deposit Information</div>
          <div class="tf-note">
            Please list out all the relevant deposit accounts for the business. This will help us
            better understand your business.
          </div>

          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="v-spacer-30" />
          <hr class="solid" />

          <div class="field-group">
            <div class="section-title2">Owner 1</div>
            <div class="ll-row row1-container">
              <!-- First Name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" v-bind:id="`business-label-owner-fname`">
                  <label for="credit-owner-fname-input"
                    >First Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  :data-test="`ll-bc-owner-fname-1`"
                  type="text"
                  id="credit-owner-fname-input"
                  aria-describedby="cred-own-fname-err"
                  name="fname"
                  v-model="owner_data.first_name"
                  :class="
                    this.missing_owner_first_name || invalid_owner_first_name
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateFName()"
                  required
                />
                <div id="cred-own-fname-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_first_name || invalid_owner_first_name"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-fname-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
              </div>
              <!-- Middle Initial -->
              <div class="ll-item ll-middle-initial">
                <div class="tf-label" v-bind:id="`business-label-owner-mi`">
                  <label for="credit-owner-mi-input"> MI</label>
                </div>
                <input
                  maxlength="1"
                  class="ll-textfield tf-middle-initial"
                  :data-test="`ll-bc-owner-mname-1`"
                  type="text"
                  id="credit-owner-mi-input"
                  aria-describedby="cred-own-mi-err"
                  name="middle-initial"
                  v-model="this.owner_data.middle_initial"
                  :class="
                    this.owner_data.middle_initial && invalid_owner_middle_name
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  @input="validateMI()"
                />
                <div id="cred-own-mi-err" class="ind-err-container">
                  <div
                    v-if="this.owner_data.middle_initial && invalid_owner_middle_name"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-mname-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid middle initial
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <!-- Last Name -->
            <div class="ll-item ll-lname">
              <div class="tf-label" v-bind:id="`business-label-owner-lname`">
                <label for="credit-owner-lname-input">Last Name<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-lname"
                type="text"
                id="credit-owner-lname-input"
                aria-describedby="cred-own-lname-err"
                name="lname"
                :data-test="`ll-bc-owner-last-name-1`"
                v-model="owner_data.last_name"
                :class="
                  missing_owner_last_name || invalid_owner_last_name
                    ? 'll-textfield-error'
                    : 'll-textfield'
                "
                v-on:blur="validateLName()"
                required
              />
              <div id="cred-own-lname-err" class="ind-err-container">
                <div
                  v-if="missing_owner_last_name || invalid_owner_last_name"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-last-name-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />

          <!-- row 3 -->
          <div class="ll-row row2-container">
            <div class="ll-item">
              <div class="tf-label" v-bind:id="`business-label-owner-dob`">
                <label for="credit-owner-dob-input"
                  >Date of Birth<sup class="fhb-red">*</sup></label
                >
              </div>

              <birthday-input
                class="ll-textfield tf-dob"
                id="credit-owner-dob-input"
                aria-describedby="cred-own-dob-hint cred-own-dob-err"
                name="dob"
                v-model="this.owner_data.date_of_birth"
                :data-test="`ll-bc-owner-dob-1`"
                :class="
                  missing_owner_birthday || invalid_owner_birthday
                    ? 'll-textfield-error'
                    : 'll-textfield'
                "
                v-on:blur="validateDOB()"
                required
              />

              <div id="cred-own-dob-hint" class="tf-note">
                You must be 18 years or older to apply.
              </div>
              <div id="cred-own-dob-err" class="ind-err-container">
                <div
                  v-if="missing_owner_birthday || invalid_owner_birthday"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-dob-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">
                    Please enter a valid date (MM/DD/YYYY)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ll-row row2-container">
            <!-- Phone Number -->
            <div class="ll-item ll-phone">
              <div class="tf-label" v-bind:id="`business-label-owner-phone`">
                <label for="credit-owner-mobile-input"
                  >Mobile Phone Number<sup class="fhb-red">*</sup></label
                >
              </div>
              <div class="ll-prefix-wrap">
                <phone-input
                  class="ll-textfield tf-phone"
                  id="credit-owner-mobile-input"
                  name="mobile-phone"
                  aria-describedby="bus-owner-phone-note cred-own-tel-err"
                  v-model="this.owner_data.phone"
                  :class="
                    missing_owner_phone || invalid_owner_phone
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validatePhone()"
                  :data-test="`ll-bc-owner-phone-1`"
                  required
                />
              </div>
              <div id="bus-owner-phone-note" class="tf-note">
                Please provide a U.S. phone number.
              </div>
              <div id="cred-own-tel-err" class="ind-err-container">
                <div
                  v-if="missing_owner_phone || invalid_owner_phone"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-phone-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div class="form-error-txt fieldErr">Please enter a valid phone number</div>
                </div>
              </div>
            </div>

            <!-- Email -->
            <div class="ll-item">
              <div class="tf-label" v-bind:id="`business-label-owner-email`">
                <label for="credit-owner-email-input">Email<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                :data-test="`ll-bc-owner-email-1`"
                id="credit-owner-mobile-input"
                aria-describedby="cred-own-hint cred-own-email-err"
                name="email"
                class="ll-textfield"
                type="text"
                v-model="this.owner_data.email"
                :class="
                  missing_owner_email || invalid_owner_email ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateEmail()"
                required
              />
              <div id="cred-own-hint" class="tf-note">
                To contact you about your inquiry and to send you special offers/information. You
                may opt out of marketing communications at any time.
              </div>
              <div id="cred-own-email-err" class="ind-err-container">
                <div
                  v-if="missing_owner_email || invalid_owner_email"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-email-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid email</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />
          <div class="ll-item">
            <div class="tf-label" v-bind:id="`business-label-owner-ssn`">
              <label for="credit-owner-ssn-input"
                >Social Security Number<sup class="fhb-red">*</sup></label
              >
            </div>
            <social-input
              class="ll-textfield tf-ssn"
              id="credit-owner-ssn-input"
              name="ssn"
              v-model="this.owner_data.ssn"
              aria-describedby="bus-owner-ssn-note cred-own-ssn-err"
              :data-test="`ll-bc-owner-ssn-1`"
              :class="
                missing_owner_ssn || invalid_owner_ssn ? 'll-textfield-error' : 'll-textfield'
              "
              v-on:blur="validateSSN()"
              required
            />
            <div class="tf-note" id="bus-owner-ssn-note">
              We use your SSN to verify your identity. Your personal information is transmitted
              securely through this online form.
            </div>
            <div id="cred-own-ssn-err" class="ind-err-container">
              <div
                v-if="missing_owner_ssn || invalid_owner_ssn"
                class="form-error-msg"
                :data-test="`ll-bc-owner-ssn-err-1`"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a valid SSN</div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Company Position -->
          <div v-if="this.company_structure !== 'sole'" class="ll-item ll-state">
            <div class="tf-label" v-bind:id="`business-label-owner-role`">
              <label for="credit-owner-position-input"
                >What role best represents your position in the Company?<sup class="fhb-red"
                  >*</sup
                ></label
              >
            </div>
            <select
              v-if="this.company_structure === 'corp' || this.company_structure === 'prof-corp'"
              class="ll-select select-state"
              name="us_state"
              id="credit-owner-position-input"
              aria-describedby="cred-own-pos-err"
              v-model="owner_data.company_role"
              :class="missing_owner_role ? 'll-select-error' : 'll-select'"
              required
              @change="validatePosition()"
              :data-test="`ll-bc-owner-position-1`"
            >
              <option value="">Select Your Position</option>
              <option v-for="pos in company_positions_corp" :value="pos.value" v-bind:key="pos.key">
                {{ pos.key }}
              </option>
            </select>
            <select
              v-else-if="this.company_structure === 'partnership'"
              class="ll-select select-state"
              name="us_state"
              id="credit-owner-position-input"
              aria-describedby="cred-own-pos-err"
              v-model="owner_data.company_role"
              :class="missing_owner_role ? 'll-select-error' : 'll-select'"
              required
              @change="validatePosition()"
              :data-test="`ll-bc-owner-position-1`"
            >
              <option value="">Select Your Position</option>
              <option
                v-for="pos in company_positions_partnership"
                :value="pos.value"
                v-bind:key="pos.key"
              >
                {{ pos.key }}
              </option>
            </select>
            <select
              v-else-if="
                this.company_structure === 'llc' || this.company_structure === 'single-llc'
              "
              class="ll-select select-state"
              name="us_state"
              id="credit-owner-position-input"
              aria-describedby="cred-own-pos-err"
              v-model="owner_data.company_role"
              :class="missing_owner_role ? 'll-select-error' : 'll-select'"
              required
              @change="validatePosition()"
              :data-test="`ll-bc-owner-position-1`"
            >
              <option value="">Select Your Position</option>
              <option v-for="pos in company_positions_LLC" :value="pos.value" v-bind:key="pos.key">
                {{ pos.key }}
              </option>
            </select>
            <div id="cred-own-pos-err" class="ind-err-container">
              <div
                v-if="missing_owner_role"
                class="form-error-msg"
                :data-test="`ll-bc-owner-position-err-1`"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please select a position</div>
              </div>
            </div>
          </div>
          <div v-else></div>

          <div class="v-spacer-30" />

          <!-- percentage of business owned -->
          <div class="ll-item" v-bind:id="`business-percentage-owned`">
            <div class="tf-label">
              <label for="credit-owner-percent-input"
                >Percentage of Business You Own<sup class="fhb-red">*</sup></label
              >
            </div>
            <percentage-input
              class="ll-textfield tf-addr-years"
              type="text"
              id="credit-owner-percent-input"
              aria-describedby="cred-own-per-err"
              name="percentage"
              v-model="this.owner_data.percentage_owned"
              :class="
                missing_owner_percentage || invalid_owner_percentage
                  ? 'll-textfield-error'
                  : 'll-textfield'
              "
              v-on:blur="validatePercentageOwned()"
              :data-test="`ll-bc-owner-percentage-1`"
              required
            />
            <div id="cred-own-per-err" class="ind-err-container">
              <div
                v-if="missing_owner_percentage || invalid_owner_percentage"
                class="form-error-msg"
                :data-test="`ll-bc-owner-percentage-err-1`"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">
                  Please enter the interest rate as a percent for this loan
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />
          <!-- Ownership through trust -->
          <RadioInputs
            :inputValues="inputArr"
            id="ownership-through-trust-id"
            name="ownership-through-trust-name"
            v-model="owner_data.is_ownership_trust"
            legendText="Please indicate if this ownership is through a trust"
            :missingSelection="missing_owner_through_trust"
          />
          <!-- <div class="tf-label" v-bind:id="`business-ownership-through-trust`">Please indicate if
              this ownership is through a trust<sup class="fhb-red">*</sup></div>
            <div class="v-spacer-10" />

            <div class="toggle-btn-container"
              :class="missing_owner_through_trust ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'">

              <button type="button" v-if="this.owner_data.is_ownership_trust != 'yes'"
                class="toggle-btn-up toggle-btn-sm" @click="toggleOwnershipThroughTrust('yes')"
                :data-test="`ll-bc-owner-trust-btn-off-1`">
                <div class="toggle-btn-txt">
                  Yes
                </div>
              </button>

              <button type="button" v-if="this.owner_data.is_ownership_trust === 'yes'"
                class="toggle-btn-down toggle-btn-sm" :data-test="`ll-bc-owner-trust-btn-on-1`">
                <div class="toggle-btn-txt">
                  Yes
                </div>
              </button>

              <button type="button" v-if="this.owner_data.is_ownership_trust != 'no'"
                class="toggle-btn-up toggle-btn-sm" @click="toggleOwnershipThroughTrust('no')"
                :data-test="`ll-bc-owner-no-trust-btn-off-1`">
                <div class="toggle-btn-txt">
                  No
                </div>
              </button>
              <button type="button" v-if="this.owner_data.is_ownership_trust === 'no'"
                class="toggle-btn-down toggle-btn-sm"
                :data-test="`ll-bc-owner-no-trust-btn-on-1`">
                <div class="toggle-btn-txt">
                  No
                </div>
              </button>

              <div v-if="missing_owner_through_trust" class="form-error-msg"
                :data-test="`ll-bc-owner-trust-err-1`">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please select an option</div>
              </div>
            </div>
            <div class="v-spacer-40" /> -->

          <!-- row 4 : current address, apt-->
          <div class="ll-row row2-container">
            <div class="ll-item ll-address">
              <div class="tf-label" v-bind:id="`business-label-owner-address`">
                <label for="credit-owner-stadd-input"
                  >Residence Street Address<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-address"
                type="text"
                id="credit-owner-stadd-input"
                aria-describedby="cred-own-addr-err"
                name="address"
                v-model="owner_data.street_address"
                :class="
                  missing_owner_street_address || invalid_owner_street_address
                    ? 'll-textfield-error'
                    : 'll-textfield'
                "
                v-on:blur="validateAddress()"
                :data-test="`ll-bc-owner-address-1`"
                required
              />
              <div id="cred-own-addr-err" class="ind-err-container">
                <div
                  v-if="missing_owner_street_address || invalid_owner_street_address"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-address-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter an address</div>
                </div>
              </div>
            </div>

            <div class="ll-item">
              <div class="tf-label">
                <label for="credit-owner-stunit-input">Apt, Unit, etc.</label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-apt"
                type="text"
                id="credit-owner-stunit-input"
                name="credit-owner-stunit-input"
                v-model="this.owner_data.street_address_2"
                :data-test="`ll-bc-owner-address2-1`"
              />
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- row 5 : city, state, zip-->
          <div class="ll-row row3-container">
            <div class="ll-item ll-city">
              <div class="tf-label" v-bind:id="`business-label-owner-city`">
                <label for="credit-owner-stcity-input">City<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-city"
                type="text"
                id="credit-owner-stcity-input"
                aria-describedby="cred-own-addrcity-err"
                name="city"
                v-model="this.owner_data.street_address_city"
                :class="
                  missing_owner_city || invalid_owner_city ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateCity()"
                :data-test="`ll-bc-owner-city-1`"
                required
              />
              <div id="cred-own-addrcity-err" class="ind-err-container">
                <div
                  v-if="missing_owner_city || invalid_owner_city"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-city-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a city</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-state">
              <div class="tf-label" v-bind:id="`business-label-owner-state`">
                <label for="credit-owner-ststate-input"
                  >State or Territory<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                class="ll-select select-state"
                name="us_state"
                id="credit-owner-ststate-input"
                aria-describedby="cred-own-addrst-err"
                v-model="this.owner_data.street_address_state"
                :class="missing_owner_state ? 'll-select-error' : 'll-select'"
                required
                @change="validateState()"
                :data-test="`ll-bc-owner-state-1`"
              >
                <option value="">Select a state</option>
                <option
                  v-for="us_state in us_states"
                  :value="us_state.value"
                  v-bind:key="us_state.key"
                >
                  {{ us_state.key }}
                </option>
              </select>
              <div id="cred-own-addrst-err" class="ind-err-container">
                <div
                  v-if="missing_owner_state"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-state-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a state</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-zipcode">
              <div class="tf-label" v-bind:id="`business-label-owner-zipcode`">
                <label for="credit-owner-stzip-input">ZIP Code<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="12"
                class="ll-textfield tf-zipcode"
                type="text"
                id="credit-owner-stzip-input"
                aria-describedby="cred-own-addrzip-err"
                name="zipcode"
                v-model="this.owner_data.street_address_zip"
                :class="
                  missing_owner_zip || invalid_owner_zip ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateZipcode()"
                :data-test="`ll-bc-owner-zipcode-1`"
                required
              />
              <div id="cred-own-addrzip-err" class="ind-err-container">
                <div
                  v-if="missing_owner_zip || invalid_owner_zip"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-zipcode-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                </div>
              </div>
            </div>
          </div>

          <!-- row 6 : ap - alt mailing address-->
          <div class="v-spacer-30" />
          <div class="ll-row">
            <div class="ll-item ll-consent">
              <label v-bind:for="`owner-mailing-same-cb`">
                <input
                  class="cb-consent"
                  type="checkbox"
                  v-bind:id="`owner-mailing-same-cb`"
                  name="owner-mailing-same-cb"
                  value="owner-mailing-same-cb"
                  v-model="this.owner_data.mailing_address_same"
                  @click="handleMailingSameCB()"
                  :data-test="`ll-bc-owner-mailing-same-cb-1`"
                />
                <span> Mailing Address is the same.</span>
              </label>
            </div>

            <div class="v-spacer-30" />

            <div v-if="!this.owner_data.mailing_address_same">
              <!-- addr, apt -->
              <div class="ll-row row2-container">
                <div class="ll-item ll-address">
                  <div class="tf-label" v-bind:id="`business-label-mailing-address`">
                    <label for="credit-owner-mailadd-input"
                      >Mailing Address (P.O. Box is okay)<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-address"
                    type="text"
                    id="credit-owner-mailadd-input"
                    aria-describedby="cred-own-mail-err"
                    name="mailing-address"
                    v-model="this.owner_data.mailing_street_address"
                    :class="missing_owner_mailing_address ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateMailingAddress()"
                    :data-test="`ll-bc-owner-mailing-addr-1`"
                    required
                  />
                  <div id="cred-own-mail-err" class="ind-err-container">
                    <div
                      v-if="missing_owner_mailing_address"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-mailing-addr-err-1`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter an address</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item">
                  <div class="tf-label">
                    <label for="credit-owner-mailunit-input">Apt, Unit, etc.</label>
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-apt"
                    type="text"
                    id="credit-owner-mailadd-input"
                    v-model="this.owner_data.mailing_street_address_2"
                    :data-test="`ll-bc-owner-mailing-addr2-1`"
                  />
                </div>
              </div>

              <div class="v-spacer-30" />

              <!-- row 6b : city, state, zip-->
              <div class="ll-row row3-container">
                <div class="ll-item ll-city">
                  <div class="tf-label" v-bind:id="`business-label-mailing-city`">
                    <label for="credit-owner-mailcity-input"
                      >City<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-city"
                    type="text"
                    id="credit-owner-mailcity-input"
                    aria-describedby="cred-own-mailcity-err"
                    v-model="owner_data.mailing_street_address_city"
                    :class="missing_owner_mailing_city ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateMailingCity()"
                    :data-test="`ll-bc-owner-mailing-city-1`"
                    required
                  />
                  <div id="cred-own-mailcity-err" class="ind-err-container">
                    <div
                      v-if="missing_owner_mailing_city"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-mailing-city-err-1`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a city</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item ll-state">
                  <div class="tf-label" v-bind:id="`business-label-mailing-state`">
                    <label for="credit-owner-mailst-input"
                      >State or Territory<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <select
                    class="ll-select select-state"
                    name="us_state"
                    id="credit-owner-mailst-input"
                    aria-describedby="cred-own-mailst-err"
                    v-model="owner_data.mailing_street_address_state"
                    :class="missing_owner_mailing_state ? 'll-select-error' : 'll-select'"
                    required
                    @change="validateMailingState()"
                    :data-test="`ll-bc-owner-mailing-state-1`"
                  >
                    <option value="">Select a state</option>
                    <option
                      v-for="us_state in us_states"
                      :value="us_state.value"
                      v-bind:key="us_state.key"
                    >
                      {{ us_state.key }}
                    </option>
                  </select>
                  <div id="cred-own-mailst-err" class="ind-err-container">
                    <div
                      v-if="missing_owner_mailing_state"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-mailing-state-err-1`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please select a state</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item ll-zipcode">
                  <div class="tf-label" v-bind:id="`business-label-mailing-zipcode`">
                    <label for="credit-owner-mailzip-input"
                      >ZIP Code<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="12"
                    class="ll-textfield tf-zipcode"
                    type="text"
                    id="credit-owner-mailzip-input"
                    aria-describedby="cred-own-mailzip-err"
                    v-model="owner_data.mailing_street_address_zip"
                    :class="
                      missing_owner_mailing_zip || invalid_owner_mailing_zip
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateMailingZipcode()"
                    :data-test="`ll-bc-owner-mailing-zipcode-1`"
                    required
                  />
                  <div id="cred-own-mailzip-err" class="ind-err-container">
                    <div
                      v-if="missing_owner_mailing_zip || invalid_owner_mailing_zip"
                      class="form-error-msg"
                      :data-test="`ll-bc-owner-mailing-zipcode-err-1`"
                    >
                      <img
                        class="form-error-icon"
                        srcset="
                          @/assets/images/exclaimation-lg.png    1x,
                          @/assets/images/exclaimation-lg-2x.png 2x
                        "
                        src="@/assets/images/exclaimation-lg-2x.png"
                        alt=""
                      />
                      <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
          </div>

          <div class="v-spacer-30" />

          <!-- Resident Status -->
          <div class="ll-row ll-row-status">
            <RadioInputs
              :inputValues="residencyInputArr"
              id="residency-id"
              name="residency-name"
              v-model="owner_data.resident_status"
              legendText="Resident Status"
              :missingSelection="missing_owner_resident_status"
            >
              <!-- image for first button -->
              <template #0>
                <span class="icon img-spacing">
                  <img
                    class="toggle-btn-icon flag"
                    srcset="
                      @/assets/images/us-flag-small.png    1x,
                      @/assets/images/us-flag-small-2x.png 2x
                    "
                    src="@/assets/images/us-flag-small-2x.png"
                    alt=""
                  />
                </span>
              </template>
              <!-- image for second button -->
              <template #1>
                <span class="icon img-spacing">
                  <img
                    class="toggle-btn-icon perm"
                    srcset="
                      @/assets/images/permanentResident-small.png    1x,
                      @/assets/images/permanentResident-small-2x.png 2x
                    "
                    src="@/assets/images/permanentResident-small-2x.png"
                    alt=""
                  />
                </span>
              </template>
              <!-- image for third button -->
              <template #2>
                <span class="icon img-spacing">
                  <img
                    class="toggle-btn-icon nonperm"
                    srcset="
                      @/assets/images/nonPermanentResident-small.png    1x,
                      @/assets/images/nonPermanentResident-small-2x.png 2x
                    "
                    src="@/assets/images/nonPermanentResident-small-2x.png"
                    alt=""
                  />
                </span>
              </template>
            </RadioInputs>
            <!-- <div class="tf-label" v-bind:id="`business-label-owner-resident-status`">Resident
                Status<sup class="fhb-red">*</sup>
              </div>

              <div class="toggle-btn-container"
                :class="this.missing_owner_resident_status ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'"> -->

            <!-- U.S. Citizen -->
            <!-- <button type="button" v-if="owner_data.resident_status != 'us'"
                  class="toggle-btn-up tf-resident-status" @click="toggleResidentStatus('us')"
                  :data-test="`ll-bc-owner-resident-us-off-1`">
                  <span class="icon"><img class="toggle-btn-icon flag"
                      srcset="@/assets/images/us-flag-small.png 1x, @/assets/images/us-flag-small-2x.png 2x"
                      src="@/assets/images/us-flag-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    U.S. Citizen
                  </div>
                </button> -->

            <!-- <button type="button" v-if="owner_data.resident_status === 'us'"
                  class="toggle-btn-down tf-resident-status"
                  :data-test="`ll-bc-owner-resident-us-on-1`">
                  <span class="icon"><img class="toggle-btn-icon flag"
                      srcset="@/assets/images/us-flag-small.png 1x, @/assets/images/us-flag-small-2x.png 2x"
                      src="@/assets/images/us-flag-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    U.S. Citizen
                  </div>
                </button> -->

            <!-- Permanent Resident -->
            <!-- <button type="button" v-if="owner_data.resident_status != 'permanent'"
                  class="toggle-btn-up tf-resident-status"
                  @click="toggleResidentStatus( 'permanent')"
                  :data-test="`ll-bc-owner-resident-perm-off-1`">
                  <span class="icon"><img class="toggle-btn-icon perm"
                      srcset="@/assets/images/permanentResident-small.png 1x, @/assets/images/permanentResident-small-2x.png 2x"
                      src="@/assets/images/permanentResident-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Permanent resident
                  </div>
                </button>

                <button type="button" v-if="owner_data.resident_status === 'permanent'"
                  class="toggle-btn-down tf-resident-status"
                  :data-test="`ll-bc-owner-resident-perm-on-1`">
                  <span class="icon"><img class="toggle-btn-icon perm"
                      srcset="@/assets/images/permanentResident-small.png 1x, @/assets/images/permanentResident-small-2x.png 2x"
                      src="@/assets/images/permanentResident-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Permanent resident
                  </div>
                </button> -->

            <!-- Non-permanent Resident -->
            <!-- <button type="button" v-if="owner_data.resident_status != 'non-permanent'"
                  class="toggle-btn-up tf-resident-status"
                  @click="toggleResidentStatus('non-permanent')"
                  :data-test="`ll-bc-owner-resident-non-perm-off-1`">
                  <span class="icon"><img class="toggle-btn-icon nonperm"
                      srcset="@/assets/images/nonPermanentResident-small.png 1x, @/assets/images/nonPermanentResident-small-2x.png 2x"
                      src="@/assets/images/nonPermanentResident-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Non-permanent resident
                  </div>
                </button>

                <button type="button" v-if="owner_data.resident_status === 'non-permanent'"
                  class="toggle-btn-down tf-resident-status"
                  :data-test="`ll-bc-owner-resident-non-perm-on-1`">
                  <span class="icon"><img class="toggle-btn-icon nonperm"
                      srcset="@/assets/images/nonPermanentResident-small.png 1x, @/assets/images/nonPermanentResident-small-2x.png 2x"
                      src="@/assets/images/nonPermanentResident-small-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Non-permanent resident
                  </div>
                </button> -->

            <!-- </div> -->
            <div
              v-if="missing_owner_resident_status"
              class="form-error-msg"
              :data-test="`ll-bc-owner-resident-err-1`"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please select an resident status</div>
            </div>
            <div class="v-spacer-30" />
          </div>

          <!-- row 6d : current address years, months-->
          <div class="ll-row">
            <!-- cur addr years, months -->
            <div class="ll-row">
              <div class="tf-label">
                How long have you lived at your this address?<sup class="fhb-red">*</sup>
              </div>
            </div>
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-owner-address-years`">
                  <label for="credit-owner-resyears-input">Years<sup class="fhb-red">*</sup></label>
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-resyears-input"
                  name="years"
                  v-model="owner_data.address_lived_at_years"
                  aria-describedby="bus-owner-yrs-add-note cred-own-addryrs-err"
                  :class="
                    missing_owner_years_at_address || invalid_owner_years_at_address
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateYearsAtAddress()"
                  :data-test="`ll-bc-owner-years-addr-1`"
                  required
                />
                <div id="bus-owner-yrs-add-note" class="tf-note">
                  Input 0 years if less than 1 year.
                </div>
                <div id="cred-own-addryrs-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_years_at_address || invalid_owner_years_at_address"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-years-addr-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter the number of years</div>
                  </div>
                </div>
              </div>

              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-owner-address-months`">
                  <label for="credit-owner-resmos-input"> Months<sup class="fhb-red">*</sup></label>
                </div>
                <select
                  class="ll-select select-addr-months"
                  name="coapp_addr_months"
                  id="credit-owner-resmos-input"
                  aria-describedby="cred-own-addrmos-err"
                  v-model="owner_data.address_lived_at_months"
                  :class="missing_owner_months_at_address ? 'll-select-error' : 'll-select'"
                  required
                  @change="validateMonthsAtAddress()"
                  :data-test="`ll-bc-owner-months-addr-1`"
                >
                  <option value="">Select months</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
                <div id="cred-own-addrmos-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_months_at_address"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-months-addr-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please select months</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- row 6c : occupancy -->
          <RadioInputs
            :inputValues="inputRentArr"
            id="occupancy-id"
            name="occupancy-name"
            v-model="owner_data.address_ownership"
            legendText="Own or Rent"
            :missingSelection="missing_owner_occupancy"
          >
            <!-- rent image -->
            <template #0>
              <span class="icon img-spacing"
                ><img
                  class="toggle-btn-icon rent-icon"
                  srcset="@/assets/images/rentIcon.png 1x, @/assets/images/rentIcon-2x.png 2x"
                  src="@/assets/images/rentIcon-2x.png"
                  alt=""
              /></span>
            </template>
            <!-- own image -->
            <template #1>
              <span class="icon img-spacing"
                ><img
                  class="toggle-btn-icon own-icon"
                  srcset="@/assets/images/ownIcon.png 1x, @/assets/images/ownIcon-2x.png 2x"
                  src="@/assets/images/ownIcon-2x.png"
                  alt=""
              /></span>
            </template>
          </RadioInputs>

          <!-- <div class="ll-row">
              <div class="tf-label" v-bind:id="`business-label-owner-occupancy`">Own or Rent<sup
                  class="fhb-red">*</sup></div>

              <div class="toggle-btn-container"
                :class="missing_owner_occupancy ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'"> -->

          <!-- rent -->
          <!-- <button type="button" v-if="owner_data.address_ownership != 'rent'"
                  class="toggle-btn-up tf-occupancy" @click="toggleOccupancy('rent')"
                  :data-test="`ll-bc-owner-occupancy-rent-off-1`">
                  <span class="icon"><img class="toggle-btn-icon rent-icon"
                      srcset="@/assets/images/rentIcon.png 1x, @/assets/images/rentIcon-2x.png 2x"
                      src="@/assets/images/rentIcon-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Rent
                  </div>
                </button>

                <button type="button" v-if="owner_data.address_ownership === 'rent'"
                  class="toggle-btn-down tf-occupancy"
                  :data-test="`ll-bc-owner-occupancy-rent-on-1`">
                  <span class="icon"><img class="toggle-btn-icon rent-icon"
                      srcset="@/assets/images/rentIcon.png 1x, @/assets/images/rentIcon-2x.png 2x"
                      src="@/assets/images/rentIcon-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Rent
                  </div>
                </button> -->

          <!-- own -->
          <!-- <button type="button" v-if="owner_data.address_ownership != 'own'"
                  class="toggle-btn-up tf-occupancy" @click="toggleOccupancy('own')"
                  :data-test="`ll-bc-owner-occupancy-own-off-1`">
                  <span class="icon"><img class="toggle-btn-icon own-icon"
                      srcset="@/assets/images/ownIcon.png 1x, @/assets/images/ownIcon-2x.png 2x"
                      src="@/assets/images/ownIcon-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Own
                  </div>
                </button>

                <button type="button" v-if="owner_data.address_ownership === 'own'"
                  class="toggle-btn-down tf-occupancy"
                  :data-test="`ll-bc-owner-occupancy-own-on-1`">
                  <span class="icon"><img class="toggle-btn-icon own-icon"
                      srcset="@/assets/images/ownIcon.png 1x, @/assets/images/ownIcon-2x.png 2x"
                      src="@/assets/images/ownIcon-2x.png" alt="" /></span>
                  <div class="toggle-btn-txt">
                    Own
                  </div>
                </button>

              </div>
              <div v-if="missing_owner_occupancy" class="form-error-msg"
                :data-test="`ll-bc-owner-occupancy-err-1`">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please select a occupancy status</div>
              </div>
              <div class="v-spacer-30" />
            </div> -->

          <!-- row 6d : years, months in state/territory -->
          <div class="ll-row">
            <!-- cur addr years, months -->
            <div class="ll-row">
              <div class="tf-label">
                How long have you lived in
                {{
                  this.owner_data.street_address_state
                    ? this.longStateTerritory()
                    : "your state/territory"
                }}?<sup class="fhb-red">*</sup>
              </div>
            </div>
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-label-state-years`">
                  <label for="credit-owner-liveyrs-input">Years<sup class="fhb-red">*</sup></label>
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-liveyrs-input"
                  name="years"
                  aria-describedby="years-state-note cred-own-resyrs-err"
                  v-model="owner_data.lived_in_serviceable_location_years"
                  :class="
                    missing_owner_years_in_state || invalid_owner_years_in_state
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateYearsInState()"
                  :data-test="`ll-bc-owner-yeras-state-1`"
                  required
                />
                <div id="years-state-note" class="tf-note">Input 0 years if less than 1 year.</div>
                <div id="cred-own-resyrs-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_years_in_state || invalid_owner_years_in_state"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-years-state-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter the number of years</div>
                  </div>
                </div>
              </div>

              <div class="ll-item">
                <div class="tf-label" v-bind:id="`business-label-state-months`">
                  <label for="credit-owner-livemos-input">Months<sup class="fhb-red">*</sup></label>
                </div>
                <select
                  class="ll-select select-addr-months"
                  name="coapp_addr_months"
                  id="credit-owner-livemos-input"
                  aria-describedby="cred-own-resmos-err"
                  v-model="owner_data.lived_in_serviceable_location_months"
                  :class="
                    missing_owner_months_in_state || invalid_owner_months_in_state
                      ? 'll-select-error'
                      : 'll-select'
                  "
                  required
                  @change="validateMonthsInState()"
                  :data-test="`ll-bc-owner-months-state-1`"
                >
                  <option value="">Select months</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
                <div id="cred-own-resmos-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_months_in_state || invalid_owner_months_in_state"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-months-state-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please select months</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Demographics -->
          <div class="v-spacer-60" />
          <div class="section-title2">Minority Owner Disclosure</div>
          <div class="disclosure-bottom">
            Federal law requires that we ask if a small business is a minority-owned business or a
            women-owned business. Federal law also requires us to ask small business applicants for
            their principal owner's ethnicity, race, and sex.
            <br /><br />
            Note: Applicants are not required to provide this information, but are encouraged to do
            so. We collect this information to help ensure that all small business applicants are
            treated fairly. However, Federal law requires us to report at least one principal
            owner's ethnicity and race based on visual observation and/or surname.
          </div>

          <div class="v-spacer-20" />

          <div class="ll-row">
            <fieldset class="ll-item ll-position">
              <legend
                class="tf-label"
                id="business-label-company-structure"
                aria-describedby="cred-own-ethn-err"
              >
                Please select one or more of the options that best describes your ethnicity<sup
                  class="fhb-red"
                  >*</sup
                >
              </legend>
              <!-- Ethinicities -->
              <table
                :class="this.missingOwnerEthnicity ? 'ethnicity-table-error' : 'ethnicity-table'"
              >
                <tr>
                  <td>
                    <label for="ethnicity-opt-1" id="ethnicity-label-1">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-1"
                        name="cuban"
                        v-model="ethnicities.ethnicityCuban.value"
                        @change="ethnicityChange()"
                      />
                      Cuban
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-2" id="ethnicity-label-2">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-2"
                        name="mexican"
                        v-model="ethnicities.ethnicityMexican.value"
                        @change="ethnicityChange()"
                      />
                      Mexican
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-3" id="ethnicity-label-3">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-3"
                        name="pr"
                        v-model="ethnicities.ethnicityPR.value"
                        @change="ethnicityChange()"
                      />
                      Puerto Rican
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-4" id="ethnicity-label-4">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-4"
                        name="aian"
                        v-model="ethnicities.ethnicityAIAN.value"
                        @change="ethnicityChange()"
                      />
                      American Indian or Alaska Native
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-5" id="ethnicity-label-5">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-5"
                        name="ai"
                        v-model="ethnicities.ethnicityAI.value"
                        @change="ethnicityChange()"
                      />
                      Asian Indian
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-6" id="ethnicity-label-6">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-6"
                        name="chinese"
                        v-model="ethnicities.ethnicityChinese.value"
                        @change="ethnicityChange()"
                      />
                      Chinese
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-7" id="ethnicity-label-7">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-7"
                        name="filipino"
                        v-model="ethnicities.ethnicityFilipino.value"
                        @change="ethnicityChange()"
                      />
                      Filipino
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-8" id="ethnicity-label-8">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-8"
                        name="japanese"
                        v-model="ethnicities.ethnicityJapanese.value"
                        @change="ethnicityChange()"
                      />
                      Japanese
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-9" id="ethnicity-label-9">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-9"
                        name="korean"
                        v-model="ethnicities.ethnicityKorean.value"
                        @change="ethnicityChange()"
                      />
                      Korean
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-10" id="ethnicity-label-10">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-10"
                        name="vietnamese"
                        v-model="ethnicities.ethnicityVietnamese.value"
                        @change="ethnicityChange()"
                      />
                      Vietnamese
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-11" id="ethnicity-label-11">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-11"
                        name="aa"
                        v-model="ethnicities.ethnicityAA.value"
                        @change="ethnicityChange()"
                      />
                      African American
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-12" id="ethnicity-label-12">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-12"
                        name="ethiopian"
                        v-model="ethnicities.ethnicityEthiopian.value"
                        @change="ethnicityChange()"
                      />
                      Ethiopian
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-13" id="ethnicity-label-13">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-13"
                        name="haitian"
                        v-model="ethnicities.ethnicityHaitian.value"
                        @change="ethnicityChange()"
                      />
                      Haitian
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-14" id="ethnicity-label-14">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-14"
                        name="jamaican"
                        v-model="ethnicities.ethnicityJamaican.value"
                        @change="ethnicityChange()"
                      />
                      Jamaican
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-15" id="ethnicity-label-15">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-15"
                        name="nigerian"
                        v-model="ethnicities.ethnicityNigerian.value"
                        @change="ethnicityChange()"
                      />
                      Nigerian
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-16" id="ethnicity-label-16">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-16"
                        name="somali"
                        v-model="ethnicities.ethnicitySomali.value"
                        @change="ethnicityChange()"
                      />
                      Somali
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-17" id="ethnicity-label-17">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-17"
                        name="gc"
                        v-model="ethnicities.ethnicityGC.value"
                        @change="ethnicityChange()"
                      />
                      Guamanian or Chamorro
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-18" id="ethnicity-label-18">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-18"
                        name="nh"
                        v-model="ethnicities.ethnicityNH.value"
                        @change="ethnicityChange()"
                      />
                      Native Hawaiian
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-19" id="ethnicity-label-19">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-19"
                        name="samoan"
                        v-model="ethnicities.ethnicitySamoan.value"
                        @change="ethnicityChange()"
                      />
                      Samoan
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-20" id="ethnicity-label-20">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-20"
                        name="white"
                        v-model="ethnicities.ethnicityWhite.value"
                        @change="ethnicityChange()"
                      />
                      White
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="ethnicity-opt-21" id="ethnicity-label-21">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-21"
                        name="other"
                        v-model="ethnicities.ethnicityOther.value"
                        @change="ethnicityChange()"
                      />
                      Other or not listed
                    </label>
                  </td>
                  <td>
                    <label for="ethnicity-opt-22" id="ethnicity-label-22">
                      <input
                        type="checkbox"
                        id="ethnicity-opt-22"
                        name="na"
                        v-model="ethnicities.ethnicityNA.value"
                        @change="ethnicityChangeNA()"
                      />
                      I do not wish to provide this information
                    </label>
                  </td>
                </tr>
              </table>
              <div id="cred-own-ethn-err" class="ind-err-container">
                <div
                  v-if="missingOwnerEthnicity"
                  class="form-error-msg"
                  data-test="cb-business-structure-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select an option</div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="v-spacer-30" />
          <!-- Company Structure -->
          <div class="ll-row row2-container">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-company-structure">
                <label for="credit-owner-womenowned-input"
                  >Is this a women-owned business?<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                :class="this.missingBusinessWomenOwned ? 'll-textfield-error' : 'll-select'"
                name="company_stucture"
                id="credit-owner-womenowned-input"
                aria-describedby="cred-own-woman-err"
                v-model="owner_data.is_women_owned"
                required
                @change="validateBusinessWomenOwned($event)"
                data-test="cb-business-structure"
              >
                <option value="">Select an option</option>
                <option v-for="opt in womenOwnedOptions" :value="opt.value" v-bind:key="opt.key">
                  {{ opt.key }}
                </option>
              </select>
              <div id="cred-own-woman-err" class="ind-err-container">
                <div
                  v-if="missingBusinessWomenOwned"
                  class="form-error-msg"
                  data-test="cb-business-structure-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select an option</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />
          <div class="section-title2">Estimated Monthly Income</div>

          <!-- row 6d : monthly salary -->
          <div class="ll-row">
            <!-- cur addr years, months -->
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="credit-owner-moinc-input"
                    >Monthly Income Salary & Wages<sup class="fhb-red">*</sup></label
                  >
                </div>
              </div>

              <div class="ll-item" v-bind:id="`business-label-monthly-salary`">
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-moinc-input"
                  aria-describedby="cred-own-moinc-err"
                  name="income"
                  v-model="owner_data.monthly_income"
                  :class="missing_owner_monthly_salary ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateMonthlySalary()"
                  :data-test="`ll-bc-owner-salary-1`"
                  required
                />
                <div id="cred-own-moinc-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_monthly_salary"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-salary-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter estimated monthly salary & wages
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- row 6d : Bonuses & commissions -->
          <div class="ll-row">
            <!-- cur addr years, months -->
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="credit-owner-boncom-input"
                    >Bonuses & Commissions<sup class="fhb-red">*</sup></label
                  >
                </div>
              </div>

              <div class="ll-item" v-bind:id="`business-label-monthly-bonus`">
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-boncom-input"
                  aria-describedby="cred-own-bonus-err"
                  name="bonus-commission"
                  v-model="owner_data.bonus_commissions"
                  :class="missing_owner_bonus ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateBonuses()"
                  :data-test="`ll-bc-owner-bonus-1`"
                  required
                />
                <div id="cred-own-bonus-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_bonus"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-bonus-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter estimated monthly bonuses & commissions
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- row 6d : Dividends & Interest -->
          <div class="ll-row">
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="credit-owner-divint-input"
                    >Dividends & Interest<sup class="fhb-red">*</sup></label
                  >
                </div>
              </div>

              <div class="ll-item" v-bind:id="`business-label-monthly-dividends`">
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-divint-input"
                  aria-describedby="cred-own-divint-err"
                  name="monthly-dividends"
                  v-model="owner_data.dividends_interest"
                  :class="missing_owner_dividends ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateDividends()"
                  :data-test="`ll-bc-owner-dividends-1`"
                  required
                />
                <div id="cred-own-divint-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_dividends"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-dividends-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter estimated monthly dividends & interest
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-10" />

          <!-- row 6d : Rentals -->
          <div class="ll-row">
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="credit-owner-rentals-input"
                    >Rentals<sup class="fhb-red">*</sup></label
                  >
                </div>
              </div>

              <div class="ll-item" v-bind:id="`business-label-monthly-rentals`">
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-rentals-input"
                  aria-describedby="cred-own-rentals-err"
                  name="rental-income"
                  v-model="owner_data.rentals"
                  :class="missing_owner_rentals ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateRentals()"
                  :data-test="`ll-bc-owner-rentals-1`"
                  required
                />
                <div id="cred-own-rentals-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_rentals"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-rentals-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter estimated monthly rental values
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="solid" />

          <div class="v-spacer-10" />
          <!-- row 6d : Gross Monthly Income -->
          <div class="ll-row">
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="credit-owner-grossmoinc-input"
                    >Gross Monthly Income<sup class="fhb-red">*</sup></label
                  >
                </div>
              </div>

              <div class="ll-item" v-bind:id="`business-label-monthly-income`">
                <input
                  disabled
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="credit-owner-grossmoinc-input"
                  aria-describedby="cred-own-grossmoinc-err"
                  name="gross-monthly-income"
                  v-model="owner_data.gross_monthly_income"
                  :class="missing_owner_gross_income ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateGrossIncome()"
                  :data-test="`ll-bc-owner-income-1`"
                  required
                />
              </div>
              <div id="cred-own-grossmoinc-err" class="ind-err-container">
                <div
                  v-if="missing_owner_gross_income"
                  class="form-error-msg"
                  :data-test="`ll-bc-owner-gross-income-err-1`"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">
                    Please enter gross monthly income value
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />
          <!-- assets in trust -->
          <div class="ll-row">
            <RadioInputs
              :inputValues="inputArr"
              id="assets-in-trust-id"
              name="assets-in-trust-name"
              v-model="owner_data.assets_in_trust"
              legendText="Are any personal assets held in trust?"
              :missingSelection="missing_owner_assets_in_trust"
            />
            <div class="ll-row row4-container">
              <!-- <div class="ll-item">
                  <div class="tf-label" v-bind:id="`business-label-assets-trust`">Are any
                    personal assets held in trust?<sup class="fhb-red">*</sup></div>
                  <div class="v-spacer-10" />

                  <div class="toggle-btn-container"
                    :class="missing_owner_assets_in_trust ? 'toggle-btn-container toggle-btn-container-error' : 'toggle-btn-container'">

                    <button type="button" v-if="owner_data.assets_in_trust != 'yes'"
                      class="toggle-btn-up toggle-btn-sm"
                      @click="toggleAssetsInTrust('yes')"
                      :data-test="`ll-bc-owner-assets-trust-off-1`">
                      <div class="toggle-btn-txt">
                        Yes
                      </div>
                    </button>

                    <button type="button" v-if="owner_data.assets_in_trust === 'yes'"
                      class="toggle-btn-down toggle-btn-sm"
                      :data-test="`ll-bc-owner-assets-trust-on-1`">
                      <div class="toggle-btn-txt">
                        Yes
                      </div>
                    </button>

                    <button type="button" v-if="owner_data.assets_in_trust != 'no'"
                      class="toggle-btn-up toggle-btn-sm"
                      @click="toggleAssetsInTrust('no')"
                      :data-test="`ll-bc-owner-no-assets-trust-off-1`">
                      <div class="toggle-btn-txt">
                        No
                      </div>
                    </button>
                    <button type="button" v-if="owner_data.assets_in_trust === 'no'"
                      class="toggle-btn-down toggle-btn-sm"
                      :data-test="`ll-bc-owner-no-assets-trust-on-1`">
                      <div class="toggle-btn-txt">
                        No
                      </div>
                    </button>

                    <div v-if="missing_owner_assets_in_trust" class="form-error-msg"
                      :data-test="`ll-bc-owner-assets-trust-err-1`">
                      <img class="form-error-icon"
                        srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                        src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                      <div role="alert" class="form-error-txt">Please select an option</div>
                    </div>
                  </div>
                </div> -->
              <!-- Explanation -->
              <div class="ll-item" v-if="this.owner_data.assets_in_trust == 'yes'">
                <div class="tf-label" v-bind:id="`business-label-asset-explanation`">
                  <label for="credit-owner-persassets-input"
                    >If yes, please detail<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  :data-test="`ll-bc-owner-assets-explanation-1`"
                  type="text"
                  id="credit-owner-persassets-input"
                  aria-describedby="cred-own-trust-desc-err"
                  name="personal-assets-explanation"
                  v-model="owner_assets_explanation"
                  :class="
                    this.missing_owner_assets_explanation ? 'll-textfield-error' : 'll-textfield'
                  "
                  v-on:blur="validateAssetExplanation()"
                  required
                />
                <div id="cred-own-trust-desc-err" class="ind-err-container">
                  <div
                    v-if="missing_owner_assets_explanation"
                    class="form-error-msg"
                    :data-test="`ll-bc-owner-assets-explanation-err-1`"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter an explanation</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />

          <!-- Financial Condition Table -->
          <div class="section-title2">
            Personal Financial Condition
            {{
              this.owner_data.first_name
                ? `for ${this.owner_data.first_name} ${this.owner_data.last_name}`
                : ""
            }}
          </div>
          <div class="section-title2">
            Assets and Liabilites as of {{ this.todaysDate }}, Itemized below
          </div>
          <div class="tf-note">
            Please make sure you have 1 checking or savings account... TODO other requirements...
          </div>
          <div class="v-spacer-20" />

          <div>
            <table class="pers-fin-cond main-table">
              <tr v-for="j in this.owner_num_assets" :key="j">
                <!-- column 1 -->
                <td class="table-col1">
                  <div :id="`business-financial-asset-type-${j}`">
                    <div class="tf-label">
                      <label :for="`credit-owner-pfcitemtype-input-${j}`">Item Type</label>
                    </div>
                    <select
                      class="ll-select select-state"
                      name="asset-type"
                      :id="`credit-owner-pfcitemtype-input-${j}`"
                      :aria-describedby="`credit-owner-pfcitemtype-error-${j}`"
                      v-model="owner_asset_type[j - 1]"
                      :class="missing_asset_type[j - 1] ? 'll-select-error' : 'll-select'"
                      required
                      @change="toggleAssetType(j - 1)"
                      :data-test="`ll-bc-owner-asset-type-1`"
                    >
                      <option value="">Select an option</option>
                      <option
                        v-for="asset in asset_types"
                        :value="asset.value"
                        v-bind:key="asset.key"
                      >
                        {{ asset.key }}
                      </option>
                    </select>
                    <div :id="`credit-owner-pfcitemtype-error-${j}`" class="ind-err-container">
                      <div
                        v-if="missing_asset_type[j - 1]"
                        class="form-error-msg"
                        :data-test="`ll-bc-owner-asset-bank-institution-err-1`"
                      >
                        <img
                          class="form-error-icon"
                          srcset="
                            @/assets/images/exclaimation-lg.png    1x,
                            @/assets/images/exclaimation-lg-2x.png 2x
                          "
                          src="@/assets/images/exclaimation-lg-2x.png"
                          alt=""
                        />
                        <div role="alert" class="form-error-txt">Please select a type</div>
                      </div>
                    </div>
                  </div>
                </td>
                <!-- column 2 -->
                <td class="table-col2">
                  <!-- Bank Account / Debt col 2 -->
                  <div
                    :id="`business-financial-asset-institution-1`"
                    class="ll-item ll-fname"
                    v-if="
                      this.owner_asset_type[j - 1] == 'checking' ||
                      this.owner_asset_type[j - 1] == 'savings' ||
                      this.owner_asset_type[j - 1] == 'debt'
                    "
                  >
                    <div class="tf-label">
                      <label :for="`credit-owner-pfcassetfi-input-${j}`"
                        >Name of Financial Institution</label
                      >
                    </div>
                    <input
                      maxlength="512"
                      class="ll-textfield tf-fname"
                      :id="`credit-owner-pfcassetfi-input-${j}`"
                      :aria-describedby="`credit-owner-pfcassetfi-error-${j}`"
                      name="financial-institution"
                      :data-test="`ll-bc-owner-asset-bank-institution-1`"
                      type="text"
                      v-model="this.owner_asset_institution[j - 1]"
                      :class="
                        missing_asset_institution[j - 1] ? 'll-textfield-error' : 'll-textfield'
                      "
                      v-on:blur="validateAssetInstitution(j - 1)"
                    />
                    <div :id="`credit-owner-pfcassetfi-error-${j}`" class="ind-err-container">
                      <div
                        v-if="missing_asset_institution[j - 1]"
                        class="form-error-msg"
                        :data-test="`ll-bc-owner-asset-bank-institution-err-1`"
                      >
                        <img
                          class="form-error-icon"
                          srcset="
                            @/assets/images/exclaimation-lg.png    1x,
                            @/assets/images/exclaimation-lg-2x.png 2x
                          "
                          src="@/assets/images/exclaimation-lg-2x.png"
                          alt=""
                        />
                        <div role="alert" class="form-error-txt">
                          Please enter the institution name.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Life Insurance col 2 -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'life-insurance'">
                    <table>
                      <tr style="width: 100%">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-institution-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcassetli-input-${j}`"
                                >Life Insurance Institution</label
                              >
                            </div>
                            <input
                              maxlength="512"
                              class="ll-textfield tf-fname"
                              :data-test="`ll-bc-owner-asset-life-institution-1`"
                              type="text"
                              :id="`credit-owner-pfcassetli-input-${j}`"
                              :aria-describedby="`credit-owner-pfcassetli-error-${j}`"
                              name="life-insurance-institution"
                              v-model="this.owner_asset_institution[j - 1]"
                              :class="
                                missing_asset_institution[j - 1]
                                  ? 'll-textfield-error'
                                  : 'll-textfield'
                              "
                              v-on:blur="validateAssetInstitution(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcassetli-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_asset_institution[j - 1] || invalid_owner_first_name"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-life-institution-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter a valid institution
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="width: 100%">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-loan-on-life-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcassetlifeinsloan-input-${j}`"
                                >Loans on Life Insurance (Please Describe)</label
                              >
                            </div>
                            <input
                              maxlength="512"
                              class="ll-textfield tf-fname"
                              :data-test="`ll-bc-owner-asset-life-loan-1`"
                              type="text"
                              :id="`credit-owner-pfcassetlifeinsloan-input-${j}`"
                              :aria-describedby="`credit-owner-pfcassetlifeinsloan-error-${j}`"
                              name="life-insurance-loan"
                              v-model="this.owner_loan_on_insurance[j - 1]"
                              :class="
                                missing_loans_on_insurance[j - 1]
                                  ? 'll-textfield-error'
                                  : 'll-textfield'
                              "
                              v-on:blur="validateLoansOnInsurance(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcassetlifeinsloan-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_loans_on_insurance[j - 1]"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-life-loan-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter a description
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <!-- Auto col 2 -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'auto'">
                    <table class="inner-table">
                      <tr class="inner-table-row" style="width: 100%">
                        <table class="inner-table">
                          <tr class="inner-table-row">
                            <td style="width: 25%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-auto-year-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcassetautoyr-input-${j}`"
                                    >Year</label
                                  >
                                </div>
                                <input
                                  maxlength="512"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-auto-year-1`"
                                  type="text"
                                  :id="`credit-owner-pfcassetautoyr-input-${j}`"
                                  :aria-describedby="`credit-owner-pfcassetautoyr-error-${j}`"
                                  v-model="this.owner_asset_auto_year[j - 1]"
                                  :class="
                                    missing_asset_auto_year[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateAutoYear(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcassetautoyr-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_auto_year[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-auto-year-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter a valid year
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style="width: 75%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-auto-make-model-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcassetautomake-input-${j}`"
                                    >Make & Model</label
                                  >
                                </div>
                                <input
                                  maxlength="512"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-auto-model-${j}`"
                                  type="text"
                                  id="`credit-owner-pfcassetautomake-input-1`"
                                  :aria-describedby="`credit-owner-pfcassetautomake-error-${j}`"
                                  name="auto-make-model"
                                  v-model="this.owner_asset_auto_make_model[j - 1]"
                                  :class="
                                    missing_asset_auto_make_model[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateAutoMakeModel(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcassetautomake-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_auto_make_model[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-auto-model-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter a valid make and model
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </tr>
                      <tr class="inner-table-row" style="width: 100%">
                        <table class="inner-table">
                          <tr class="inner-table-row">
                            <td style="width: 60%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-institution-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcautolender-input-${j}`"
                                    >Auto Loan Lender (if applicable)</label
                                  >
                                </div>
                                <input
                                  maxlength="512"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-auto-lender-1`"
                                  type="text"
                                  :id="`credit-owner-pfcautolender-input-${j}`"
                                  :aria-describedby="`credit-owner-pfcassetautolender-error-${j}`"
                                  name="auto-loan-lender"
                                  v-model="this.owner_asset_institution[j - 1]"
                                  :class="
                                    this.missing_asset_institution[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateAssetInstitution(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcassetautolender-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_institution[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-auto-lender-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter a valid institution
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style="width: 40%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-payment-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcautomo-input-${j}`"
                                    >Monthly Payment</label
                                  >
                                </div>
                                <money-input
                                  maxlength="6"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-auto-payment-${j}`"
                                  type="text"
                                  :id="`credit-owner-pfcautomo-input-1`"
                                  :aria-describedby="`credit-owner-pfcassetautomo-error-${j}`"
                                  name="monthly-payment"
                                  v-model="this.owner_asset_monthly_payment[j - 1]"
                                  :class="
                                    missing_asset_monthly_payment[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateMonthlyPayment(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcassetautomo-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_monthly_payment[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-auto-payment-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter your monthly payment
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </tr>
                    </table>
                  </div>

                  <!-- Real Estate col 2 -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'real-estate'">
                    <table class="inner-table">
                      <tr class="inner-table-row" style="width: 100%">
                        <td style="width: 25%">
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-property-owner-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcpropowner-input-${j}`"
                                >Unless otherwise noted, is in the name(s) of</label
                              >
                            </div>
                            <input
                              maxlength="512"
                              class="ll-textfield tf-fname"
                              :data-test="`ll-bc-owner-asset-property-owner-1`"
                              type="text"
                              :id="`credit-owner-pfcpropowner-input-${j}`"
                              :aria-describedby="`credit-owner-pfcpropowner-error-${j}`"
                              name="property-owner"
                              v-model="this.owner_asset_property_owner[j - 1]"
                              :class="
                                missing_asset_property_owner[j - 1]
                                  ? 'll-textfield-error'
                                  : 'll-textfield'
                              "
                              v-on:blur="validatePropertyOwner(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcpropowner-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_asset_property_owner[j - 1]"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-property-owner-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter the property owner's name
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="inner-table-row" style="width: 100%">
                        <td style="width: 25%">
                          <div
                            class="ll-item ll-fname0"
                            :id="`business-financial-asset-property-location-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcproploc-input-${j}`"
                                >Location (Street Address, City, State/Territory, Zipcode)</label
                              >
                            </div>
                            <input
                              maxlength="512"
                              class="ll-textfield tf-fname"
                              :data-test="`ll-bc-owner-asset-property-zip-1`"
                              type="text"
                              :id="`credit-owner-pfcproploc-input-${j}`"
                              :aria-describedby="`credit-owner-pfcproploc-error-${j}`"
                              name="property-location"
                              v-model="this.owner_asset_property_location[j - 1]"
                              :class="
                                missing_asset_property_location[j - 1]
                                  ? 'll-textfield-error'
                                  : 'll-textfield'
                              "
                              v-on:blur="validatePropertyLocation(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcproploc-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_asset_property_location[j - 1]"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-property-zip-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter the property address
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="width: 100%">
                        <table>
                          <tr class="inner-table-row">
                            <td style="width: 50%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-institution-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcmortgageinst-input-${j}`"
                                    >Mortgage Institution</label
                                  >
                                </div>
                                <input
                                  maxlength="512"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-property-institution-1`"
                                  type="text"
                                  :id="`credit-owner-pfcmortgageinst-input-${j}`"
                                  :aria-describedby="`credit-owner-pfcmortgageinst-error-${j}`"
                                  name="mortgage-institution"
                                  v-model="this.owner_asset_institution[j - 1]"
                                  :class="
                                    missing_asset_institution[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateAssetInstitution(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcmortgageinst-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_institution[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-property-institution-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter a valid institution
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style="width: 25%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-payment-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcmortgagepay-input-${j}`"
                                    >Monthly Payment</label
                                  >
                                </div>
                                <money-input
                                  maxlength="6"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-property-payment-1`"
                                  type="text"
                                  :id="`credit-owner-pfcmortgagepay-input-${j}`"
                                  :aria-describedby="`credit-owner-pfcmortgagepay-error-${j}`"
                                  name="mortgage-payment"
                                  v-model="this.owner_asset_monthly_payment[j - 1]"
                                  :class="
                                    missing_asset_monthly_payment[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validateMonthlyPayment(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcmortgagepay-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_monthly_payment[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-property-paymnet-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter a monthly payment
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style="width: 25%">
                              <div
                                class="ll-item ll-fname"
                                :id="`business-financial-asset-property-income-${j}`"
                              >
                                <div class="tf-label">
                                  <label :for="`credit-owner-pfcmortgagerent-input-${j}`"
                                    >Rental Income</label
                                  >
                                </div>
                                <money-input
                                  maxlength="7"
                                  class="ll-textfield tf-fname"
                                  :data-test="`ll-bc-owner-asset-property-income-1`"
                                  type="text"
                                  :id="`credit-owner-pfcmortgagerent-input-${j}`"
                                  :aria-describedby="`credit-owner-pfcmortgagerent-error-${j}`"
                                  name="rental-income"
                                  v-model="this.owner_asset_property_rental_income[j - 1]"
                                  :class="
                                    missing_asset_property_rental_income[j - 1]
                                      ? 'll-textfield-error'
                                      : 'll-textfield'
                                  "
                                  v-on:blur="validatePropertyRentalIncome(j - 1)"
                                />
                                <div
                                  :id="`credit-owner-pfcmortgagerent-error-${j}`"
                                  class="ind-err-container"
                                >
                                  <div
                                    v-if="missing_asset_property_rental_income[j - 1]"
                                    class="form-error-msg"
                                    :data-test="`ll-bc-owner-asset-property-income-err-1`"
                                  >
                                    <img
                                      class="form-error-icon"
                                      srcset="
                                        @/assets/images/exclaimation-lg.png    1x,
                                        @/assets/images/exclaimation-lg-2x.png 2x
                                      "
                                      src="@/assets/images/exclaimation-lg-2x.png"
                                      alt=""
                                    />
                                    <div role="alert" class="form-error-txt">
                                      Please enter the property rental income
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </tr>
                    </table>
                  </div>
                </td>
                <!-- column 3 -->
                <td class="table-col3">
                  <!-- Bank Account col 3 -->
                  <div
                    v-if="
                      this.owner_asset_type[j - 1] == 'checking' ||
                      this.owner_asset_type[j - 1] == 'savings'
                    "
                    class="ll-item ll-fname"
                    :id="`business-financial-asset-balance-${j}`"
                  >
                    <div class="tf-label">
                      <label :for="`credit-owner-pfccheckbal-input-${j}`">Balance</label>
                    </div>
                    <div class="ll-item">
                      <money-input
                        maxlength="12"
                        class="ll-textfield tf-addr-years"
                        type="text"
                        :id="`credit-owner-pfccheckbal-input-${j}`"
                        :aria-describedby="`credit-owner-pfccheckbal-error-${j}`"
                        name="checking-balance"
                        v-model="owner_asset_balance[j - 1]"
                        :class="
                          missing_asset_balance[j - 1] ? 'll-textfield-error' : 'll-textfield'
                        "
                        v-on:blur="validateAssetBalance(j - 1)"
                        :data-test="`ll-bc-owner-asset-account-balance-1`"
                      />
                      <div :id="`credit-owner-pfccheckbal-error-${j}`" class="ind-err-container">
                        <div
                          v-if="missing_asset_balance[j - 1]"
                          class="form-error-msg"
                          :data-test="`ll-bc-owner-asset-account-balance-err-1`"
                        >
                          <img
                            class="form-error-icon"
                            srcset="
                              @/assets/images/exclaimation-lg.png    1x,
                              @/assets/images/exclaimation-lg-2x.png 2x
                            "
                            src="@/assets/images/exclaimation-lg-2x.png"
                            alt=""
                          />
                          <div role="alert" class="form-error-txt">
                            Please enter the account balance
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Life Insurance col 3 -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'life-insurance'">
                    <table>
                      <tr class="inner-table-row">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-amount-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfclinsamt-input-${j}`"
                                >Amount (not face amount)</label
                              >
                            </div>
                            <div class="ll-item">
                              <money-input
                                maxlength="12"
                                class="ll-textfield tf-addr-years"
                                type="text"
                                :id="`credit-owner-pfcclinsamt-input-${j}`"
                                :aria-describedby="`credit-owner-pfcclinsamt-error-${j}`"
                                name="checking-amount"
                                v-model="owner_asset_amount[j - 1]"
                                :class="
                                  missing_asset_amount[j - 1]
                                    ? 'll-textfield-error'
                                    : 'll-textfield'
                                "
                                v-on:blur="validateAssetAmount(j - 1)"
                                :data-test="`ll-bc-owner-asset-life-amount-1`"
                              />
                              <div
                                :id="`credit-owner-pfcclinsamt-error-${j}`"
                                class="ind-err-container"
                              >
                                <div
                                  v-if="missing_asset_amount[j - 1]"
                                  class="form-error-msg"
                                  :data-test="`ll-bc-owner-asset-life-amount-err-1`"
                                >
                                  <img
                                    class="form-error-icon"
                                    srcset="
                                      @/assets/images/exclaimation-lg.png    1x,
                                      @/assets/images/exclaimation-lg-2x.png 2x
                                    "
                                    src="@/assets/images/exclaimation-lg-2x.png"
                                    alt=""
                                  />
                                  <div role="alert" class="form-error-txt">
                                    Please enter a valid amount
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="inner-table-row">
                        <td>
                          <div class="ll-item ll-fname">
                            <div class="tf-label">
                              <label :for="`credit-owner-pfclinsbal-input-${j}`">Balance</label>
                            </div>
                            <div class="ll-item" :id="`business-financial-asset-balance-${j}`">
                              <money-input
                                maxlength="12"
                                class="ll-textfield tf-addr-years"
                                type="text"
                                :id="`credit-owner-pfclinsbal-input-${j}`"
                                :aria-describedby="`credit-owner-pfclinsbal-error-${j}`"
                                name="life-insurance-balance"
                                v-model="this.owner_asset_balance[j - 1]"
                                :class="
                                  missing_asset_balance[j - 1]
                                    ? 'll-textfield-error'
                                    : 'll-textfield'
                                "
                                v-on:blur="validateAssetBalance(j - 1)"
                                :data-test="`ll-bc-owner-asset-life-balance-1`"
                              />
                              <div
                                :id="`credit-owner-pfclinsbal-error-${j}`"
                                class="ind-err-container"
                              >
                                <div
                                  v-if="missing_asset_balance[j - 1]"
                                  class="form-error-msg"
                                  :data-test="`ll-bc-owner-asset-life-balance-err-1`"
                                >
                                  <img
                                    class="form-error-icon"
                                    srcset="
                                      @/assets/images/exclaimation-lg.png    1x,
                                      @/assets/images/exclaimation-lg-2x.png 2x
                                    "
                                    src="@/assets/images/exclaimation-lg-2x.png"
                                    alt=""
                                  />
                                  <div role="alert" class="form-error-txt">
                                    Please enter the loan balance
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <!-- Auto col 3 -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'auto'">
                    <table>
                      <tr class="inner-table-row" style="width: 100%">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-amount-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcautoval-input-${j}`">Value</label>
                            </div>
                            <money-input
                              maxlength="8"
                              class="ll-textfield tf-fname"
                              :id="`credit-owner-pfcautoval-input-${j}`"
                              :aria-describedby="`credit-owner-pfcautoval-error-${j}`"
                              name="auto-value"
                              :data-test="`ll-bc-owner-asset-auto-amount-1`"
                              type="text"
                              v-model="owner_asset_amount[j - 1]"
                              :class="
                                missing_asset_amount[j - 1] ? 'll-textfield-error' : 'll-textfield'
                              "
                              v-on:blur="validateAssetAmount(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcautoval-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_asset_amount[j - 1]"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-auto-amount-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter a valid amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="inner-table-row" style="width: 100%">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-balance-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcautobal-input-${j}`">Balance</label>
                            </div>
                            <money-input
                              maxlength="8"
                              class="ll-textfield tf-fname"
                              :data-test="`ll-bc-owner-asset-auto-balance-1`"
                              type="text"
                              :id="`credit-owner-pfcautobal-input-${j}`"
                              :aria-describedby="`credit-owner-pfcautobal-error-${j}`"
                              name="auto-balance"
                              v-model="owner_asset_balance[j - 1]"
                              :class="
                                missing_asset_balance[j - 1] ? 'll-textfield-error' : 'll-textfield'
                              "
                              v-on:blur="validateAssetBalance(j - 1)"
                            />
                            <div
                              :id="`credit-owner-pfcautobal-error-${j}`"
                              class="ind-err-container"
                            >
                              <div
                                v-if="missing_asset_balance[j - 1]"
                                class="form-error-msg"
                                :data-test="`ll-bc-owner-asset-auto-balance-err-1`"
                              >
                                <img
                                  class="form-error-icon"
                                  srcset="
                                    @/assets/images/exclaimation-lg.png    1x,
                                    @/assets/images/exclaimation-lg-2x.png 2x
                                  "
                                  src="@/assets/images/exclaimation-lg-2x.png"
                                  alt=""
                                />
                                <div role="alert" class="form-error-txt">
                                  Please enter the loan balance
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <!-- Real Estate -->
                  <div v-else-if="this.owner_asset_type[j - 1] == 'real-estate'">
                    <table>
                      <tr class="inner-table-row">
                        <td>
                          <div class="ll-item ll-fname">
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcremv-input-${j}`"
                                >Present Market Value</label
                              >
                            </div>
                            <div class="ll-item" :id="`business-financial-asset-amount-${j}`">
                              <money-input
                                maxlength="12"
                                class="ll-textfield tf-addr-years"
                                type="text"
                                :id="`credit-owner-pfcremv-input-${j}`"
                                :aria-describedby="`credit-owner-pfcremv-error-${j}`"
                                name="present-market-value"
                                v-model="this.owner_asset_amount[j - 1]"
                                :class="
                                  missing_asset_amount[j - 1]
                                    ? 'll-textfield-error'
                                    : 'll-textfield'
                                "
                                v-on:blur="validateAssetAmount(j - 1)"
                                :data-test="`ll-bc-owner-asset-property-value-1`"
                              />
                              <div
                                :id="`credit-owner-pfcremv-error-${j}`"
                                class="ind-err-container"
                              >
                                <div
                                  v-if="missing_asset_amount[j - 1]"
                                  class="form-error-msg"
                                  :data-test="`ll-bc-owner-asset-property-value-err-1`"
                                >
                                  <img
                                    class="form-error-icon"
                                    srcset="
                                      @/assets/images/exclaimation-lg.png    1x,
                                      @/assets/images/exclaimation-lg-2x.png 2x
                                    "
                                    src="@/assets/images/exclaimation-lg-2x.png"
                                    alt=""
                                  />
                                  <div role="alert" class="form-error-txt">
                                    Please enter the present market value
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="inner-table-row">
                        <td>
                          <div
                            class="ll-item ll-fname"
                            :id="`business-financial-asset-balance-${j}`"
                          >
                            <div class="tf-label">
                              <label :for="`credit-owner-pfcrebal-input-${j}`">Balance</label>
                            </div>
                            <div class="ll-item">
                              <money-input
                                maxlength="12"
                                class="ll-textfield tf-addr-years"
                                type="text"
                                :id="`credit-owner-pfcrebal-input-${j}`"
                                :aria-describedby="`credit-owner-pfcrebal-error-${j}`"
                                name="real-estate-balance"
                                v-model="this.owner_asset_balance[j - 1]"
                                :class="
                                  missing_asset_balance[j - 1]
                                    ? 'll-textfield-error'
                                    : 'll-textfield'
                                "
                                v-on:blur="validateAssetBalance(j - 1)"
                                :data-test="`ll-bc-owner-asset-property-balance-1`"
                              />
                              <div
                                :id="`credit-owner-pfcrebal-error-${j}`"
                                class="ind-err-container"
                              >
                                <div
                                  v-if="missing_asset_balance[j - 1]"
                                  class="form-error-msg"
                                  :data-test="`ll-bc-owner-asset-property-balance-err-1`"
                                >
                                  <img
                                    class="form-error-icon"
                                    srcset="
                                      @/assets/images/exclaimation-lg.png    1x,
                                      @/assets/images/exclaimation-lg-2x.png 2x
                                    "
                                    src="@/assets/images/exclaimation-lg-2x.png"
                                    alt=""
                                  />
                                  <div role="alert" class="form-error-txt">
                                    Please enter the remaining balance
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <!-- Debt -->
                  <div
                    v-else-if="this.owner_asset_type[j - 1] == 'debt'"
                    class="ll-item ll-fname"
                    :id="`business-financial-asset-balance-${j}`"
                  >
                    <div class="tf-label">
                      <label :for="`credit-owner-pfcdebtamt-input-${j}`">Amount Due</label>
                    </div>
                    <div class="ll-item">
                      <money-input
                        maxlength="12"
                        class="ll-textfield tf-addr-years"
                        type="text"
                        :id="`credit-owner-pfcdebtamt-input-${j}`"
                        :aria-describedby="`credit-owner-pfcdebtamt-error-${j}`"
                        name="amount-due"
                        v-model="this.owner_asset_balance[j - 1]"
                        :class="
                          missing_asset_balance[j - 1] ? 'll-textfield-error' : 'll-textfield'
                        "
                        v-on:blur="validateAssetBalance(j - 1)"
                        :data-test="`ll-bc-owner-debt-amount-1`"
                      />
                      <div :id="`credit-owner-pfcdebtamt-error-${j}`" class="ind-err-container">
                        <div
                          v-if="missing_asset_balance[j - 1]"
                          class="form-error-msg"
                          :data-test="`ll-bc-owner-debt-amount-err-1`"
                        >
                          <img
                            class="form-error-icon"
                            srcset="
                              @/assets/images/exclaimation-lg.png    1x,
                              @/assets/images/exclaimation-lg-2x.png 2x
                            "
                            src="@/assets/images/exclaimation-lg-2x.png"
                            alt=""
                          />
                          <div role="alert" class="form-error-txt">Please enter the amount due</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- End of asset for loop -->
            </table>
          </div>

          <div class="v-spacer-30" />
          <button
            type="button"
            class="toggle-btn-up toggle-btn-sm"
            @click="addAsset()"
            :data-test="`ll-bc-add-asset-btn-1`"
          >
            <div class="toggle-btn-txt add-plus">Add Financial Item</div>
          </button>

          <!--  end of owner for loop -->

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} :
                <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button
              id="personal-continue-btn"
              type="button"
              class="continue-btn"
              @click="handleContinue()"
              data-test="ll-bc-btn-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Constants from "@/app/business_constants"
import Validator from "@/app/validation"
import Header from "@/components/Header"
import BreadcrumbCreditOwner from "@/views/business/credit_app_owner/BreadcrumbCreditOwner.vue"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import BirthdayInput from "@/components/CustomInputs/BirthdayInput.vue"
import MoneyInput from "../../../components/CustomInputs/MoneyInput.vue"
import PercentageInput from "../../../components/CustomInputs/PercentageInput.vue"
import SocialInput from "../../../components/CustomInputs/SocialInput.vue"
import PhoneInput from "../../../components/CustomInputs/PhoneInput.vue"
import RadioInputs from "../../../components/CustomInputs/RadioInputs.vue"

export default {
  name: "Getting Started",
  components: {
    Header,
    BreadcrumbCreditOwner,
    MainId,
    Footer,
    BirthdayInput,
    SocialInput,
    MoneyInput,
    PercentageInput,
    PhoneInput,
    RadioInputs,
  },
  data() {
    return {
      company_positions_corp: Constants.CompanyPositionsCorp,
      company_positions_LLC: Constants.CompanyPositionsLLC,
      company_positions_partnership: Constants.CompanyPositionsPartnership,
      us_states: Constants.USStates,
      asset_types: Constants.AssetTypes,
      ethnicity_options: Constants.EthnicityOptions,
      womenOwnedOptions: [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
        { key: "I do not wish to provide this information", value: "na" },
      ],

      owner_data: "",

      ownerEthnicity: "",
      ethnicities: {
        ethnicityCuban: { value: false, str: "Cuban" },
        ethnicityMexican: { value: false, str: "Mexican" },
        ethnicityPR: { value: false, str: "Puerto Rican" },
        ethnicityAIAN: {
          value: false,
          str: "American Indian or Alaska Native",
        },
        ethnicityAI: { value: false, str: "Asian Indian" },
        ethnicityChinese: { value: false, str: "Chinese" },
        ethnicityFilipino: { value: false, str: "Filipino" },
        ethnicityJapanese: { value: false, str: "Japanese" },
        ethnicityKorean: { value: false, str: "Korean" },
        ethnicityVietnamese: { value: false, str: "Vietnamese" },
        ethnicityAA: { value: false, str: "African American" },
        ethnicityEthiopian: { value: false, str: "Ethiopian" },
        ethnicityHaitian: { value: false, str: "Haitian" },
        ethnicityJamaican: { value: false, str: "Jamaican" },
        ethnicityNigerian: { value: false, str: "Nigerian" },
        ethnicitySomali: { value: false, str: "Somali" },
        ethnicityGC: { value: false, str: "Guamanian or Chamorro" },
        ethnicityNH: { value: false, str: "Native Hawaiian" },
        ethnicitySamoan: { value: false, str: "Samoan" },
        ethnicityWhite: { value: false, str: "White" },
        ethnicityOther: { value: false, str: "Other" },
        ethnicityNA: { value: false, str: "N/A" },
      },

      owner_asset_type: [],
      owner_num_assets: 1,

      owner_asset_institution: [],
      owner_asset_amount: [],
      owner_asset_balance: [],
      // life insurance
      owner_loan_on_insurance: [],
      // auto
      owner_asset_auto_year: [],
      owner_asset_auto_make_model: [],
      owner_asset_monthly_payment: [],
      // real estate
      owner_asset_property_owner: [],
      owner_asset_property_location: [],
      owner_asset_property_rental_income: [],

      // errors
      missing_owner_first_name: false,
      invalid_owner_first_name: false,
      missing_owner_last_name: false,
      invalid_owner_last_name: false,
      invalid_owner_middle_name: false,
      missing_owner_birthday: false,
      invalid_owner_birthday: false,
      missing_owner_ssn: false,
      invalid_owner_ssn: false,

      missing_owner_role: false,
      missing_owner_percentage: false,
      invalid_owner_percentage: false,
      missing_owner_through_trust: false,

      missing_owner_street_address: false,
      invalid_owner_street_address: false,
      missing_owner_city: false,
      invalid_owner_city: false,
      missing_owner_state: false,
      missing_owner_zip: false,
      invalid_owner_zip: false,

      missing_owner_mailing_address: false,
      missing_owner_mailing_city: false,
      missing_owner_mailing_state: false,
      missing_owner_mailing_zip: false,
      invalid_owner_mailing_zip: false,

      missing_owner_phone: false,
      invalid_owner_phone: false,
      missing_owner_email: false,
      invalid_owner_email: false,

      missing_owner_resident_status: false,
      missing_owner_years_at_address: false,
      invalid_owner_years_at_address: false,
      missing_owner_months_at_address: false,
      missing_owner_years_in_state: false,
      invalid_owner_years_in_state: false,
      missing_owner_months_in_state: false,
      invalid_owner_months_in_state: false,
      missing_owner_occupancy: false,

      missingOwnerEthnicity: false,
      missingBusinessWomenOwned: false,

      missing_owner_monthly_salary: false,
      missing_owner_bonus: false,
      missing_owner_dividends: false,
      missing_owner_rentals: false,
      missing_owner_gross_income: false,
      invalid_owner_gross_income: false,

      missing_owner_assets_in_trust: false,
      missing_owner_assets_explanation: false,

      missing_asset_type: [],
      missing_asset_institution: [],
      missing_asset_amount: [],
      missing_asset_balance: [],
      missing_loans_on_insurance: [],
      missing_asset_auto_year: [],
      missing_asset_auto_make_model: [],
      missing_asset_monthly_payment: [],
      missing_asset_property_owner: [],
      missing_asset_property_location: [],
      missing_asset_property_rental_income: [],

      errCnt: 0,
      systemErrorMsg: "",

      inputArr: Constants.radioInputValues,
      inputRentArr: Constants.radioInputValuesThree,
      residencyInputArr: Constants.residencyRadioValues,
    }
  },
  async created() {
    console.log("business type: ", this.business_type)
    this.setCurrentPage(1)
    await this.getOwners()
    console.log("id is: ", this.appId, "and owner id is: ", this.ownerId)

    if (this.owner_data.assets_liabilities) {
      for (let j = 0; j < this.owner_data.assets_liabilities.length; j++) {
        this.owner_asset_type.push(this.owner_data.assets_liabilities[j].item_type)
        console.log(`length asset-type: ${this.owner_asset_type.length}`)
        this.owner_asset_institution.push(this.owner_data.assets_liabilities[j].institution_name)
        this.owner_asset_amount.push(`$${this.owner_data.assets_liabilities[j].amount}`)
        this.owner_asset_balance.push(`$${this.owner_data.assets_liabilities[j].balance}`)
        this.owner_loan_on_insurance.push(
          `${this.owner_data.assets_liabilities[j].loans_on_insurance}`
        )
        this.owner_asset_auto_year.push(this.owner_data.assets_liabilities[j].auto_year)
        this.owner_asset_auto_make_model.push(this.owner_data.assets_liabilities[j].auto_make_model)
        this.owner_asset_monthly_payment.push(
          `$${this.owner_data.assets_liabilities[j].monthly_payment}`
        )
        this.owner_asset_property_owner.push(this.owner_data.assets_liabilities[j].property_owner)
        this.owner_asset_property_location.push(
          this.owner_data.assets_liabilities[j].property_location
        )
        this.owner_asset_property_rental_income.push(
          `$${this.owner_data.assets_liabilities[j].rental_income}`
        )
        console.log("number of assets after pushing: ", this.owner_num_assets)
        console.log("owner asset type after creation: ", this.owner_asset_type)
      }
    }
  },
  computed: {
    ...mapGetters("businessCreditOwner", [
      "jwtToken",
      "currentPage",
      "businessOwners",
      "numBusinessOwners",
      "ownerFirstName",
      "ownerLastName",
      "ownerId",
      "appId",
      "companyStructure",
    ]),
    ...mapGetters("questionnaire", ["qid", "business_type"]),
    getEthnicities: {
      get() {
        let ret = ""
        for (let e in this.ethnicities) {
          ret += `${this.ethnicities[e].str},`
        }
        return ret.slice(0, -1)
      },
    },
    company_structure: {
      get() {
        return this.companyStructure
      },
      set(value) {
        this.setCompanyStructure(value)
      },
    },
    owner: {
      get() {
        return this.businessOwners
      },
    },
    ownerFinancialPayload: {
      get() {
        return this.createOwnerFinancialPayload()
      },
    },
    numOwners: {
      get() {
        return this.numBusinessOwners
      },
      set(value) {
        this.setNumBusinessOwners(value)
      },
    },
    todaysDate: {
      get() {
        let today = new Date()
        var dd = String(today.getDate()).padStart(2, "0")
        var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
        var yyyy = today.getFullYear()

        today = mm + "/" + dd + "/" + yyyy
        return today
      },
    },
    ownersHaveErrors: {
      get() {
        if (
          this.missing_owner_first_name ||
          this.invalid_owner_first_name ||
          this.missing_owner_last_name ||
          this.invalid_owner_last_name ||
          this.invalid_owner_middle_name ||
          this.missing_owner_birthday ||
          this.invalid_owner_birthday ||
          this.missing_owner_ssn ||
          this.invalid_owner_ssn ||
          this.missing_owner_role ||
          this.missing_owner_percentage ||
          this.invalid_owner_percentage ||
          this.missing_owner_through_trust ||
          this.missing_owner_street_address ||
          this.invalid_owner_street_address ||
          this.missing_owner_city ||
          this.missing_owner_state ||
          this.missing_owner_zip ||
          this.invalid_owner_zip ||
          this.missing_owner_mailing_address ||
          this.missing_owner_mailing_city ||
          this.missing_owner_mailing_state ||
          this.missing_owner_mailing_zip ||
          this.invalid_owner_mailing_zip ||
          this.missing_owner_phone ||
          this.invalid_owner_phone ||
          this.missing_owner_email ||
          this.invalid_owner_email ||
          this.missing_owner_resident_status ||
          this.missing_owner_years_at_address ||
          this.invalid_owner_years_at_address ||
          this.missing_owner_months_at_address ||
          this.missing_owner_years_in_state ||
          this.invalid_owner_years_in_state ||
          this.missing_owner_months_in_state ||
          this.invalid_owner_months_in_state ||
          this.missing_owner_occupancy ||
          this.missing_owner_monthly_salary ||
          this.missing_owner_bonus ||
          this.missing_owner_dividends ||
          this.missing_owner_rentals ||
          this.missing_owner_gross_income ||
          this.invalid_owner_gross_income ||
          this.missing_owner_assets_in_trust ||
          this.missing_owner_assets_explanation
        )
          return true

        console.log(`ownersHaveErrors() owner is valid checking assets`)

        if (this.ownerAssetsContainErrors()) return true

        console.log(`ownersHaveErrors() assets are valid`)
        return false
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("businessCreditOwner", [
      "setCurrentPage",
      "setBusinessOwners",
      "setCompanyStructure",
      "setValidatedNavPage",
    ]),
    async getOwners() {
      const respVerified = await Api.getCreditAppOwner(
        this.appId,
        this.ownerId,
        localStorage.getItem("token")
      )
      console.log("results of verified API method: ", respVerified)
      if (respVerified && respVerified.status == 200) {
        this.company_structure = respVerified.data.response.business_structure
        this.owner_data = respVerified.data.response.owner
        if (!this.owner_data.is_women_owned) this.owner_data.is_women_owned = ""
        this.formatOwnerDataToString()
      }
    },
    async putPage(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []
      const resp = await Api.putCreditAppOwner(
        this.appId,
        this.ownerId,
        localStorage.getItem("token"),
        payload
      )
      console.log("--- response ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          // sleep for a smooth transition
          this.formatOwnerDataFromString()
          this.setBusinessOwners(this.owner_data)
          this.setValidatedNavPage({ name: "owner-assets", isValid: 1 })

          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Business Owner Signature" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message)
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    formatOwnerDataToString() {
      this.owner_data.percentage_owned = `${this.owner_data.percentage_owned}%`

      this.owner_data.lived_in_serviceable_location_years = `${this.owner_data.lived_in_serviceable_location_years}`
      this.owner_data.lived_in_serviceable_location_months = `${this.owner_data.lived_in_serviceable_location_months}`
      this.owner_data.address_lived_at_years = `${this.owner_data.address_lived_at_years}`
      this.owner_data.address_lived_at_months = `${this.owner_data.address_lived_at_months}`

      this.owner_data.monthly_income = `$${this.owner_data.monthly_income}`
      this.owner_data.bonus_commissions = `$${this.owner_data.bonus_commissions}`
      this.owner_data.rentals = `$${this.owner_data.rentals}`
      this.owner_data.dividends_interest = `$${this.owner_data.dividends_interest}`
      this.owner_data.gross_monthly_income = `$${this.owner_data.gross_monthly_income}`

      // this.owner_data.assets_in_trust = this.owner_data.assets_in_trust ? "yes" : "no"
      // this.owner_data.is_ownership_trust = this.owner_data.is_ownership_trust ? "yes" : "no"
    },
    formatOwnerDataFromString() {
      this.owner_data.percentage_owned = this.formatPercentageFromString(
        this.owner_data.percentage_owned
      )

      this.owner_data.lived_in_serviceable_location_years = parseInt(
        this.owner_data.lived_in_serviceable_location_years
      )
      this.owner_data.lived_in_serviceable_location_months = parseInt(
        this.owner_data.lived_in_serviceable_location_months
      )
      this.owner_data.address_lived_at_years = parseInt(this.owner_data.address_lived_at_years)
      this.owner_data.address_lived_at_months = parseInt(this.owner_data.address_lived_at_months)

      this.owner_data.monthly_income = this.formatMoneyFromString(this.owner_data.monthly_income)
      this.owner_data.bonus_commissions = this.formatMoneyFromString(
        this.owner_data.bonus_commissions
      )
      this.owner_data.rentals = this.formatMoneyFromString(this.owner_data.rentals)
      this.owner_data.dividends_interest = this.formatMoneyFromString(
        this.owner_data.dividends_interest
      )
      this.owner_data.gross_monthly_income = this.formatMoneyFromString(
        this.owner_data.gross_monthly_income
      )

      this.owner_data.assets_in_trust = this.owner_data.assets_in_trust == "yes" ? true : false
      this.owner_data.is_ownership_trust =
        this.owner_data.is_ownership_trust == "yes" ? true : false

      this.owner_data.assets_liabilities = this.ownerFinancialPayload
      this.owner_data.app_id = this.appId
      this.owner_data.owner_id = this.ownerId
    },
    formatPercentageFromString(per) {
      let str = per.replace("%", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formatMoneyFromString(mon) {
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    pushAssetErrors() {
      this.missing_asset_type.push(false)
      this.missing_asset_institution.push(false)
      this.missing_asset_amount.push(false)
      this.missing_asset_balance.push(false)
      this.missing_loans_on_insurance.push(false)
      this.missing_asset_auto_year.push(false)
      this.missing_asset_auto_make_model.push(false)
      this.missing_asset_monthly_payment.push(false)
      this.missing_asset_property_owner.push(false)
      this.missing_asset_property_location.push(false)
      this.missing_asset_property_rental_income.push(false)
    },
    longStateTerritory() {
      switch (this.owner_data.street_address_state) {
        case "HI":
          return "Hawaii"
        case "GU":
          return "Guam"
        case "MP":
          return "Saipan"
        default:
          return ""
      }
    },
    formattedPercentageOwned() {
      let str = this.owner_data.percentage_owned.toString().replace("%", "").replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlySalary() {
      let str = this.owner_data.monthly_income
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlyBonus() {
      let str = this.owner_data.bonus_commissions
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlyDividends() {
      let str = this.owner_data.dividends_interest
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedRentals() {
      let str = this.owner_data.rentals
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedGrossIncome() {
      let str = this.owner_data.gross_monthly_income
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    maskedMoney(str) {
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`)
      return `$${str}`
    },
    formattedPhone() {
      let str = this.owner_data.phone
        .toString()
        .replaceAll("-", "")
        .replaceAll(" ", "")
        .replace("+1", "")
        .replace("(", "")
        .replace(")", "")
      return `+1${str}`
    },

    addAsset() {
      for (var j = 0; j < this.owner_num_assets; j++) {
        this.validateAsset(j)
      }
      if (!this.ownerAssetsContainErrors() && this.owner_asset_type[this.owner_num_assets - 1]) {
        this.pushAssetErrors()
        this.owner_num_assets += 1
        console.log(this.owner_num_assets)
      }
    },
    createOwnerFinancialPayload() {
      let payload = []

      for (let j = 0; j < this.owner_asset_type.length; j++) {
        let item = {
          item_type: this.owner_asset_type[j],
          balance: this.owner_asset_balance[j] ? this.formattedAssetBalance(j) : null,
          institution_name: this.owner_asset_institution[j],
          amount: null,
          monthly_payment:
            this.owner_asset_type[j] == "auto" || this.owner_asset_type[j] == "real-estate"
              ? this.formattedMonthlyPayment(j)
              : null,
          owner_name:
            this.owner_asset_type[j] == "real-estate" ? this.owner_asset_property_owner[j] : "",
          location:
            this.owner_asset_type[j] == "real-estate" ? this.owner_asset_property_location[j] : "",
          rental_income:
            this.owner_asset_type[j] == "real-estate" ? this.formattedRentalIncome(j) : null,
          market_value:
            this.owner_asset_type[j] == "real-estate" ? this.formattedAssetAmount(j) : null,
          year: this.owner_asset_type[j] == "auto" ? parseInt(this.owner_asset_auto_year[j]) : null,
          make_model: this.owner_asset_type[j] == "auto" ? this.owner_asset_auto_make_model[j] : "",
          loans_on_insurance:
            this.owner_asset_type[j] == "life-insurance" ? this.owner_loan_on_insurance[j] : "",
        }
        if (item.item_type == "auto") {
          item.amount = this.formattedAssetAmount(j)
        } else if (item.item_type == "checking" || item.item_type == "savings") {
          item.amount = this.formattedAssetBalance(j)
        }
        payload.push(item)
      }
      return payload
    },
    handleMailingSameCB() {
      let cb = jQuery(`#owner-mailing-same-cb`)
      this.owner_data.mailing_address_same = cb.prop("checked") == true
      if (this.owner_data.mailing_address_same == true) {
        this.missing_owner_mailing_address = false
        this.missing_owner_mailing_city = false
        this.missing_owner_mailing_state = false
        this.missing_owner_mailing_zip = false
      }
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")

      this.validatePage()
      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length
        if (!this.errCnt) {
          const payload = {
            page: 1,
            first_name: this.owner_data.first_name,
            middle_initial: this.owner_data.middle_initial,
            last_name: this.owner_data.last_name,
            email: this.owner_data.email,
            phone: this.formattedPhone(),
            date_of_birth: this.owner_data.date_of_birth,
            company_role: this.owner_data.company_role,
            percentage_owned: this.formattedPercentageOwned(),
            is_ownership_trust: this.owner_data.is_ownership_trust == "yes" ? true : false,
            resident_status: this.owner_data.resident_status,
            address_lived_at_years: parseInt(this.owner_data.address_lived_at_years),
            address_lived_at_months: parseInt(this.owner_data.address_lived_at_months),
            address_ownership: this.owner_data.address_ownership,
            lived_in_serviceable_location_years: parseInt(
              this.owner_data.lived_in_serviceable_location_years
            ),
            lived_in_serviceable_location_months: parseInt(
              this.owner_data.lived_in_serviceable_location_months
            ),
            monthly_income: this.formattedMonthlySalary(this.owner_data.monthly_income),
            bonus_commissions: this.formattedMonthlyBonus(this.owner_data.bonus_commissions),
            dividends_interest: this.formattedMonthlyDividends(this.owner_data.dividends_interest),
            rentals: this.formattedRentals(this.owner_data.rentals),
            gross_monthly_income: this.formattedGrossIncome(this.owner_data.gross_monthly_income),
            assets_in_trust: this.owner_data.assets_in_trust == "yes" ? true : false,
            assets_in_trust_detail: this.owner_assets_explanation,
            assets_liabilities: this.ownerFinancialPayload,

            ssn: this.owner_data.ssn,
            street_address: this.owner_data.street_address,
            street_address_2: this.owner_data.street_address_2,
            street_address_city: this.owner_data.street_address_city,
            street_address_state: this.owner_data.street_address_state,
            street_address_zip: this.owner_data.street_address_zip,
            mailing_address_same: this.owner_data.mailing_address_same,
            mailing_street_address: this.owner_data.mailing_street_address,
            mailing_street_address_2: this.owner_data.mailing_street_address_2,
            mailing_street_address_city: this.owner_data.mailing_street_address_city,
            mailing_street_address_state: this.owner_data.mailing_street_address_state,
            mailing_street_address_zip: this.owner_data.mailing_street_address_zip,

            ethnicity: this.getEthnicities,
            is_women_owned: this.owner_data.is_women_owned,
          }
          console.log(payload)
          this.putPage(payload)
        } else {
          this.scrollToError()
        }
      })
    },
    scrollToError() {
      if (this.errCnt > 1) {
        jQuery("html, body").animate({
          scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
        })
      } else {
        let offset = 0

        if (this.missing_owner_first_name || this.invalid_owner_first_name) {
          this.indexOfError(this.missing_owner_first_name, this.invalid_owner_first_name)
          offset = jQuery(`#business-label-owner-fname`).offset().top
        } else if (this.missing_owner_last_name || this.invalid_owner_last_name) {
          this.indexOfError(this.missing_owner_last_name, this.invalid_owner_last_name)
          offset = jQuery(`#business-label-owner-lname`).offset().top
        } else if (this.invalid_owner_middle_name) {
          this.indexOfError(this.invalid_owner_middle_name)
          offset = jQuery(`#business-label-owner-mi`).offset().top
        } else if (this.missing_owner_birthday || this.invalid_owner_birthday) {
          this.indexOfError(this.missing_owner_birthday, this.invalid_owner_birthday)
          offset = jQuery(`#business-label-owner-dob`).offset().top
        } else if (this.missing_owner_ssn || this.invalid_owner_ssn) {
          this.indexOfError(this.missing_owner_ssn, this.invalid_owner_ssn)
          offset = jQuery(`#business-label-owner-ssn`).offset().top
        } else if (this.missing_owner_role) {
          this.indexOfError(this.missing_owner_role)
          offset = jQuery(`#business-label-owner-role`).offset().top
        } else if (this.missing_owner_percentage || this.invalid_owner_percentage) {
          this.indexOfError(this.missing_owner_percentage, this.invalid_owner_percentage)
          offset = jQuery(`#business-percentage-owned`).offset().top
        } else if (this.missing_owner_through_trust) {
          this.indexOfError(this.missing_owner_through_trust)
          offset = jQuery(`#business-ownership-through-trust`).offset().top
        } else if (this.missing_owner_street_address || this.invalid_owner_street_address) {
          this.indexOfError(this.missing_owner_street_address, this.invalid_owner_street_address)
          offset = jQuery(`#business-label-owner-address`).offset().top
        } else if (this.missing_owner_city || this.invalid_owner_city) {
          this.indexOfError(this.missing_owner_city, this.invalid_owner_city)
          offset = jQuery(`#business-label-owner-city`).offset().top
        } else if (this.missing_owner_state) {
          this.indexOfError(this.missing_owner_state)
          offset = jQuery(`#business-label-owner-state`).offset().top
        } else if (this.missing_owner_zip || this.invalid_owner_zip) {
          this.indexOfError(this.missing_owner_zip, this.invalid_owner_zip)
          offset = jQuery(`#business-label-owner-zipcode`).offset().top
        } else if (this.missing_owner_mailing_address) {
          this.indexOfError(this.missing_owner_mailing_address)
          offset = jQuery(`#business-label-mailing-address`).offset().top
        } else if (this.missing_owner_mailing_city) {
          this.indexOfError(this.missing_owner_mailing_city)
          offset = jQuery(`#business-label-maling-city`).offset().top
        } else if (this.missing_owner_mailing_state) {
          this.indexOfError(this.missing_owner_mailing_state)
          offset = jQuery(`#business-label-mailing-state`).offset().top
        }
        if (this.missing_owner_mailing_zip || this.invalid_owner_mailing_zip) {
          this.indexOfError(this.missing_owner_first_name, this.invalid_owner_mailing_zip)
          offset = jQuery(`#business-label-mailing-zipcode`).offset().top
        } else if (this.missing_owner_phone || this.invalid_owner_phone) {
          this.indexOfError(this.missing_owner_phone, this.invalid_owner_phone)
          offset = jQuery(`#business-label-owner-phone`).offset().top
        } else if (this.missing_owner_email || this.invalid_owner_email) {
          this.indexOfError(this.missing_owner_email, this.invalid_owner_email)
          offset = jQuery(`#business-label-owner-email`).offset().top
        } else if (this.missing_owner_resident_status) {
          this.indexOfError(this.missing_owner_resident_status)
          offset = jQuery(`#business-label-owner-resident-status`).offset().top
        } else if (this.missing_owner_years_at_address || this.invalid_owner_years_at_address) {
          this.indexOfError(
            this.missing_owner_years_at_address,
            this.invalid_owner_years_at_address
          )
          offset = jQuery(`#business-label-address-years`).offset().top
        } else if (this.missing_owner_months_at_address) {
          this.indexOfError(this.missing_owner_months_at_address)
          offset = jQuery(`#business-label-address-months`).offset().top
        } else if (this.missing_owner_years_in_state || this.invalid_owner_years_in_state) {
          this.indexOfError(this.missing_owner_years_in_state, this.invalid_owner_years_in_state)
          offset = jQuery(`#business-label-state-years`).offset().top
        } else if (this.missing_owner_months_in_state || this.invalid_owner_months_in_state) {
          this.indexOfError(this.missing_owner_months_in_state, this.invalid_owner_months_in_state)
          offset = jQuery(`#business-label-state-months`).offset().top
        } else if (this.missing_owner_occupancy) {
          this.indexOfError(this.missing_owner_occupancy)
          offset = jQuery(`#business-label-owner-occupancy`).offset().top
        } else if (this.missing_owner_monthly_salary) {
          this.indexOfError(this.missing_owner_monthly_salary)
          offset = jQuery(`#business-label-monthly-salary`).offset().top
        } else if (this.missing_owner_bonus) {
          this.indexOfError(this.missing_owner_bonus)
          offset = jQuery(`#business-label-monthly-bonus`).offset().top
        } else if (this.missing_owner_dividends) {
          this.indexOfError(this.missing_owner_dividends)
          offset = jQuery(`#business-label-monthly-dividends`).offset().top
        } else if (this.missing_owner_rentals) {
          this.indexOfError(this.missing_owner_rentals)
          offset = jQuery(`#business-label-mothly-rentals`).offset().top
        } else if (this.missing_owner_gross_income || this.invalid_owner_gross_income) {
          this.indexOfError(this.missing_owner_gross_income, this.invalid_owner_gross_income)
          offset = jQuery(`#business-label-monthly-income`).offset().top
        } else if (this.missing_owner_assets_in_trust) {
          this.indexOfError(this.missing_owner_assets_in_trust)
          offset = jQuery(`#business-label-assets-trust`).offset().top
        } else if (this.missing_owner_assets_explanation) {
          this.indexOfError(this.missing_owner_assets_explanation)
          offset = jQuery(`#business-label-asset-explanation`).offset().top
        }
        // assets/liabilities errors
        else {
          if (this.missing_asset_type.includes(true)) {
            this.indexOfError(this.missing_asset_type)
            offset = jQuery(`#business-financial-asset-type`).offset().top
          } else if (this.missing_asset_institution.includes(true)) {
            this.indexOfError(this.missing_asset_institution)
            offset = jQuery(`#business-financial-asset-institution`).offset().top
          } else if (this.missing_asset_amount.includes(true)) {
            this.indexOfError(this.missing_asset_amount)
            offset = jQuery(`#business-financial-asset-amount`).offset().top
          } else if (this.missing_asset_balance.includes(true)) {
            this.indexOfError(this.missing_asset_balance)
            offset = jQuery(`#business-financial-asset-balance`).offset().top
          } else if (this.missing_loans_on_insurance.includes(true)) {
            this.indexOfError(this.missing_loans_on_insurance)
            offset = jQuery(`#business-financial-asset-loan-on-life`).offset().top
          } else if (this.missing_asset_auto_year.includes(true)) {
            this.indexOfError(this.missing_asset_auto_year)
            offset = jQuery(`#business-financial-asset-auto-year`).offset().top
          } else if (this.missing_asset_auto_make_model.includes(true)) {
            this.indexOfError(this.missing_asset_auto_make_model)
            offset = jQuery(`#business-financial-asset-auto-make-model`).offset().top
          } else if (this.missing_asset_monthly_payment.includes(true)) {
            this.indexOfError(this.missing_asset_monthly_payment)
            offset = jQuery(`#business-financial-asset-payment`).offset().top
          } else if (this.missing_asset_property_owner.includes(true)) {
            this.indexOfError(this.missing_asset_property_owner)
            offset = jQuery(`#business-financial-asset-property-owner`).offset().top
          } else if (this.missing_asset_property_location.includes(true)) {
            this.indexOfError(this.missing_asset_property_location)
            offset = jQuery(`#business-financial-asset-property-location`).offset().top
          } else if (this.missing_asset_property_rental_income.includes(true)) {
            this.indexOfError(this.missing_asset_property_rental_income)
            offset = jQuery(`#business-financial-asset-property-income`).offset().top
          }
        }
        jQuery("html, body").animate({ scrollTop: offset })
      }
      // remove processing
      Utils.removeProcessingMask("#ll-continue-btn")
    },
    indexOfError(arr1, arr2) {
      if (arr1 == true) return
      if (arr2) {
        if (arr2 == true) return
      }
    },
    formattedAssetBalance(j) {
      let str = this.owner_asset_balance[j]
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedAssetAmount(j) {
      let str = this.owner_asset_amount[j]
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedMonthlyPayment(j) {
      let str = this.owner_asset_monthly_payment[j]
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    formattedRentalIncome(j) {
      let str = this.owner_asset_property_rental_income[j]
        .toString()
        .replace("$", "")
        .replaceAll(",", "")
        .replaceAll(" ", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    toggleResidentStatus(value) {
      this.owner_data.resident_status = value
      this.validateResidentStatus()
    },
    toggleOwnershipThroughTrust(val) {
      this.owner_data.is_ownership_trust = val
      this.validateOwnershipTrust()
    },
    toggleOccupancy(val) {
      this.owner_data.address_ownership = val
      this.validateOccupancy()
    },
    toggleAssetsInTrust(val) {
      this.owner_data.assets_in_trust = val
      this.validateAssetsInTrust()
    },
    validatePage() {
      this.validateOwner()
    },
    validateOwner() {
      this.validateFName()
      this.validateMI()
      this.validateLName()
      this.validateEmail()
      this.validatePhone()
      if (this.company_structure !== "sole") {
        this.validatePosition()
      }
      this.validateDOB()
      this.validateSSN()
      this.validatePercentageOwned()
      this.validateOwnershipTrust()
      this.validateAddress()
      this.validateCity()
      this.validateState()
      this.validateZipcode()
      if (!this.owner_data.mailing_address_same) {
        this.validateMailingAddress()
        this.validateMailingCity()
        this.validateMailingState()
        this.validateMailingZipcode()
      }
      this.validateOccupancy()
      this.validateYearsAtAddress()
      this.validateMonthsAtAddress()
      this.validateResidentStatus()
      this.validateYearsInState()
      this.validateMonthsInState()
      this.validateMonthlySalary()
      this.validateBonuses()
      this.validateDividends()
      this.validateRentals()
      this.validateAssetsInTrust()
      if (this.owner_data.assets_in_trust) {
        this.validateAssetExplanation()
      }
      for (let j = 0; j < this.owner_num_assets; j++) {
        if (this.owner_asset_type[j]) {
          this.validateAsset(j)
        }
      }
    },
    validateFName() {
      this.owner_data.first_name = this.owner_data.first_name.trim()
      this.missing_owner_first_name = this.owner_data.first_name ? false : true
      if (!this.missing_owner_first_name) {
        this.invalid_owner_first_name = Validator.isValidName(this.owner_data.first_name)
          ? false
          : true
      }
    },
    validateMI() {
      this.owner_data.middle_initial = this.owner_data.middle_initial.trim()
      if (this.owner_data.middle_initial) {
        this.invalid_owner_middle_name = Validator.isValidName(this.owner_data.middle_initial)
          ? false
          : true
      }
    },
    validateLName() {
      this.owner_data.last_name = this.owner_data.last_name.trim()
      this.missing_owner_last_name = this.owner_data.last_name ? false : true
      if (!this.missing_owner_last_name) {
        this.invalid_owner_last_name = Validator.isValidName(this.owner_data.last_name)
          ? false
          : true
      }
    },
    validateEmail() {
      this.owner_data.email = this.owner_data.email.trim()
      this.missing_owner_email = this.owner_data.email ? false : true
      if (!this.missing_owner_email) {
        this.invalid_owner_email = Validator.isValidEmail(this.owner_data.email) ? false : true
      }
    },
    validatePhone() {
      this.missing_owner_phone = this.owner_data.phone ? false : true
      if (!this.missing_owner_phone) {
        this.invalid_owner_phone = Validator.isValidPhoneNumber(this.owner_data.phone)
          ? false
          : true
      }
    },
    validatePosition() {
      this.missing_owner_role = false
      if (!this.owner_data.company_role || this.owner_data.company_role == "") {
        this.missing_owner_role = true
      }
    },
    validateDOB() {
      this.missing_owner_birthday = this.owner_data.date_of_birth ? false : true
      if (!this.missing_owner_birthday) {
        this.invalid_owner_birthday = Validator.isValidDate(this.owner_data.date_of_birth, 18)
          ? false
          : true
      }
    },
    validateSSN() {
      this.missing_owner_ssn = this.owner_data.ssn ? false : true
      if (!this.missing_owner_ssn) {
        // resident status not empty
        this.invalid_owner_ssn = Validator.isValidSSN(this.owner_data.ssn) ? false : true
      }
    },
    validateResidentStatus() {
      this.missing_owner_resident_status = this.owner_data.resident_status ? false : true
    },
    validateAddress() {
      this.owner_data.street_address = this.owner_data.street_address.trim()
      this.missing_owner_street_address = this.owner_data.street_address ? false : true
      if (!this.missing_owner_street_address) {
        const regex = /^[a-zA-Z0-9 -]+$/
        this.invalid_owner_street_address = !this.owner_data.street_address.match(regex)
      }
    },
    validateCity() {
      this.owner_data.street_address_city = this.owner_data.street_address_city.trim()
      this.missing_owner_city = this.owner_data.street_address_city ? false : true

      if (!this.missing_owner_city) {
        const regex = /^[a-zA-Z0-9 -]+$/
        this.invalid_owner_city = !this.owner_data.street_address_city.match(regex)
      }
    },
    validateState() {
      this.missing_owner_state = this.owner_data.street_address_state ? false : true
    },
    validateZipcode() {
      // determine new state
      this.missing_owner_zip = this.owner_data.street_address_zip ? false : true
      if (!this.missing_owner_zip) {
        this.invalid_owner_zip = Validator.isValidZipcode(this.owner_data.street_address_zip)
          ? false
          : true
      }
    },
    validateMailingAddress() {
      this.owner_data.mailing_street_address = this.owner_data.mailing_street_address.trim()
      this.missing_owner_mailing_address = this.owner_data.mailing_street_address ? false : true
    },
    validateMailingCity() {
      this.owner_data.mailing_street_address_city =
        this.owner_data.mailing_street_address_city.trim()
      this.missing_owner_mailing_city = this.owner_data.mailing_street_address_city ? false : true
    },
    validateMailingState() {
      // determine new state
      this.missing_owner_mailing_state = this.owner_data.mailing_street_address_state ? false : true
    },
    validateMailingZipcode() {
      // determine new state
      this.missing_owner_mailing_zip = this.owner_data.mailing_street_address_zip ? false : true
      if (!this.missing_owner_mailing_zip) {
        this.invalid_owner_mailing_zip = Validator.isValidZipcode(
          this.owner_data.mailing_street_address_zip
        )
          ? false
          : true
      }
    },
    validateOccupancy() {
      this.missing_owner_occupancy = this.owner_data.address_ownership ? false : true
    },
    validateYearsAtAddress() {
      this.owner_data.address_lived_at_years = `${this.owner_data.address_lived_at_years}`.trim()
      this.missing_owner_years_at_address = this.owner_data.address_lived_at_years ? false : true
      this.invalid_owner_years_at_address = false
      if (!this.missing_owner_years_at_address) {
        this.invalid_owner_years_at_address = Validator.isValidNumber(
          this.owner_data.address_lived_at_years
        )
          ? false
          : true
        if (
          !this.invalid_owner_years_at_address &&
          this.owner_data.lived_in_serviceable_location_years
        ) {
          this.validateYearsInState()
        }
      }
    },
    validateMonthsAtAddress() {
      this.missing_owner_months_at_address = this.owner_data.address_lived_at_months ? false : true
      if (
        !this.missing_owner_months_at_address &&
        this.owner_data.lived_in_serviceable_location_months
      ) {
        this.validateMonthsInState()
      }
    },
    validateYearsInState() {
      this.owner_data.lived_in_serviceable_location_years =
        this.owner_data.lived_in_serviceable_location_years.trim()
      this.missing_owner_years_in_state = this.owner_data.lived_in_serviceable_location_years
        ? false
        : true
      this.invalid_owner_years_in_state = false
      if (!this.missing_owner_years_in_state) {
        this.invalid_owner_years_in_state = Validator.isValidNumber(
          this.owner_data.lived_in_serviceable_location_years
        )
          ? false
          : true
        if (!this.invalid_owner_years_in_state && this.owner_data.address_lived_at_years) {
          this.invalid_owner_years_in_state =
            parseInt(this.owner_data.lived_in_serviceable_location_years) <
            parseInt(this.owner_data.address_lived_at_years)
              ? true
              : false
        }
      }
    },
    validateMonthsInState() {
      this.missing_owner_months_at_address = this.owner_data.address_lived_at_months ? false : true
      this.invalid_owner_months_in_state = false
      if (
        this.owner_data.address_lived_at_months &&
        this.owner_data.lived_in_serviceable_location_years ==
          this.owner_data.address_lived_at_years
      ) {
        this.invalid_owner_months_in_state =
          parseInt(this.owner_data.address_lived_at_months) >
          parseInt(this.owner_data.lived_in_serviceable_location_months)
            ? true
            : false
      }
    },
    validateMonthlySalary() {
      this.missing_owner_monthly_salary = this.owner_data.monthly_income ? false : true
      if (!this.missing_owner_monthly_salary) {
        if (this.owner_data.gross_monthly_income) {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedGrossIncome() + this.formattedMonthlySalary())
          )
        } else {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedMonthlySalary())
          )
        }
        this.owner_data.gross_monthly_income = this.maskedMoney(this.formattedGrossIncome())
      }
    },
    validateBonuses() {
      this.missing_owner_bonus = this.owner_data.bonus_commissions ? false : true
      if (!this.missing_owner_bonus) {
        if (this.owner_data.gross_monthly_income) {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedGrossIncome() + this.formattedMonthlyBonus())
          )
        } else {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedMonthlyBonus())
          )
        }
        this.owner_data.gross_monthly_income = this.maskedMoney(this.formattedGrossIncome())
      }
    },
    validateDividends() {
      this.missing_owner_dividends = this.owner_data.dividends_interest ? false : true
      if (!this.missing_owner_dividends) {
        if (this.owner_data.gross_monthly_income) {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedGrossIncome() + this.formattedMonthlyDividends())
          )
        } else {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedMonthlyDividends())
          )
        }
        this.owner_data.gross_monthly_income = this.maskedMoney(this.formattedGrossIncome())
      }
    },
    validateRentals() {
      this.missing_owner_rentals = this.owner_data.rentals ? false : true
      if (!this.missing_owner_rentals) {
        if (this.owner_data.gross_monthly_income) {
          this.owner_data.gross_monthly_income = this.maskedMoney(
            String(this.formattedGrossIncome() + this.formattedRentals())
          )
        } else {
          this.owner_data.gross_monthly_income = this.maskedMoney(String(this.formattedRentals()))
        }
        this.owner_data.gross_monthly_income = this.maskedMoney(this.formattedGrossIncome())
      }
    },
    validateAssetsInTrust() {
      this.missing_owner_assets_in_trust = this.owner_data.assets_in_trust ? false : true
    },
    validateAssetExplanation() {
      this.missing_owner_assets_explanation = false
      if (this.owner_data.assets_in_trust == true) {
        this.missing_owner_assets_explanation =
          this.owner_assets_explanation && this.owner_assets_explanation != "" ? false : true
      }
    },
    validatePercentageOwned() {
      this.missing_owner_percentage = this.owner_data.percentage_owned ? false : true
      if (!this.missing_owner_percentage) {
        this.invalid_owner_percentage = parseFloat(this.owner_data.percentage_owned) > 100
      }
    },
    validateOwnershipTrust() {
      this.missing_owner_through_trust = this.owner_data.is_ownership_trust ? false : true
    },

    // Assets
    ownerAssetsContainErrors() {
      if (this.missing_asset_type.includes(true)) return true
      if (this.missing_asset_institution.includes(true)) return true
      if (this.missing_asset_amount.includes(true)) return true
      if (this.missing_asset_balance.includes(true)) return true
      if (this.missing_loans_on_insurance.includes(true)) return true
      if (this.missing_asset_auto_year.includes(true)) return true
      if (this.missing_asset_auto_make_model.includes(true)) return true
      if (this.missing_asset_monthly_payment.includes(true)) return true
      if (this.missing_asset_property_owner.includes(true)) return true
      if (this.missing_asset_property_location.includes(true)) return true
      if (this.missing_asset_property_rental_income.includes(true)) return true
      return false
    },
    validateAsset(j) {
      this.validateAssetType(j)
      if (!this.missing_asset_type[j]) {
        this.validateAssetInstitution(j)
        this.validateAssetBalance(j)
        if (
          this.owner_asset_type[j] == "auto" ||
          this.owner_asset_type[j] == "real-estate" ||
          this.owner_asset_type[j] == "life-insurance"
        ) {
          this.validateAssetAmount(j)
        }
        if (this.owner_asset_type[j] == "auto" || this.owner_asset_type[j] == "real-estate") {
          this.validateMonthlyPayment(j)
        }
        if (this.owner_asset_type[j] == "auto") {
          this.validateAutoYear(j)
          this.validateAutoMakeModel(j)
        }
        if (this.owner_asset_type[j] == "real-estate") {
          this.validatePropertyOwner(j)
          this.validatePropertyLocation(j)
          this.validatePropertyRentalIncome(j)
        }
        if (this.owner_asset_type[j] == "life-insurance") {
          this.validateLoansOnInsurance(j)
        }
      }
    },
    toggleAssetType(j) {
      this.missing_asset_institution[j] = false
      this.missing_asset_amount[j] = false
      this.missing_asset_balance[j] = false
      this.missing_loans_on_insurance[j] = false
      this.missing_asset_auto_year[j] = false
      this.missing_asset_auto_make_model[j] = false
      this.missing_asset_monthly_payment[j] = false
      this.missing_asset_property_owner[j] = false
      this.missing_asset_property_location[j] = false
      this.missing_asset_property_rental_income[j] = false
      this.validateAssetType(j)
    },
    validateAssetType(j) {
      this.missing_asset_type[j] = this.owner_asset_type[j] ? false : true
    },
    validateAssetInstitution(j) {
      this.owner_asset_institution[j] = this.owner_asset_institution[j].trim()
      this.missing_asset_institution[j] = this.owner_asset_institution[j] ? false : true
    },
    validateAssetAmount(j) {
      this.missing_asset_amount[j] = this.owner_asset_amount[j] ? false : true
    },
    validateAssetBalance(j) {
      this.missing_asset_balance[j] = this.owner_asset_balance[j] ? false : true
    },
    validateMonthlyPayment(j) {
      this.missing_asset_monthly_payment[j] = this.owner_asset_monthly_payment[j] ? false : true
    },
    // Auto
    validateAutoYear(j) {
      this.missing_asset_auto_year[j] = this.owner_asset_auto_year[j] ? false : true
    },
    validateAutoMakeModel(j) {
      this.owner_asset_auto_make_model[j] = this.owner_asset_auto_make_model[j].trim()
      this.missing_asset_auto_make_model[j] = this.owner_asset_auto_make_model[j] ? false : true
    },
    // real-estate
    validatePropertyOwner(j) {
      this.owner_asset_property_owner[j] = this.owner_asset_property_owner[j].trim()
      this.missing_asset_property_owner[j] = this.owner_asset_property_owner[j] ? false : true
    },
    validatePropertyLocation(j) {
      this.owner_asset_property_location[j] = this.owner_asset_property_location[j].trim()
      this.missing_asset_property_location[j] = this.owner_asset_property_location[j] ? false : true
    },
    validatePropertyRentalIncome(j) {
      this.missing_asset_property_rental_income[j] = this.owner_asset_property_rental_income[j]
        ? false
        : true
    },
    // life insurace
    validateLoansOnInsurance(j) {
      this.missing_loans_on_insurance[j] = this.owner_loan_on_insurance[j] ? false : true
    },
    validateBusinessWomenOwned() {
      this.missingBusinessWomenOwned = this.owner_data.is_women_owned ? false : true
    },
    validateOwnerEthnicity() {
      for (let e in this.ethnicities) {
        if (this.ethnicities[e].value) {
          this.missingOwnerEthnicity = false
          return
        }
      }
      this.missingOwnerEthnicity = true
    },
    ethnicityChange() {
      let hasEth = false
      for (let e in this.ethnicities) {
        if (e != "ethnicityNA" && this.ethnicities[e].value) {
          if (this.ethnicities.ethnicityNA.value) this.ethnicities.ethnicityNA.value = false
          hasEth = true
        }
      }
      this.missingOwnerEthnicity = hasEth || this.ethnicities.ethnicityNA.value ? false : true
      console.log(this.missingOwnerEthnicity)
    },
    ethnicityChangeNA() {
      let hasEth = false
      for (let e in this.ethnicities) {
        if (e != "ethnicityNA" && this.ethnicities[e].value) {
          if (this.ethnicities.ethnicityNA.value) this.ethnicities[e].value = false
          hasEth = true
        }
      }
      this.missingOwnerEthnicity = hasEth || this.ethnicities.ethnicityNA.value ? false : true
      console.log(this.missingOwnerEthnicity)
    },

    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}

.ll-section {
  text-align: left;
}

.section-title2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5em;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  & + .ll-row {
    margin-top: 2em;
  }
}

.ll-item {
  position: relative;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}

.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// table
table,
tr,
td {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid black;
}

td {
  padding: 5px;
}

.main-table {
  //border: 1px solid black;
}

.inner-table {
  border: 1px solid #bbb;
}

.inner-table-row {
  border: 1px solid #bbb;
}

.table-col1 {
  width: 25%;
}

.table-col2 {
  width: 55%;
  border-left: 1px solid gray;
}

.table-col3 {
  width: 20%;
}

.ethnicity-table,
.ethnicity-table-error {
  border: 1px solid #bbb;
  tr {
    border: unset;
  }
  td {
    width: 50%;
  }
}
.ethnicity-table-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
}
.img-spacing {
  margin: 0 3px;
  vertical-align: middle;
}
</style>
