<template>
  <div class="body-bg">
    <div class="enhanced-security">
      <header class="header-bg">
        <div class="header-container container">
          <a href="https://www.fhb.com/"
            ><img
              class="header-logo"
              srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
              src="@/assets/images/FHBColorLogo-2x.png"
              alt="First Hawaiian Bank home logo"
              title="FHB home"
          /></a>
        </div>
      </header>
      <div role="main">
        <div class="err-container">
          <div role="alert" v-if="otpErrMsg" class="boe-err-msg" id="otp-err-msg">
            <i class="fas fa-exclamation-triangle" style="color: #a1252e"></i>{{ otpErrMsg }}
          </div>
        </div>
        <div class="boe-section-header">
          <img class="center" v-if="sentOTPMethod == 'phone'" :src="chatIcon" alt="chat icon" />
          <img class="center" v-else :src="emailIcon" alt="email icon" />
          <h1 id="enter-verification-code">Enter verification code</h1>
          <p>
            To confirm your identity, we've sent a verification code to the
            <span v-if="sentOTPMethod == 'phone'">mobile number</span
            ><span v-else>email address</span> provided for your account.
          </p>
        </div>
        <div class="boe-form-row">
          <div class="boe-otp-input">
            <OtpInput
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
              required
            />
          </div>
        </div>
        <div class="v-spacer-20" />
        <div class="boe-form-row">
          <button
            id="verify-code-btn"
            class="boe-button"
            :class="{ disabled: isProcessing || !isOTPValid }"
            :disabled="!isOTPValid"
            @click="verifyOTP"
          >
            <span v-if="!isProcessing" id="verifyText">Verify</span>
            <span v-else id="processingText">Processing...</span>
          </button>
        </div>
        <div class="boe-form-row cta-footer">
          <span v-if="!isOTPAttemptsExceeded">
            <a id="resend-code-link" href="#" @click="resendCode">Resend code</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoApi from "@/app/ll-commercial-api"
import OtpInput from "vue3-otp-input"
import { datadogLogs } from "@datadog/browser-logs"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "VerifyCode",
  components: {
    OtpInput,
  },
  computed: mapGetters("businessCreditOwner", [
    "sentOTPMethod",
    "loading",
    "applicationID",
    "ownerId",
    "appId",
    "jwtToken",
    "ownerIsPersonalFi",
  ]),

  data() {
    return {
      chatIcon: require("@/assets/images/chat.svg"),
      emailIcon: require("@/assets/images/email.svg"),
      // sentOTPMethod: 'email',
      isProcessing: false,
      isOTPValid: false,
      isOTPAttemptsExceeded: false,
      otpCode: "",
      // otpValidated: false,
      // otpDeliveryMethods: {},
      otpErrMsg: "",
    }
  },
  created() {
    // this.updateID(this.$route.params.id)
    // this.fetchUserData()
    console.log(this.ownerId, this.appId)
  },
  methods: {
    ...mapActions("businessCreditOwner", [
      "updateSentOTPMethod",
      "updateOtpValidated",
      "updateLoading",
    ]),
    isValidOTP(code) {
      if (code.match(/\d{6}/)) {
        return true
      } else {
        return false
      }
    },
    wrapOTP() {
      let code = ""
      for (let i = 0; i < this.$refs.otpInput.otp.length; i++) {
        code += this.$refs.otpInput.otp[i]
      }
      return code
    },
    handleOnComplete(value) {
      var reg = /^\d+$/

      if (reg.test(value)) {
        this.isOTPValid = true
      } else {
        this.isOTPValid = false
      }
    },
    handleOnChange(value) {
      if (value.length < 6) {
        this.isOTPValid = false
      }
    },
    async verifyOTP() {
      this.isProcessing = true
      this.otpCode = this.wrapOTP()
      console.log("otp code is: ", this.otpCode)

      // validate OTPCode
      if (this.isValidOTP(this.otpCode)) {
        this.otpErrMsg = ""
        // this.loading = true
        this.updateLoading(true)
        const respVerify = await UserInfoApi.putCreditAppOwnerOtp(
          this.appId,
          this.ownerId,
          this.otpCode
        )
        console.log("results of API method: ", respVerify)
        if (respVerify.data.message === "otp_invalid") {
          console.log("Invalid Verification Code.")
          this.otpErrMsg = "Invalid Verification Code."
          this.isProcessing = false
          this.updateLoading(false)
          return
        }
        if (respVerify.data.exceeded_fails) {
          window.location.href = "/exceededfailedattempts"
          return
        }
        if (respVerify.data.message === "otp_exceeded") {
          console.log("OTP verification attempts exceeded.")
          this.otpErrMsg = respVerify.data.response
          this.isOTPAttemptsExceeded = true

          window.location.href = "/exceededfailedattempts"

          // this.updateLoading(false)
          return
        }
        if (respVerify.status >= 500) {
          console.log("network error")
          this.otpErrMsg = "Error verifying OTP Code. Please try again."
          // this.loading = false
          this.isProcessing = false
          this.updateLoading(false)

          datadogLogs.logger.error("enrollFiserUser", respVerify)

          return
        }
        if (respVerify.status === 409) {
          window.location.href = "/expired"
          this.isProcessing = false
          this.updateLoading(false)
          return
        }
        if (respVerify.status > 400) {
          console.log("not found")
          this.otpErrMsg = "Error verifying OTP Code. Please reload the page."
          this.updateLoading(false)
          this.isProcessing = false
          return
        }

        console.log("valid code - token: " + respVerify.data.access_token)
        localStorage.setItem("token", respVerify.data.access_token)
        // this.otpValidated = true
        this.updateOtpValidated(true)

        const respVerified = await UserInfoApi.getCreditAppOwner(
          this.appId,
          this.ownerId,
          respVerify.data.access_token
        )
        const respOwner = respVerified.data.response.owner
        if (
          respOwner == null ||
          respOwner.signature_signed_at !== null ||
          respVerified.data.response.owner_is_filer == true ||
          respOwner.personal_financial_submitted_at != null
        ) {
          this.$router.push({ name: "Business Status" })
        } else if (this.ownerIsPersonalFi) {
          this.$router.push({
            path: "/personalFi/personalfinancial",
            query: { business_credit_id: this.appId, business_credit_owner_id: this.ownerId },
          })
        } else {
          this.$router.push({ name: "Business Owner Assets" })
        }
        // this.loading = false
      } else {
        this.otpErrMsg = "Invalid Verification Code. Please enter 6 digits."
      }
      this.updateLoading(false)
      this.isProcessing = false
    },
    resendCode(evt) {
      evt.preventDefault()
      // this.sentOTPMethod = ''
      // this.sentOTPMethodModel = ''
      // this.otpValidated = false
      this.updateSentOTPMethod("")
      this.updateOtpValidated(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.boe-button:disabled {
  color: $white;
  background-color: #6c757d;
  cursor: not-allowed;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover,
  &:focus {
    background-color: #6c757d;
  }
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.boe-section-header > h1 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
  color: $black;
}

.boe-section-header > p {
  margin: 0.35em 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
  color: $black;
}
.boe-form-row:not(:last-of-type) {
  margin-bottom: 25px;
}
.boe-button {
  width: auto;
  min-width: 15em;
  height: 40px;
  background-color: $red;
  color: $white;
  margin: 0px auto;
  border-radius: 20px;
  border-style: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0.95rem;
  padding: 0.25em 2.5em;
  position: relative;
  line-height: 1.5;
}

.boe-button:hover {
  cursor: pointer;
  background-color: $redHover;
}

.boe-button:focus {
  background-color: $redHover;
}

.boe-err-msg {
  border: 1px solid $red;
  margin: 0.5em 0 1.5em;
  text-align: center;
  color: $redDark;
  font-size: 0.9em;
  background: $redFaint;
  border-radius: 4px;
  /* border: 1px solid #A1252D; */
  padding: 0.5em 1em;
}
.boe-err-msg i {
  margin-right: 10px;
}

.err-container {
  height: auto;
}
.cta-footer {
  margin-top: 12px;
  text-align: center;
  font-size: 0.9rem;
}
.cta-footer a {
  color: $black;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover,
  &:focus {
    color: $grayDark;
  }
}

.otp-input {
  width: 36px;
  height: 50px;
  padding: 5px;
  margin: 0 7.5px;
  font-size: 25px;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input:focus {
  outline: 1px solid #b92543;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.center {
  height: auto;
  width: 65px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header-container a {
  margin: auto;
}

.header-container {
  align-items: center;
  text-align: center;
  display: flex;
  gap: 1em;
  height: 55px;
  flex-wrap: wrap;
  padding: 0.3em 0 0.5em;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    height: 80px;
    gap: 2em;
  }
}

.enhanced-security {
  background-color: $white;
  box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1em 2.5em 2.5em;
  @include media-breakpoint-up(sm) {
    margin: auto;
    max-width: 480px;
  }
  @include media-breakpoint-up(lg) {
    margin: auto;
    max-width: 580px;
  }
}

.body-bg {
  background-color: $orangeFaint;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    padding: 1em 2em;
  }
}
</style>
<style>
.boe-otp-input > div {
  justify-content: center;
  display: block;
  margin: 1em auto 0;
  max-width: 100%;
  column-gap: 0.45em;
}
.boe-otp-input input {
  font-size: 1.2rem;
  min-height: 50px;
  text-align: center;
  width: 3em;
}
@media (max-width: 767.98px) {
  .boe-otp-input input {
    font-size: 1rem;
    min-height: 40px;
    width: 2.5em;
  }
}
</style>
