<template>
  <input
    type="text"
    placeholder="###-##-####"
    maxlength="9"
    :value="modelValue"
    @keydown="validateKeydown"
    @blur="formatInput"
    @focus="removeMask"
  />
</template>

<script>
export default {
  name: "SocialInput",
  props: ["modelValue"],
  data() {
    return {
      raw: "",
      formatted: "",
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() == "firefox") {
          return 224
        }
        return 91
      },
    },
  },
  methods: {
    getBrowser() {
      let agent = navigator.userAgent
      if (agent.match("/chrome|chromium|crios/i")) return "chrome"
      if (agent.match("/firefox|fxios/i")) return "firefox"
      if (agent.match("/safari/i")) return "safari"
      if (agent.match("/edg/i")) return "edge"
    },
    validateKeydown(e) {
      var key = e.key // event.key press
      // if non-number prevent typing
      if (
        !(
          key == "Backspace" ||
          key == "ArrowLeft" ||
          key == "ArrowRight" ||
          key == "Tab" ||
          key == "Delete" ||
          e.keycode == this.cmdKey ||
          e.ctrlKey
        )
      ) {
        if (key.match(`[^0-9cv]+`)) {
          e.preventDefault()
        }
      }
    },
    formatInput(e) {
      // format number
      if (!e.target.value || e.target.value == "") this.formatted = ""
      else this.formatted = this.wearMask(e.target.value.replace(/\D/g, ""))
      this.$emit("update:modelValue", this.formatted)
    },
    wearMask(str) {
      if (str === null || str.length == 0) {
        return ``
      }
      if (str.length > 9) {
        str = str.substring(0, 9)
      }
      return this.addSSNHyphens(str)
    },
    removeMask(e) {
      this.raw = e.target.value.replaceAll("-", "")
      this.$emit("update:modelValue", this.raw)
    },
    addSSNHyphens(str) {
      if (str.length <= 3) {
        return str
      }
      let a = str.substring(0, 3)
      let b = str.substring(3, 5)
      if (str.length <= 5) {
        return `${a}-${b}`
      }
      let c = str.substring(5, 9)
      return `${a}-${b}-${c}`
    },
  },
}
</script>
