import { createRouter, createWebHistory } from "vue-router"
import businessCreditRoutes from "./business_credit_routes"
import businessCreditOwnerRoutes from "./business_credit_owner_routes"
import bankerRoutes from "./connect_to_banker_routes"
import personalFinancialRoutes from "./personal_financial_routes"
import questionnaireRoutes from "./commercial_questionnaire_routes.js"
import bankerSimRoutes from "./connect_to_banker_simplified_routes.js"
// import store from '@/store'

let routes = []

routes = questionnaireRoutes.loadRoutes(routes)
routes = businessCreditRoutes.loadBusinessCreditRoutes(routes)
routes = bankerRoutes.loadBankerRoutes(routes)
routes = bankerSimRoutes.loadBankerSimRoutes(routes)
routes = personalFinancialRoutes.loadPersonalFinancialRoutes(routes)
routes = businessCreditOwnerRoutes.loadBusinessCreditOwnerRoutes(routes)

// Not found route
routes.push({
  path: "/:pathMatch(.*)*",
  name: "Page Not Found",
  component: () => import("../views/PageNotFound.vue"),
})

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// router.beforeEach((to,from) => {
//   console.log('===========================================')
//   console.log("to.path: " + to.path, "from.path: " + from.path)
//   console.log("to.name: " + to.name, "from.name: " + from.name)

//   if (from.path.includes('personal/')) {
//     if (from.path.includes('submitted')) {
//       if (to.path.includes('submit')) {
//         window.alert('Your form is being submitted. No more edits are allowed.')
//         return false
//       }
//     }

//     if (store.getters['personal/in_app_nav_used'] === false) {
//       if (!to.name.includes('Getting Started')) {
//         window.alert('Please use the in-app navigation back button and continue button on the bottom of the page.')
//         return false
//       }

//       const answer = window.confirm('Are you sure you want to leave? You will lose unsaved changes.')
//       if (!answer) return false
//     }
//   }
// })

export default router
