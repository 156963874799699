<template>
  <div class="page-bg">
    <div class="content-container">
      <div class="not-found overlay-content">
        <div class="header">
          <img
            class="header-logo"
            srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
            src="@/assets/images/FHBColorLogo-2x.png"
            alt="First Hawaiian Bank home logo"
          />
        </div>
        <MainId />
        <img
          class="icon"
          srcset="@/assets/images/PageNotFound.png 1x, @/assets/images/PageNotFound-2x.png 2x"
          src="@/assets/images/PageNotFound-2x.png"
        />
        <div class="msg">Page Not Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainId from "@/components/MainId"

export default {
  name: "PageNotFound",
  components: {
    MainId,
  },
  data() {
    return {
      PageNotFound: require("@/assets/images/PageNotFound.svg"),
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.header {
  margin: auto;
  padding: 0 0 2rem;
}
.icon {
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 102px;
}
.msg {
  margin: 0.5em auto;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}
</style>
