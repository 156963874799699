const questionnaireRoutes = {
  loadRoutes(routes) {
    routes.push({
      path: "/business/questionnaire",
      name: "Questionnaire",
      component: () => import("@/views/questionnaire/Questionnaire.vue"),
    })
    return routes
  },
}

export default questionnaireRoutes
