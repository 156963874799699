<template>
  <div class="business-page">
    <Header />
    <Breadcrumb_credit_app />
    <MainId />

    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Tell Us About Your Business</h1>
          <div class="tf-note">
            Required fields are indicated by an asterisk (<span class="fhb-red"
              ><strong>*</strong></span
            >)
          </div>

          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="field-group">
            <!-- Business Name -->
            <div class="ll-item ll-lname">
              <div class="tf-label" id="business-label-legal-name">
                <label for="buscred-bus-legalname-input"
                  >Business Legal Name<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-fname"
                data-test="ll-bc-business-fname"
                type="text"
                id="buscred-bus-legalname-input"
                name="bus-legal-name"
                v-model="legalName"
                aria-describedby="credit-legal-name-note"
                :class="
                  missingLegalName || invalidLegalName ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateLegalName"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingLegalName || invalidLegalName"
                class="form-error-msg"
                data-test="ll-bc-business-fname-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a valid name</div>
              </div>
            </div>
            <div id="credit-legal-name-note" class="tf-note">
              If you're a sole proprietorship or independent contractor and do not have a business
              entity, such as an LLC, you can use your legal name.
            </div>
            <div class="v-spacer-30" />
          </div>

          <div class="field-group">
            <!-- use alt business name -->
            <div class="ll-item ll-consent">
              <label for="business-alt-name-cb" id="business-label-alt-name-cb">
                <input
                  class="cb-consent"
                  type="checkbox"
                  id="business-alt-name-cb"
                  name="alt-name-cb"
                  value="alt-name-cb"
                  @click="toggleAltName()"
                  data-test="ll-bc-business-alt-name-cb"
                  :disabled="this.processing"
                />
                <span> I do business under a different name.</span>
              </label>
            </div>
            <!-- Alt Name -->
            <div v-if="useAltName" class="ll-item ll-lname">
              <div class="v-spacer-10" />
              <div class="tf-label" id="business-label-alt-name">
                <label for="buscred-bus-dba-input"
                  >Doing business as Name<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-lname"
                type="text"
                id="buscred-bus-dba-input"
                name="dba"
                data-test="ll-bc-business-lname"
                v-model="altName"
                :class="missingAltName || invalidAltName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateAltName"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingAltName || invalidAltName"
                class="form-error-msg"
                data-test="ll-bc-business-lname-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a valid name</div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />
          <!-- Nature of Business -->
          <div class="ll-item ll-state">
            <div class="tf-label" id="business-label-business-nature">
              <label for="buscred-bus-nature-input"
                >Nature of Business<sup class="fhb-red">*</sup></label
              >
            </div>
            <DropdownSearch
              v-model="natureOfBusiness"
              :missingSelection="missingBusinessNature"
              id="nature-of-business"
              name="nature-of-business-name"
              :dropdownArray="business_nature"
              dataTest="ll-bc-business-nature"
              @selected="validateBusinessNature"
              ariaDescribe="ll-bc-business-nature-error"
            />
            <div
              v-if="missingBusinessNature"
              class="form-error-msg"
              data-test="ll-bc-business-nature-error"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please select an option</div>
            </div>
            <div class="tf-note-business">
              <p>
                We recommend searching for your industry by typing keywords in the field below.
                Alternatively, you can select using the dropdown. Example keywords might include
                construction, contractor, lawyer, stores, management and restaurants.
              </p>
              <p>
                Names with a "T" at the end indicate trilateral agreement (United States, Canada,
                and Mexico)
              </p>
            </div>
          </div>

          <div class="v-spacer-60" />

          <div class="field-group">
            <!-- Federal Tax ID SSN -->
            <div class="ll-item ll-lname">
              <div class="tf-label" id="business-label-tax-id-ssn">
                <label for="buscred-bus-id-input">
                  {{ this.companyStructure == "sole" ? "SSN" : "Federal Tax ID"
                  }}<sup class="fhb-red">*</sup></label
                >
              </div>
              <input
                maxlength="9"
                class="ll-textfield tf-fname"
                id="buscred-bus-id-input"
                name="ssn"
                data-test="ll-bc-business-taxid-ssn"
                type="tel"
                v-model="taxID"
                :class="missingIDSSN || invalidIDSSN ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateIDSSN"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingIDSSN || invalidIDSSN"
                class="form-error-msg"
                data-test="ll-bc-business-taxid-ssn-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">
                  {{
                    this.companyStructure == "sole"
                      ? "Please enter a valid ID or SSN"
                      : "Please enter a valid ID or EIN"
                  }}
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
          </div>

          <!-- Established Date -->
          <div class="field-group">
            <div class="tf-label" id="business-label-est-date">
              <label for="buscred-bus-estdate-input"
                >Business Established Date<sup class="fhb-red">*</sup></label
              >
            </div>
            <birthday-input
              class="ll-textfield tf-dob"
              id="buscred-bus-estdate-input"
              name="established-date"
              v-model="estDate"
              :class="missingEstDate || invalidEstDate ? 'll-textfield-error' : 'll-textfield'"
              v-on:blur="validateEstDate"
              data-test="ll-bc-business-est-date"
              :disabled="this.processing"
              required
            />
            <div
              v-if="missingEstDate || invalidEstDate"
              class="form-error-msg"
              data-test="ll-bc-business-est-date-error"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please enter a valid date (MM/DD/YYYY)</div>
            </div>
          </div>

          <div class="v-spacer-30" />

          <!-- owned the business duration -->
          <div class="ll-row row-container">
            <div class="ll-row">
              <div class="tf-label" id="business-label-own-years">
                How long have you owned the business?<sup class="fhb-red">*</sup>
              </div>
            </div>
            <div class="ll-row row1-container">
              <div class="ll-item">
                <div class="tf-label">
                  <label for="buscred-bus-yrsowned-input">Years<sup class="fhb-red">*</sup></label>
                </div>
                <input
                  maxlength="3"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="buscred-bus-yrsowned-input"
                  name="years-owned"
                  v-model="ownYears"
                  :class="missingOwnYears ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateOwnYears"
                  data-test="ll-bc-business-own-years"
                  :disabled="this.processing"
                  required
                />
                <div
                  v-if="missingOwnYears"
                  class="form-error-msg"
                  data-test="ll-bc-business-own-years-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter the number of years</div>
                </div>
              </div>

              <div class="ll-item">
                <div class="tf-label" id="business-label-own-months">
                  <label for="buscred-bus-mosowned-input">Months<sup class="fhb-red">*</sup></label>
                </div>
                <select
                  class="ll-select select-addr-months"
                  name="addr_months"
                  id="buscred-bus-mosowned-input"
                  v-model="ownMonths"
                  :class="missingOwnMonths ? 'll-select-error' : 'll-select'"
                  required
                  @change="validateOwnMonths($event)"
                  data-test="ll-bc-business-own-months"
                  :disabled="this.processing"
                >
                  <option value="">Select months</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
                <div
                  v-if="missingOwnMonths"
                  class="form-error-msg"
                  data-test="ll-bc-business-own-months-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select months</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <!-- annual revenue -->
          <div class="ll-row row-container">
            <div class="ll-row row1-container">
              <div class="ll-item" id="business-label-annual-revenue">
                <div class="tf-label">
                  <label for="buscred-bus-anngrossrev-input"
                    >Annual Gross Revenue<sup class="fhb-red">*</sup></label
                  >
                </div>
                <money-input
                  maxlength="12"
                  class="ll-textfield tf-addr-years"
                  type="text"
                  id="buscred-bus-anngrossrev-input"
                  name="annual-gross-revenue"
                  v-model="annualRevenue"
                  :class="missingRevenue ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateAnnualRevenue"
                  data-test="ll-bc-business-revenue"
                  :disabled="this.processing"
                  required
                />
                <div
                  v-if="missingRevenue"
                  class="form-error-msg"
                  data-test="ll-bc-business-revenue-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">
                    Please enter the annual gross revenue for your business
                  </div>
                </div>
              </div>

              <div class="ll-item">
                <div class="tf-label" id="business-label-number-employees">
                  <label for="buscred-bus-noemp-input"
                    >Number of Employees<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="5"
                  class="ll-textfield tf-addr-years"
                  type="tel"
                  id="buscred-bus-noemp-input"
                  name="number-employees"
                  v-model="numEmployees"
                  :class="
                    missingNumEmployees || invalidNumEmployees
                      ? 'll-textfield-error'
                      : 'll-textfield'
                  "
                  v-on:blur="validateNumEmployees"
                  data-test="ll-bc-business-num-employees"
                  :disabled="this.processing"
                  required
                />
                <div
                  v-if="missingNumEmployees || invalidNumEmployees"
                  class="form-error-msg"
                  data-test="ll-bc-business-num-employees-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter number of employees</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <!-- Country of Formation -->
          <div class="ll-item ll-state">
            <div class="tf-label" id="business-label-country">
              <label for="country-formation"
                >Business Country of Formation<sup class="fhb-red">*</sup></label
              >
            </div>
            <select
              class="ll-select select-country"
              name="country-formation"
              id="country-formation"
              v-model="country"
              :class="missingCountry ? 'll-select-error' : 'll-select'"
              required
              @change="validateCountry($event)"
              data-test="ll-bc-business-country"
              :disabled="this.processing"
            >
              <option value="">Select a country</option>
              <option value="United States">United States</option>
              <option value="Japan">Japan</option>
              <option value="Philippines">Philippines</option>
              <option value="Hong Kong (PRC)">Hong Kong (PRC)</option>
              <option value="Canada">Canada</option>
              <option v-for="country in countries" :value="country" v-bind:key="country">
                {{ country }}
              </option>
            </select>
            <div
              v-if="missingCountry"
              class="form-error-msg"
              data-test="ll-bc-business-country-error"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please select a Country</div>
            </div>
          </div>

          <div class="v-spacer-60" />

          <!-- Delinquent taxes -->
          <RadioInputs
            :inputValues="inputArr"
            id="delinquent-taxes-id"
            name="delinquent-taxes-name"
            v-model="delinquentTax"
            legendText="Are any tax payments delinquent?"
            :missingSelection="missingDeliquent"
          />
          <!-- explanation -->
          <div
            class="ll-item yes-explain"
            v-if="delinquentTax == 'Y'"
            id="business-label-delinquent-explanation"
          >
            <div class="tf-label">
              <label for="buscred-bus-taxdexp-input"
                >If yes, please explain<sup class="fhb-red">*</sup></label
              >
            </div>
            <input
              maxlength="512"
              class="ll-textfield tf-fname"
              type="text"
              id="buscred-bus-taxdexp-input"
              name="tax-deliquency-explanation"
              v-model="delinquentExplanation"
              :class="missingDelinquentExplanation ? 'll-textfield-error' : 'll-textfield'"
              :disabled="this.processing"
              v-on:blur="validateDelinquentExplanation()"
              data-test="ll-bc-business-lawsuit-explanation"
              required
            />
            <div v-if="missingDelinquentExplanation" class="form-error-msg">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">
                Please explain the delinquent tax payment.
              </div>
            </div>
          </div>
          <!-- </div> -->

          <div class="v-spacer-40" />

          <!-- Lawsuit or Litigation -->
          <RadioInputs
            :inputValues="inputArr"
            id="lawsuit-or-litigation-id"
            name="lawsuit-or-litigation-name"
            v-model="lawsuit"
            legendText="Is the company or any owner currently involved in any lawsuit or pending litigation?"
            :missingSelection="missingLawsuit"
          />

          <!-- explanation -->
          <div
            class="ll-item yes-explain"
            v-if="lawsuit == 'Y'"
            id="business-label-lawsuit-litigation-explanation"
          >
            <div class="tf-label">
              <label for="buscred-bus-lawsuitexp-input"
                >If yes, please explain<sup class="fhb-red">*</sup></label
              >
            </div>
            <input
              maxlength="512"
              class="ll-textfield tf-fname"
              type="text"
              id="buscred-bus-lawsuitexp-input"
              name="lawsuit-explanation"
              v-model="lawsuitExplanation"
              :class="missingExplanation ? 'll-textfield-error' : 'll-textfield'"
              :disabled="this.processing"
              v-on:blur="validateLawsuitExplanation()"
              data-test="ll-bc-business-lawsuit-explanation"
              required
            />
            <div v-if="missingExplanation" class="form-error-msg">
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">
                Please explain the lawsuit or litigation.
              </div>
            </div>
          </div>
          <!-- </div> -->

          <div class="v-spacer-60" />

          <!-- row 4 : current address, apt-->
          <div class="ll-row row2-container">
            <div class="ll-item ll-address">
              <div class="tf-label" id="business-label-current-address">
                <label for="buscred-bus-stadd-input"
                  >Business Street Address (No P.O. Box Addresses allowed)<sup class="fhb-red"
                    >*</sup
                  ></label
                >
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-address"
                type="text"
                id="buscred-bus-stadd-input"
                v-model="address"
                :class="missingAddress || invalidAddress ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateAddress"
                data-test="ll-bc-business-address"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingAddress || invalidAddress"
                class="form-error-msg"
                data-test="ll-bc-business-address-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter an address</div>
              </div>
            </div>

            <div class="ll-item">
              <div class="tf-label">
                <label for="buscred-bus-unit-input">Apt, Unit, etc.<sup>&nbsp;</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-apt"
                type="text"
                id="buscred-bus-unit-input"
                name="address-unit"
                v-model="aptUnit"
                data-test="ll-bc-business-address2"
                :disabled="this.processing"
              />
            </div>
          </div>

          <div class="v-spacer-30" />

          <!-- row 5 : city, state, zip-->
          <div class="ll-row row3-container">
            <div class="ll-item ll-city">
              <div class="tf-label" id="business-label-current-city">
                <label for="buscred-bus-staddcity-input">City<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-city"
                type="text"
                id="buscred-bus-staddcity-input"
                name="street-address-city"
                v-model="city"
                :class="missingCity || invalidCity ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateCity"
                data-test="ll-bc-business-city"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingCity || invalidCity"
                class="form-error-msg"
                data-test="ll-bc-business-city-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a city</div>
              </div>
            </div>

            <div class="ll-item ll-state">
              <div class="tf-label" id="business-label-current-state">
                <label for="buscred-bus-staddstate-input"
                  >State or Territory<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                class="ll-select select-state"
                id="buscred-bus-staddstate-input"
                name="us_state"
                v-model="state"
                :class="missingState ? 'll-select-error' : 'll-select'"
                required
                @change="validateState($event)"
                data-test="ll-bc-business-state"
                :disabled="this.processing"
              >
                <option value="">Select a state</option>
                <option
                  v-for="us_state in us_states"
                  :value="us_state.value"
                  v-bind:key="us_state.key"
                >
                  {{ us_state.key }}
                </option>
              </select>
              <div
                v-if="missingState"
                class="form-error-msg"
                data-test="ll-bc-business-state-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please select a state</div>
              </div>
            </div>

            <div class="ll-item ll-zipcode">
              <div class="tf-label" id="business-label-current-zipcode">
                <label for="buscred-bus-staddzip-input">ZIP Code<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="12"
                class="ll-textfield tf-zipcode"
                type="text"
                id="buscred-bus-staddzip-input"
                name="zipcode"
                v-model="zipcode"
                :class="missingZipcode || invalidZipcode ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateZipcode"
                data-test="ll-bc-business-zipcode"
                :disabled="this.processing"
                required
              />
              <div
                v-if="missingZipcode || invalidZipcode"
                class="form-error-msg"
                data-test="ll-bc-business-zipcode-error"
              >
                <img
                  class="form-error-icon"
                  srcset="
                    @/assets/images/exclaimation-lg.png    1x,
                    @/assets/images/exclaimation-lg-2x.png 2x
                  "
                  src="@/assets/images/exclaimation-lg-2x.png"
                  alt=""
                />
                <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
              </div>
            </div>
          </div>

          <!-- row 6 : ap - alt mailing address-->
          <div class="v-spacer-30" />
          <div class="ll-row">
            <div class="ll-item ll-consent">
              <label for="alt-mailing-addr-cb">
                <input
                  class="cb-consent"
                  type="checkbox"
                  id="alt-mailing-addr-cb"
                  name="alt-mailing-addr-cb"
                  value="alt-mailing-addr-cb"
                  v-model="mailingSame"
                  @click="handleMailingAddrCB()"
                  data-test="ll-bc-business-mailing-same-cb"
                  :disabled="this.processing"
                />
                <span> Business Mailing Address is the same.</span>
              </label>
            </div>

            <div class="v-spacer-30" />

            <div v-if="!mailingSame">
              <!-- addr, apt -->
              <div class="ll-row row2-container">
                <div class="ll-item ll-address">
                  <div class="tf-label" id="business-label-mailing-address">
                    <label for="buscred-bus-mailadd-input"
                      >Mailing Address (P.O. Box is okay)<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-address"
                    type="text"
                    id="buscred-bus-mailadd-input"
                    name="mailing-address"
                    v-model="mailingAddress"
                    :class="
                      missingMailingAddress || invalidMailingAddress
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateMailingAddress || invalidMailingAddress"
                    data-test="ll-bc-business-alt-address"
                    :disabled="this.processing"
                    required
                  />
                  <div
                    v-if="missingMailingAddress"
                    class="form-error-msg"
                    data-test="ll-bc-business-alt-address-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter an address</div>
                  </div>
                </div>

                <div class="ll-item">
                  <div class="tf-label">
                    <label for="buscred-bus-mailunit-input">Apt, Unit, etc.<sup>&nbsp;</sup></label>
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-apt"
                    type="text"
                    id="buscred-bus-mailadd-input"
                    name="address-unit"
                    v-model="mailingApt"
                    data-test="ll-bc-business-alt-address2"
                    :disabled="this.processing"
                  />
                </div>
              </div>

              <div class="v-spacer-30" />

              <!-- row 6b : city, state, zip-->
              <div class="ll-row row3-container">
                <div class="ll-item ll-city">
                  <div class="tf-label" id="business-label-mailing-city">
                    <label for="buscred-bus-mailcity-input">City<sup class="fhb-red">*</sup></label>
                  </div>
                  <input
                    maxlength="512"
                    class="ll-textfield tf-city"
                    type="text"
                    id="buscred-bus-mailcity-input"
                    name="mailing-city"
                    v-model="mailingCity"
                    :class="
                      missingMailingCity || invalidMailingCity
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateMailingCity"
                    data-test="ll-bc-business-alt-city"
                    :disabled="this.processing"
                    required
                  />
                  <div
                    v-if="missingMailingCity || invalidMailingCity"
                    class="form-error-msg"
                    data-test="ll-bc-business-alt-city-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a city</div>
                  </div>
                </div>

                <div class="ll-item ll-state">
                  <div class="tf-label" id="business-label-mailing-state">
                    <label for="buscred-bus-mailstate-input"
                      >State or Territory<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <select
                    class="ll-select select-state"
                    name="us_state"
                    id="buscred-bus-mailstate-input"
                    v-model="mailingState"
                    :class="missingMailingState ? 'll-select-error' : 'll-select'"
                    required
                    @change="validateMailingState($event)"
                    data-test="ll-bc-business-alt-state"
                    :disabled="this.processing"
                  >
                    <option value="">Select a state</option>
                    <option
                      v-for="us_state in us_states"
                      :value="us_state.value"
                      v-bind:key="us_state.key"
                    >
                      {{ us_state.key }}
                    </option>
                  </select>
                  <div
                    v-if="missingMailingState"
                    class="form-error-msg"
                    data-test="ll-bc-business-alt-state-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please select a state</div>
                  </div>
                </div>

                <div class="ll-item ll-zipcode">
                  <div class="tf-label" id="business-label-mailing-zipcode">
                    <label for="buscred-bus-mailzip-input"
                      >ZIP Code<sup class="fhb-red">*</sup></label
                    >
                  </div>
                  <input
                    maxlength="12"
                    class="ll-textfield tf-zipcode"
                    type="text"
                    id="buscred-bus-mailzip-input"
                    name="zipcode"
                    v-model="mailingZipcode"
                    :class="
                      missingMailingZipcode || invalidMailingZipcode
                        ? 'll-textfield-error'
                        : 'll-textfield'
                    "
                    v-on:blur="validateMailingZipcode"
                    data-test="ll-bc-business-alt-zipcode"
                    :disabled="this.processing"
                    required
                  />
                  <div
                    v-if="missingMailingZipcode || invalidMailingZipcode"
                    class="form-error-msg"
                    data-test="ll-bc-business-alt-zipcode-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Phone Number -->
          <div class="ll-item">
            <div class="tf-label" id="business-label-business-phone">
              <label for="buscred-bus-busphone-input"
                >Business phone Number<sup class="fhb-red">*</sup></label
              >
            </div>
            <div class="ll-prefix-wrap">
              <phone-input
                class="ll-textfield tf-phone"
                id="business-label-business-phone"
                name="business-phone"
                aria-describedby="credit-us-phone-note"
                v-model="phone"
                :class="missingPhone || invalidPhone ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validatePhone"
                data-test="ll-bc-business-phone"
                :disabled="this.processing"
                required
              />
            </div>
            <div id="credit-us-phone-note" class="tf-note">Please provide a U.S. phone number.</div>
            <div
              v-if="missingPhone || invalidPhone"
              class="form-error-msg"
              data-test="ll-bc-business-phone-error"
            >
              <img
                class="form-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg-2x.png"
                alt=""
              />
              <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
            </div>
          </div>

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button
              type="button"
              class="back-btn"
              @click="handleBack()"
              data-test="ll-bc-btn-back"
              :disabled="this.processing"
            >
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              id="ll-bp-continue-btn"
              type="button"
              class="continue-btn"
              @click="handleContinue"
              aria-live="assertive"
              data-test="ll-bc-btn-continue"
              :role="this.processing ? 'status' : 'button'"
              :disabled="this.processing"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import DropdownSearch from "../../../components/CustomInputs/DropdownSearch.vue"
import Constants from "@/app/business_constants"
import Validator from "@/app/validation"
import Header from "@/components/Header"
import Breadcrumb_credit_app from "@/views/business/credit_app/Breadcrumb_credit_app.vue"
import MainId from "@/components/MainId"
import Footer from "@/components/Footer"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import PhoneInput from "@/components/CustomInputs/PhoneInput.vue"
import BirthdayInput from "@/components/CustomInputs/BirthdayInput.vue"
import MoneyInput from "../../../components/CustomInputs/MoneyInput.vue"
import RadioInputs from "../../../components/CustomInputs/RadioInputs.vue"

export default {
  name: "Getting Started",
  components: {
    Header,
    Breadcrumb_credit_app,
    MainId,
    Footer,
    PhoneInput,
    BirthdayInput,
    MoneyInput,
    DropdownSearch,
    RadioInputs,
  },
  data() {
    return {
      business_nature: Constants.NatureOfBusiness6,
      countries: Constants.Countries,
      us_states: Constants.USStates,

      processing: false,
      errCnt: 0,
      systemErrorMsg: "",

      missingLegalName: false,
      invalidLegalName: false,

      missingAltName: false,
      invalidAltName: false,

      missingBusinessNature: false,

      missingIDSSN: false,
      invalidIDSSN: false,

      missingEstDate: false,
      invalidEstDate: false,

      missingOwnYears: false,

      missingOwnMonths: false,

      missingRevenue: false,

      missingNumEmployees: false,
      invalidNumEmployees: false,

      missingCountry: false,

      missingDeliquent: false,
      missingDelinquentExplanation: false,

      missingLawsuit: false,
      missingExplanation: false,

      missingAddress: false,
      invalidAddress: false,

      missingCity: false,
      invalidCity: false,

      missingState: false,

      missingZipcode: false,
      invalidZipcode: false,

      missingMailingAddress: false,
      invalidMailingAddress: false,

      missingMailingCity: false,
      invalidMailingCity: false,

      missingMailingState: false,

      missingMailingZipcode: false,
      invalidMailingZipcode: false,

      missingPhone: false,
      invalidPhone: false,
      inputArr: Constants.radioInputValuesTwo,
    }
  },
  created() {
    this.setCurrentPage(2)
  },
  computed: {
    ...mapGetters("businessCreditApp", [
      "applicationID",
      "jwtToken",
      "currentPage",
      "businessLegalName",
      "businessUseAltName",
      "businessAltName",
      "businessNature",
      "federalTaxID",
      "businessSSN",
      "establishedDate",
      "ownedForYears",
      "ownedForMonths",
      "annualGrossRevenue",
      "numberOfEmployees",
      "countryOfFormation",
      "delinquentTaxPayments",
      "delinquentTaxExplanation",
      "lawsuitOrLitigation",
      "lawsuitLitigationExplanation",
      "businessAddress",
      "businessAptUnit",
      "businessCity",
      "businessStateTerritory",
      "businessZipcode",
      "businessMailingAddressSame",
      "businessMailingAddress",
      "businessMailingAptUnit",
      "businessMailingCity",
      "businessMailingStateTerritory",
      "businessMailingZipcode",
      "businessPhoneNumber",
      "companyStructure",
    ]),
    legalName: {
      get() {
        return this.businessLegalName
      },
      set(value) {
        this.setBusinessLegalName(value)
      },
    },
    useAltName: {
      get() {
        return this.businessUseAltName
      },
      set(value) {
        this.setBusinessUseAltName(value)
      },
    },
    altName: {
      get() {
        return this.businessAltName
      },
      set(value) {
        this.setBusinessAltName(value)
      },
    },
    natureOfBusiness: {
      get() {
        return this.businessNature
      },
      set(value) {
        this.setBusinessNature(value)
        this.validateBusinessNature()
      },
    },
    taxID: {
      get() {
        return this.federalTaxID
      },
      set(value) {
        this.setFederalTaxID(value)
      },
    },
    ssn: {
      get() {
        return this.businessSSN
      },
      set(value) {
        this.setBusinessSSN(value)
      },
    },
    formattedSSN: {
      get() {
        return this.ssn.replaceAll("-", "")
      },
    },
    estDate: {
      get() {
        return this.establishedDate
      },
      set(value) {
        this.setEstablishedDate(value)
      },
    },
    ownYears: {
      get() {
        return this.ownedForYears
      },
      set(value) {
        this.setOwnedForYears(value)
      },
    },
    ownMonths: {
      get() {
        return this.ownedForMonths
      },
      set(value) {
        this.setOwnedForMonths(value)
      },
    },
    annualRevenue: {
      get() {
        return this.annualGrossRevenue
      },
      set(value) {
        this.setAnnualGrossRevenue(value)
      },
    },
    formattedRevenue: {
      get() {
        let rev = this.annualRevenue.replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")
        let float = parseFloat(rev)
        let ret = +float
        return ret
      },
    },
    numEmployees: {
      get() {
        return this.numberOfEmployees
      },
      set(value) {
        this.setNumberOfEmployees(value)
      },
    },
    country: {
      get() {
        return this.countryOfFormation
      },
      set(value) {
        this.setCountryOfFormation(value)
      },
    },
    delinquentTax: {
      get() {
        return this.delinquentTaxPayments
      },
      set(value) {
        this.setDelinquentTaxPayments(value)
      },
    },
    lawsuit: {
      get() {
        return this.lawsuitOrLitigation
      },
      set(value) {
        this.setLawsuitOrLitigation(value)
      },
    },
    lawsuitExplanation: {
      get() {
        return this.lawsuitLitigationExplanation
      },
      set(value) {
        this.setLawsuitLitigationExplanation(value)
      },
    },
    delinquentExplanation: {
      get() {
        return this.delinquentTaxExplanation
      },
      set(value) {
        this.setDelinquentTaxExplanation(value)
      },
    },
    address: {
      get() {
        return this.businessAddress
      },
      set(value) {
        this.setBusinessAddress(value)
      },
    },
    aptUnit: {
      get() {
        return this.businessAptUnit
      },
      set(value) {
        this.setBusinessAptUnit(value)
      },
    },
    city: {
      get() {
        return this.businessCity
      },
      set(value) {
        this.setBusinessCity(value)
      },
    },
    state: {
      get() {
        return this.businessStateTerritory
      },
      set(value) {
        this.setBusinessStateTerritory(value)
      },
    },
    zipcode: {
      get() {
        return this.businessZipcode
      },
      set(value) {
        this.setBusinessZipcode(value)
      },
    },
    mailingSame: {
      get() {
        return this.businessMailingAddressSame
      },
      set(value) {
        this.setBusinessMailingAddressSame(value)
      },
    },
    mailingAddress: {
      get() {
        return this.businessMailingAddress
      },
      set(value) {
        this.setBusinessMailingAddress(value)
      },
    },
    mailingApt: {
      get() {
        return this.businessMailingAptUnit
      },
      set(value) {
        this.setBusinessMailingAptUnit(value)
      },
    },
    mailingCity: {
      get() {
        return this.businessMailingCity
      },
      set(value) {
        this.setBusinessMailingCity(value)
      },
    },
    mailingState: {
      get() {
        return this.businessMailingStateTerritory
      },
      set(value) {
        this.setBusinessMailingStateTerritory(value)
      },
    },
    mailingZipcode: {
      get() {
        return this.businessMailingZipcode
      },
      set(value) {
        this.setBusinessMailingZipcode(value)
      },
    },
    phone: {
      get() {
        return this.businessPhoneNumber
      },
      set(value) {
        this.setBusinessPhoneNumber(value)
      },
    },
    formattedPhone: {
      get() {
        return this.phone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", "")
      },
    },
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 })
  },
  methods: {
    ...mapActions("businessCreditApp", [
      "setCurrentPage",
      "setValidatedNavPage",
      "setBusinessLegalName",
      "setBusinessUseAltName",
      "setBusinessAltName",
      "setBusinessNature",
      "setFederalTaxID",
      "setBusinessSSN",
      "setEstablishedDate",
      "setOwnedForYears",
      "setOwnedForMonths",
      "setAnnualGrossRevenue",
      "setNumberOfEmployees",
      "setCountryOfFormation",
      "setDelinquentTaxPayments",
      "setDelinquentTaxExplanation",
      "setLawsuitOrLitigation",
      "setLawsuitLitigationExplanation",
      "setBusinessAddress",
      "setBusinessAptUnit",
      "setBusinessCity",
      "setBusinessStateTerritory",
      "setBusinessZipcode",
      "setBusinessMailingAddressSame",
      "setBusinessMailingAddress",
      "setBusinessMailingAptUnit",
      "setBusinessMailingCity",
      "setBusinessMailingStateTerritory",
      "setBusinessMailingZipcode",
      "setBusinessPhoneNumber",
    ]),
    async putPage(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.putCreditAppPage(this.applicationID, payload, this.jwtToken)
      console.log("--- response ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          //update breadcrumb state
          this.setValidatedNavPage({ name: "business", isValid: 1 })

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Banking Relations" })

          Utils.removeProcessingMask("#ll-bp-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message)
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-bp-continue-btn")
          this.processing = false
        }
      }
    },
    toggleAltName() {
      let cb = jQuery("#business-alt-name-cb")
      this.useAltName = cb.prop("checked") == true
    },
    toggleDelinquentTax(val) {
      this.delinquentTax = val
      this.validateDelinquentTax()
    },
    toggleLawsuit(val) {
      this.lawsuit = val
      this.validateLawsuit()
    },
    handleMailingAddrCB() {
      let cb = jQuery("#alt-mailing-addr-cb")
      this.mailingSame = cb.prop("checked") == true
    },
    handleBack() {
      this.$router.push({ name: "Filer Info" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-bp-continue-btn")
      this.processing = true

      this.validatePage()

      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length

        if (!this.errCnt) {
          const payload = {
            // "application_id": this.applicationID,
            page: this.currentPage,
            // "business": {
            business_legal_name: this.legalName,
            business_different_name_indicator: this.useAltName,
            business_different_name: this.useAltName ? this.altName : "",
            business_nature: this.natureOfBusiness.value,

            federal_tax_id: this.taxID, // if not sole prop
            // "ssn": this.formattedSSN, 		// if sole prop

            business_established_date: this.estDate,
            business_owned_years: parseInt(this.ownYears),
            business_owned_months: parseInt(this.ownMonths),
            annual_gross_revenue: this.formattedRevenue,
            number_of_employees: parseInt(this.numEmployees),

            business_country_of_formation: this.country,
            tax_payment_delinquent: this.delinquentTax == "Y" ? true : false,
            delinquent_explanation: this.delinquentExplanation ? this.delinquentExplanation : "",
            litigation_indicator: this.lawsuit == "Y" ? true : false,
            litigation_explanation: this.lawsuit ? this.lawsuitExplanation : "",

            business_street_address: this.address,
            business_street_address_2: this.aptUnit,
            business_city: this.city,
            business_state: this.state,
            business_zip: this.zipcode,

            business_mailing_same: this.mailingSame,
            business_mailing_street_address: this.mailingSame ? this.address : this.mailingAddress,
            business_mailing_street_address_2: this.mailingSame ? this.aptUnit : this.mailingApt,
            business_mailing_city: this.mailingSame ? this.city : this.mailingCity,
            business_mailing_state: this.mailingSame ? this.state : this.mailingState,
            business_mailing_zip: this.mailingSame ? this.zipcode : this.mailingZipcode,

            business_phone_number: this.formattedPhone,
            // }
          }
          console.log(payload)
          this.putPage(payload)
        } else {
          this.setValidatedNavPage({ name: "business", isValid: 0 })
          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missingLegalName || this.invalidLegalName)
              offset = jQuery("#business-label-legal-name").offset().top
            if (this.missingAltName || this.invalidAltName)
              offset = jQuery("#business-label-alt-name").offset().top
            if (this.missingBusinessNature)
              offset = jQuery("#business-label-business-nature").offset().top
            if (this.missingIDSSN || this.invalidIDSSN)
              offset = jQuery("#business-label-tax-id-ssn").offset().top
            if (this.missingEstDate || this.invalidEstDate)
              offset = jQuery("#business-label-est-date").offset().top
            if (this.missingOwnYears) offset = jQuery("#business-label-own-years").offset().top
            if (this.missingOwnMonths) offset = jQuery("#business-label-own-months").offset().top
            if (this.missingRevenue) offset = jQuery("#business-label-annual-revenue").offset().top
            if (this.missingNumEmployees || this.invalidNumEmployees)
              offset = jQuery("#business-label-number-employees").offset().top
            if (this.missingCountry) offset = jQuery("#business-label-country").offset().top
            if (this.missingDeliquent) offset = jQuery("#delinquent-taxes-id").offset().top
            if (this.missingDeliquentExplanation)
              offset = jQuery("#business-label-delinquent-explanation").offset().top
            if (this.missingLawsuit)
              offset = jQuery("#business-label-lawsuit-litigation").offset().top
            if (this.missingExplanation)
              offset = jQuery("#business-label-lawsuit-litigation-explanation").offset().top
            if (this.missingAddress || this.invalidAddress)
              offset = jQuery("#business-label-current-address").offset().top
            if (this.missingCity || this.invalidCity)
              offset = jQuery("#business-label-current-city").offset().top
            if (this.missingState) offset = jQuery("#business-label-current-state").offset().top
            if (this.missingZipcode || this.invalidZipcode)
              offset = jQuery("#business-label-current-zipcode").offset().top
            if (this.missingMailingAddress || this.invalidMailingAddress)
              offset = jQuery("#business-label-mailing-address").offset().top
            if (this.missingMailingCity || this.invalidMailingCity)
              offset = jQuery("#business-label-mailing-city").offset().top
            if (this.missingMailingState)
              offset = jQuery("#business-label-mailing-state").offset().top
            if (this.missingMailingZipcode || this.invalidMailingZipcode)
              offset = jQuery("#business-label-mailing-zipcode").offset().top
            if (this.missingPhone || this.invalidPhone)
              offset = jQuery("#business-label-business-phone").offset().top

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-bp-continue-btn")
          this.processing = false
        }
      })
    },
    validatePage() {
      this.validateLegalName()
      this.validateAltName()
      this.validateBusinessNature()
      this.validateIDSSN()
      this.validateEstDate()
      this.validateOwnYears()
      this.validateOwnMonths()
      this.validateAnnualRevenue()
      this.validateNumEmployees()
      this.validateCountry()
      this.validateDelinquentTax()
      this.validateDelinquentExplanation()
      this.validateLawsuit()
      this.validateLawsuitExplanation()
      this.validateAddress()
      this.validateCity()
      this.validateState()
      this.validateZipcode()
      if (!this.mailingSame) {
        this.validateMailingAddress()
        this.validateMailingCity()
        this.validateMailingState()
        this.validateMailingZipcode()
      }
      this.validatePhone()
    },
    validateLegalName() {
      this.legalName = this.legalName.trim()
      this.missingLegalName = this.legalName ? false : true
      if (!this.missingLegalName) {
        this.invalidLegalName = Validator.isValidName(this.legalName) ? false : true
      }
    },
    validateAltName() {
      this.altName = this.altName.trim()
      this.missingAltName = false
      this.invalidAltName = false
      if (this.useAltName) {
        this.missingAltName = this.altName ? false : true
        if (!this.missingAltName) {
          this.invalidAltName = Validator.isValidName(this.altName) ? false : true
        }
      }
    },
    validateBusinessNature() {
      this.missingBusinessNature = this.natureOfBusiness ? false : true
    },
    validateIDSSN() {
      this.ssn = this.ssn.trim()
      this.taxID = this.taxID.trim()
      this.missingIDSSN = this.taxID || this.ssn ? false : true
      if (!this.missingIDSSN) {
        this.invalidIDSSN = this.taxID ? this.taxID.length != 9 : this.ssn.length != 9
      }
    },
    validateEstDate() {
      this.missingEstDate = !this.estDate || this.estDate == ""
      if (!this.missingEstDate) {
        this.invalidEstDate = false
        if (!Validator.isValidDate(this.estDate)) {
          this.invalidEstDate = true
          return
        }
        try {
          const date = Date.parse(this.estDate)
          if (date > Date.now()) {
            this.invalidEstDate = true
            return
          }
        } catch (err) {
          console.log(err)
          this.invalidEstDate = true
          return
        }
      }
    },
    validateOwnYears() {
      this.ownYears = this.ownYears.trim()
      this.missingOwnYears = this.ownYears ? false : true
    },
    validateOwnMonths() {
      this.missingOwnMonths = this.ownMonths ? false : true
    },
    validateAnnualRevenue() {
      this.missingRevenue = this.annualRevenue ? false : true
    },
    validateNumEmployees() {
      if (this.numEmployees) {
        this.numEmployees = this.numEmployees.trim()
      }
      this.missingNumEmployees = !this.numEmployees || this.numEmployees == ""

      if (!this.missingNumEmployees) {
        this.invalidNumEmployees = Validator.isValidNumber(this.numEmployees) ? false : true
      }
    },
    validateCountry() {
      this.missingCountry = this.country ? false : true
    },
    validateDelinquentTax() {
      this.missingDeliquent = this.delinquentTax == ""
    },
    validateDelinquentExplanation() {
      this.missingDeliquentExplanation = false
      if (this.delinquentTax == "Y") {
        this.missingDeliquentExplanation = this.delinquentExplanation.replaceAll(" ", "") == ""
      }
    },
    validateLawsuit() {
      this.missingLawsuit = this.lawsuit == ""
    },
    validateLawsuitExplanation() {
      this.missingExplanation = false
      if (this.lawsuitOrLitigation == "Y") {
        this.missingExplanation = this.lawsuitExplanation.replaceAll(" ", "") == ""
      }
    },
    validateAddress() {
      this.address = this.address.trim()
      this.missingAddress = this.address && this.address != "" ? false : true
      if (!this.missingAddress) {
        const regex = /^[a-zA-Z0-9 -]+$/
        this.invalidAddress = !this.address.match(regex)
      }
      if (!this.missingAddress) this.invalidAddress = Validator.containsPOBox(this.address)
    },
    validateCity() {
      this.city = this.city.trim()
      this.missingCity = !this.city

      const regex = /^[a-zA-Z0-9 -]*$/
      if (!this.missingCity) {
        this.invalidCity = !this.city.match(regex)
      }
    },
    validateState() {
      this.missingState = this.state ? false : true
    },
    validateZipcode() {
      this.zipcode = this.zipcode.trim()
      this.missingZipcode = this.zipcode ? false : true
      if (!this.missingZipcode) {
        this.invalidZipcode = Validator.isValidZipcode(this.zipcode) ? false : true
      }
    },
    validateMailingAddress() {
      this.mailingAddress = this.mailingAddress.trim()
      this.missingMailingAddress = this.mailingAddress ? false : true
      if (!this.missingMailingAddress) {
        const regex = /^[a-zA-Z0-9 -]+$/
        this.invalidMailingAddress = !this.mailingAddress.match(regex)
      }
    },
    validateMailingCity() {
      this.mailingCity = this.mailingCity.trim()
      this.missingMailingCity = this.mailingCity ? false : true

      const regex = /^[a-zA-Z0-9 -]*$/
      if (!this.invalidMailingCity) {
        this.invalidMailingCity = !this.mailingCity.match(regex)
      }
    },
    validateMailingState() {
      this.missingMailingState = this.mailingState ? false : true
    },
    validateMailingZipcode() {
      this.missingMailingZipcode = this.mailingZipcode ? false : true
      if (!this.missingMailingZipcode) {
        this.invalidMailingZipcode = Validator.isValidZipcode(this.mailingZipcode) ? false : true
      }
    },
    validatePhone() {
      this.missingPhone = this.phone ? false : true
      if (!this.missingPhone) {
        this.invalidPhone = Validator.isValidPhoneNumber(this.phone) ? false : true
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
    dropdownShouldOpen(VueSelect) {
      if (this.businessNature !== null) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  & + .ll-row {
    margin-top: 2em;
  }
}

.ll-item {
  position: relative;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;

  .form-error-msg {
    width: 100%;
  }
}

.toggle-btn-container > * {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: unset;
  min-width: 10em;
  width: calc(50% - 0.6em);
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .toggle-btn-txt {
  text-align: center;
}
.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.row2-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  > div + div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.style {
  box-sizing: border-box;
  border-radius: 3px;
  --vs-border-color: #b0b0b0;
  --vs-line-height: 2.5;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.ll-select-error-business-nature {
  background: $white;
  border: 1px solid $red;
}

.tf-note-business {
  margin-top: 4px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.tf-address {
  min-width: 300px;
  max-width: unset;
}

.yn-question {
  display: block;

  @include media-breakpoint-up(md) {
    display: flex;
  }
  .tf-label {
    flex-basis: 50%;
  }

  .toggle-btn-container {
    flex: 1;

    @include media-breakpoint-down(md) {
      button {
        flex-basis: calc(50% - 0.6em);
      }
    }
  }
  .yes-explain {
    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
    padding-left: 2em;
    width: 100%;
  }
}

.question-label {
  align-self: center;
}

.field-group + .field-group {
  margin-top: 0.5em;
}
</style>
