export default {
  namespaced: true,
  state() {
    return {
      count: 0,
      currentPage: 0,
      pageInProgress: 0,
      debug: false,

      nav: {
        validated_personal_page: 0,
        validated_business_page: 0,
        validated_document_page: 0,
      },
      code: "",
      code_type: "",
      application_id: "",
      jwt_token: "",

      submission_id: "",

      type: "commerical-loan",

      //1. Personal information
      applicant: {
        loan_amount: "",
        id: "",
        email: "",
        fname: "",
        middle_initial: "",
        lname: "",
        phone_num: "",
        company_position: "",
        preferred_banker_indicator: false,
        preferred_banker_name: "",
        agree_to_terms: "",
      },

      business_delinquent_tax: "",
      business_delinquent_tax_indicator: false,
      business_lawsuit: "",
      business_lawsuit_indicator: false,
      business_legal_name: "",
      business_alt_name_indicator: false,
      business_alt_name: "",
      business_nature: null,
      business_company_structure: "",
      business_tax_ID: "",
      business_date: "",
      business_years_owned: null,
      business_months_owned: null,
      business_annual_gross_revenue: null,
      business_number_of_employees: "",
      business_country_formation: "",
      business_lawsuit_reason: "",
      business_adress: "",
      business_address2: "",
      business_city: "",
      business_state: "",
      business_zipcode: "",
      business_alt_mailing_address_indicator: false,
      business_mailing_address: "",
      business_mailing_address2: "",
      business_mailing_city: "",
      business_mailing_state: "",
      business_mailing_zipcode: "",
      business_phone_number: "",
      business_census_tract: "",

      //DocumentUploadSetup: [],
      document_upload_setup: [],
      //AdditionalDocumentUploadSetup: [],
      additional_document_upload_setup: [],

      //UploadedDocuments: {},
      uploaded_documents: {},
      uploaded_additional_documents: {},

      uploaded_additional_files: [],
      uploaded_files: [],

      banker_sim: "",
      banker_sim_indicator: false,
      banker_sim_email: "",
      loan_request: "",
      bus_change: "",
      bus_change_indicator: false,
      bus_change_description: "",
    }
  },

  getters: {
    debug: state => state.debug,
    currentPage: state => state.currentPage,
    pageInProgress: state => state.pageInProgress,
    application_id: state => state.application_id,
    jwt_token: state => state.jwt_token,
    type: state => state.type,
    code: state => state.code,
    code_type: state => state.code_type,

    submission_id: state => state.submission_id,

    // nav
    nav_validated_personal_page: state => state.nav.validated_personal_page,
    nav_validated_business_page: state => state.nav.validated_business_page,
    nav_validated_document_page: state => state.nav.validated_document_page,

    email: state => state.email,

    loan_amount: state => state.applicant.loan_amount,
    applicant_id: state => state.applicant.id,
    applicant_fname: state => state.applicant.fname,
    applicant_middle_initial: state => state.applicant.middle_initial,
    applicant_lname: state => state.applicant.lname,
    applicant_email: state => state.applicant.email,
    applicant_phone_num: state => state.applicant.phone_num,
    applicant_company_position: state => state.applicant.company_position,
    applicant_preferred_banker_indicator: state => state.applicant.preferred_banker_indicator,
    applicant_preferred_banker_name: state => state.applicant.preferred_banker_name,
    applicant_agree_to_terms: state => state.applicant.agree_to_terms,

    business_delinquent_tax: state => state.business_delinquent_tax,
    business_delinquent_tax_indicator: state => state.business_delinquent_tax_indicator,
    business_lawsuit: state => state.business_lawsuit,
    business_lawsuit_indicator: state => state.business_lawsuit_indicator,
    business_legal_name: state => state.business_legal_name,
    business_alt_name: state => state.business_alt_name,
    business_alt_name_indicator: state => state.business_alt_name_indicator,
    business_nature: state => state.business_nature,
    business_company_structure: state => state.business_company_structure,
    business_tax_ID: state => state.business_tax_ID,
    business_date: state => state.business_date,
    business_months_owned: state => state.business_months_owned,
    business_years_owned: state => state.business_years_owned,
    business_annual_gross_revenue: state => state.business_annual_gross_revenue,
    business_number_of_employees: state => state.business_number_of_employees,
    business_country_formation: state => state.business_country_formation,
    business_lawsuit_reason: state => state.business_lawsuit_reason,
    business_address: state => state.business_address,
    business_address2: state => state.business_address2,
    business_city: state => state.business_city,
    business_state: state => state.business_state,
    business_zipcode: state => state.business_zipcode,
    business_alt_mailing_address_indicator: state => state.business_alt_mailing_address_indicator,
    business_mailing_address: state => state.business_mailing_address,
    business_mailing_address2: state => state.business_mailing_address2,
    business_mailing_city: state => state.business_mailing_city,
    business_mailing_state: state => state.business_mailing_state,
    business_mailing_zipcode: state => state.business_mailing_zipcode,
    business_phone_number: state => state.business_phone_number,
    business_census_tract: state => state.business_census_tract,
    document_upload_setup: state => state.document_upload_setup,
    additional_document_upload_setup: state => state.additional_document_upload_setup,
    uploaded_documents: state => state.uploaded_documents,
    uploaded_files: state => state.uploaded_files,
    uploaded_additional_files: state => state.uploaded_additional_files,
    uploaded_additional_documents: state => state.uploaded_additional_documents,

    banker_sim: state => state.banker_sim,
    banker_sim_indicator: state => state.banker_sim_indicator,
    banker_sim_email: state => state.banker_sim_email,
    loan_request: state => state.loan_request,
    bus_change: state => state.bus_change,
    bus_change_indicator: state => state.bus_change_indicator,
    bus_change_description: state => state.bus_change_description,
  },
  actions: {
    resetAll({ commit }) {
      commit("setCurrentPage", 0)
      commit("resetPageInProgress", 0)
      commit("setValidatedNavPage", { name: "personal", isValid: 0 })

      commit("setType", "commerical-loan")
      commit("setCode", "")
      commit("setCodeType", "")
      commit("setApplicationID", "")
      commit("setJWTToken", "")
      commit("setSubmissionID", "")

      // applicant
      commit("setLoanAmount", "")
      commit("setAppEmail", "")
      commit("setAppID", "")
      commit("setAppFName", "")
      commit("setAppMiddleInitial", "")
      commit("setAppLName", "")
      commit("setAppPhoneNum", "")
      commit("setAppEmail", "")
      commit("setAppCompanyPosition", "")
      commit("setAppBankerIndicator", "")
      commit("setAppPreferredBankerName", "")
      commit("setAppAgreeToTerms", "")

      commit("setDelinquentTaxPayment", "")
      commit("setDelinquentTaxPaymentIndicator", "")
      commit("setLawsuit", "")
      commit("setLawsuitIndicator", "")
      commit("setBusinessLegalName", "")
      commit("setBusinessAltName", "")
      commit("setBusinessAltNameIndicator", "")
      commit("setBusinessNature", "")
      commit("setBusinessCompanyStructure", "")
      commit("setBusinessTaxID", "")
      commit("setBusinessDate", "")
      commit("setBusinessYearsOwned", "")
      commit("setBusinessMonthsOwned", "")
      commit("setBusinessAnnualGrossRevenue", "")
      commit("setBusinessNumberOfEmployees", "")
      commit("setBusinessCountryFormation", "")
      commit("setBusinessLawsuitReason", "")
      commit("setBusinessAddress", "")
      commit("setBusinessAddress2", "")
      commit("setBusinessCity", "")
      commit("setBusinessState", "")
      commit("setBusinessZipcode", "")
      commit("setBusinessAltMailingAddressIndicator", "")
      commit("setBusinessMailingAddress", "")
      commit("setBusinessMailingAddress2", "")
      commit("setBusinessMailingCity", "")
      commit("setBusinessMailingState", "")
      commit("setBusinessMailingZipcode", "")
      commit("setBusinessPhoneNumber", "")
      commit("setBusinessCensusTract", "")
      commit("setDocumentUpload", [])
      commit("setAdditionalDocumentUpload", [])
      commit("setUploadedFiles", [])
      commit("setUploadedAdditionalFiles", [])

      commit("setBankerSim", "")
      commit("setBankerSimIndicator", "")
      commit("setBankerSimEmail", "")
      commit("setLoanRequest", "")
      commit("setBusChange", "")
      commit("setBusChangeIndicator", "")
      commit("setBusChangeDescription", "")
    },
    setType({ commit }, type) {
      commit("setType", type)
    },
    setSubmissionID({ commit }, id) {
      commit("setSubmissionID", id)
    },
    setCurrentPage({ commit }, currentPage) {
      commit("setCurrentPage", currentPage)
      commit("setPageInProgress", currentPage)
    },
    setPageInProgress({ commit }, page) {
      commit("setPageInProgress", page)
    },
    resetPageInProgress({ commit }, page) {
      commit("resetPageInProgress", page)
    },
    setValidatedNavPage({ commit }, page) {
      commit("setValidatedNavPage", page)
    },
    setApplicationID({ commit }, value) {
      commit("setApplicationID", value)
    },
    setJWTToken({ commit }, value) {
      commit("setJWTToken", value)
    },
    setCode({ commit }, value) {
      commit("setCode", value)
    },
    setCodeType({ commit }, value) {
      commit("setCodeType", value)
    },

    // applicant
    setLoanAmount({ commit }, value) {
      commit("setLoanAmount", value)
    },
    setAppID({ commit }, value) {
      commit("setAppID", value)
    },
    setAppFName({ commit }, value) {
      commit("setAppFName", value)
    },
    setAppMiddleInitial({ commit }, value) {
      commit("setAppMiddleInitial", value)
    },
    setAppLName({ commit }, value) {
      commit("setAppLName", value)
    },
    setAppPhoneNum({ commit }, value) {
      commit("setAppPhoneNum", value)
    },
    setAppEmail({ commit }, value) {
      commit("setAppEmail", value)
    },
    setAppCompanyPosition({ commit }, value) {
      commit("setAppCompanyPosition", value)
    },
    setAppBankerIndicator({ commit }, value) {
      commit("setAppBankerIndicator", value)
    },
    setAppPreferredBankerName({ commit }, value) {
      commit("setAppPreferredBankerName", value)
    },
    setAppAgreeToTerms({ commit }, value) {
      commit("setAppAgreeToTerms", value)
    },
    setDelinquentTaxPayment({ commit }, value) {
      commit("setDelinquentTaxPayment", value)
    },
    setDelinquentTaxPaymentIndicator({ commit }, value) {
      commit("setDelinquentTaxPaymentIndicator", value)
    },
    setLawsuit({ commit }, value) {
      commit("setLawsuit", value)
    },
    setLawsuitIndicator({ commit }, value) {
      commit("setLawsuitIndicator", value)
    },
    setBusinessLegalName({ commit }, value) {
      commit("setBusinessLegalName", value)
    },
    setBusinessAltNameIndicator({ commit }, value) {
      commit("setBusinessAltNameIndicator", value)
    },
    setBusinessAltName({ commit }, value) {
      commit("setBusinessAltName", value)
    },
    setBusinessNature({ commit }, value) {
      commit("setBusinessNature", value)
    },
    setBusinessCompanyStructure({ commit }, value) {
      commit("setBusinessCompanyStructure", value)
    },
    setBusinessTaxID({ commit }, value) {
      commit("setBusinessTaxID", value)
    },
    setBusinessDate({ commit }, value) {
      commit("setBusinessDate", value)
    },
    setBusinessYearsOwned({ commit }, value) {
      commit("setBusinessYearsOwned", value)
    },
    setBusinessMonthsOwned({ commit }, value) {
      commit("setBusinessMonthsOwned", value)
    },
    setBusinessAnnualGrossRevenue({ commit }, value) {
      commit("setBusinessAnnualGrossRevenue", value)
    },
    setBusinessNumberOfEmployees({ commit }, value) {
      commit("setBusinessNumberOfEmployees", value)
    },
    setBusinessCountryFormation({ commit }, value) {
      commit("setBusinessCountryFormation", value)
    },
    setBusinessLawsuitReason({ commit }, value) {
      commit("setBusinessLawsuitReason", value)
    },
    setBusinessAddress({ commit }, value) {
      commit("setBusinessAddress", value)
    },
    setBusinessAddress2({ commit }, value) {
      commit("setBusinessAddress2", value)
    },
    setBusinessCity({ commit }, value) {
      commit("setBusinessCity", value)
    },
    setBusinessState({ commit }, value) {
      commit("setBusinessState", value)
    },
    setBusinessZipcode({ commit }, value) {
      commit("setBusinessZipcode", value)
    },
    setBusinessAltMailingAddressIndicator({ commit }, value) {
      commit("setBusinessAltMailingAddressIndicator", value)
    },
    setBusinessMailingAddress({ commit }, value) {
      commit("setBusinessMailingAddress", value)
    },
    setBusinessMailingAddress2({ commit }, value) {
      commit("setBusinessMailingAddress2", value)
    },
    setBusinessMailingCity({ commit }, value) {
      commit("setBusinessMailingCity", value)
    },
    setBusinessMailingState({ commit }, value) {
      commit("setBusinessMailingState", value)
    },
    setBusinessMailingZipcode({ commit }, value) {
      commit("setBusinessMailingZipcode", value)
    },
    setBusinessPhoneNumber({ commit }, value) {
      commit("setBusinessPhoneNumber", value)
    },
    setBusinessCensusTract({ commit }, value) {
      commit("setBusinessCensusTract", value)
    },
    setDocumentUpload({ commit }, value) {
      commit("setDocumentUpload", value)
    },
    setAdditionalDocumentUpload({ commit }, value) {
      commit("setAdditionalDocumentUpload", value)
    },
    setUploadedDocuments({ commit }, value) {
      commit("setUploadedDocuments", value)
    },
    setUploadedDocumentsInit({ commit }, value) {
      commit("setUploadedDocumentsInit", value)
    },
    setUploadedDocumentsReset({ commit }, value) {
      commit("setUploadedDocumentsReset", value)
    },
    setUploadedDocumentsReason({ commit }, value) {
      commit("setUploadedDocumentsReason", value)
    },
    setUploadedDocumentsUploadAttempt({ commit }, value) {
      commit("setUploadedDocumentsUploadAttempt", value)
    },
    setUploadedDocumentsUploadFailed({ commit }, value) {
      commit("setUploadedDocumentsUploadFailed", value)
    },
    setUploadedDocumentsUploadValid({ commit }, value) {
      commit("setUploadedDocumentsUploadValid", value)
    },
    setUploadedFiles({ commit }, value) {
      commit("setUploadedFiles", value)
    },

    setUploadedAdditionalFiles({ commit }, value) {
      commit("setUploadedAdditionalFiles", value)
    },
    setUploadedAdditionalDocuments({ commit }, value) {
      commit("setUploadedAdditionalDocuments", value)
    },
    setUploadedAdditionalDocumentsInit({ commit }, value) {
      commit("setUploadedAdditionalDocumentsInit", value)
    },
    setUploadedAdditionalDocumentsReset({ commit }, value) {
      commit("setUploadedAdditionalDocumentsReset", value)
    },
    setUploadedAdditionalDocumentsReason({ commit }, value) {
      commit("setUploadedAdditionalDocumentsReason", value)
    },
    setUploadedAdditionalDocumentsUploadAttempt({ commit }, value) {
      commit("setUploadedAdditionalDocumentsUploadAttempt", value)
    },
    setUploadedAdditionalDocumentsUploadFailed({ commit }, value) {
      commit("setUploadedAdditionalDocumentsUploadFailed", value)
    },
    setUploadedAdditionalDocumentsUploadValid({ commit }, value) {
      commit("setUploadedAdditionalDocumentsUploadValid", value)
    },

    setBankerSim({ commit }, value) {
      commit("setBankerSim", value)
    },
    setBankerSimIndicator({ commit }, value) {
      commit("setBankerSimIndicator", value)
    },
    setBankerSimEmail({ commit }, value) {
      commit("setBankerSimEmail", value)
    },
    setLoanRequest({ commit }, value) {
      commit("setLoanRequest", value)
    },
    setBusChange({ commit }, value) {
      commit("setBusChange", value)
    },
    setBusChangeIndicator({ commit }, value) {
      commit("setBusChangeIndicator", value)
    },
    setBusChangeDescription({ commit }, value) {
      commit("setBusChangeDescription", value)
    },
  },
  mutations: {
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    resetPageInProgress(state, value) {
      state.pageInProgress = value
    },
    setPageInProgress(state, value) {
      if (value > state.pageInProgress) {
        state.pageInProgress = value
      }
    },
    setValidatedNavPage(state, page) {
      switch (page.name) {
        case "personal":
          state.nav.validated_personal_page = page.isValid
          break
        case "business":
          state.nav.validated_business_page = page.isValid
          break
        case "document":
          state.nav.validated_document_page = page.isValid
          break
      }
    },
    setApplicationID(state, value) {
      state.application_id = value
    },
    setJWTToken(state, value) {
      state.jwt_token = value
    },
    setType(state, value) {
      state.type = value
    },
    setCode(state, value) {
      state.code = value
    },
    setCodeType(state, value) {
      state.code_type = value
    },
    setSubmissionID(state, value) {
      state.submission_id = value
    },

    // applicant
    setLoanAmount(state, value) {
      state.applicant.loan_amount = value
    },
    setAppID(state, value) {
      state.applicant.id = value
    },
    setAppFName(state, value) {
      state.applicant.fname = value
    },
    setAppMiddleInitial(state, value) {
      state.applicant.middle_initial = value
    },
    setAppLName(state, value) {
      state.applicant.lname = value
    },
    setAppPhoneNum(state, value) {
      state.applicant.phone_num = value
    },
    setAppEmail(state, value) {
      state.applicant.email = value
    },
    setAppCompanyPosition(state, value) {
      state.applicant.company_position = value
    },
    setAppBankerIndicator(state, value) {
      state.applicant.preferred_banker_indicator = value
    },
    setAppPreferredBankerName(state, value) {
      state.applicant.preferred_banker_name = value
    },
    setAppAgreeToTerms(state, value) {
      state.applicant.agree_to_terms = value
    },

    setDelinquentTaxPayment(state, value) {
      state.business_delinquent_tax = value
    },
    setDelinquentTaxPaymentIndicator(state, value) {
      state.business_delinquent_tax_indicator = value
    },
    setLawsuit(state, value) {
      state.business_lawsuit = value
    },
    setLawsuitIndicator(state, value) {
      state.business_lawsuit_indicator = value
    },
    setBusinessLegalName(state, value) {
      state.business_legal_name = value
    },
    setBusinessAltNameIndicator(state, value) {
      state.business_alt_name_indicator = value
    },
    setBusinessAltName(state, value) {
      state.business_alt_name = value
    },
    setBusinessNature(state, value) {
      state.business_nature = value
    },
    setBusinessCompanyStructure(state, value) {
      state.business_company_structure = value
    },
    setBusinessTaxID(state, value) {
      state.business_tax_ID = value
    },
    setBusinessDate(state, value) {
      state.business_date = value
    },
    setBusinessYearsOwned(state, value) {
      state.business_years_owned = value
    },
    setBusinessMonthsOwned(state, value) {
      state.business_months_owned = value
    },
    setBusinessAnnualGrossRevenue(state, value) {
      state.business_annual_gross_revenue = value
    },
    setBusinessNumberOfEmployees(state, value) {
      state.business_number_of_employees = value
    },
    setBusinessCountryFormation(state, value) {
      state.business_country_formation = value
    },
    setBusinessLawsuitReason(state, value) {
      state.business_lawsuit_reason = value
    },
    setBusinessAddress(state, value) {
      state.business_address = value
    },
    setBusinessAddress2(state, value) {
      state.business_address2 = value
    },
    setBusinessCity(state, value) {
      state.business_city = value
    },
    setBusinessState(state, value) {
      state.business_state = value
    },
    setBusinessZipcode(state, value) {
      state.business_zipcode = value
    },
    setBusinessAltMailingAddressIndicator(state, value) {
      state.business_alt_mailing_address_indicator = value
    },
    setBusinessMailingAddress(state, value) {
      state.business_mailing_address = value
    },
    setBusinessMailingAddress2(state, value) {
      state.business_mailing_address2 = value
    },
    setBusinessMailingCity(state, value) {
      state.business_mailing_city = value
    },
    setBusinessMailingState(state, value) {
      state.business_mailing_state = value
    },
    setBusinessMailingZipcode(state, value) {
      state.business_mailing_zipcode = value
    },
    setBusinessPhoneNumber(state, value) {
      state.business_phone_number = value
    },
    setBusinessCensusTract(state, value) {
      state.business_census_tract = value
    },
    setDocumentUpload(state, value) {
      state.document_upload_setup = value
    },

    setAdditionalDocumentUpload(state, value) {
      state.additional_document_upload_setup = value
    },
    setUploadedDocuments(state, value) {
      state.uploaded_documents[value.index] = {}
      state.uploaded_documents[value.index].file = value.file
      state.uploaded_documents[value.index].hovered = false
      state.uploaded_documents[value.index].url = value.url
    },
    setUploadedDocumentsInit(state, value) {
      state.uploaded_documents[value] = {}
    },
    setUploadedDocumentsReset(state, value) {
      state.uploaded_documents[value.index] = null
    },
    setUploadedDocumentsReason(state, value) {
      state.uploaded_documents[value.index].reason = value.reason
    },
    setUploadedDocumentsUploadAttempt(state, value) {
      state.uploaded_documents[value.index].uploadAttempt = value.value
    },
    setUploadedDocumentsUploadFailed(state, value) {
      state.uploaded_documents[value.index].uploadFailed = value.value
    },
    setUploadedDocumentsUploadValid(state, value) {
      state.uploaded_documents[value.index].valid = value.value
    },
    setUploadedFiles(state, value) {
      state.uploaded_files = value
    },
    setUploadedAdditionalFiles(state, value) {
      state.uploaded_additional_files = value
    },
    setUploadedAdditionalDocuments(state, value) {
      state.uploaded_additional_documents[value.index] = {}
      state.uploaded_additional_documents[value.index].file = value.file
      state.uploaded_additional_documents[value.index].hovered = false
      state.uploaded_additional_documents[value.index].url = value.url
    },
    setUploadedAdditionalDocumentsInit(state, value) {
      state.uploaded_additional_documents[value] = {}
    },
    setUploadedAdditionalDocumentsReset(state, value) {
      state.uploaded_additional_documents[value.index] = null
    },
    setUploadedAdditionalDocumentsReason(state, value) {
      state.uploaded_additional_documents[value.index].reason = value.reason
    },
    setUploadedAdditionalDocumentsUploadAttempt(state, value) {
      state.uploaded_additional_documents[value.index].uploadAttempt = value.value
    },
    setUploadedAdditionalDocumentsUploadFailed(state, value) {
      state.uploaded_additional_documents[value.index].uploadFailed = value.value
    },
    setUploadedAdditionalDocumentsUploadValid(state, value) {
      state.uploaded_additional_documents[value.index].valid = value.value
    },
    setBankerSim(state, value) {
      state.banker_sim = value
    },
    setBankerSimIndicator(state, value) {
      state.banker_sim_indicator = value
    },
    setBankerSimEmail(state, value) {
      state.banker_sim_email = value
    },
    setLoanRequest(state, value) {
      state.loan_request = value
    },
    setBusChange(state, value) {
      state.bus_change = value
    },
    setBusChangeIndicator(state, value) {
      state.bus_change_indicator = value
    },
    setBusChangeDescription(state, value) {
      state.bus_change_description = value
    },
  },
}
