//import auth from './auth_routes.js'
import store from "@/store"

//const jwtISS = 'fhb-loans-commercial'

const bankerSimRoutes = {
  getToken() {
    return store.getters["connectToBanker/jwt_token"]
  },
  getRedirect() {
    return "/connectToBankerSimplified/banker" // TODO change to Banker Getting Started when available
  },
  loadBankerSimRoutes(routes) {
    routes.push(
      {
        path: "/connectToBankerSimplified/banker",
        name: "Connect Banker Simplified",
        component: () =>
          import("../views/business/connect_to_banker_simplified/Connect_banker_simplified.vue"),
      },
      {
        path: "/connectToBankerSimplified/submitted",
        name: "Connect To Banker Simplified Submitted",
        component: () => import("../views/business/connect_to_banker_simplified/Submitted.vue"),
      }
    )
    return routes
  },
}

export default bankerSimRoutes
