<template>
  <div class="page-bg">
    <div class="content-container submitted-container">
      <div class="overlay-content submitted">
        <div class="overlay-header submitted-header">
          <img
            class="submitted-logo"
            srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
            src="@/assets/images/FHBColorLogo-2x.png"
            alt="First Hawaiian Bank home logo"
          />
        </div>
        <MainId />
        <div role="main">
          <div class="overlay-splash submitted-splash">
            <img
              srcset="@/assets/images/submitted.png 1x, @/assets/images/submitted-2x.png 2x"
              src="@/assets/images/submitted-2x.png"
              alt=""
            />
          </div>
          <div class="overlay-title submitted-title">
            <h1>Application received!</h1>
          </div>
          <div class="overlay-body submitted-body">
            Thank you for submitting your application. Your application has been received and logged
            into our secure database. We will review it during the next 3 to 5 business days.
          </div>
          <div class="overlay-body submitted-body">
            <h2>Submission ID:</h2>
            <p class="submission-id">{{ this.submission_id }}</p>
          </div>
          <div class="v-spacer-20" />
          <div class="link-container">
            <a class="return-fhb-link-btn" href="http://fhb.com">Return to fhb.com</a>
          </div>
        </div>

        <div class="v-spacer-40" />

        <h2 class="submitted-txt-sm">
          <strong>Got questions?</strong>
        </h2>
        <div class="submitted-txt-sm">
          Please email <a href="mailto:businessbanker@fhb.com">businessbanker@fhb.com</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainId from "@/components/MainId"
import { mapGetters } from "vuex"

export default {
  name: "PersonalFi Submitted",
  components: {
    MainId,
  },
  computed: {
    ...mapGetters("personalFiStatement", ["submission_id"]),
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
/* html, body {
   height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;
   background: #FDF8F4;
  opacity: 0.95;
} */
.page-bg {
  background-color: $orangeFaint;
  height: 100%;
  min-height: 100vh;
}
a {
  color: $black;
  text-decoration: none;
}
.submitted-splash img {
  height: auto;
  width: 70px;

  @include media-breakpoint-up(md) {
    width: 97px;
  }
}
.submitted-title {
  margin-top: 10px;
  @include media-breakpoint-up(md) {
    margin-top: 25px;
  }
  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
    font-size: 1.6rem;
    margin: 0;
  }
}
.submitted-body {
  font-size: 1rem;
  margin-top: 0;
  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
    margin-top: 15px;
  }

  h2 {
    font-size: 1.3rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }
}
.submitted-subtitle {
  font-size: 1.1rem;
  margin-top: 0.25em;
}
.submitted-txt-sm {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin: 0.15em auto;
}
.appid-txt {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
}
.spinner img {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
@-moz-keyframes spin {
  80% {
    -moz-transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes spin {
  80% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes spin {
  80% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.overlay-splash {
  margin: 2em auto 0;
  position: relative;
  width: 80px;
  z-index: 2;
}
.overlay-splash + .overlay-title {
  margin-bottom: 10px;
  margin-top: 25px;
}
.overlay-splash .spinner {
  height: 80px;
  position: absolute;
  top: 4px;
  width: 80px;
  z-index: 10;
}
.overlay-splash .logo {
  z-index: 1;
  position: relative;
}
.overlay-splash .spinner img {
  height: auto;
  width: 80px;
}
.overlay-splash .logo img {
  height: auto;
  width: 60px;
  animation: fadeIn 5s linear infinite;
  -webkit-animation: fadeIn 5s linear infinite;
  -moz-animation: fadeIn 5s linear infinite;
  -o-animation: fadeIn 5s linear infinite;
  -ms-animation: fadeIn 5s linear infinite;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.overlay-body {
  position: relative;
  z-index: 40;
}
</style>
