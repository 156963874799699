<template>
  <div class="personal-info">
    <Header v-bind:type="type" />
    <Breadcrumb_business />
    <MainId />
    <div class="ll-body container" role="main">
      <table v-if="debug" class="dump">
        <tr>
          <td>errCnt:</td>
          <td>{{ errCnt }}</td>
        </tr>
      </table>

      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Connect to Banker</div>

          <h1 class="section-title">Tell Us About Yourself</h1>
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img
                class="page-error-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="field-group">
            <!-- Loan Amount -->
            <div class="ll-item ll-fname">
              <div class="tf-label" id="business-label-loan-amount">
                <label for="cb-business-loan-amount-input"
                  >Requested Loan Amount<sup class="fhb-red">*</sup></label
                >
              </div>
              <money-input
                maxlength="512"
                class="ll-textfield tf-fname"
                data-test="ll-bc-filer-fname"
                type="text"
                name="cb-business-loan-amount-input"
                id="cb-business-loan-amount-input"
                aria-describedby="connect-loanamt-err"
                v-model="loanAmt"
                :class="
                  missingLoanAmount || invalidLoanAmount ? 'll-textfield-error' : 'll-textfield'
                "
                v-on:blur="validateLoanAmount"
                required
              />
              <div id="connect-loanamt-err" class="ind-err-container">
                <div
                  v-if="missingLoanAmount || invalidLoanAmount"
                  class="form-error-msg"
                  data-test="ll-bc-filer-fname-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a Loan Amount</div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <div class="ll-row row1-container">
              <!-- First Name -->
              <div class="ll-item ll-fname">
                <div class="tf-label" id="business-label-app-fname">
                  <label for="cb-business-fname-input"
                    >First Name<sup class="fhb-red">*</sup></label
                  >
                </div>
                <input
                  maxlength="512"
                  class="ll-textfield tf-fname"
                  data-test="cb-app-fname"
                  type="text"
                  name="cb-business-fname-input"
                  id="cb-business-fname-input"
                  aria-describedby="cb-bus-fname-err"
                  v-model="appFName"
                  :class="missingFName || invalidFName ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateFName"
                  required
                />
                <div id="cb-bus-fname-err" class="ind-err-container">
                  <div
                    v-if="missingFName || invalidFName"
                    class="form-error-msg"
                    data-test="cb-app-fname-error"
                  >
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
              </div>
              <!-- Middle Initial -->
              <div class="ll-item ll-middle-initial">
                <div class="tf-label" id="business-label-app-mi">
                  <label for="cb-business-mi-input">MI</label>
                </div>
                <input
                  maxlength="1"
                  class="ll-textfield tf-middle-initial"
                  data-test="cb-app-mi"
                  type="text"
                  name="cb-business-mi-input"
                  id="cb-business-mi-input"
                  aria-describedby="cb-bus-mi-err"
                  v-model="appMI"
                  :class="appMI && invalidMName ? 'll-textfield-error' : 'll-textfield'"
                  @input="validateMI"
                />
                <div id="cb-bus-mi-err" class="ind-err-container">
                  <div v-if="appMI && invalidMI" class="form-error-msg" data-test="cb-app-mi-error">
                    <img
                      class="form-error-icon"
                      srcset="
                        @/assets/images/exclaimation-lg.png    1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      "
                      src="@/assets/images/exclaimation-lg-2x.png"
                      alt=""
                    />
                    <div role="alert" class="form-error-txt">
                      Please enter a valid middle initial
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-spacer-30" />
            <!-- Last Name -->
            <div class="ll-item ll-lname">
              <div class="tf-label" id="business-label-app-lname">
                <label for="cb-business-lname">Last Name<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                class="ll-textfield tf-lname"
                type="text"
                data-test="cb-app-lname"
                name="cb-business-lname"
                id="cb-business-lname"
                aria-describedby="cb-bus-lname-err"
                v-model="appLName"
                :class="missingLName || invalidLName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateLName"
                required
              />
              <div id="cb-bus-lname-err" class="ind-err-container">
                <div
                  v-if="missingLName || invalidLName"
                  class="form-error-msg"
                  data-test="cb-app-lname-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid last name</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />
          <div class="ll-row row2-container">
            <!-- Email -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-app-email">
                <label for="cb-business-email">Email<sup class="fhb-red">*</sup></label>
              </div>
              <input
                maxlength="512"
                data-test="cb-email"
                class="ll-textfield"
                type="text"
                name="cb-business-email"
                id="cb-business-email"
                aria-describedby="cb-email-use cb-bus-email-err"
                v-model="email"
                :class="missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateEmail"
                required
              />
              <div id="cb-email-use" class="tf-note">
                To contact you about your inquiry and to send you special offers/information. You
                may opt out of marketing communications at any time.
              </div>
              <div id="cb-bus-email-err" class="ind-err-container">
                <div
                  v-if="missingEmail || invalidEmail"
                  class="form-error-msg"
                  data-test="cb-email-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid email</div>
                </div>
              </div>
            </div>
            <!-- Phone Number -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-app-phone">
                <label for="cb-business-phone"
                  >Mobile Phone Number<sup class="fhb-red">*</sup></label
                >
              </div>
              <div class="ll-prefix-wrap">
                <phone-input
                  class="ll-textfield tf-phone"
                  v-model="appPhone"
                  id="cb-business-phone"
                  name="cb-business-phone"
                  aria-describedby="ctb-us-phone-hint cb-bus-phone-err"
                  :class="missingPhone || invalidPhone ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validatePhone"
                  data-test="cb-app-phone"
                  required
                />
              </div>
              <div class="tf-note" id="ctb-us-phone-hint">Please provide a U.S. phone number.</div>
              <div id="cb-bus-phone-err" class="ind-err-container">
                <div
                  v-if="missingPhone || invalidPhone"
                  class="form-error-msg"
                  data-test="cb-app-phone-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />
          <!-- Company Structure -->
          <div class="ll-row row2-container">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-company-structure">
                <label for="cb-business-company-structure"
                  >What is your company structure?<sup class="fhb-red">*</sup></label
                >
              </div>
              <select
                class="ll-select"
                name="company_position"
                id="cb-business-company-structure"
                aria-describedby="cb-bus-structure-err"
                v-model="businessCompanyStructure"
                :class="missingBusinessCompanyStructure ? 'll-select-error' : 'll-select'"
                @change="validateBusinessCompanyStructure($event)"
                data-test="cb-business-structure"
                required
              >
                <option value="">Select an option</option>
                <option v-for="opt in company_structures" :value="opt.value" v-bind:key="opt.key">
                  {{ opt.key }}
                </option>
              </select>
              <div id="cb-bus-structure-err" class="ind-err-container">
                <div
                  v-if="missingBusinessCompanyStructure"
                  class="form-error-msg"
                  data-test="cb-business-structure-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select an option</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <!-- Company Position -->
          <div class="row2-container">
            <div
              v-if="
                this.businessCompanyStructure &&
                this.businessCompanyStructure !== 'sole' &&
                this.businessCompanyStructure !== 'other'
              "
              class="ll-item ll-position"
            >
              <div class="tf-label" id="business-label-app-position">
                <label for="cb-business-position"
                  >What role best represents your position in the company?<sup class="fhb-red"
                    >*</sup
                  ></label
                >
              </div>
              <select
                v-if="
                  this.businessCompanyStructure === 'corp' ||
                  this.businessCompanyStructure === 'prof-corp'
                "
                class="ll-select"
                name="company_position"
                id="cb-business-position"
                aria-describedby="cb-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
                required
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_corp"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'partnership' ||
                  this.businessCompanyStructure === 'limited'
                "
                class="ll-select"
                name="company_position"
                id="cb-business-position"
                aria-describedby="cb-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_partnership"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <select
                v-else-if="
                  this.businessCompanyStructure === 'llc' ||
                  this.businessCompanyStructure === 'single-llc'
                "
                class="ll-select"
                name="company_position"
                id="cb-business-position"
                aria-describedby="cb-bus-pos-err"
                v-model="appPosition"
                :class="missingPosition ? 'll-select-error' : 'll-select'"
                required
                @change="validatePosition($event)"
                data-test="cb-app-company-position"
              >
                <option value="">Select Your Position</option>
                <option
                  v-for="pos in company_positions_llc"
                  :value="pos.value"
                  v-bind:key="pos.key"
                >
                  {{ pos.key }}
                </option>
              </select>
              <div id="cb-bus-pos-err" class="ind-err-container">
                <div
                  v-if="missingPosition"
                  class="form-error-msg"
                  data-test="cb-app-company-position-error"
                >
                  <img
                    class="form-error-icon"
                    srcset="
                      @/assets/images/exclaimation-lg.png    1x,
                      @/assets/images/exclaimation-lg-2x.png 2x
                    "
                    src="@/assets/images/exclaimation-lg-2x.png"
                    alt=""
                  />
                  <div role="alert" class="form-error-txt">Please select a position</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img
                class="form-error-icon sys-err-icon"
                srcset="
                  @/assets/images/exclaimation-lg.png    1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                "
                src="@/assets/images/exclaimation-lg.png"
                alt=""
              />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img
                class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png"
                alt=""
              />
              <span class="sr-only">Back</span>
            </button>
            <button
              id="ll-continue-btn"
              type="button"
              :class="'continue-btn'"
              @click="handleContinue()"
              data-test="nav-continue"
            >
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />
        </section>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import Api from "@/app/ll-commercial-api"
import Header from "@/components/Header"
import Validator from "@/app/validation"
import MainId from "@/components/MainId"
import Constants from "@/app/business_constants"
import Footer from "@/components/Footer"
import Breadcrumb_business from "@/views/business/connect_banker/Breadcrumb_business.vue"
import jQuery from "jquery"
import { mapActions, mapGetters } from "vuex"
import Utils from "@/app/utils"
import MoneyInput from "@/components/CustomInputs/MoneyInput.vue"
import PhoneInput from "@/components/CustomInputs/PhoneInput.vue"

export default {
  name: "Personal Info",
  components: {
    Header,
    MainId,
    Footer,
    Breadcrumb_business,
    PhoneInput,
    MoneyInput,
  },
  data() {
    return {
      systemErrorMsg: "",
      use_banker: false,

      company_positions_corp: Constants.CompanyPositionsCorp,
      company_positions_llc: Constants.CompanyPositionsLLC,
      company_positions_partnership: Constants.CompanyPositionsPartnership,

      company_structures: Constants.CompanyStructure,

      errCnt: 0,

      missingLoanAmount: false,
      invalidLoanAmount: false,

      missingFName: false,
      invalidFName: false,

      invalidMName: false,

      missingLName: false,
      invalidLName: false,

      missingEmail: false,
      invalidEmail: false,

      missingPhone: false,
      invalidPhone: false,

      missingBusinessCompanyStructure: false,

      missingPosition: false,

      missingBankerName: false,
      invalidBankerName: false,
    }
  },
  created() {
    this.setCurrentPage(1)
  },
  computed: {
    ...mapGetters("connectToBanker", [
      "debug",
      "currentPage",
      "type",
      "application_id",
      "applicant_preferred_banker_indicator",
      "loan_amount",
    ]),
    ...mapGetters("questionnaire", ["qid"]),
    loanAmt: {
      get() {
        return this.loan_amount
      },
      set(value) {
        this.setLoanAmount(value)
      },
    },
    appFName: {
      get() {
        return this.$store.state.connectToBanker.applicant.fname
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppFName", value)
      },
    },
    appMI: {
      get() {
        return this.$store.state.connectToBanker.applicant.middle_initial
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppMiddleInitial", value)
      },
    },
    appLName: {
      get() {
        return this.$store.state.connectToBanker.applicant.lname
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppLName", value)
      },
    },
    email: {
      get() {
        return this.$store.state.connectToBanker.applicant.email
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppEmail", value)
      },
    },
    appPhone: {
      get() {
        return this.$store.state.connectToBanker.applicant.phone_num
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppPhoneNum", value)
      },
    },
    businessCompanyStructure: {
      get() {
        return this.$store.state.connectToBanker.business_company_structure
      },
      set(value) {
        this.$store.commit("connectToBanker/setBusinessCompanyStructure", value)
      },
    },
    appPosition: {
      get() {
        return this.$store.state.connectToBanker.applicant.company_position
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppCompanyPosition", value)
      },
    },
    appCurrPage: {
      get() {
        return this.$store.state.connectToBanker.currentPage
      },
      set(value) {
        this.$store.commit("connectToBanker/setCurrentPage", value)
      },
    },
    appPreferredBanker: {
      get() {
        return this.$store.state.connectToBanker.applicant.preferred_banker_name
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppPreferredBankerName", value)
      },
    },
    appPreferredBankerIndicator: {
      get() {
        return this.$store.state.connectToBanker.applicant.preferred_banker_indicator
      },
      set(value) {
        this.$store.commit("connectToBanker/setAppBankerIndicator", value)
      },
    },
  },
  mounted() {
    this.setType("commercial-loan")
    jQuery("html, body").animate({ scrollTop: 0 })
    this.use_banker = this.applicant_preferred_banker_indicator ? true : false
  },
  methods: {
    ...mapActions("connectToBanker", [
      "setCurrentPage",
      "setJWTToken",
      "setType",
      "setLoanAmount",
      "setAppFirstName",
      "setAppBankerIndicator",
      "setAppPreferredBankerName",
      "setAppMiddleInitial",
      "setAppLastName",
      "setAppEmail",
      "setAppPhoneNumber",
      "setAppCompanyPosition",
      "setAppAgreeToTerms",
      "setApplicationID",
      "setValidatedNavPage",
    ]),
    formatMoneyFromString(mon) {
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
    async postPageBanker(payload) {
      this.systemErrorMsg = ""
      this.systemErrorArray = []

      const resp = await Api.postPageBanker(payload)
      console.log("--- response ---")
      console.log(resp)

      if (resp && resp.data) {
        if (resp.data.status == "success") {
          this.setJWTToken(resp.data.access_token)

          if (resp.data.response) {
            this.setApplicationID(resp.data.response.application_id)
          }
          // update breadcrumb state
          this.setValidatedNavPage({ name: "personal", isValid: 1 })

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400))

          this.$router.push({ name: "Business Information" })

          Utils.removeProcessingMask("#ll-continue-btn")
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message
          }

          if (
            resp.data.response.application_errors &&
            resp.data.response.application_errors.length > 0
          ) {
            for (const x of resp.data.response.application_errors) {
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "Getting Started" })
    },
    handleContinue() {
      Utils.addProcessingMask("#ll-continue-btn")

      this.validatePage()

      this.$nextTick(() => {
        this.errCnt = jQuery(".form-error-txt").length

        if (!this.errCnt) {
          const payload = {
            page: this.currentPage,
            loan_amount: this.formatMoneyFromString(this.loanAmt),
            questionnaire_id: this.qid,
            first_name: this.appFName,
            middle_initial: this.appMI,
            last_name: this.appLName,
            email: this.email,
            phone_number: this.appPhone ? Utils.formatPhoneNumber(this.appPhone) : "",
            business_structure: this.businessCompanyStructure,
            company_role: this.appPosition,
            preferred_banker_indicator: this.appPreferredBankerIndicator,
            preferred_banker_name: this.appPreferredBanker,
          }

          this.postPageBanker(payload)
        } else {
          this.setValidatedNavPage({ name: "personal", isValid: 0 })

          if (this.errCnt > 1) {
            jQuery("html, body").animate({
              scrollTop: jQuery("#commercial-page-errors").offset().top - 20,
            })
          } else {
            let offset = 0

            if (this.missingLoanAmount || this.invalidLoanAmount)
              offset = jQuery("#business-label-loan-amount").offset().top
            if (this.missingFName || this.invalidFName)
              offset = jQuery("#business-label-app-fname").offset().top
            if (this.invalidMName) offset = jQuery("#business-label-app-mi").offset().top
            if (this.missingLName || this.invalidLName)
              offset = jQuery("#business-label-app-lname").offset().top
            if (this.missingEmail || this.invalidEmail)
              offset = jQuery("#business-label-app-email").offset().top
            if (this.missingPhone || this.invalidPhone)
              offset = jQuery("#business-label-app-phone").offset().top
            if (this.missingBusinessCompanyStructure)
              offset = jQuery("#business-label-company-structure").offset().top
            if (this.missingPosition) offset = jQuery("#business-label-app-position").offset().top
            if (this.missingBankerName || this.invalidBankerName)
              offset = jQuery("#business-label-app-banker-name").offset().top

            jQuery("html, body").animate({ scrollTop: offset })
          }
          // remove processing
          Utils.removeProcessingMask("#ll-continue-btn")
        }
      })
    },
    validatePage() {
      this.validateLoanAmount
      this.validateFName()
      this.validateMI()
      this.validateLName()
      this.validateEmail()
      this.validatePhone()
      this.validateBusinessCompanyStructure()
      this.validatePosition()
      if (this.use_banker) {
        this.validatePreferredBankerName()
      }
    },
    validateLoanAmount() {
      this.missingLoanAmount = this.loanAmt ? false : true
      if (!this.missingLoanAmount) {
        this.invalidLoanAmount = Validator.isValidMoney(this.loanAmt) ? false : true
      }
    },
    validateFName() {
      this.appFName = this.appFName.trim()
      this.missingFName = this.appFName ? false : true
      if (!this.missingFName) {
        this.invalidFName = Validator.isValidName(this.appFName) ? false : true
      }
    },
    validateMI() {
      this.appMI = this.appMI.trim()
      if (this.appMI) {
        this.invalidMI = Validator.isValidName(this.appMI) ? false : true
      }
    },
    validateLName() {
      this.appLName = this.appLName.trim()
      this.missingLName = this.appLName ? false : true
      if (!this.missingLName) {
        this.invalidLName = Validator.isValidName(this.appLName) ? false : true
      }
    },
    validateEmail() {
      this.email = this.email.trim()
      this.missingEmail = this.email ? false : true
      if (!this.missingEmail) {
        this.invalidEmail = Validator.isValidEmail(this.email) ? false : true
      }
    },
    validatePhone() {
      this.missingPhone = this.appPhone ? false : true
      if (!this.missingPhone) {
        this.invalidPhone = Validator.isValidPhoneNumber(this.appPhone) ? false : true
      }
    },
    validateBusinessCompanyStructure() {
      this.businessCompanyStructure = this.businessCompanyStructure.trim()
      this.missingBusinessCompanyStructure = this.businessCompanyStructure ? false : true
      if (!this.missingBusinessCompanyStructure && this.appPosition) {
        if (this.businessCompanyStructure == "sole" || this.businessCompanyStructure == "other")
          this.appPosition = ""
        this.validatePosition()
      }
    },
    validatePosition() {
      this.missingPosition = false
      if (
        this.businessCompanyStructure &&
        this.businessCompanyStructure != "sole" &&
        this.businessCompanyStructure != "other"
      ) {
        this.missingPosition = !this.appPosition || this.appPosition == "" ? true : false
      }
    },
    validateAgree() {
      let cb = jQuery("#consent-cb")
      if (cb.prop("checked") == true) {
        this.appAgree = true
        this.agree = true
        this.missingAgree = false
      } else if (cb.prop("checked") == false) {
        this.appAgree = false
        this.agree = false
        this.missingAgree = true
      }
    },
    handleUseBanker() {
      let cb = jQuery("#use-banker")
      if (cb.prop("checked") == true) {
        this.use_banker = true
        this.$store.commit("connectToBanker/setAppBankerIndicator", true)
      } else if (cb.prop("checked") == false) {
        this.use_banker = false
        this.$store.commit("connectToBanker/setAppBankerIndicator", false)
      }
    },
    validatePreferredBankerName() {
      this.appPreferredBanker = this.appPreferredBanker.trim()
      this.missingBankerName = this.appPreferredBanker ? false : true
      if (!this.missingBankerName) {
        this.invalidBankerName = Validator.isValidName(this.appPreferredBanker) ? false : true
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace("https://fhb.com")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.ll-fname,
.ll-lname {
  @include media-breakpoint-up(md) {
    flex-basis: calc(50% - 4em - 100px);
  }

  @include media-breakpoint-down(md) {
    flex-basis: calc(100% - 180px - 2em);
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
  }
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.continue-btn-disabled {
  background: $grayVlight;
  color: $grayMid;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}
</style>
