const loansBusinessURLs = {
  staging: "https://staging.loans.fhb-eng.com/business",
  prod: "https://loans.fhb.com/business",
}

const commercialURLs = {
  staging: "https://staging.loans-business.fhb-eng.com",
  prod: "https://loans-business.fhb.com",
}

export default {
  getLoansBusinessURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return loansBusinessURLs.staging
    } else if (window.location.href.indexOf("staging.") > -1) {
      return loansBusinessURLs.staging
    } else if (window.location.href.indexOf("localhost") > -1) {
      return loansBusinessURLs.staging
    } else {
      return loansBusinessURLs.prod
    }
  },
  getCommercialURL() {
    if (window.location.href.indexOf("dev.") > -1) {
      return commercialURLs.staging
    } else if (window.location.href.indexOf("staging.") > -1) {
      return commercialURLs.staging
    } else if (window.location.href.indexOf("localhost") > -1) {
      return commercialURLs.staging
    } else {
      return commercialURLs.prod
    }
  },
}
